@charset "UTF-8";
/*---------------------------------------*/
/*--------   Table of contents   --------*/
/*
1. Variable
2. Mixin
3. External module
	3.1 Bootstrap
	3.3 Slider Slick
	3.3 Magnific popup
	3.4 Slider price
	3.5 datepicker
	3.6 perfect scrollbar
3. Fonts icons
4. Typography
5. Header
6. Content layout
7. Modal
8. Back To Top
9. breadcrumbs
10. Blog
11. Footer
---------------------------------------*/
/*---------------------------------------*/
/*--------   3. External module  --------*/
/*---------------------------------------*/
/*-------  3.1 Bootstrap  --------*/
/*!
 * Bootstrap v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}

@-ms-viewport {
  width: device-width;
}

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
}

p {
  margin-top: 0;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}
.pb-40{
  padding-bottom: 40px;
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 576px;
  }
}

@media (min-width: 767px) {
  .container {
    max-width: 767px;
  }
}

@media (min-width: 1025px) {
  .container {
    max-width: 990px;
  }
}

@media (min-width: 1230px) {
  .container {
    max-width: 1200px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 767px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1025px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1230px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px);
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
.input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text,
.input-group-sm > .input-group-append > .form-control-plaintext.input-group-text,
.input-group-sm > .input-group-prepend > .form-control-plaintext.btn,
.input-group-sm > .input-group-append > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
.input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text,
.input-group-lg > .input-group-append > .form-control-plaintext.input-group-text,
.input-group-lg > .input-group-prepend > .form-control-plaintext.btn,
.input-group-lg > .input-group-append > .form-control-plaintext.btn {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0;
}

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px);
}

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0;
}

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px);
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: .2rem;
}

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745;
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
.custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip, .was-validated
.custom-select:valid ~ .valid-feedback,
.was-validated
.custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  background-color: #71dd8a;
}

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
  border-color: inherit;
}

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: .2rem;
}

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
.custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip, .was-validated
.custom-select:invalid ~ .invalid-feedback,
.was-validated
.custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  background-color: #efa2a9;
}

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
  border-color: inherit;
}

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn:hover, .btn:focus {
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
  background-image: none;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  background-color: transparent;
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  border-color: transparent;
  box-shadow: none;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
}

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file:focus {
  z-index: 3;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::before {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label,
.input-group > .custom-file:not(:first-child) .custom-file-label::before {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.modal-open {
  overflow: hidden;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 25;
  display: none;
  overflow: hidden;
  outline: 0;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  // transition: transform 0.3s ease-out;
  // transform: translate(0, -25%);
}

.modal.show .modal-dialog {
  transform: translate(0, 0);
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (10px * 2));
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: transparent;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 24;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.modal-header .close {
  padding: 0;
  margin: 0 0 0 auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 15px;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px;
  border-top: 1px solid #e9ecef;
}

.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 30px auto;
  }
  .modal-dialog-centered {
    min-height: calc(100% - (30px * 2));
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 1025px) {
  .modal-lg {
    max-width: 800px;
  }
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded {
  border-radius: 0 !important;
}

.rounded-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-right {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-left {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 767px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1025px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1230px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 767px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1025px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1230px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 767px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 1025px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1230px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 23;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 23;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 22;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 767px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1025px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1230px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 767px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 1025px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1230px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0062cc !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #545b62 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #1e7e34 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #bd2130 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 1025px !important;
  }
  .container {
    min-width: 1025px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important;
  }
}

/*-------  3.2 Slider Slick  --------*/
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
  width: 100%;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

/*-------  3.3 Magnific popup  --------*/
/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 21;
  overflow: hidden;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 22;
  position: fixed;
  outline: none;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 20px 8px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 20;
}

.mfp-inline-holder .mfp-content, .mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom {
  cursor: pointer;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close, .mfp-arrow,
.mfp-preloader, .mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none;
}

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 20;
}

.mfp-preloader a {
  color: #cccccc;
}

.mfp-preloader a:hover {
  color: #ffffff;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 21;
  -webkit-box-shadow: none;
  box-shadow: none;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: -43px;
  top: -44px;
  text-decoration: none;
  text-align: center;
  padding: 0 0 18px 10px;
  color: #ffffff;
  font-size: 0px;
  line-height: 0;
  transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}

.mfp-close:before {
  font-family: "carleader";
  content: "\e933";
  font-size: 18px;
  line-height: 1;
}

.mfp-close:hover {
  color: #dd3d53;
}

.mfp-counter {
  top: 0;
  right: 0;
  color: #ffffff;
  font-size: 14px;
  line-height: 18px;
  font-weight: lighter;
  white-space: nowrap;
}

button.mfp-arrow {
  position: absolute;
  margin: 0;
  top: 50%;
  margin-top: -25px;
  padding: 0;
  width: 50px;
  height: 50px;
  transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1;
  filter: alpha(opacity=100);
}

.mfp-arrow:before,
.mfp-arrow:after,
.mfp-arrow .mfp-b,
.mfp-arrow .mfp-a {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  border: medium inset transparent;
}

.mfp-arrow {
  display: block;
  font-family: "carleader";
  font-size: 30px;
  line-height: 45px;
  color: #ffffff;
  opacity: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mfp-arrow:hover,
button.mfp-arrow:hover {
  color: #dd3d53;
}

.mfp-arrow:before {
  display: block;
}

button.mfp-arrow-right,
button.mfp-arrow-left {
  position: absolute;
  top: 50%;
  background: none;
  border: none;
  outline: none;
  display: inline-block;
  text-align: center;
  width: 49px;
  height: 49px;
  border: 1px solid #ffffff;
  font-size: 0;
  line-height: 0;
  cursor: pointer;
  margin-top: -44px;
  color: #ffffff;
  transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}

button.mfp-arrow-right:before,
button.mfp-arrow-left:before {
  font-family: "carleader";
  font-size: 15px;
  line-height: 2.75;
  margin-left: 13px;
}

button.mfp-arrow-right:hover,
button.mfp-arrow-left:hover {
  border-color: #dd3d53;
  color: #dd3d53;
}

.mfp-arrow-right {
  left: 20px;
}

.mfp-arrow-right:before {
  content: '\e916';
}

.mfp-arrow-left {
  right: 20px;
}

.mfp-arrow-left:before {
  content: '\e917';
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  background: #444;
}

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -21px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
  text-align: center;
  font-family: "Montserrat", sans-serif;
}

.mfp-title {
  color: #fff;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  word-wrap: break-word;
}

.mfp-image-holder .mfp-content {
  max-width: 72%;
}

.mfp-gallery img.mfp-img {
  max-height: 80vh !important;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
    * Remove all paddings around the image on small screen
    */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}

.mfp-ie7 .mfp-img {
  padding: 0;
}

.mfp-ie7 .mfp-bottom-bar {
  width: 600px;
  left: 50%;
  margin-left: -300px;
  margin-top: 5px;
  padding-bottom: 5px;
}

.mfp-ie7 .mfp-container {
  padding: 0;
}

.mfp-ie7 .mfp-content {
  padding-top: 44px;
}

.mfp-ie7 .mfp-close {
  top: 0;
  right: 0;
  padding-top: 0;
}

body.mfp-zoom-out-cur #tt-stuck.stuck {
  padding-right: 17px;
}

/*-------  3.4 Slider price  --------*/
/*! nouislider - 11.1.0 - 2018-04-02 11:18:13 */
/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-target {
  position: relative;
  direction: ltr;
}

.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

/* Wrapper for all connect elements.
 */
.noUi-connects {
  overflow: hidden;
  z-index: 0;
}

.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  margin-left: 2px;
  height: 100%;
  width: 100%;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

/* Offset direction
 */
html:not([dir="rtl"]) .noUi-horizontal .noUi-origin {
  left: auto;
  right: 0;
}

/* Give origins 0 height/width so they don't interfere with clicking the
 * connect elements.
 */
.noUi-vertical .noUi-origin {
  width: 0;
}

.noUi-horizontal .noUi-origin {
  height: 0;
}

.noUi-handle {
  position: absolute;
}

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 3px;
  cursor: pointer;
}

.noUi-horizontal .noUi-handle {
  width: 15px;
  height: 15px;
  left: -9px;
  top: -6px;
  outline: none;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  cursor: pointer;
}

.noUi-horizontal .noUi-handle:before {
  content: '';
  position: absolute;
  display: block;
  top: 2px;
  left: 2px;
  width: 9px;
  height: 9px;
  background-color: #dd3d53;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
}

.noUi-vertical {
  width: 18px;
}

.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  left: -6px;
  top: -17px;
}

html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
  right: -17px;
  left: auto;
}

/* Styling;
 * Giving the connect element a border radius causes issues with using transform: scale
 */
.noUi-target {
  background: #ebebeb;
}

.noUi-connect {
  background: #dd3d53;
}

/* Handles and cursors;
 */
.noUi-draggable {
  cursor: ew-resize;
}

.noUi-vertical .noUi-draggable {
  cursor: ns-resize;
}

.noUi-handle {
  border: 1px solid #D9D9D9;
  border-radius: 50%;
  background: #ffffff;
  cursor: default;
}

/* Handle stripes;
 */
/* Disabled state;
 */
[disabled] .noUi-connect {
  background: #B8B8B8;
}

[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
  cursor: not-allowed;
}

/* Base;
 *
 */
.noUi-pips,
.noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-pips {
  position: absolute;
  color: #999;
}

/* Values;
 *
 */
.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center;
}

.noUi-value-sub {
  color: #ccc;
  font-size: 10px;
}

/* Markings;
 *
 */
.noUi-marker {
  position: absolute;
  background: #CCC;
}

.noUi-marker-sub {
  background: #AAA;
}

.noUi-marker-large {
  background: #AAA;
}

/* Horizontal layout;
 *
 */
.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%;
}

.noUi-value-horizontal {
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
}

.noUi-rtl .noUi-value-horizontal {
  -webkit-transform: translate(50%, 50%);
  transform: translate(50%, 50%);
}

.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px;
}

.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px;
}

.noUi-marker-horizontal.noUi-marker-large {
  height: 15px;
}

/* Vertical layout;
 *
 */
.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%;
}

.noUi-value-vertical {
  -webkit-transform: translate(0, -50%);
  transform: translate(-50%, 0);
  padding-left: 25px;
}

.noUi-rtl .noUi-value-vertical {
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%);
}

.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px;
}

.noUi-marker-vertical.noUi-marker-sub {
  width: 10px;
}

.noUi-marker-vertical.noUi-marker-large {
  width: 15px;
}

.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
  white-space: nowrap;
}

.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%;
}

.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 120%;
}

.tt-slider-price {
  position: relative;
}

.tt-slider-price .slider-value-row {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-top: 24px;
}

.tt-slider-price .slider-value-row .slider-value {
  width: calc(50% - 3px);
  height: 44px;
  padding: 3px 4px 1px;
  line-height: 35px;
  text-align: center;
  border: 1px solid #dfdfdf;
  color: #222222;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0px 0px 1px #dfdfdf;
  -moz-box-shadow: 0px 0px 1px #dfdfdf;
  box-shadow: 0px 0px 1px #dfdfdf;
}

.tt-slider-price .slider-value-row .slider-value:not(:first-child) {
  margin-left: 6px;
}

/*-------  3.5 datepicker  --------*/
/*!
 * Datepicker for Bootstrap
 *
 * Copyright 2012 Stefan Petre
 * Improvements by Andrew Rowls
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 */
.datepicker {
  padding: 0px;
  margin-top: 0px;
  direction: ltr;
}

.datepicker-inline {
  width: 100%;
}

.datepicker.datepicker-rtl {
  direction: rtl;
}

.datepicker.datepicker-rtl table tr td span {
  float: right;
}

.datepicker-dropdown {
  top: 0;
  left: 0;
}

.datepicker-dropdown:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: -7px;
  left: 6px;
}

.datepicker-dropdown:after {
  content: '';
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
  position: absolute;
  top: -6px;
  left: 7px;
}

.datepicker > div {
  display: none;
}

.datepicker th {
  font-weight: 400;
}

.datepicker.days div.datepicker-days {
  display: block;
}

.datepicker.months div.datepicker-months {
  display: block;
}

.datepicker.years div.datepicker-years {
  display: block;
}

.datepicker table {
  margin: 0;
}

.table-striped .datepicker table tr td,
.table-striped .datepicker table tr th {
  background-color: transparent;
}

.datepicker table {
  width: 100%;
}

.datepicker table tr td.day:hover {
  cursor: pointer;
}

.datepicker table tr td.old,
.datepicker table tr td.new {
  color: #222222;
}

.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
  background: none;
  color: #222222;
  cursor: default;
}

.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover {
  background-color: #fde19a;
  border-color: #fdf59a #fdf59a #fbed50;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
}

.datepicker table tr td.today:hover,
.datepicker table tr td.today:hover:hover,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today.disabled:hover:hover,
.datepicker table tr td.today:active,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:hover.active,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today:hover.disabled,
.datepicker table tr td.today.disabled.disabled,
.datepicker table tr td.today.disabled:hover.disabled,
.datepicker table tr td.today[disabled],
.datepicker table tr td.today:hover[disabled],
.datepicker table tr td.today.disabled[disabled],
.datepicker table tr td.today.disabled:hover[disabled] {
  color: #dd3d53;
}

.datepicker table tr td.today:active,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:hover.active {
  color: #dd3d53 \9;
}

.datepicker table tr td.active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover {
  color: #dd3d53;
}

.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active:hover.disabled,
.datepicker table tr td.active.disabled.disabled,
.datepicker table tr td.active.disabled:hover.disabled,
.datepicker table tr td.active[disabled],
.datepicker table tr td.active:hover[disabled],
.datepicker table tr td.active.disabled[disabled],
.datepicker table tr td.active.disabled:hover[disabled] {
  color: #dd3d53;
}

.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active {
  color: #dd3d53 \9;
}

.datepicker table tr td span {
  display: block;
  width: 23%;
  height: 54px;
  line-height: 54px;
  float: left;
  margin: 1%;
  cursor: pointer;
}

.datepicker table tr td span:hover {
  color: #dd3d53;
}

.datepicker table tr td span.disabled,
.datepicker table tr td span.disabled:hover {
  background: none;
  color: #222222;
  cursor: default;
}

.datepicker table tr td span.active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover {
  background-color: #dd3d53;
  color: #fff;
}

.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active:hover.disabled,
.datepicker table tr td span.active.disabled.disabled,
.datepicker table tr td span.active.disabled:hover.disabled,
.datepicker table tr td span.active[disabled],
.datepicker table tr td span.active:hover[disabled],
.datepicker table tr td span.active.disabled[disabled],
.datepicker table tr td span.active.disabled:hover[disabled] {
  background-color: #dd3d53;
}

.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active {
  background-color: #003399 \9;
}

.datepicker table tr td span.old {
  color: #222222;
}

.datepicker th.switch {
  width: 145px;
}

.datepicker thead tr:first-child th,
.datepicker tfoot tr:first-child th {
  cursor: pointer;
}

.datepicker thead tr:first-child th:hover,
.datepicker tfoot tr:first-child th:hover {
  color: #dd3d53;
}

.input-append.date .add-on i,
.input-prepend.date .add-on i {
  display: block;
  cursor: pointer;
  width: 16px;
  height: 16px;
}

.datepicker td,
.datepicker th {
  text-align: center;
  width: 14%;
  height: 20px;
  line-height: 1.2;
  border: none;
  padding: 9px 3px 10px;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  font-weight: 300;
}

.datepicker thead tr:first-child th {
  padding: 9px 3px 7px;
}

.datepicker thead tr:first-child {
  color: #222222;
}

.datepicker thead tr:first-child th {
  color: #222222;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  text-transform: uppercase;
}

.datepicker thead th.dow {
  color: #222222;
  background-color: #f5f5f5;
  font-family: "Open Sans", sans-serif;
  text-transform: uppercase;
}

.datepicker thead .icon-arrow-right,
.datepicker thead .icon-arrow-left {
  display: block;
  font-family: "carleader";
  font-size: 14px;
  line-height: 1;
  color: #222222;
  opacity: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.datepicker thead .icon-arrow-right:before {
  content: "\e917";
}

.datepicker thead .icon-arrow-left:before {
  content: "\e916";
}

.datepicker thead th.next:hover i,
.datepicker thead th.prev:hover i {
  color: #dd3d53;
}

.datepicker table {
  border-spacing: 1px;
  border-collapse: separate;
}

.datepicker th.prev {
  text-align: left;
}

.datepicker th.next {
  text-align: right;
}

.datepicker td {
  border: 1px solid #f6f6f6;
}

.datepicker td.day.active {
  background-color: #dd3d53;
  color: #ffffff !important;
  border-color: #dd3d53;
}

/*-------  3.6 perfect scrollbar  --------*/
/* perfect-scrollbar v0.6.10 */
.ps-container {
  -ms-touch-action: none;
  touch-action: none;
  overflow: hidden !important;
  -ms-overflow-style: none;
}

@supports (-ms-overflow-style: none) {
  .ps-container {
    overflow: auto !important;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps-container {
    overflow: auto !important;
  }
}

.ps-container.ps-active-x > .ps-scrollbar-x-rail, .ps-container.ps-active-y > .ps-scrollbar-y-rail {
  display: block;
  background-color: transparent;
}

.ps-container.ps-in-scrolling {
  pointer-events: none;
}

.ps-container.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
  background-color: #eee;
  opacity: 0.9;
}

.ps-container.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
  background-color: #999;
}

.ps-container.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
  background-color: #eee;
  opacity: 0.9;
}

.ps-container.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
  background-color: #999;
}

.ps-container > .ps-scrollbar-x-rail {
  display: none;
  position: absolute;
  /* please don't change 'position' */
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  opacity: 0;
  -webkit-transition: background-color .2s linear, opacity .2s linear;
  -moz-transition: background-color .2s linear, opacity .2s linear;
  -o-transition: background-color .2s linear, opacity .2s linear;
  transition: background-color .2s linear, opacity .2s linear;
  bottom: 3px;
  /* there must be 'bottom' for ps-scrollbar-x-rail */
  height: 8px;
}

.ps-container > .ps-scrollbar-x-rail > .ps-scrollbar-x {
  position: absolute;
  /* please don't change 'position' */
  background-color: #aaa;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: background-color .2s linear;
  -moz-transition: background-color .2s linear;
  -o-transition: background-color .2s linear;
  transition: background-color .2s linear;
  bottom: 0;
  /* there must be 'bottom' for ps-scrollbar-x */
  height: 8px;
}

.ps-container > .ps-scrollbar-y-rail {
  display: none;
  position: absolute;
  z-index: 21;
  /* please don't change 'position' */
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  opacity: 0;
  -webkit-transition: background-color .2s linear, opacity .2s linear;
  -moz-transition: background-color .2s linear, opacity .2s linear;
  -o-transition: background-color .2s linear, opacity .2s linear;
  transition: background-color .2s linear, opacity .2s linear;
  right: 3px;
  /* there must be 'right' for ps-scrollbar-y-rail */
  width: 8px;
}

.ps-container > .ps-scrollbar-y-rail > .ps-scrollbar-y {
  position: absolute;
  /* please don't change 'position' */
  background-color: #aaa;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: background-color .2s linear;
  -moz-transition: background-color .2s linear;
  -o-transition: background-color .2s linear;
  transition: background-color .2s linear;
  right: 0;
  /* there must be 'right' for ps-scrollbar-y */
  width: 5px;
}

.ps-container:hover.ps-in-scrolling {
  pointer-events: none;
}

.ps-container:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
  background-color: #eee;
  opacity: 0.9;
}

.ps-container:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
  background-color: #999;
}

.ps-container:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
  background-color: #eee;
  opacity: 0.9;
}

.ps-container:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
  background-color: #999;
}

.ps-container:hover > .ps-scrollbar-x-rail, .ps-container:hover > .ps-scrollbar-y-rail {
  opacity: 0.6;
}

.ps-container:hover > .ps-scrollbar-x-rail:hover {
  background-color: #eee;
  opacity: 0.9;
}

.ps-container:hover > .ps-scrollbar-x-rail:hover > .ps-scrollbar-x {
  background-color: #999;
}

.ps-container:hover > .ps-scrollbar-y-rail:hover {
  background-color: #eee;
  opacity: 0.9;
}

.ps-container:hover > .ps-scrollbar-y-rail:hover > .ps-scrollbar-y {
  background-color: #999;
}

/*---------------------------------------*/
/*--------     3. Fonts icons    --------*/
/*---------------------------------------*/
@font-face {
  font-family: 'carleader';
  src: url("/fonts/carleader.eot?ig0chb");
  src: url("/fonts/carleader.eot?ig0chb#iefix") format("embedded-opentype"), url("/fonts/carleader.ttf?ig0chb") format("truetype"), url("/fonts/carleader.woff?ig0chb") format("woff"), url("/fonts/carleader.svg?ig0chb#carleader") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'carleader' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-5:before {
  content: "\e938";
}

.icon-618867:before {
  content: "\e939";
}

.icon-tools:before {
  content: "\e93a";
}

.icon-y1:before {
  content: "\e93b";
}

.icon-y2:before {
  content: "\e93c";
}

.icon-y3:before {
  content: "\e93d";
}

.icon-y4:before {
  content: "\e93e";
}

.icon-y5:before {
  content: "\e93f";
}

.icon-1:before {
  content: "\e940";
}

.icon-2:before {
  content: "\e941";
}

.icon-3:before {
  content: "\e942";
}

.icon-4:before {
  content: "\e943";
}

.icon-handsshake:before {
  content: "\e900";
}

.icon-icon:before {
  content: "\e901";
}

.icon-mail:before {
  content: "\e902";
}

.icon-15874:before {
  content: "\e903";
}

.icon-user:before {
  content: "\e904";
}

.icon-cart:before {
  content: "\e905";
}

.icon-addcar:before {
  content: "\e906";
}

.icon-mail1:before {
  content: "\e907";
}

.icon-menu:before {
  content: "\e908";
}

.icon-musica-searcher:before {
  content: "\e909";
}

.icon-car-washing-machine:before {
  content: "\e90a";
}

.icon-financing:before {
  content: "\e90b";
}

.icon-carsearch2:before {
  content: "\e90c";
}

.icon-payment:before {
  content: "\e90d";
}

.icon-tradein:before {
  content: "\e90e";
}

.icon-tradein2:before {
  content: "\e90f";
}

.icon-testdrive:before {
  content: "\e910";
}

.icon-photo-camera:before {
  content: "\e911";
}

.icon-compare:before {
  content: "\e912";
}

.icon-play:before {
  content: "\e913";
}

.icon-price-tag1:before {
  content: "\e914";
}

.icon-refresh-button:before {
  content: "\e915";
}

.icon-left-arrow:before {
  content: "\e916";
}

.icon-right-arrow:before {
  content: "\e917";
}

.icon-right-arrow1:before {
  content: "\e918";
}

.icon-road:before {
  content: "\e919";
}

.icon-soldout:before {
  content: "\e91a";
}

.icon-star:before {
  content: "\e91b";
}

.icon-user1:before {
  content: "\e91c";
}

.icon-vehicles:before {
  content: "\e91d";
}

.icon-report:before {
  content: "\e91e";
}

.icon-price-tag:before {
  content: "\e91f";
}

.icon-awards:before {
  content: "\e920";
}

.icon-customers:before {
  content: "\e921";
}

.icon-staff:before {
  content: "\e922";
}

.icon-3665:before {
  content: "\e923";
}

.icon-8800:before {
  content: "\e924";
}

.icon-226234:before {
  content: "\e925";
}

.icon-733613:before {
  content: "\e926";
}

.icon-84466:before {
  content: "\e927";
}

.icon-546419:before {
  content: "\e928";
}

.icon-130415:before {
  content: "\e929";
}

.icon-149984:before {
  content: "\e92a";
}

.icon-arrowdown:before {
  content: "\e92b";
}

.icon-arrowup:before {
  content: "\e92c";
}

.icon-arrowsdown:before {
  content: "\e92d";
}

.icon-arrowsup:before {
  content: "\e92e";
}

.icon-attachment:before {
  content: "\e92f";
}

.icon-calculator:before {
  content: "\e930";
}

.icon-carsearch:before {
  content: "\e931";
}

.icon-check-mark:before {
  content: "\e932";
}

.icon-close:before {
  content: "\e933";
}

.icon-done-tick:before {
  content: "\e934";
}

.icon-filer:before {
  content: "\e935";
}

.icon-grid:before {
  content: "\e936";
}

.icon-listing:before {
  content: "\e937";
}

.icon-pause:before {
  content: "\ea16";
}

/*---------------------------------------*/
/*-------      4. Typography        -----*/
/*---------------------------------------*/
@-webkit-keyframes fade-in {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fade-in {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fade-in {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

body {
  font-family: "Open Sans", sans-serif;
  color: #707070;
  background: #ffffff;
  margin: 0;
  overflow-x: hidden;
  font-size: 15px;
  line-height: 24px;
  -webkit-animation: fade-in 1s linear;
  -moz-animation: fade-in 1s linear;
  -o-animation: fade-in 1s linear;
  animation: fade-in 1s linear;
}

@media (max-width: 575px) {
  body {
    font-size: 14px;
    line-height: 20px;
  }
}

img {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  outline: none;
  color: #707070;
}

a:hover {
  outline: none;
  text-decoration: none;
  outline: none;
  color: #707070;
}

a:active,
a:visited,
a:focus {
  text-decoration: none;
  outline: none;
}

.btn {
  background: #dd3d53;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  border: none;
  font-size: 11px;
  line-height: 1.1;
  font-weight: 600;
  position: relative;
  outline: none;
  padding: 4px 24px 4px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 46px;
  cursor: pointer;
  -webkit-box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}

.btn:hover {
  background: #222222;
  color: #ffffff;
  outline: none;
}

.btn:active, .btn:visited, .btn:focus, .btnbutton:focus {
  outline: none;
  -webkit-box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
}

.btn:not(.tt-icon-right) [class^="icon-"] {
  font-size: 18px;
  line-height: 1.2;
  margin-right: 6px;
}

.btn.btn-wide {
  padding-left: 38px;
  padding-right: 38px;
}

.btn.btn-color02 {
  background: #ffffff;
  color: #222222;
}

.btn.btn-color02:hover {
  background: #222222;
  color: #ffffff;
}

.btn.btn-fullwidth {
  width: 100%;
}

.btn.btn-xl {
  height: 49px;
  font-size: 14px;
  padding-left: 40px;
  padding-right: 40px;
}

.btn.btn-xl [class^="icon-"] {
  font-size: 20px;
  line-height: 1.2;
  margin-right: 11px;
  position: relative;
  top: -1px;
}

@media (max-width: 1024px) {
  .btn.btn-xl {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (max-width: 767px) {
  .btn.btn-xl {
    padding-left: 30px;
    padding-right: 30px;
  }
  .btn.btn-xl [class^="icon-"] {
    font-size: 18px;
  }
}

@media (max-width: 575px) {
  .btn.btn-xl {
    padding-left: 25px;
    padding-right: 25px;
  }
  .btn.btn-xl [class^="icon-"] {
    font-size: 18px;
  }
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

p {
  padding: 0 0 12px 0;
}

@media (max-width: 1024px) {
  p {
    padding-bottom: 17px;
    margin-bottom: 0;
  }
}

@media (max-width: 575px) {
  p {
    padding-bottom: 30px;
    margin-bottom: 0;
  }
}

hr {
  border: none;
  width: 100%;
  height: 1px;
  display: block;
  background-color: #dfdfdf;
  padding: 0;
}

@media (min-width: 1025px) {
  hr {
    margin: 60px 0 53px 0;
  }
}

@media (max-width: 1024px) {
  hr {
    margin: 40px 0 33px 0;
  }
}

textarea,
input[type="text"],
input[type="button"],
input[type="submit"] {
  -webkit-appearance: none;
  border-radius: 0;
}

/*
    tt-block-title
*/
.tt-title span{
font-weight: inherit;
font-family: inherit;

}
.tt-block-title {
  text-align: center;
  font-family: "Montserrat", sans-serif;
  position: relative;
}

.tt-block-title .tt-title {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  line-height: 1.2;
  margin: 0;
  padding: 0px;
  color: #222222;
}

.tt-block-title .tt-title:first-child {
  margin-top: -4px;
}

.tt-block-title .tt-description {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.3;
  color: #dd3d53;
  text-transform: uppercase;
  margin-top: 7px;
}

.tt-block-title.tt-title-custom .tt-description {
  color: #222222;
  text-transform: inherit;
  font-size: 18px;
}

@media (min-width: 1025px) {
  .tt-block-title {
    padding-bottom: 45px;
  }
  .tt-block-title .tt-title {
    font-size: 36px;
  }
  .tt-block-title .tt-description {
    font-size: 14px;
  }
  .tt-block-title.tt-sub-pages {
    padding-bottom: 56px;
  }
}

@media (max-width: 1024px) and (min-width: 576px) {
  .tt-block-title {
    padding-bottom: 32px;
  }
  .tt-block-title .tt-title {
    font-size: 30px;
  }
  .tt-block-title .tt-description {
    font-size: 12px;
  }
  .tt-block-title.tt-sub-pages {
    padding-bottom: 46px;
  }
}

@media (max-width: 575px) {
  .tt-block-title {
    padding-bottom: 27px;
  }
  .tt-block-title .tt-title {
    font-size: 24px;
    line-height: 1.1;
  }
  .tt-block-title .tt-description {
    font-size: 11px;
    padding-bottom: 4px;
  }
  .tt-block-title.tt-sub-pages {
    padding-bottom: 41px;
  }
  .tt-block-title.tt-title-custom .tt-title {
    font-size: 24px;
    line-height: 30px;
  }
  .tt-block-title.tt-title-custom .tt-description {
    font-size: 14px;
    line-height: 26px;
    padding-bottom: 4px;
  }
}

.tt-title-light .tt-block-title .tt-title {
  color: #ffffff;
}

.tt-indent-btn {
  margin-top: -19px;
}

.tt-block-subpages {
  font-family: "Montserrat", sans-serif;
  color: #222222;
  line-height: 1.3;
  font-weight: 600;
  margin: 0;
}

@media (min-width: 1025px) {
  .tt-block-subpages {
    font-size: 36px;
    padding: 0 0 50px 0;
  }
}

@media (max-width: 1024px) {
  .tt-block-subpages {
    font-size: 30px;
    padding: 0 0 21px 0;
  }
}

@media (max-width: 575px) {
  .tt-block-subpages {
    font-size: 24px;
    padding: 0 0 23px 0;
  }
}

.tt-title-subpages {
  font-family: "Montserrat", sans-serif;
  color: #222222;
  font-size: 24px;
  line-height: 1.3;
  font-weight: 600;
  padding: 0 0 28px 0;
  margin: 0;
}

p + .tt-title-subpages {
  margin-top: 33px;
}

@media (max-width: 1024px) {
  p + .tt-title-subpages {
    margin-top: 20px;
  }
}

.img-bottom {
  margin-bottom: 7px;
}

/* layout indent */
.container-indent:not(.no-margin) {
  margin-top: 117px;
}

@media (max-width: 1024px) {
  .container-indent:not(.no-margin) {
    margin-top: 79px;
  }
}

@media (max-width: 575px) {
  .container-indent:not(.no-margin) {
    margin-top: 57px;
  }
}

.container-indent-02:not(.no-margin) {
  margin-top: 123px;
}

@media (max-width: 1024px) {
  .container-indent-02:not(.no-margin) {
    margin-top: 65px;
  }
}

@media (max-width: 575px) {
  .container-indent-02:not(.no-margin) {
    margin-top: 55px;
  }
}

.container-indent-03:not(.no-margin) {
  margin-top: 85px;
}

@media (max-width: 1024px) {
  .container-indent-03:not(.no-margin) {
    margin-top: 52px;
  }
}

@media (max-width: 575px) {
  .container-indent-03:not(.no-margin) {
    margin-top: 42px;
  }
}

.container-indent-04:not(.no-margin) {
  margin-top: 85px;
}

@media (max-width: 1024px) {
  .container-indent-04:not(.no-margin) {
    margin-top: 47px;
  }
}

@media (max-width: 575px) {
  .container-indent-04:not(.no-margin) {
    margin-top: 25px;
  }
}

.container-indent-05:not(.no-margin) {
  margin-top: 54px;
}

@media (max-width: 1024px) {
  .container-indent-05:not(.no-margin) {
    margin-top: 47px;
  }
}

@media (max-width: 575px) {
  .container-indent-05:not(.no-margin) {
    margin-top: 25px;
  }
}
.m-40{
    margin: 40px auto 0;
}
.margin-indent01 {
  margin-top: -50px;
}

@media (max-width: 1229px) {
  .margin-indent01 {
    margin-top: -60px;
  }
}

@media (max-width: 1024px) {
  .margin-indent01 {
    margin-top: -109px;
  }
}

.tt-box-indent {
  margin-top: 49px;
}

@media (max-width: 1024px) {
  .tt-box-indent {
    margin-top: 31px;
  }
}

@media (max-width: 767px) {
  .tt-box-indent {
    margin-top: 26px;
  }
}

@media (max-width: 767px) {
  #tt-pageContent .container {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media (max-width: 1024px) {
  #tt-pageContent > *:not(.no-margin)[class^="container-indent"]:first-child {
    margin-top: 25px;
  }
}

@media (max-width: 767px) {
  #tt-pageContent > *:not(.no-margin)[class^="container-indent"]:first-child {
    margin-top: 17px;
  }
}

/* tt-conent-layout */
.tt-conent-layout p {
  margin: 16px 0 0 0;
}

.tt-conent-layout p:first-child {
  margin-top: 0;
}

.tt-text-center {
  text-align: center;
  max-width: 996px;
  margin: 0 auto !important;
}

.tt-block-title + .tt-text-center {
  margin-top: 4px !important;
}

.tt-text-center + *[class^="js-carousel"] {
  margin-top: 39px;
}

@media (max-width: 1024px) {
  .tt-text-center + *[class^="js-carousel"] {
    margin-top: 27px;
  }
}

@media (max-width: 575px) {
  .tt-text-center + *[class^="js-carousel"] {
    margin-top: 17px;
  }
}

@media (max-width: 767px) {
  .tt-mobile-hidden {
    display: none;
  }
}

/*
    tt-form-default
*/
/* form */
::-webkit-input-placeholder {
  color: #222222;
}

::-moz-placeholder {
  color: #222222;
}

:-moz-placeholder {
  color: #222222;
}

:-ms-input-placeholder {
  color: #222222;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus:-moz-placeholder {
  color: transparent;
}

input:focus::-moz-placeholder {
  color: transparent;
}

input:focus:-ms-input-placeholder {
  color: transparent;
}

textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

textarea:focus:-moz-placeholder {
  color: transparent;
}

textarea:focus::-moz-placeholder {
  color: transparent;
}

textarea:focus:-ms-input-placeholder {
  color: transparent;
}

.tt-form-default .form-group {
  margin-bottom: 20px;
}

.tt-form-default label {
  font-weight: 600;
  color: #222222;
  font-size: 14px;
  line-height: 1.2;
  padding-right: 4px;
  padding-bottom: 7px;
  display: block;
  font-family: "Montserrat", sans-serif;
}

.tt-form-default input[type=text],
.tt-form-default input[type=email],
.tt-form-default input[type=number],
.tt-form-default textarea {
  background: transparent;
  font-size: 12px;
  border: 1px solid #dfdfdf;
  padding: 3px 12px 4px 14px;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  box-shadow: none;
  outline: none;
  color: #222222;
  transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  width: 100%;
  -webkit-box-shadow: 0px 0px 1px #dfdfdf;
  -moz-box-shadow: 0px 0px 1px #dfdfdf;
  box-shadow: 0px 0px 1px #dfdfdf;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}

.tt-form-default input[type=text]:not(textarea),
.tt-form-default input[type=email]:not(textarea),
.tt-form-default input[type=number]:not(textarea) {
  height: 40px;
}

@media (max-width: 575px) {
  .tt-form-default input[type=text]:not(textarea),
  .tt-form-default input[type=email]:not(textarea),
  .tt-form-default input[type=number]:not(textarea) {
    height: 39px;
  }
}

.tt-form-default input.form-control,
.tt-form-default textarea.form-control {
  -webkit-appearance: none;
}

.tt-form-default select.form-control {
  padding: 10px 12px 11px 15px;
  cursor: pointer;
}

.tt-form-default select.form-control:not([size]):not([multiple]) {
  height: auto;
}

.tt-form-default input[type=text]:focus,
.tt-form-default input[type=email]:focus,
.tt-form-default input[type=number]:focus,
.tt-form-default textarea:focus {
  border-color: #dd3d53;
  -webkit-box-shadow: 0px 0px 1px #dd3d53;
  -moz-box-shadow: 0px 0px 1px #dd3d53;
  box-shadow: 0px 0px 1px #dd3d53;
}

/*
    tt-form-default02
*/
.tt-form-default02 .form-group {
  margin-bottom: 15px;
}

.tt-form-default02 input[type=text].form-control,
.tt-form-default02 input[type=email].form-control,
.tt-form-default02 textarea {
  font-size: 15px;
  line-height: 28px;
  border: none;
  outline: none;
  border-bottom: 1px solid #c8c8ca;
  padding: 5px 12px 11px 4px;
  font-family: "Open Sans", sans-serif;
  box-shadow: none;
  outline: none;
  color: #707070;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}

.tt-form-default02 input[type=text]:not(textarea),
.tt-form-default02 input[type=email]:not(textarea) {
  height: 40px;
}

@media (max-width: 575px) {
  .tt-form-default02 input[type=text]:not(textarea),
  .tt-form-default02 input[type=email]:not(textarea) {
    height: 39px;
  }
}

.tt-form-default02 input.form-control,
.tt-form-default02 textarea.form-control {
  -webkit-appearance: none;
}

.tt-form-default02 select.form-control {
  padding: 10px 12px 11px 15px;
  cursor: pointer;
}

.tt-form-default02 select.form-control:not([size]):not([multiple]) {
  height: auto;
}

.tt-form-default02 input[type=text]:focus,
.tt-form-default02 input[type=email]:focus,
.tt-form-default02 textarea:focus {
  border-color: #dd3d53;
  box-shadow: none;
}

.tt-form-default02 .nice-select.tt-skin-01 {
  height: 39px;
  padding: 8px 33px 3px 14px;
}

.tt-form-default02 .tt-rating i {
  cursor: pointer;
}

/* radio-group */
.radio {
  display: block;
  cursor: pointer;
  margin: 0 0 8px;
  font-size: 12px;
  line-height: 24px;
  transition: all 0.15s linear;
  -ms-transition: all 0.15s linear;
  -webkit-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
  color: #222222;
}

.radio input {
  width: 1px;
  height: 1px;
  opacity: 0;
}

.radio input:focus + .outer .inner {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  background-color: #7f7f7f;
}

.radio .outer {
  width: 16px;
  height: 16px;
  display: block;
  float: left;
  margin: 4px 4px 0 0;
  background-color: #f7f8fa;
  border: 1px solid #e0e0e0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 0px 0px 1px #dfdfdf;
  -moz-box-shadow: 0px 0px 1px #dfdfdf;
  box-shadow: 0px 0px 1px #dfdfdf;
}

.radio .inner {
  width: 8px;
  height: 8px;
  top: 3px;
  left: 3px;
  display: block;
  background-color: #7f7f7f;
  opacity: 0;
  position: relative;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}

.radio input:checked + .outer .inner {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

.radio:hover {
  color: #dd3d53;
}

.radio:hover .inner {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

/* checkbox-group */
.checkbox-group {
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
  font-size: 11px;
  line-height: 19px;
}

.checkbox-group input[type=checkbox] {
  display: none;
}

.checkbox-group label {
  padding-left: 25px;
  cursor: pointer;
  font-size: 11px;
  line-height: 19px;
  color: #222222;
  transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}

.checkbox-group label span {
  display: block;
  position: absolute;
  left: 0;
  -webkit-transition-duration: 0.03s;
  -moz-transition-duration: 0.03s;
  transition-duration: 0.03s;
}

.checkbox-group label .box {
  background: #ffffff;
  height: 17px;
  width: 17px;
  z-index: 9;
  margin-top: 1px;
  border: 1px solid #d1d1d1;
}

.checkbox-group label .check {
  top: 2px;
  left: 3px;
  opacity: 0;
  z-index: 10;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  background: transparent;
  width: 10px;
  height: 10px;
  line-height: 1;
  position: absolute;
}

.checkbox-group label .check:before {
  content: "\e934";
  color: #222222;
  font-size: 10px;
  line-height: 1;
  position: absolute;
  left: 1px;
  top: 3px;
  font-family: "carleader";
  font-style: normal;
  font-weight: 400;
}

.checkbox-group:hover label {
  color: #dd3d53;
}

input[type=checkbox]:checked ~ label .check {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.list-form-column {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  list-style: none;
  padding: 0;
  margin: 0 0 0 -15px;
}

.list-form-column li {
  margin-left: 15px;
}

/* input type file*/
.tt-input-file {
  width: 292px;
  padding-bottom: 15px;
  position: relative;
}

.tt-input-file .input-group-btn:before {
  font-family: "carleader";
  content: '\e92f';
  display: block;
  position: absolute;
  top: 13px;
  left: 15px;
  font-size: 13px;
  line-height: 1.2;
  z-index: 2;
}

.tt-input-file input[type=text] {
  width: 232px;
  height: 40px;
  font-family: "Open Sans", sans-serif;
  outline: none;
  padding: 0 0 0 35px;
  box-shadow: none;
  border: 1px solid #dfdfdf;
  color: #222222;
  font-size: 12px;
  text-transform: uppercase;
  background-color: transparent;
  position: absolute;
  left: 0;
  -webkit-appearance: none;
  pointer-events: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0px 0px 1px #dfdfdf;
  -moz-box-shadow: 0px 0px 1px #dfdfdf;
  box-shadow: 0px 0px 1px #dfdfdf;
}

.tt-input-file input[type=file] {
  padding: 0 0 0 25px;
}

.tt-input-file:before {
  content: '+';
  display: block;
  position: absolute;
  right: 0;
  width: 43px;
  height: 40px;
  font-size: 28px;
  line-height: 1.3;
  border: 1px solid #dfdfdf;
  color: #888888;
  font-weight: 400;
  text-align: center;
  pointer-events: none;
  cursor: pointer;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -webkit-box-shadow: 0px 0px 1px #dfdfdf;
  -moz-box-shadow: 0px 0px 1px #dfdfdf;
  box-shadow: 0px 0px 1px #dfdfdf;
}

.tt-input-file:before:before {
  content: '+';
  display: inline-block;
  font-size: 28px;
  line-height: 1.2;
}

.tt-input-file .tt-btn-icon {
  padding: 13px 0 0 12px;
  text-transform: uppercase;
  width: 292px;
  height: 42px;
  color: #222222;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.2;
  display: block;
  padding: 14px 0 0 38px;
  cursor: pointer;
}

.tt-input-file.tt-upload input[type=text] {
  background-color: #ffffff;
}

.tt-input-file:hover:before {
  color: #dd3d53;
  border-color: #dd3d53;
  -webkit-box-shadow: 0px 0px 1px #dd3d53;
  -moz-box-shadow: 0px 0px 1px #dd3d53;
  box-shadow: 0px 0px 1px #dd3d53;
}

@media (max-width: 480px) {
  .tt-input-file {
    width: 200px;
  }
  .tt-input-file .tt-btn-icon {
    width: 200px;
  }
  .tt-input-file:before {
    display: none;
  }
  .tt-input-file input[type=text] {
    width: 200px;
  }
}

/*
  Custom select
*/
.nice-select.tt-skin-01 {
  background-color: #fff;
  border: solid 1px #dfdfdf;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  color: #222222;
  font-size: 12px;
  line-height: 24px;
  font-weight: 500;
  height: 44px;
  outline: none;
  text-transform: uppercase;
  padding: 10px 33px 3px 14px;
  position: relative;
  text-align: left !important;
  transition: all 0.2s ease-in-out;
  user-select: none;
  white-space: nowrap;
  width: auto;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0px 0px 1px #dfdfdf;
  -moz-box-shadow: 0px 0px 1px #dfdfdf;
  box-shadow: 0px 0px 1px #dfdfdf;
}

.nice-select.tt-skin-01:hover {
  border-color: #dd3d53;
  -webkit-box-shadow: 0px 0px 1px #dd3d53;
  -moz-box-shadow: 0px 0px 1px #dd3d53;
  box-shadow: 0px 0px 1px #dd3d53;
}

.nice-select.tt-skin-01:active, .nice-select.tt-skin-01:focus {
  border-color: #dd3d53;
  -webkit-box-shadow: 0px 0px 1px #dd3d53;
  -moz-box-shadow: 0px 0px 1px #dd3d53;
  box-shadow: 0px 0px 1px #dd3d53;
}

.nice-select.tt-skin-01:after {
  content: '';
  display: block;
  height: 5px;
  margin-top: -1px;
  pointer-events: none;
  position: absolute;
  right: 17px;
  top: 50%;
  transition: all 0.15s ease-in-out;
  border: 3px solid transparent;
  border-top: 3px solid #000000;
}

.nice-select.tt-skin-01 .current span {
  color: #dd3d53;
  display: inline-block;
  font-weight: 500;
}

.nice-select.tt-skin-01 .current span:empty {
  display: none;
}

.nice-select.tt-skin-01.open .list {
  opacity: 1;
  pointer-events: auto;
  transform: scale(1) translateY(0);
}

.nice-select.tt-skin-01.disabled {
  pointer-events: none;
}

.nice-select.tt-skin-01.wide {
  width: 100%;
}

.nice-select.tt-skin-01.wide .list {
  left: 0 !important;
  right: 0 !important;
}

.nice-select.tt-skin-01.right {
  float: right;
}

.nice-select.tt-skin-01.right .list {
  left: auto;
  right: 0;
}

.nice-select.tt-skin-01 .list {
  background-color: #ffffff;
  border: 1px solid #dfdfdf;
  color: #222222;
  margin-top: 4px;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  top: 100%;
  left: 0;
  transform-origin: 50% 0;
  transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  z-index: 9;
  width: 100%;
  color: #222222;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0px 0px 1px #dfdfdf;
  -moz-box-shadow: 0px 0px 1px #dfdfdf;
  box-shadow: 0px 0px 1px #dfdfdf;
}

.nice-select.tt-skin-01 .list:hover .option:not(:hover) {
  background-color: transparent !important;
  color: #222222;
}

.nice-select.tt-skin-01 .list li:first-child {
  padding-top: 4px;
}

.nice-select.tt-skin-01 .list li:last-child {
  padding-bottom: 4px;
}

.nice-select.tt-skin-01 .option {
  list-style: none;
  cursor: pointer;
  outline: none;
  text-align: left;
  padding: 2px 14px;
  -moz-transition: background-color 0.2s linear;
  -o-transition: background-color 0.2s linear;
  -webkit-transition: background-color 0.2s linear;
  transition: background-color 0.2s linear;
}

.nice-select.tt-skin-01 .option:hover, .nice-select.tt-skin-01 .option.focus, .nice-select.tt-skin-01 .option.selected.focus {
  background-color: #dd3d53;
  color: #ffffff;
}

.nice-select.tt-skin-01 .option.disabled {
  font-weight: bold;
  cursor: default;
}

.no-csspointerevents .nice-select .list {
  display: none;
}

.no-csspointerevents .nice-select.open .list {
  display: block;
}

.tt-select {
  display: none;
}

.nice-select.tt-skin-02 {
  background-color: transparent;
  border: solid 1px transparent;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: inherit;
  color: #ffffff;
  font-size: 11px;
  line-height: 1.4;
  font-weight: normal;
  height: 30px;
  min-width: 37px;
  outline: none;
  padding: 3px 12px 4px 2px;
  position: relative;
  text-align: left !important;
  transition: all 0.2s ease-in-out;
  user-select: none;
  white-space: nowrap;
  width: auto;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0px 0px 1px transparent;
  -moz-box-shadow: 0px 0px 1px transparent;
  box-shadow: 0px 0px 1px transparent;
}

.nice-select.tt-skin-02:hover {
  border-color: transparent;
  -webkit-box-shadow: 0px 0px 1px transparent;
  -moz-box-shadow: 0px 0px 1px transparent;
  box-shadow: 0px 0px 1px transparent;
}

.nice-select.tt-skin-02:active, .nice-select.tt-skin-02:focus {
  border-color: transparent;
  -webkit-box-shadow: 0px 0px 1px transparent;
  -moz-box-shadow: 0px 0px 1px transparent;
  box-shadow: 0px 0px 1px transparent;
}

.nice-select.tt-skin-02:after {
  content: '';
  display: block;
  height: 5px;
  margin-top: -4px;
  pointer-events: none;
  position: absolute;
  right: 0px;
  top: 50%;
  transition: all 0.15s ease-in-out;
  border: 3px solid transparent;
  border-top: 3px solid #ffffff;
}

.nice-select.tt-skin-02 .current span {
  color: #dd3d53;
  display: inline-block;
  font-weight: 300;
}

.nice-select.tt-skin-02 .current span:empty {
  display: none;
}

.nice-select.tt-skin-02.open .list {
  opacity: 1;
  pointer-events: auto;
  transform: scale(1) translateY(0);
}

.nice-select.tt-skin-02.disabled {
  pointer-events: none;
}

.nice-select.tt-skin-02.wide {
  width: 100%;
}

.nice-select.tt-skin-02.wide .list {
  left: 0 !important;
  right: 0 !important;
}

.nice-select.tt-skin-02.right {
  float: right;
}

.nice-select.tt-skin-02.right .list {
  left: auto;
  right: 0;
}

.nice-select.tt-skin-02 .list {
  background-color: #ffffff;
  border: 1px solid transparent;
  color: #222222;
  margin-top: 4px;
  opacity: 0;
  overflow: hidden;
  min-width: 70px;
  pointer-events: none;
  position: absolute;
  top: 100%;
  left: 0;
  transform-origin: 50% 0;
  transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  z-index: 9;
  width: 100%;
  color: #222222;
  -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}

.nice-select.tt-skin-02 .list:hover .option:not(:hover) {
  background-color: transparent !important;
  color: #222222;
}

.nice-select.tt-skin-02 .list li:first-child {
  padding-top: 4px;
}

.nice-select.tt-skin-02 .list li:last-child {
  padding-bottom: 4px;
}

.nice-select.tt-skin-02 .option {
  list-style: none;
  cursor: pointer;
  outline: none;
  text-align: left;
  padding: 2px 14px;
  -moz-transition: background-color 0.2s linear;
  -o-transition: background-color 0.2s linear;
  -webkit-transition: background-color 0.2s linear;
  transition: background-color 0.2s linear;
}

.nice-select.tt-skin-02 .option:hover, .nice-select.tt-skin-02 .option.focus, .nice-select.tt-skin-02 .option.selected.focus {
  background-color: #dd3d53;
  color: #ffffff;
}

.nice-select.tt-skin-02 .option.disabled {
  font-weight: bold;
  cursor: default;
}

.tt-default-color {
  color: #dd3d53;
}

.tt-default-color02 {
  color: #222222;
}

@media (max-width: 575px) {
  .hidden-mobile {
    display: none !important;
  }
}

@media (min-width: 576px) {
  .visible-mobile {
    display: none !important;
  }
}

/*video-block */
.tt-video-block:not(:first-child) {
  margin-top: 34px;
}

@media (max-width: 1229px) {
  .tt-video-block:not(:first-child) {
    margin-top: 28px;
  }
}

@media (max-width: 575px) {
  .tt-video-block:not(:first-child) {
    margin-top: 22px;
  }
}

.tt-video-block {
  position: relative;
  line-height: 0;
  width: 100%;
}

.tt-video-block video {
  width: 100%;
}

.tt-video-block .link-video {
  position: absolute;
  display: inline-block;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.tt-video-block .link-video:before {
  color: #ffffff;
  font-size: 56px;
  line-height: 1;
  float: left;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.tt-video-block .link-video:hover:before {
  color: #dd3d53;
}

.tt-video-block .link-video:before {
  content: '\e913';
  font-family: "carleader";
  display: inline-block;
  z-index: 3;
}

.tt-video-block.play .link-video:before {
  content: '\ea16';
}

.tt-wrapper-01 {
  background: #fafafa;
  padding: 97px 0 101px 0;
}

@media (max-width: 1024px) {
  .tt-wrapper-01 {
    padding: 67px 0 70px 0;
  }
}

@media (max-width: 575px) {
  .tt-wrapper-01 {
    padding: 57px 0 60px 0;
  }
}

h6:not([class^="tt-title"]) {
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
  color: #222222;
  margin: 0;
  padding: 0 0 21px 0;
  font-family: "Montserrat", sans-serif;
}

@media (max-width: 1024px) {
  h6:not([class^="tt-title"]) {
    font-size: 16px;
  }
}

.tt-title-default {
  font-size: 24px;
  line-height: 26px;
  font-weight: 600;
  color: #222222;
  margin: 0;
  padding: 0 0 21px 0;
  font-family: "Montserrat", sans-serif;
}

@media (max-width: 1024px) {
  .tt-title-default {
    font-size: 18px;
  }
}

@media (max-width: 575px) {
  .tt-title-default {
    font-size: 16px;
    padding: 0 0 9px 0;
  }
}

.tt-list01 {
  list-style: none;
  padding: 0 0 0 19px;
  margin: 0;
}

.tt-list01 li {
  padding: 0 0 10px 30px;
  color: #222222;
  position: relative;
  font-weight: 600;
  font-size: 16px;
}

.tt-list01 li:before {
  content: '\e932';
  font-family: "carleader";
  display: block;
  position: absolute;
  top: 4px;
  left: 0;
  font-size: 16px;
  line-height: 1;
  color: #dd3d53;
}

.tt-list01 li a {
  color: #222222;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.tt-list01 li a:hover {
  color: #dd3d53;
}

.tt-list02 {
  list-style: none;
  padding: 0 0 0 0px;
  margin: 0;
}

.tt-list02 li {
  padding: 0 0 5px 25px;
  color: #222222;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
  font-size: 11px;
  line-height: 1.4;
  font-family: "Montserrat", sans-serif;
}

.tt-list02 li:before {
  content: '\e917';
  font-family: "carleader";
  display: block;
  position: absolute;
  top: 0px;
  left: 0;
  font-size: 13px;
  line-height: 1;
  color: #dd3d53;
}

.tt-list02 li a {
  color: #222222;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.tt-list02 li a:hover {
  color: #dd3d53;
}

.tt-list02 li:not(:last-child) {
  margin-bottom: 10px;
}

.tt-list02 li:last-child {
  padding-bottom: 0;
}

.tt-list03 {
  list-style: none;
  padding: 0 0 0 0px;
  margin: 0;
}

.tt-list03 li {
  padding: 0 0 4px 27px;
  margin-bottom: 10px;
  color: #222222;
  font-weight: 500;
  text-transform: uppercase;
  position: relative;
  font-size: 12px;
  line-height: 1.4;
  font-family: "Montserrat", sans-serif;
}

.tt-list03 li:before {
  content: '\e934';
  font-family: "carleader";
  display: block;
  position: absolute;
  top: 3px;
  left: 8px;
  font-size: 8px;
  line-height: 1;
  color: #dd3d53;
}

.tt-list03 li a {
  color: #222222;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.tt-list03 li a:hover {
  color: #dd3d53;
}

@media (max-width: 1024px) {
  .tt-list03 li {
    font-size: 11px;
  }
}

.tt-link {
  color: #dd3d53;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
  font-weight: bold;
}

.tt-link:hover {
  color: #222222;
}

.tt-link-back {
  font-size: 11px;
  text-transform: uppercase;
  color: #dd3d53;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
  font-weight: bold;
  margin-bottom: 18px;
  display: inline-block;
  font-family: "Montserrat", sans-serif;
}

.tt-link-back:hover {
  color: #222222;
}

.tt-link-back:before {
  position: relative;
  display: inline-block;
  top: -1px;
  margin-right: 8px;
  font-family: "carleader";
  content: "\e92b";
  font-size: 8px;
  line-height: 1.1;
  font-weight: 600;
  -webkit-transform: rotate(-270deg);
  -moz-transform: rotate(-270deg);
  -ms-transform: rotate(-270deg);
  -o-transform: rotate(-270deg);
  transform: rotate(-270deg);
}

@media (max-width: 1024px) {
  .tt-link-back {
    margin-bottom: 4px;
  }
}

@media (max-width: 575px) {
  .tt-link-back {
    margin-bottom: 8px;
  }
}

/*
tooltip
*/
.tooltip {
  display: inline;
  position: relative;
  font-family: "Montserrat", sans-serif;
}

.tooltip:after {
  background: #222222;
  border-radius: 5px;
  top: -34px;
  color: #ffffff;
  content: attr(title);
  left: 50%;
  margin-left: -39px;
  padding: 5px 5px;
  position: absolute;
  font-weight: 500;
  z-index: 1;
  width: 78px;
  font-size: 10px;
  line-height: 1.3;
  -moz-opacity: 0;
  -khtml-opacity: 0;
  -webkit-opacity: 0;
  opacity: 0;
  transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}

.tooltip:before {
  border: solid;
  border-color: #222222 transparent;
  border-width: 5px 5px 0 5px;
  top: -11px;
  content: "";
  left: 50%;
  margin-left: -5px;
  position: absolute;
  font-weight: 500;
  z-index: 1;
  -moz-opacity: 0;
  -khtml-opacity: 0;
  -webkit-opacity: 0;
  opacity: 0;
  transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}

.tooltip:hover:after, .tooltip:hover:before {
  -moz-opacity: 1;
  -khtml-opacity: 1;
  -webkit-opacity: 1;
  opacity: 1;
}

.divider--lg {
  height: 45px;
}

.divider {
  height: 40px;
  clear: both;
  overflow: hidden;
  width: 100%;
  display: none !important;
}

.visible-xs, .visible-sm, .visible-md, .visible-lg, .visible-xl {
  display: none;
}

@media (max-width: 766px) {
  .visible-xs {
    display: block !important;
  }
}

@media (max-width: 766px) {
  .hidden-xs {
    display: none !important;
  }
}

@media (min-width: 1230px) {
  .hidden-xl {
    display: none !important;
  }
}

@media (max-width: 1229px) and (min-width: 1025px) {
  .hidden-lg {
    display: none !important;
  }
}

/*---------------------------------------*/
/*--------     5. Header         --------*/
/*---------------------------------------*/
.panel-menu,
.mmpanels,
.mmpanels > .mmpanel {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: inherit;
}

.panel-menu {
  width: 320px;
  background: #484848;
  position: fixed;
  z-index: 50;
}

.panel-menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.panel-menu ul li {
  margin: 0;
  width: 100%;
}

.panel-menu ul li a {
  display: flex;
  padding: 9px 27px 9px 30px;
  font-size: 15px;
  line-height: 22px;
  text-decoration: none;
  color: #ffffff;
  background: transparent;
  position: relative;
  transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}

.panel-menu ul li a:hover {
  color: #dd3d53;
  background: transparent;
}

.panel-menu #mm0.mmpanel a:not(.mm-close) {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  color: #ffffff;
  background-color: transparent;
}

.panel-menu #mm0.mmpanel a:not(.mm-close):after {
  color: #ffffff;
}

.panel-menu #mm0.mmpanel a:not(.mm-close):hover {
  color: #dd3d53;
  background-color: transparent;
}

.panel-menu #mm0.mmpanel a:not(.mm-close):hover:after {
  color: #dd3d53;
}

.panel-menu #mm0.mmpanel .mm-next-level:after {
  top: 18px;
}

.panel-menu .mmpanel:not(#mm0) a:not(.mm-original-link) {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

.panel-menu .mmpanel .mm-original-link .tt-badge {
  top: -1px;
}

.panel-menu .mm-close,
.panel-menu .mm-prev-level,
.panel-menu .mm-original-link {
  display: block;
  color: #ffffff;
  background-color: transparent;
  background-color: transparent;
  text-transform: uppercase;
}

.panel-menu .mm-close:before,
.panel-menu .mm-prev-level:before,
.panel-menu .mm-next-level:after {
  font-family: "carleader";
  font-size: 14px;
  line-height: 14px;
  font-weight: bold;
  transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  color: #ffffff;
}

.panel-menu .mm-close:hover,
.panel-menu .mm-prev-level:hover,
.panel-menu .mm-next-level:hover {
  color: #dd3d53;
  background-color: transparent;
}

.panel-menu .mm-close:hover:before
.mm-prev-level:hover:before,
.panel-menu .mm-next-level:hover:after {
  color: #dd3d53;
}

.panel-menu li.mm-close-parent {
  margin-bottom: 32px;
}

.panel-menu li.mm-close-parent .mm-close {
  padding: 14px 16px 15px 29px;
  color: #ffffff;
  border-bottom: 1px solid #dfdfdf;
  background-color: transparent;
}

.panel-menu li.mm-close-parent .mm-close:before {
  content: "\e933";
  padding-right: 10px;
  position: relative;
  top: 0px;
  color: #ffffff;
}

.panel-menu li.mm-close-parent .mm-close:hover {
  color: #dd3d53;
  background-color: transparent;
}

.panel-menu li.mm-close-parent .mm-close:hover:before {
  color: #dd3d53;
}

.panel-menu .mm-prev-level {
  padding: 14px 16px 15px 29px;
  color: #ffffff;
  background-color: transparent;
  border-bottom: 1px solid #dfdfdf;
  margin-bottom: 15px;
}

.panel-menu .mm-prev-level:before {
  content: "\e92b";
  padding-right: 10px;
  position: relative;
  top: 5px;
  color: #ffffff;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  display: inline-block;
}

.panel-menu .mm-prev-level:hover {
  color: #dd3d53;
  background-color: transparent;
}

.panel-menu .mm-prev-level:hover:before {
  color: #dd3d53;
}

.panel-menu .mm-next-level {
  color: #222222;
  background-color: transparent;
}

.panel-menu .mm-next-level:after {
  content: "\e92b";
  padding-left: 10px;
  top: 12px;
  right: 30px;
  position: absolute;
  color: #ffffff;
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}

.panel-menu .mm-next-level:hover {
  color: #dd3d53;
  background-color: transparent;
}

.panel-menu .mm-next-level:hover:after {
  color: #dd3d53;
}

.panel-menu .mm-original-link {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  color: #ffffff;
  background-color: transparent;
}

.panel-menu .mm-original-link:before {
  padding-right: 10px;
  color: #ffffff;
}

.panel-menu .mm-original-link:hover {
  color: #dd3d53;
  background-color: transparent;
}

.panel-menu .mm-original-link:hover:before {
  color: #dd3d53;
}

.mm-open {
  overflow: hidden;
}

.mm-open .mm-fullscreen-bg {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.55);
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 21;
  display: none;
}

.mmitemopen.panel-menu,
.mmitemopen.panel-menu.mm-right {
  -webkit-transition: -webkit-transform .3s ease;
  transition: -webkit-transform .3s ease;
  transition: transform .3s ease;
  transition: transform .3s ease,-webkit-transform .3s ease;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.panel-menu {
  -webkit-transform: translate(-100%, 0);
  -ms-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

.panel-menu.mm-right,
.mmitemopen.panel-menu.mm-right.mmhide {
  left: auto;
  right: 0;
  -webkit-transform: translate(100%, 0);
  -ms-transform: translate(100%, 0);
  transform: translate(100%, 0);
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.mmitemopen.panel-menu.mmhide {
  -webkit-transform: translate(-100%, 0);
  -ms-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

.mmpanel {
  -webkit-transition: -webkit-transform .3s ease;
  transition: -webkit-transform .3s ease;
  transition: transform .3s ease;
  transition: transform .3s ease,-webkit-transform .3s ease;
  -webkit-transform: translate(100%, 0);
  -ms-transform: translate(100%, 0);
  transform: translate(100%, 0);
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.mmpanels {
  overflow: hidden;
}

.mmpanel.mmopened {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mmpanel.mmsubopened {
  -webkit-transform: translate(-80%, 0);
  -ms-transform: translate(-80%, 0);
  transform: translate(-80%, 0);
  -webkit-transform: translate3d(-80%, 0, 0);
  transform: translate3d(-80%, 0, 0);
}

.mmpanels > .mmpanel {
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: auto;
}

.mmpanels,
.mmpanels >
.mmpanel {
  background: inherit;
  border-color: inherit;
}

.mmpanels > .mmpanel:not(.mmhidden) {
  display: block;
}

.mmpanels > .mmpanel:after {
  content: '';
  display: block;
  height: 20px;
}

.mmhidden,
.mm-nav-btn {
  display: none;
}

.mm-fullscreen-bg,
.mm-fullscreen-bg:focus,
.mm-fullscreen-bg:active,
.mm-fullscreen-bg:hover {
  outline: none;
}

.tt-menu-slider {
  max-height: 389px;
}

/*
	tt-header-01(CarLeader)
*/
#tt-header.tt-header-01 {
  position: relative;
  z-index: 3;
  /*
		panel-info(desctope top line)
	*/
  /*
		quickLinks(mobile top line)
	*/
  /*
		header-holder layout
	*/
  /*
		header-obj
	*/
  /* desctop-menu */
}

@media (min-width: 1025px) {
  #tt-header.tt-header-01.tt-on-top {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    pointer-events: none;
  }
  #tt-header.tt-header-01.tt-on-top > * {
    pointer-events: auto;
  }
}

#tt-header.tt-header-01:not(.tt-on-top) {
  height: 190px;
}

@media (min-width: 1025px) {
  #tt-header.tt-header-01:not(.tt-on-top) {
    background: url("/images/custom/header-sub-menu.jpg") 0 0 no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}

@media (max-width: 767px) {
  #tt-header.tt-header-01:not(.tt-on-top) {
    height: 127px;
  }
}

@media (max-width: 767px) {
  #tt-header.tt-header-01 .tt-panel-info {
    display: none;
  }
}

@media (min-width: 1025px) {
  #tt-header.tt-header-01 .tt-panel-info {
    background-color: rgba(0, 0, 0, 0.6);
  }
  #tt-header.tt-header-01 .tt-panel-info .row {
    height: 43px;
  }
  #tt-header.tt-header-01 .tt-panel-info .nice-select.tt-skin-02 {
    height: 30px;
  }
}

@media (max-width: 1024px) {
  #tt-header.tt-header-01 .tt-panel-info {
    background-color: #222222;
  }
  #tt-header.tt-header-01 .tt-panel-info .row {
    height: 28px;
    position: relative;
    top: -1px;
  }
  #tt-header.tt-header-01 .tt-panel-info .nice-select.tt-skin-02 {
    height: 27px;
  }
}

#tt-header.tt-header-01 .tt-box-info {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 12px;
}

#tt-header.tt-header-01 .tt-box-info li {
  text-transform: uppercase;
  color: #ffffff;
}

#tt-header.tt-header-01 .tt-box-info li a {
  color: #ffffff;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

#tt-header.tt-header-01 .tt-box-info li a:hover {
  color: #dd3d53;
}

#tt-header.tt-header-01 .tt-box-info li i {
  font-size: 18px;
  line-height: 1;
  color: #dd3d53;
  margin-right: 7px;
  position: relative;
  top: 3px;
}

@media (min-width: 1230px) {
  #tt-header.tt-header-01 .tt-box-info li:not(:last-child) {
    margin-right: 40px;
  }
}

@media (max-width: 1229px) {
  #tt-header.tt-header-01 .tt-box-info li:not(:last-child) {
    margin-right: 22px;
  }
}

@media (max-width: 1024px) {
  #tt-header.tt-header-01 .tt-box-info li:not(:last-child) {
    margin-right: 10px;
  }
}

@media (max-width: 1024px) {
  #tt-header.tt-header-01 .tt-box-info {
    font-size: 10px;
  }
  #tt-header.tt-header-01 .tt-box-info li strong {
    display: none;
  }
  #tt-header.tt-header-01 .tt-box-info li i {
    font-size: 16px;
    margin-right: 7px;
  }
  #tt-header.tt-header-01 .tt-box-info li i.icon-15874 {
    margin-right: 3px;
  }
}

@media (max-width: 767px) {
  #tt-header.tt-header-01 .tt-box-info .tt-text {
    display: none;
  }
}

#tt-header.tt-header-01 .tt-dropdown-obj {
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  margin: 0 20px 0 0px;
  position: relative;
  top: 3px;
}

#tt-header.tt-header-01 .tt-dropdown-obj:last-child {
  margin-right: 0;
}

#tt-header.tt-header-01 .tt-dropdown-obj .tt-dropdown-toggle {
  font-size: 18px;
  line-height: 1;
  color: #dd3d53;
  display: inline-block;
  padding: 6px 3px 4px 3px;
  position: relative;
  -moz-transition: 0.2s linear;
  -o-transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  transition: 0.2s linear;
}

#tt-header.tt-header-01 .tt-dropdown-obj .tt-dropdown-toggle:hover {
  color: #ffffff;
}

#tt-header.tt-header-01 .tt-dropdown-obj .tt-dropdown-toggle:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
}

@media (max-width: 1024px) {
  #tt-header.tt-header-01 .tt-dropdown-obj {
    margin: 0 5px 0 0px;
  }
  #tt-header.tt-header-01 .tt-dropdown-obj .tt-dropdown-toggle {
    font-size: 16px;
  }
}

#tt-header.tt-header-01 .tt-dropdown-obj.active .tt-dropdown-toggle {
  color: #ffffff;
}

#tt-header.tt-header-01 .tt-searcher .tt-dropdown-menu {
  position: fixed;
  opacity: 0;
  width: 100%;
  text-align: left;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: 0.2s ease 0s;
  transition: 0.2s ease 0s;
  background: #ffffff;
  right: 0;
  left: 0;
  z-index: 22;
  -webkit-box-shadow: 0 0px 40px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0px 40px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0px 40px rgba(0, 0, 0, 0.1);
  border-bottom: 3px solid #dd3d53;
}

@media (min-width: 1025px) {
  #tt-header.tt-header-01 .tt-searcher .tt-dropdown-menu {
    top: 43px;
  }
}

@media (max-width: 1024px) {
  #tt-header.tt-header-01 .tt-searcher .tt-dropdown-menu {
    top: 28px;
  }
}

#tt-header.tt-header-01 .tt-searcher .tt-dropdown-menu form {
  position: relative;
  display: block;
}

#tt-header.tt-header-01 .tt-searcher .tt-dropdown-menu .tt-row-search {
  height: 70px;
}

#tt-header.tt-header-01 .tt-searcher .tt-dropdown-menu .tt-search-input {
  font-family: "Open Sans", sans-serif;
  background: transparent;
  font-size: 20px;
  font-weight: 500;
  border: none;
  padding: 24px 12px 10px 0px;
  margin-left: 38px;
  box-shadow: none;
  outline: none;
  width: calc(100% - 71px);
  color: #5f5f5f;
  border-radius: 0;
  transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}

#tt-header.tt-header-01 .tt-searcher .tt-dropdown-menu .tt-search-input:not(focus)::-webkit-input-placeholder {
  color: #5f5f5f;
}

#tt-header.tt-header-01 .tt-searcher .tt-dropdown-menu .tt-search-input:not(focus)::-moz-placeholder {
  color: #5f5f5f;
}

#tt-header.tt-header-01 .tt-searcher .tt-dropdown-menu .tt-search-input:not(focus):-moz-placeholder {
  color: #5f5f5f;
}

#tt-header.tt-header-01 .tt-searcher .tt-dropdown-menu .tt-search-input:not(focus):-ms-input-placeholder {
  color: #5f5f5f;
}

#tt-header.tt-header-01 .tt-searcher .tt-dropdown-menu .tt-btn-search {
  color: #5f5f5f;
  font-size: 20px;
  line-height: 1;
  font-family: "carleader";
  position: absolute;
  cursor: pointer;
  top: 22px;
  padding: 4px 0 4px 4px;
  left: 0px;
  z-index: 3;
  display: inline-block;
  background: none;
  border: none;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

#tt-header.tt-header-01 .tt-searcher .tt-dropdown-menu .tt-btn-search:before {
  content: "\e909";
}

#tt-header.tt-header-01 .tt-searcher .tt-dropdown-menu .tt-btn-search:hover {
  color: #dd3d53;
}

#tt-header.tt-header-01 .tt-searcher .tt-dropdown-menu .tt-btn-close {
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  position: absolute;
  top: 20px;
  right: 0;
  color: #5f5f5f;
  font-size: 20px;
  line-height: 1;
  font-family: "carleader";
  padding: 5px 0px 5px 5px;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

#tt-header.tt-header-01 .tt-searcher .tt-dropdown-menu .tt-btn-close:before {
  font-family: "carleader";
  content: "\e933";
  line-height: 1;
}

#tt-header.tt-header-01 .tt-searcher .tt-dropdown-menu .tt-btn-close:hover {
  color: #dd3d53;
}

@media (max-width: 765px) {
  #tt-header.tt-header-01 .tt-searcher .tt-dropdown-menu .tt-row-search {
    height: 60px;
  }
  #tt-header.tt-header-01 .tt-searcher .tt-dropdown-menu .tt-search-input {
    font-size: 18px;
    padding-top: 18px;
  }
  #tt-header.tt-header-01 .tt-searcher .tt-dropdown-menu .tt-btn-close {
    top: 15px;
  }
  #tt-header.tt-header-01 .tt-searcher .tt-dropdown-menu .tt-btn-search {
    top: 17px;
  }
}

#tt-header.tt-header-01 .tt-searcher:not(.active) .tt-dropdown-menu {
  pointer-events: none;
}

@media (max-width: 1024px) {
  #tt-header.tt-header-01 .tt-searcher .tt-dropdown-toggle {
    font-size: 16px;
  }
}

#tt-header.tt-header-01 .tt-searcher.active .tt-dropdown-menu {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: 0.3s ease 0.15s;
  transition: 0.3s ease 0.15s;
}

@media (max-width: 765px) {
  #tt-header.tt-header-01 .tt-searcher .container {
    max-width: 100%;
  }
}

#tt-header.tt-header-01 .tt-language {
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  margin-right: 20px;
}

#tt-header.tt-header-01 .tt-language select {
  opacity: 0;
}

@media (max-width: 1229px) {
  #tt-header.tt-header-01 .tt-language {
    margin-right: 17px;
  }
}

@media (max-width: 1024px) {
  #tt-header.tt-header-01 .tt-language {
    margin-right: 12px;
  }
}

#tt-header.tt-header-01 .tt-cart {
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  position: relative;
}

@media (min-width: 768px) {
  #tt-header.tt-header-01 .tt-cart {
    top: 3px;
  }
}

#tt-header.tt-header-01 .tt-cart .tt-dropdown-toggle {
  font-size: 18px;
  line-height: 1;
  color: #dd3d53;
  display: inline-block;
  padding: 6px 0 4px 5px;
  position: relative;
  -moz-transition: 0.2s linear;
  -o-transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  transition: 0.2s linear;
}

#tt-header.tt-header-01 .tt-cart .tt-dropdown-toggle i {
  pointer-events: none;
}

#tt-header.tt-header-01 .tt-cart .tt-dropdown-toggle:hover {
  color: #ffffff;
}

#tt-header.tt-header-01 .tt-cart .tt-dropdown-menu {
  position: absolute;
  opacity: 0;
  width: 299px;
  margin: 15px 0 0 0;
  text-align: left;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: 0.2s ease 0s;
  transition: 0.2s ease 0s;
  background: #ffffff;
  right: 0;
  top: 100%;
  z-index: 22;
  -webkit-box-shadow: 0 0px 40px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0px 40px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0px 40px rgba(0, 0, 0, 0.1);
  border-bottom: 3px solid #dd3d53;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}

#tt-header.tt-header-01 .tt-cart .tt-dropdown-menu:before {
  content: '';
  display: block;
  height: 5px;
  margin-top: -5px;
  pointer-events: none;
  position: absolute;
  right: 23px;
  top: -7px;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  border: 7px solid transparent;
  border-bottom: 7px solid #ffffff;
  z-index: 1;
}

#tt-header.tt-header-01 .tt-cart .tt-dropdown-menu:after {
  content: '';
  display: block;
  height: 5px;
  margin-top: -5px;
  pointer-events: none;
  position: absolute;
  right: 23px;
  top: -7px;
  z-index: 0;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  border: 7px solid transparent;
  border-bottom: 7px solid rgba(0, 0, 0, 0.2);
}

#tt-header.tt-header-01 .tt-cart .tt-dropdown-menu .tt-dropdown-title {
  font-family: "Montserrat", sans-serif;
  color: #222222;
  font-weight: 600;
  border-bottom: 1px solid #e8e8e8;
  position: relative;
  padding: 15px 7px 14px 22px;
}

#tt-header.tt-header-01 .tt-cart .tt-dropdown-menu .tt-dropdown-title .tt-btn-close {
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  color: #dd3d53;
  position: absolute;
  right: 15px;
  top: 17px;
  cursor: pointer;
}

#tt-header.tt-header-01 .tt-cart .tt-dropdown-menu .tt-dropdown-title .tt-btn-close:hover {
  color: #222222;
}

#tt-header.tt-header-01 .tt-cart .tt-dropdown-menu .tt-search-results {
  padding: 0 20px 0 20px;
}

#tt-header.tt-header-01 .tt-cart .tt-dropdown-menu .tt-search-results li {
  margin: 24px 0 0 0;
  position: relative;
}

#tt-header.tt-header-01 .tt-cart .tt-dropdown-menu .tt-search-results li a {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-right: 18px;
}

#tt-header.tt-header-01 .tt-cart .tt-dropdown-menu .tt-search-results li a .thumbnail {
  width: 89px;
  min-width: 89px;
}

#tt-header.tt-header-01 .tt-cart .tt-dropdown-menu .tt-search-results li a .tt-description {
  padding: 0 5px 0 12px;
}

#tt-header.tt-header-01 .tt-cart .tt-dropdown-menu .tt-search-results li a .tt-description .tt-title {
  font-size: 15px;
  line-height: 22px;
  color: #222222;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

#tt-header.tt-header-01 .tt-cart .tt-dropdown-menu .tt-search-results li a .tt-description .tt-price {
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  color: #222222;
  font-family: "Open Sans", sans-serif;
  display: inline-block;
  margin-top: 9px;
}

#tt-header.tt-header-01 .tt-cart .tt-dropdown-menu .tt-search-results li a:hover .tt-description .tt-title {
  color: #dd3d53;
}

#tt-header.tt-header-01 .tt-cart .tt-dropdown-menu .tt-search-results li .tt-close-item {
  color: #222222;
  font-size: 12px;
  padding: 5px 0px 5px 5px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

#tt-header.tt-header-01 .tt-cart .tt-dropdown-menu .tt-search-results li .tt-close-item:hover {
  color: #dd3d53;
}

#tt-header.tt-header-01 .tt-cart .tt-dropdown-menu .tt-search-bottom {
  border-top: 1px solid #e8e8e8;
  margin-top: 24px;
  padding: 12px 20px 23px 20px;
}

#tt-header.tt-header-01 .tt-cart .tt-dropdown-menu .tt-search-bottom .price-total {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  font-size: 18px;
  line-height: 1.5;
  color: #222222;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}

#tt-header.tt-header-01 .tt-cart .tt-dropdown-menu .tt-search-bottom .price-total label {
  padding-right: 10px;
}

#tt-header.tt-header-01 .tt-cart .tt-dropdown-menu .tt-search-bottom .price-total .tt-price {
  padding-left: 10px;
  font-size: 18px;
  line-height: 1.4;
  color: #222222;
}

#tt-header.tt-header-01 .tt-cart .tt-dropdown-menu .tt-search-bottom .btn {
  width: 100%;
  margin-top: 9px;
}

#tt-header.tt-header-01 .tt-cart:not(.active) .tt-dropdown-menu {
  pointer-events: none;
}

@media (max-width: 1024px) {
  #tt-header.tt-header-01 .tt-cart .tt-dropdown-toggle {
    font-size: 16px;
  }
}

#tt-header.tt-header-01 .tt-cart.active .tt-dropdown-menu {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: 0.3s ease 0.15s;
  transition: 0.3s ease 0.15s;
}

#tt-header.tt-header-01 .tt-account {
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  position: relative;
}

@media (min-width: 768px) {
  #tt-header.tt-header-01 .tt-account {
    top: 3px;
  }
}

#tt-header.tt-header-01 .tt-account .tt-dropdown-toggle {
  font-size: 18px;
  line-height: 1;
  color: #dd3d53;
  display: inline-block;
  padding: 6px 0 4px 5px;
  position: relative;
  -moz-transition: 0.2s linear;
  -o-transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  transition: 0.2s linear;
}

#tt-header.tt-header-01 .tt-account .tt-dropdown-toggle i {
  pointer-events: none;
}

#tt-header.tt-header-01 .tt-account .tt-dropdown-toggle:hover {
  color: #ffffff;
}

#tt-header.tt-header-01 .tt-account .tt-dropdown-menu {
  position: absolute;
  opacity: 0;
  width: 230px;
  margin: 15px 0 0 0;
  padding: 0 20px 20px;
  text-align: left;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: 0.2s ease 0s;
  transition: 0.2s ease 0s;
  background: #ffffff;
  right: -23px;
  top: 100%;
  z-index: 22;
  -webkit-box-shadow: 0 0px 40px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0px 40px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0px 40px rgba(0, 0, 0, 0.1);
  border-bottom: 3px solid #dd3d53;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}

#tt-header.tt-header-01 .tt-account .tt-dropdown-menu:before {
  content: '';
  display: block;
  height: 5px;
  margin-top: -5px;
  pointer-events: none;
  position: absolute;
  right: 23px;
  top: -7px;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  border: 7px solid transparent;
  border-bottom: 7px solid #ffffff;
  z-index: 1;
}

#tt-header.tt-header-01 .tt-account .tt-dropdown-menu:after {
  content: '';
  display: block;
  height: 5px;
  margin-top: -5px;
  pointer-events: none;
  position: absolute;
  right: 23px;
  top: -7px;
  z-index: 0;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  border: 7px solid transparent;
  border-bottom: 7px solid rgba(0, 0, 0, 0.2);
}

#tt-header.tt-header-01 .tt-account .tt-dropdown-menu .tt-row-close {
  position: relative;
  padding: 15px 0px 14px 22px;
}

#tt-header.tt-header-01 .tt-account .tt-dropdown-menu .tt-row-close .tt-btn-close {
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  color: #222222;
  position: absolute;
  right: -4px;
  top: 17px;
  cursor: pointer;
}

#tt-header.tt-header-01 .tt-account .tt-dropdown-menu .tt-row-close .tt-btn-close:hover {
  color: #dd3d53;
}

#tt-header.tt-header-01 .tt-account .tt-dropdown-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

#tt-header.tt-header-01 .tt-account .tt-dropdown-menu ul li {
  color: #222222;
  font-size: 16px;
}

#tt-header.tt-header-01 .tt-account .tt-dropdown-menu ul li a {
  color: #222222;
  display: block;
  padding: 4px 0;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

#tt-header.tt-header-01 .tt-account .tt-dropdown-menu ul li a:hover {
  color: #dd3d53;
}

#tt-header.tt-header-01 .tt-account:not(.active) .tt-dropdown-menu {
  pointer-events: none;
}

@media (max-width: 1024px) {
  #tt-header.tt-header-01 .tt-account .tt-dropdown-toggle {
    font-size: 16px;
  }
}

#tt-header.tt-header-01 .tt-account.active .tt-dropdown-menu {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: 0.3s ease 0.15s;
  transition: 0.3s ease 0.15s;
}

#tt-header.tt-header-01 .tt-quickLinks-popup {
  position: relative;
}

@media (min-width: 768px) {
  #tt-header.tt-header-01 .tt-quickLinks-popup {
    display: none;
  }
}

@media (min-width: 766px) {
  #tt-header.tt-header-01 .tt-mobile-quickLinks {
    display: none;
  }
}

@media (max-width: 767px) {
  #tt-header.tt-header-01 .tt-mobile-quickLinks {
    display: block;
    background-color: #222222;
  }
  #tt-header.tt-header-01 .tt-mobile-quickLinks .row .col {
    text-align: center;
    line-height: 24px;
    display: inline-block;
  }
  #tt-header.tt-header-01 .tt-mobile-quickLinks .row .col:not(:last-child) {
    border-right: 1px solid #3a3a3a;
  }
  #tt-header.tt-header-01 .tt-mobile-quickLinks .row .col:not(:first-child) {
    border-left: 1px solid #181818;
  }
  #tt-header.tt-header-01 .tt-mobile-quickLinks .row .col.tt-cart {
    display: block;
  }
  #tt-header.tt-header-01 .tt-mobile-quickLinks .row .col a.btn-toggle {
    color: #dd3d53;
    display: block;
    height: 28px;
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    -moz-transition: color 0.2s linear;
    -o-transition: color 0.2s linear;
    -webkit-transition: color 0.2s linear;
    transition: color 0.2s linear;
  }
  #tt-header.tt-header-01 .tt-mobile-quickLinks .row .col a.btn-toggle i {
    position: relative;
    top: 3px;
  }
  #tt-header.tt-header-01 .tt-mobile-quickLinks .row .col a.btn-toggle:hover {
    color: #ffffff;
  }
  #tt-header.tt-header-01 .tt-mobile-quickLinks .row .col a.btn-toggle.active {
    color: #ffffff;
  }
  #tt-header.tt-header-01 .tt-mobile-quickLinks .row .col .nice-select.tt-skin-02 {
    display: inline-block;
    float: inherit;
    height: 25px;
    padding: 5px 12px 2px 2px;
    color: #ffffff;
    min-width: 37px;
  }
  #tt-header.tt-header-01 .tt-mobile-quickLinks .row .col .nice-select.tt-skin-02:after {
    border-top-color: #ffffff;
    margin-top: -1px;
  }
  #tt-header.tt-header-01 .tt-mobile-quickLinks .row .col .nice-select.tt-skin-02 .list {
    background: #ffffff;
    color: #222222;
    -webkit-box-shadow: 0 0px 8px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0px 8px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0px 8px rgba(0, 0, 0, 0.1);
  }
  #tt-header.tt-header-01 .tt-mobile-quickLinks .row .quickLinks-layout {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    background-color: #f5f5f5;
    color: #424242;
    padding: 30px;
    top: 100%;
    left: 0;
    transform: translateY(-20px);
    z-index: -1;
    transition: opacity 0.3s ease-in-out 0s, transform 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
    pointer-events: none;
    text-align: left;
    text-transform: none;
    -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  }
}

#tt-header.tt-header-01 .quickLinks-map address {
  font-size: 12px;
  line-height: 1.1;
  margin: 0;
  padding: 4px 10px 9px 13px;
  color: #707070;
  text-transform: uppercase;
}

#tt-header.tt-header-01 .quickLinks-map address i {
  color: #dd3d53;
  font-size: 18px;
  line-height: 1.1;
  margin: 0 10px 0 0;
  position: relative;
  top: 3px;
}

#tt-header.tt-header-01 .quickLinks-address {
  padding: 13px 10px 13px 13px;
}

#tt-header.tt-header-01 .quickLinks-address i {
  color: #dd3d53;
  font-size: 18px;
  line-height: 1.1;
  margin: 0 10px 0 0;
  position: relative;
  top: 3px;
}

#tt-header.tt-header-01 .quickLinks-address .tt-title {
  font-size: 19px;
  line-height: 1.2;
  font-family: "Montserrat", sans-serif;
  color: #222222;
  font-weight: 600;
  margin: 0;
  padding: 0 0 8px 0;
}

#tt-header.tt-header-01 .quickLinks-address ul li {
  color: #707070;
}

#tt-header.tt-header-01 .quickLinks-address ul li a {
  color: #707070;
  text-decoration: none;
}

#tt-header.tt-header-01 .quickLinks-address ul li:not(:first-child) {
  margin-top: 6px;
}

#tt-header.tt-header-01 .header-holder {
  position: relative;
  z-index: 7;
}

#tt-header.tt-header-01 .header-holder .col-logo {
  text-align: center;
  z-index: 2;
}

@media (min-width: 1025px) {
  #tt-header.tt-header-01 .header-holder .col-logo {
    margin-top: 19px;
    margin-bottom: 12px;
  }
}

@media (max-width: 1024px) {
  #tt-header.tt-header-01 .header-holder .col-logo {
    margin-top: 21px;
    margin-bottom: 11px;
  }
}

@media (min-width: 768px) {
  #tt-header.tt-header-01 .header-holder .col-logo {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  #tt-header.tt-header-01 .header-holder .col-logo {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin-top: 22px;
    margin-bottom: 22px;
    -webkit-flex: 2 1 auto;
    -ms-flex: 2 1 auto;
    flex: 2 1 auto;
  }
}

#tt-header.tt-header-01 .header-holder .col-menu {
  -webkit-flex: 2 1 auto;
  -ms-flex: 2 1 auto;
  flex: 2 1 auto;
}

@media (max-width: 1024px) {
  #tt-header.tt-header-01 .header-holder .col-menu {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 1025px) {
  #tt-header.tt-header-01 .header-holder .col-btn-toggle-modal {
    margin-top: -1px;
  }
}

@media (max-width: 1024px) {
  #tt-header.tt-header-01 .header-holder .col-btn-toggle-modal {
    position: absolute;
    right: 0px;
    top: 24px;
  }
}

@media (min-width: 768px) {
  #tt-header.tt-header-01 .header-holder .col-menu-toggle {
    display: none;
  }
}

@media (max-width: 1024px) and (min-width: 768px) {
  #tt-header.tt-header-01 .header-holder {
    min-height: 120px;
  }
}

@media (max-width: 767px) {
  #tt-header.tt-header-01 .header-holder {
    min-height: 82px;
  }
  #tt-header.tt-header-01 .header-holder .container {
    max-width: 100%;
  }
  #tt-header.tt-header-01 .header-holder .col-menu,
  #tt-header.tt-header-01 .header-holder .col-btn-toggle-modal {
    display: none;
  }
  #tt-header.tt-header-01 .header-holder .col-logo {
    margin-left: 15px;
  }
  #tt-header.tt-header-01 .header-holder .col-menu-toggle {
    display: inline-block;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  #tt-header.tt-header-01 .header-holder .col-menu-toggle .tt-menu-toggle {
    padding-top: 15px;
    padding-bottom: 15px;
    display: inline-block;
  }
}

#tt-header.tt-header-01 .tt-logo {
  max-width: 194px;
  display: inline-block;
  line-height: 0;
}

#tt-header.tt-header-01 .tt-logo .st0 {
  fill: #FFFFFF;
}

#tt-header.tt-header-01 .tt-logo .st1 {
  display: none;
}

#tt-header.tt-header-01 .tt-logo .st2 {
  display: inline;
  fill: #222222;
}

#tt-header.tt-header-01 .tt-logo .st3 {
  display: inline;
  fill: #222222;
}

#tt-header.tt-header-01 .tt-logo .st4 {
  fill: #dd3d53;
}

#tt-header.tt-header-01 .tt-logo .svg-img {
  width: auto;
  height: 50px;
  max-width: inherit;
  margin-bottom: 1px;
}

@media (min-width: 1025px) {
  #tt-header.tt-header-01 .tt-logo .tt-logo-dark {
    display: none;
  }
  #tt-header.tt-header-01 .tt-logo .tt-logo-light {
    display: block;
  }
}

@media (max-width: 1024px) {
  #tt-header.tt-header-01 .tt-logo {
    max-width: 168px;
  }
  #tt-header.tt-header-01 .tt-logo .svg-img {
    width: auto;
    height: 43px;
    margin-bottom: 0;
  }
  #tt-header.tt-header-01 .tt-logo .tt-logo-dark {
    display: block;
  }
  #tt-header.tt-header-01 .tt-logo .tt-logo-light {
    display: none;
  }
  #tt-header.tt-header-01 .tt-logo .st0 {
    fill: #222222;
  }
  #tt-header.tt-header-01 .tt-logo .st1 {
    display: none;
  }
  #tt-header.tt-header-01 .tt-logo .st2 {
    display: inline;
    fill: #222222;
  }
  #tt-header.tt-header-01 .tt-logo .st3 {
    display: inline;
    fill: #222222;
  }
  #tt-header.tt-header-01 .tt-logo .st4 {
    fill: #dd3d53;
  }
}

@media (max-width: 767px) {
  #tt-header.tt-header-01 .tt-logo {
    max-width: 131px;
    margin-top: 3px;
  }
  #tt-header.tt-header-01 .tt-logo .svg-img {
    height: 34px;
  }
}

#tt-header.tt-header-01 .tt-logo img:not(.svg-img) {
  max-width: 100%;
  height: auto;
}

#tt-header.tt-header-01 .tt-menu-toggle {
  color: #222222;
  font-size: 26px;
  line-height: 1.2em;
  padding: 5px;
  z-index: 3;
  outline: none;
  position: relative;
  top: 2px;
  display: inline-block !important;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

#tt-header.tt-header-01 .tt-menu-toggle:hover {
  color: #dd3d53;
}

#tt-header.tt-header-01 .btn.btn-toggle-modal {
  padding-left: 15px;
  padding-right: 18px;
  padding-top: 5px;
  position: relative;
  z-index: 2;
}

#tt-header.tt-header-01 .btn.btn-toggle-modal i {
  font-size: 22px;
  margin-top: -3px;
  margin-right: 12px;
}

@media (max-width: 1024px) {
  #tt-header.tt-header-01 .btn.btn-toggle-modal {
    padding-top: 4px;
    height: 38px;
  }
  #tt-header.tt-header-01 .btn.btn-toggle-modal i {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  #tt-header.tt-header-01 #tt-desctop-menu {
    display: none;
  }
}

#tt-header.tt-header-01 #tt-desctop-menu nav > ul { 
  display: flex; 
  flex-direction: row; 
  flex-wrap: wrap; 
  justify-content: center; 
  align-content: center; 
  align-items: center;
}

#tt-header.tt-header-01 #tt-desctop-menu nav > ul > li {
  display: inline-block;
  position: relative;
}

#tt-header.tt-header-01 #tt-desctop-menu nav > ul > li a {
  color: #ffffff;
  font-weight: 700;
  font-size: 15px;
  display: inline-block;
  position: relative;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

@media (min-width: 1230px) {
  #tt-header.tt-header-01 #tt-desctop-menu nav > ul > li a {
    padding: 9px 25px 15px;
  }
}

@media (max-width: 1229px) {
  #tt-header.tt-header-01 #tt-desctop-menu nav > ul > li a {
    padding: 9px 11px 15px;
  }
}

@media (max-width: 1024px) {
  #tt-header.tt-header-01 #tt-desctop-menu nav > ul > li a {
    color: #222222;
    padding: 9px 10px 10px;
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  #tt-header.tt-header-01 #tt-desctop-menu nav > ul > li a {
    padding: 9px 10px 15px;
  }
}

#tt-header.tt-header-01 #tt-desctop-menu nav > ul > li a:hover {
  color: #dd3d53;
}

#tt-header.tt-header-01 #tt-desctop-menu nav > ul > li:first-child > a {
  padding-left: 0;
}

#tt-header.tt-header-01 #tt-desctop-menu nav > ul > li:first-child ul {
  left: 0;
}

#tt-header.tt-header-01 #tt-desctop-menu nav > ul > li:last-child a {
  padding-right: 0;
}

#tt-header.tt-header-01 #tt-desctop-menu nav > ul > li.is-subMenu > a:after {
  content: '';
  display: inline-block;
  width: 3px;
  height: 5px;
  margin-top: 1px;
  margin-left: 9px;
  pointer-events: none;
  transition: all 0.15s ease-in-out;
  border: 3px solid transparent;
  border-top: 3px solid #ffffff;
}

@media (max-width: 1024px) {
  #tt-header.tt-header-01 #tt-desctop-menu nav > ul > li.is-subMenu > a:after {
    border-top-color: #222222;
  }
}

#tt-header.tt-header-01 #tt-desctop-menu nav > ul > li.is-active > a {
  color: #dd3d53;
}

#tt-header.tt-header-01 #tt-desctop-menu nav > ul > li.is-active > a:after {
  border-top-color: #dd3d53 !important;
}

#tt-header.tt-header-01 #tt-desctop-menu nav > ul > li ul {
  position: absolute;
  z-index: 1;
  opacity: 0;
  min-width: 211px;
  z-index: 1000;
  list-style: none;
  margin: 0;
  text-transform: uppercase;
  text-align: left;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: 0.2s ease 0s;
  transition: 0.2s ease 0s;
}

#tt-header.tt-header-01 #tt-desctop-menu nav > ul > li.is-hover ul {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: 0.3s ease 0.15s;
  transition: 0.3s ease 0.15s;
}

#tt-header.tt-header-01 #tt-desctop-menu nav > ul ul {
  background: #ffffff;
  top: auto;
  left: 28px;
  width: 211px;
  border-bottom: 3px solid #dd3d53;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  padding: 9px 0 11px 0;
  pointer-events: none;
  -moz-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2);
}

#tt-header.tt-header-01 #tt-desctop-menu nav > ul ul:before {
  content: '';
  display: block;
  height: 5px;
  margin-top: -5px;
  pointer-events: none;
  position: absolute;
  left: 23px;
  top: -7px;
  transition: all 0.15s ease-in-out;
  border: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
  z-index: 1;
}

#tt-header.tt-header-01 #tt-desctop-menu nav > ul ul:after {
  content: '';
  display: block;
  height: 5px;
  margin-top: -5px;
  pointer-events: none;
  position: absolute;
  left: 23px;
  top: -7px;
  z-index: 0;
  transition: all 0.15s ease-in-out;
  border: 6px solid transparent;
  border-bottom: 6px solid rgba(0, 0, 0, 0.2);
}

#tt-header.tt-header-01 #tt-desctop-menu nav > ul ul li a {
  color: #0e0e0e;
  font-size: 14px;
  line-height: 1.3;
  text-transform: uppercase;
  font-weight: 700;
  padding: 10px 20px 5px;
}

#tt-header.tt-header-01 #tt-desctop-menu nav > ul ul li a:hover {
  color: #dd3d53;
}

#tt-header.tt-header-01 #tt-desctop-menu nav > ul ul li.is-active > a {
  color: #dd3d53;
}

@media (max-width: 1229px) {
  #tt-header.tt-header-01 #tt-desctop-menu nav > ul ul {
    left: 11px;
  }
}

/*
Header stuck
*/
#tt-stuck {
  width: 100%;
  position: fixed;
  backface-visibility: hidden;
  top: -76px;
  z-index: 20;
  -webkit-box-shadow: 0 0px 8px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0 0px 8px rgba(0, 0, 0, 0.6);
  box-shadow: 0 0px 8px rgba(0, 0, 0, 0.6);
  background-color: #ffffff;
  -moz-transition: top 0.2s linear;
  -o-transition: top 0.2s linear;
  -webkit-transition: top 0.2s linear;
  transition: top 0.2s linear;
}

@media (min-width: 1025px) {
  #tt-stuck .tt-stuck-row {
    height: 76px;
  }
}

@media (max-width: 1024px) {
  #tt-stuck .tt-stuck-row {
    height: 56px;
  }
}

#tt-stuck .tt-stuck-row > [class^="col-"] {
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: 768px) {
  #tt-stuck .tt-stuck-row {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

@media (max-width: 767px) {
  #tt-stuck .tt-stuck-row {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  #tt-stuck .tt-stuck-row .tt-stuck-parent-logo {
    -webkit-flex: 2 1 auto;
    -ms-flex: 2 1 auto;
    flex: 2 1 auto;
  }
  #tt-stuck .tt-stuck-row .tt-menu-toggle {
    top: 8px;
  }
  #tt-stuck .tt-stuck-row .tt-logo {
    margin-top: 9px;
  }
}

#tt-stuck #tt-desctop-menu nav > ul {
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

#tt-stuck #tt-desctop-menu nav > ul > li > a:not(:hover) {
  color: #222222;
}

#tt-stuck #tt-desctop-menu nav > ul > li.is-active > a {
  color: #dd3d53;
}

#tt-stuck #tt-desctop-menu nav > ul > li.is-active > a:after {
  border-top-color: #dd3d53 !important;
}

#tt-stuck #tt-desctop-menu nav > ul > li.is-subMenu > a:after {
  border-top-color: #222222 !important;
}

#tt-stuck #tt-desctop-menu nav > ul ul {
  left: 15px;
}

#tt-stuck #tt-desctop-menu nav > ul ul li.is-active > a {
  color: #dd3d53 !important;
}

#tt-stuck.stuck {
  top: 0;
}

@media (max-width: 767px) {
  #tt-stuck {
    max-height: 56px;
  }
}

@media (max-width: 1024px) {
  body.touch #tt-header .tt-panel-info {
    padding-bottom: 2px;
  }
}

/* stuck tt-header-01*/
#tt-header.tt-header-01 #tt-stuck .col-logo {
  max-width: 157px;
  display: inline-block;
}

@media (max-width: 1229px) and (min-width: 768px) {
  #tt-header.tt-header-01 #tt-stuck .col-logo {
    display: none;
  }
}

#tt-header.tt-header-01 #tt-stuck .col-logo .tt-logo-dark {
  display: block;
}

#tt-header.tt-header-01 #tt-stuck .col-logo .tt-logo-light {
  display: none;
}

#tt-header.tt-header-01 #tt-stuck .col-logo .st0 {
  fill: #222222;
}

#tt-header.tt-header-01 #tt-stuck .col-logo .st1 {
  display: none;
}

#tt-header.tt-header-01 #tt-stuck .col-logo .st2 {
  display: inline;
  fill: #222222;
}

#tt-header.tt-header-01 #tt-stuck .col-logo .st3 {
  display: inline;
  fill: #222222;
}

#tt-header.tt-header-01 #tt-stuck .col-logo .st4 {
  fill: #dd3d53;
}

#tt-header.tt-header-01 #tt-stuck .col-logo .svg-img {
  width: auto;
  height: 40px;
}

@media (max-width: 767px) {
  #tt-header.tt-header-01 #tt-stuck .col-logo .svg-img {
    height: 34px;
  }
}

@media (max-width: 1024px) {
  #tt-header.tt-header-01 #tt-stuck .col-btn-toggle-modal {
    display: none;
  }
}

@media (min-width: 768px) {
  #tt-header.tt-header-01 #tt-stuck .col-menu-toggle {
    display: none;
  }
}

#tt-header.tt-header-01 #tt-stuck .col-menu {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

@media (min-width: 1230px) {
  #tt-header.tt-header-01 #tt-stuck .col-menu {
    -webkit-flex: 2 1 auto;
    -ms-flex: 2 1 auto;
    flex: 2 1 auto;
  }
}

#tt-header.tt-header-01 #tt-stuck #tt-desctop-menu nav > ul > li > a {
  padding: 20px 10px;
}

@media (max-width: 1024px) {
  #tt-header.tt-header-01 #tt-stuck #tt-desctop-menu nav > ul > li > a {
    padding: 15px 10px;
  }
}

@media (max-width: 780px) {
  #tt-header.tt-header-01 #tt-stuck #tt-desctop-menu nav > ul > li > a {
    padding: 15px 9px;
  }
}

/*
	tt-header-02(MotoLeader)
*/
#tt-header.tt-header-02 {
  position: relative;
  z-index: 3;
  /*
		panel-info(desctope top line)
	*/
  /*
		quickLinks(mobile top line)
	*/
  /*
		header-holder layout
	*/
  /*
		header-obj
	*/
  /* desctop-menu */
}

@media (min-width: 1025px) {
  #tt-header.tt-header-02.tt-on-top {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    pointer-events: none;
  }
  #tt-header.tt-header-02.tt-on-top > * {
    pointer-events: auto;
  }
}

#tt-header.tt-header-02:not(.tt-on-top) {
  height: 190px;
}

@media (min-width: 1025px) {
  #tt-header.tt-header-02:not(.tt-on-top) {
    background: url("/images/custom/header-sub-menu.jpg") 0 0 no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}

@media (max-width: 767px) {
  #tt-header.tt-header-02:not(.tt-on-top) {
    height: 127px;
  }
}

@media (max-width: 767px) {
  #tt-header.tt-header-02 .tt-panel-info {
    display: none;
  }
}

@media (min-width: 1025px) {
  #tt-header.tt-header-02 .tt-panel-info {
    background-color: #ffffff;
  }
  #tt-header.tt-header-02 .tt-panel-info .nice-select.tt-skin-02 {
    height: 30px;
  }
}

@media (min-width: 1025px) {
  #tt-header.tt-header-02 .tt-panel-info .col-btn-toggle-modal {
    display: none;
  }
}

#tt-header.tt-header-02 .tt-panel-info .nice-select.tt-skin-02 {
  color: #222222;
  font-weight: 700;
}

#tt-header.tt-header-02 .tt-panel-info .nice-select.tt-skin-02:after {
  border-top-color: #222222;
}

#tt-header.tt-header-02 .tt-panel-info .tt-wrapper-icon {
  margin-top: 34px;
  margin-bottom: 30px;
}

#tt-header.tt-header-02 .tt-box-info {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 12px;
}

#tt-header.tt-header-02 .tt-box-info li {
  text-transform: uppercase;
  position: relative;
  color: #222222;
  padding: 0 0 6px 39px;
  font-size: 12px;
  line-height: 1.7;
  font-weight: 600;
}

#tt-header.tt-header-02 .tt-box-info li a {
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
  color: #222222;
}

#tt-header.tt-header-02 .tt-box-info li a:hover {
  color: #dd3d53;
}

#tt-header.tt-header-02 .tt-box-info li i {
  font-size: 16px;
  line-height: 1.9;
  width: 30px;
  height: 30px;
  text-align: center;
  background: #dd3d53;
  color: #ffffff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  top: -2px;
  left: 0;
}

#tt-header.tt-header-02 .tt-box-info li:not(:first-child) {
  margin-left: 50px;
}

@media (min-width: 1230px) {
  #tt-header.tt-header-02 .tt-box-info {
    margin: 40px 0 40px 57px;
  }
}

@media (max-width: 1229px) {
  #tt-header.tt-header-02 .tt-box-info {
    margin: 0px 0 16px 0px;
  }
}

@media (max-width: 1024px) {
  #tt-header.tt-header-02 .tt-box-info li:not(:first-child) {
    margin-left: 20px;
  }
}

#tt-header.tt-header-02 .tt-dropdown-obj {
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  margin: 0 20px 0 0px;
  position: relative;
  top: 3px;
}

#tt-header.tt-header-02 .tt-dropdown-obj:last-child {
  margin-right: 0 !important;
}

#tt-header.tt-header-02 .tt-dropdown-obj .tt-dropdown-toggle {
  font-size: 18px;
  line-height: 1;
  color: #dd3d53;
  display: inline-block;
  padding: 6px 3px 4px 3px;
  position: relative;
  -moz-transition: 0.2s linear;
  -o-transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  transition: 0.2s linear;
}

#tt-header.tt-header-02 .tt-dropdown-obj .tt-dropdown-toggle:hover {
  color: #222222;
}

#tt-header.tt-header-02 .tt-dropdown-obj .tt-dropdown-toggle:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
}

@media (max-width: 1229px) {
  #tt-header.tt-header-02 .tt-dropdown-obj {
    margin: 0 17px 0 0px;
  }
}

@media (max-width: 1024px) {
  #tt-header.tt-header-02 .tt-dropdown-obj {
    margin: 0 5px 0 0px;
  }
  #tt-header.tt-header-02 .tt-dropdown-obj .tt-dropdown-toggle {
    font-size: 16px;
  }
}

#tt-header.tt-header-02 .tt-dropdown-obj.active .tt-dropdown-toggle {
  color: #222222;
}

#tt-header.tt-header-02 .tt-searcher .tt-dropdown-menu {
  position: fixed;
  opacity: 0;
  width: 100%;
  text-align: left;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: 0.2s ease 0s;
  transition: 0.2s ease 0s;
  background: #ffffff;
  right: 0;
  left: 0;
  z-index: 22;
  -webkit-box-shadow: 0 0px 40px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0px 40px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0px 40px rgba(0, 0, 0, 0.1);
  border-bottom: 3px solid #dd3d53;
}

@media (min-width: 1025px) {
  #tt-header.tt-header-02 .tt-searcher .tt-dropdown-menu {
    top: 80px;
  }
}

@media (max-width: 1024px) {
  #tt-header.tt-header-02 .tt-searcher .tt-dropdown-menu {
    top: 80px;
  }
}

@media (max-width: 766px) {
  #tt-header.tt-header-02 .tt-searcher .tt-dropdown-menu {
    top: 28px;
  }
}

#tt-header.tt-header-02 .tt-searcher .tt-dropdown-menu form {
  position: relative;
  display: block;
}

#tt-header.tt-header-02 .tt-searcher .tt-dropdown-menu .tt-row-search {
  height: 70px;
}

#tt-header.tt-header-02 .tt-searcher .tt-dropdown-menu .tt-search-input {
  font-family: "Open Sans", sans-serif;
  background: transparent;
  font-size: 20px;
  font-weight: 500;
  border: none;
  padding: 24px 12px 10px 0px;
  margin-left: 38px;
  box-shadow: none;
  outline: none;
  width: calc(100% - 71px);
  color: #5f5f5f;
  border-radius: 0;
  transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}

#tt-header.tt-header-02 .tt-searcher .tt-dropdown-menu .tt-search-input:not(focus)::-webkit-input-placeholder {
  color: #5f5f5f;
}

#tt-header.tt-header-02 .tt-searcher .tt-dropdown-menu .tt-search-input:not(focus)::-moz-placeholder {
  color: #5f5f5f;
}

#tt-header.tt-header-02 .tt-searcher .tt-dropdown-menu .tt-search-input:not(focus):-moz-placeholder {
  color: #5f5f5f;
}

#tt-header.tt-header-02 .tt-searcher .tt-dropdown-menu .tt-search-input:not(focus):-ms-input-placeholder {
  color: #5f5f5f;
}

#tt-header.tt-header-02 .tt-searcher .tt-dropdown-menu .tt-btn-search {
  color: #5f5f5f;
  font-size: 20px;
  line-height: 1;
  font-family: "carleader";
  position: absolute;
  cursor: pointer;
  top: 22px;
  padding: 4px 0 4px 4px;
  left: 0px;
  z-index: 3;
  display: inline-block;
  background: none;
  border: none;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

#tt-header.tt-header-02 .tt-searcher .tt-dropdown-menu .tt-btn-search:before {
  content: "\e909";
}

#tt-header.tt-header-02 .tt-searcher .tt-dropdown-menu .tt-btn-search:hover {
  color: #dd3d53;
}

#tt-header.tt-header-02 .tt-searcher .tt-dropdown-menu .tt-btn-close {
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  position: absolute;
  top: 20px;
  right: 0;
  color: #5f5f5f;
  font-size: 20px;
  line-height: 1;
  font-family: "carleader";
  padding: 5px 0px 5px 5px;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

#tt-header.tt-header-02 .tt-searcher .tt-dropdown-menu .tt-btn-close:before {
  font-family: "carleader";
  content: "\e933";
  line-height: 1;
}

#tt-header.tt-header-02 .tt-searcher .tt-dropdown-menu .tt-btn-close:hover {
  color: #dd3d53;
}

@media (max-width: 765px) {
  #tt-header.tt-header-02 .tt-searcher .tt-dropdown-menu .tt-row-search {
    height: 60px;
  }
  #tt-header.tt-header-02 .tt-searcher .tt-dropdown-menu .tt-search-input {
    font-size: 18px;
    padding-top: 18px;
  }
  #tt-header.tt-header-02 .tt-searcher .tt-dropdown-menu .tt-btn-close {
    top: 15px;
  }
  #tt-header.tt-header-02 .tt-searcher .tt-dropdown-menu .tt-btn-search {
    top: 17px;
  }
}

#tt-header.tt-header-02 .tt-searcher:not(.active) .tt-dropdown-menu {
  pointer-events: none;
}

@media (max-width: 1024px) {
  #tt-header.tt-header-02 .tt-searcher .tt-dropdown-toggle {
    font-size: 16px;
  }
}

#tt-header.tt-header-02 .tt-searcher.active .tt-dropdown-menu {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: 0.3s ease 0.15s;
  transition: 0.3s ease 0.15s;
}

@media (max-width: 765px) {
  #tt-header.tt-header-02 .tt-searcher .container {
    max-width: 100%;
  }
}

#tt-header.tt-header-02 .tt-language {
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  margin-right: 20px;
}

#tt-header.tt-header-02 .tt-language select {
  opacity: 0;
}

@media (max-width: 1229px) {
  #tt-header.tt-header-02 .tt-language {
    margin-right: 17px;
  }
}

@media (max-width: 1024px) {
  #tt-header.tt-header-02 .tt-language {
    margin-right: 12px;
  }
}

#tt-header.tt-header-02 .tt-cart {
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  position: relative;
}

@media (min-width: 768px) {
  #tt-header.tt-header-02 .tt-cart {
    top: 3px;
  }
}

#tt-header.tt-header-02 .tt-cart .tt-dropdown-toggle {
  font-size: 18px;
  line-height: 1;
  color: #dd3d53;
  display: inline-block;
  padding: 6px 0 4px 5px;
  position: relative;
  -moz-transition: 0.2s linear;
  -o-transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  transition: 0.2s linear;
}

#tt-header.tt-header-02 .tt-cart .tt-dropdown-toggle i {
  pointer-events: none;
}

#tt-header.tt-header-02 .tt-cart .tt-dropdown-toggle:hover {
  color: #222222;
}

#tt-header.tt-header-02 .tt-cart .tt-dropdown-menu {
  position: absolute;
  opacity: 0;
  width: 299px;
  margin: 15px 0 0 0;
  text-align: left;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: 0.2s ease 0s;
  transition: 0.2s ease 0s;
  background: #ffffff;
  right: 0;
  top: 100%;
  z-index: 22;
  -webkit-box-shadow: 0 0px 40px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0px 40px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0px 40px rgba(0, 0, 0, 0.1);
  border-bottom: 3px solid #dd3d53;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}

#tt-header.tt-header-02 .tt-cart .tt-dropdown-menu:before {
  content: '';
  display: block;
  height: 5px;
  margin-top: -5px;
  pointer-events: none;
  position: absolute;
  right: 23px;
  top: -7px;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  border: 7px solid transparent;
  border-bottom: 7px solid #ffffff;
  z-index: 1;
}

#tt-header.tt-header-02 .tt-cart .tt-dropdown-menu:after {
  content: '';
  display: block;
  height: 5px;
  margin-top: -5px;
  pointer-events: none;
  position: absolute;
  right: 23px;
  top: -7px;
  z-index: 0;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  border: 7px solid transparent;
  border-bottom: 7px solid rgba(0, 0, 0, 0.2);
}

#tt-header.tt-header-02 .tt-cart .tt-dropdown-menu .tt-dropdown-title {
  font-family: "Montserrat", sans-serif;
  color: #222222;
  font-weight: 600;
  border-bottom: 1px solid #e8e8e8;
  position: relative;
  padding: 15px 7px 14px 22px;
}

#tt-header.tt-header-02 .tt-cart .tt-dropdown-menu .tt-dropdown-title .tt-btn-close {
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  color: #dd3d53;
  position: absolute;
  right: 15px;
  top: 17px;
  cursor: pointer;
}

#tt-header.tt-header-02 .tt-cart .tt-dropdown-menu .tt-dropdown-title .tt-btn-close:hover {
  color: #222222;
}

#tt-header.tt-header-02 .tt-cart .tt-dropdown-menu .tt-search-results {
  padding: 0 20px 0 20px;
}

#tt-header.tt-header-02 .tt-cart .tt-dropdown-menu .tt-search-results li {
  margin: 24px 0 0 0;
  position: relative;
}

#tt-header.tt-header-02 .tt-cart .tt-dropdown-menu .tt-search-results li a {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-right: 18px;
}

#tt-header.tt-header-02 .tt-cart .tt-dropdown-menu .tt-search-results li a .thumbnail {
  width: 89px;
  min-width: 89px;
}

#tt-header.tt-header-02 .tt-cart .tt-dropdown-menu .tt-search-results li a .tt-description {
  padding: 0 5px 0 12px;
}

#tt-header.tt-header-02 .tt-cart .tt-dropdown-menu .tt-search-results li a .tt-description .tt-title {
  font-size: 15px;
  line-height: 22px;
  color: #222222;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

#tt-header.tt-header-02 .tt-cart .tt-dropdown-menu .tt-search-results li a .tt-description .tt-price {
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  color: #222222;
  font-family: "Open Sans", sans-serif;
  display: inline-block;
  margin-top: 9px;
}

#tt-header.tt-header-02 .tt-cart .tt-dropdown-menu .tt-search-results li a:hover .tt-description .tt-title {
  color: #dd3d53;
}

#tt-header.tt-header-02 .tt-cart .tt-dropdown-menu .tt-search-results li .tt-close-item {
  color: #222222;
  font-size: 12px;
  padding: 5px 0px 5px 5px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

#tt-header.tt-header-02 .tt-cart .tt-dropdown-menu .tt-search-results li .tt-close-item:hover {
  color: #dd3d53;
}

#tt-header.tt-header-02 .tt-cart .tt-dropdown-menu .tt-search-bottom {
  border-top: 1px solid #e8e8e8;
  margin-top: 24px;
  padding: 12px 20px 23px 20px;
}

#tt-header.tt-header-02 .tt-cart .tt-dropdown-menu .tt-search-bottom .price-total {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  font-size: 18px;
  line-height: 1.5;
  color: #222222;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}

#tt-header.tt-header-02 .tt-cart .tt-dropdown-menu .tt-search-bottom .price-total label {
  padding-right: 10px;
}

#tt-header.tt-header-02 .tt-cart .tt-dropdown-menu .tt-search-bottom .price-total .tt-price {
  padding-left: 10px;
  font-size: 18px;
  line-height: 1.4;
  color: #222222;
}

#tt-header.tt-header-02 .tt-cart .tt-dropdown-menu .tt-search-bottom .btn {
  width: 100%;
  margin-top: 9px;
}

#tt-header.tt-header-02 .tt-cart:not(.active) .tt-dropdown-menu {
  pointer-events: none;
}

@media (max-width: 1024px) {
  #tt-header.tt-header-02 .tt-cart .tt-dropdown-toggle {
    font-size: 16px;
  }
}

#tt-header.tt-header-02 .tt-cart.active .tt-dropdown-menu {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: 0.3s ease 0.15s;
  transition: 0.3s ease 0.15s;
}

#tt-header.tt-header-02 .tt-account {
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  position: relative;
}

@media (min-width: 768px) {
  #tt-header.tt-header-02 .tt-account {
    top: 3px;
  }
}

#tt-header.tt-header-02 .tt-account .tt-dropdown-toggle {
  font-size: 18px;
  line-height: 1;
  color: #dd3d53;
  display: inline-block;
  padding: 6px 0 4px 5px;
  position: relative;
  -moz-transition: 0.2s linear;
  -o-transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  transition: 0.2s linear;
}

#tt-header.tt-header-02 .tt-account .tt-dropdown-toggle i {
  pointer-events: none;
}

#tt-header.tt-header-02 .tt-account .tt-dropdown-toggle:hover {
  color: #222222;
}

#tt-header.tt-header-02 .tt-account .tt-dropdown-menu {
  position: absolute;
  opacity: 0;
  width: 230px;
  margin: 15px 0 0 0;
  padding: 0 20px 20px;
  text-align: left;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: 0.2s ease 0s;
  transition: 0.2s ease 0s;
  background: #ffffff;
  right: -23px;
  top: 100%;
  z-index: 22;
  -webkit-box-shadow: 0 0px 40px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0px 40px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0px 40px rgba(0, 0, 0, 0.1);
  border-bottom: 3px solid #dd3d53;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}

#tt-header.tt-header-02 .tt-account .tt-dropdown-menu:before {
  content: '';
  display: block;
  height: 5px;
  margin-top: -5px;
  pointer-events: none;
  position: absolute;
  right: 23px;
  top: -7px;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  border: 7px solid transparent;
  border-bottom: 7px solid #ffffff;
  z-index: 1;
}

#tt-header.tt-header-02 .tt-account .tt-dropdown-menu:after {
  content: '';
  display: block;
  height: 5px;
  margin-top: -5px;
  pointer-events: none;
  position: absolute;
  right: 23px;
  top: -7px;
  z-index: 0;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  border: 7px solid transparent;
  border-bottom: 7px solid rgba(0, 0, 0, 0.2);
}

#tt-header.tt-header-02 .tt-account .tt-dropdown-menu .tt-row-close {
  position: relative;
  padding: 15px 0px 14px 22px;
}

#tt-header.tt-header-02 .tt-account .tt-dropdown-menu .tt-row-close .tt-btn-close {
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  color: #222222;
  position: absolute;
  right: -4px;
  top: 17px;
  cursor: pointer;
}

#tt-header.tt-header-02 .tt-account .tt-dropdown-menu .tt-row-close .tt-btn-close:hover {
  color: #dd3d53;
}

#tt-header.tt-header-02 .tt-account .tt-dropdown-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

#tt-header.tt-header-02 .tt-account .tt-dropdown-menu ul li {
  color: #222222;
  font-size: 16px;
}

#tt-header.tt-header-02 .tt-account .tt-dropdown-menu ul li a {
  color: #222222;
  display: block;
  padding: 4px 0;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

#tt-header.tt-header-02 .tt-account .tt-dropdown-menu ul li a:hover {
  color: #dd3d53;
}

#tt-header.tt-header-02 .tt-account:not(.active) .tt-dropdown-menu {
  pointer-events: none;
}

@media (max-width: 1024px) {
  #tt-header.tt-header-02 .tt-account .tt-dropdown-toggle {
    font-size: 16px;
  }
}

#tt-header.tt-header-02 .tt-account.active .tt-dropdown-menu {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: 0.3s ease 0.15s;
  transition: 0.3s ease 0.15s;
}

#tt-header.tt-header-02 .tt-quickLinks-popup {
  position: relative;
}

@media (min-width: 768px) {
  #tt-header.tt-header-02 .tt-quickLinks-popup {
    display: none;
  }
}

@media (min-width: 766px) {
  #tt-header.tt-header-02 .tt-mobile-quickLinks {
    display: none;
  }
}

@media (max-width: 767px) {
  #tt-header.tt-header-02 .tt-mobile-quickLinks {
    display: block;
    background-color: #222222;
  }
  #tt-header.tt-header-02 .tt-mobile-quickLinks .row .col {
    text-align: center;
    line-height: 24px;
    display: inline-block;
  }
  #tt-header.tt-header-02 .tt-mobile-quickLinks .row .col:not(:last-child) {
    border-right: 1px solid #3a3a3a;
  }
  #tt-header.tt-header-02 .tt-mobile-quickLinks .row .col:not(:first-child) {
    border-left: 1px solid #181818;
  }
  #tt-header.tt-header-02 .tt-mobile-quickLinks .row .col.tt-cart {
    display: block;
  }
  #tt-header.tt-header-02 .tt-mobile-quickLinks .row .col a.btn-toggle {
    color: #dd3d53;
    display: block;
    height: 28px;
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    -moz-transition: color 0.2s linear;
    -o-transition: color 0.2s linear;
    -webkit-transition: color 0.2s linear;
    transition: color 0.2s linear;
  }
  #tt-header.tt-header-02 .tt-mobile-quickLinks .row .col a.btn-toggle i {
    position: relative;
    top: 3px;
  }
  #tt-header.tt-header-02 .tt-mobile-quickLinks .row .col a.btn-toggle:hover {
    color: #ffffff;
  }
  #tt-header.tt-header-02 .tt-mobile-quickLinks .row .col a.btn-toggle.active {
    color: #ffffff;
  }
  #tt-header.tt-header-02 .tt-mobile-quickLinks .row .col .nice-select.tt-skin-02 {
    display: inline-block;
    float: inherit;
    height: 25px;
    padding: 5px 12px 2px 2px;
    color: #ffffff;
    min-width: 37px;
  }
  #tt-header.tt-header-02 .tt-mobile-quickLinks .row .col .nice-select.tt-skin-02:after {
    border-top-color: #ffffff;
    margin-top: -1px;
  }
  #tt-header.tt-header-02 .tt-mobile-quickLinks .row .col .nice-select.tt-skin-02 .list {
    background: #ffffff;
    color: #222222;
    -webkit-box-shadow: 0 0px 8px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0px 8px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0px 8px rgba(0, 0, 0, 0.1);
  }
  #tt-header.tt-header-02 .tt-mobile-quickLinks .row .quickLinks-layout {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    background-color: #f5f5f5;
    color: #424242;
    padding: 30px;
    top: 100%;
    left: 0;
    transform: translateY(-20px);
    z-index: -1;
    transition: opacity 0.3s ease-in-out 0s, transform 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
    pointer-events: none;
    text-align: left;
    text-transform: none;
    -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  }
}

#tt-header.tt-header-02 .quickLinks-map address {
  font-size: 12px;
  line-height: 1.1;
  margin: 0;
  padding: 4px 10px 9px 13px;
  color: #707070;
  text-transform: uppercase;
}

#tt-header.tt-header-02 .quickLinks-map address i {
  color: #dd3d53;
  font-size: 18px;
  line-height: 1.1;
  margin: 0 10px 0 0;
  position: relative;
  top: 3px;
}

#tt-header.tt-header-02 .quickLinks-address {
  padding: 13px 10px 13px 13px;
}

#tt-header.tt-header-02 .quickLinks-address i {
  color: #dd3d53;
  font-size: 18px;
  line-height: 1.1;
  margin: 0 10px 0 0;
  position: relative;
  top: 3px;
}

#tt-header.tt-header-02 .quickLinks-address .tt-title {
  font-size: 19px;
  line-height: 1.2;
  font-family: "Montserrat", sans-serif;
  color: #222222;
  font-weight: 600;
  margin: 0;
  padding: 0 0 8px 0;
}

#tt-header.tt-header-02 .quickLinks-address ul li {
  color: #707070;
}

#tt-header.tt-header-02 .quickLinks-address ul li a {
  color: #707070;
  text-decoration: none;
}

#tt-header.tt-header-02 .quickLinks-address ul li:not(:first-child) {
  margin-top: 6px;
}

#tt-header.tt-header-02 .header-holder {
  position: relative;
  z-index: 7;
  padding-top: 9px;
  padding-bottom: 8px;
}

#tt-header.tt-header-02 .header-holder .col-logo {
  z-index: 2;
  margin-right: 15px;
  -webkit-flex: 2 1 auto;
  -ms-flex: 2 1 auto;
  flex: 2 1 auto;
}

#tt-header.tt-header-02 .header-holder .col-logo .tt-logo {
  max-width: 131px;
}

@media (min-width: 768px) {
  #tt-header.tt-header-02 .header-holder .col-logo {
    display: none;
  }
}

@media (min-width: 768px) {
  #tt-header.tt-header-02 .header-holder {
    background-color: #1a1a21;
  }
  #tt-header.tt-header-02 .header-holder .col-menu-toggle {
    display: none;
  }
}

@media (min-width: 1025px) {
  #tt-header.tt-header-02 .header-holder {
    min-height: 65px;
  }
}

@media (max-width: 1024px) {
  #tt-header.tt-header-02 .header-holder {
    min-height: 60px;
  }
  #tt-header.tt-header-02 .header-holder .col-btn-toggle-modal {
    display: none;
  }
}

@media (max-width: 767px) {
  #tt-header.tt-header-02 .header-holder {
    min-height: 82px;
  }
  #tt-header.tt-header-02 .header-holder .container {
    max-width: 100%;
  }
  #tt-header.tt-header-02 .header-holder .col-menu,
  #tt-header.tt-header-02 .header-holder .col-btn-toggle-modal {
    display: none;
  }
  #tt-header.tt-header-02 .header-holder .col-logo {
    margin-left: 15px;
  }
  #tt-header.tt-header-02 .header-holder .col-menu-toggle {
    display: inline-block;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  #tt-header.tt-header-02 .header-holder .col-menu-toggle .tt-menu-toggle {
    padding-top: 15px;
    padding-bottom: 15px;
    display: inline-block;
  }
}

#tt-header.tt-header-02 .tt-logo {
  max-width: 224px;
  margin-top: 24px;
  margin-bottom: 24px;
  display: inline-block;
}

#tt-header.tt-header-02 .tt-logo img {
  max-width: 100%;
  height: auto;
}

@media (max-width: 1230px) {
  #tt-header.tt-header-02 .tt-logo {
    max-width: 170px;
  }
}

@media (max-width: 1024px) {
  #tt-header.tt-header-02 .tt-logo {
    max-width: 160px;
    margin-top: 22px;
    margin-bottom: 22px;
  }
}

#tt-header.tt-header-02 .tt-menu-toggle {
  color: #222222;
  font-size: 26px;
  line-height: 1.2em;
  padding: 5px;
  z-index: 3;
  outline: none;
  position: relative;
  top: 2px;
  display: inline-block !important;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

#tt-header.tt-header-02 .tt-menu-toggle:hover {
  color: #dd3d53;
}

#tt-header.tt-header-02 .btn.btn-toggle-modal {
  padding-left: 15px;
  padding-right: 18px;
  padding-top: 5px;
  position: relative;
  z-index: 2;
}

#tt-header.tt-header-02 .btn.btn-toggle-modal i {
  font-size: 27px;
  margin-top: -3px;
  margin-right: 7px;
}

@media (max-width: 1024px) {
  #tt-header.tt-header-02 .btn.btn-toggle-modal {
    padding-top: 4px;
    height: 38px;
  }
  #tt-header.tt-header-02 .btn.btn-toggle-modal i {
    font-size: 18px;
  }
}

#tt-header.tt-header-02 .btn.btn-toggle-modal:hover {
  background: #ffffff;
  color: #222222;
}

@media (max-width: 767px) {
  #tt-header.tt-header-02 #tt-desctop-menu {
    display: none;
  }
}

#tt-header.tt-header-02 #tt-desctop-menu nav > ul {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

#tt-header.tt-header-02 #tt-desctop-menu nav > ul > li {
  display: inline-block;
  position: relative;
}

#tt-header.tt-header-02 #tt-desctop-menu nav > ul > li a {
  color: #ffffff;
  font-weight: 700;
  font-size: 15px;
  display: inline-block;
  position: relative;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

@media (min-width: 1230px) {
  #tt-header.tt-header-02 #tt-desctop-menu nav > ul > li a {
    padding: 9px 25px 15px;
  }
}

@media (max-width: 1229px) {
  #tt-header.tt-header-02 #tt-desctop-menu nav > ul > li a {
    padding: 9px 11px 15px;
  }
}

@media (max-width: 1024px) {
  #tt-header.tt-header-02 #tt-desctop-menu nav > ul > li a {
    padding: 9px 10px 10px;
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  #tt-header.tt-header-02 #tt-desctop-menu nav > ul > li a {
    padding: 9px 10px 15px;
  }
}

#tt-header.tt-header-02 #tt-desctop-menu nav > ul > li a:hover {
  color: #dd3d53;
}

#tt-header.tt-header-02 #tt-desctop-menu nav > ul > li:first-child > a {
  padding-left: 0;
}

#tt-header.tt-header-02 #tt-desctop-menu nav > ul > li:first-child ul {
  left: 0;
}

#tt-header.tt-header-02 #tt-desctop-menu nav > ul > li:last-child a {
  padding-right: 0;
}

#tt-header.tt-header-02 #tt-desctop-menu nav > ul > li.is-subMenu > a:after {
  content: '';
  display: inline-block;
  width: 3px;
  height: 5px;
  margin-top: 1px;
  margin-left: 9px;
  pointer-events: none;
  transition: all 0.15s ease-in-out;
  border: 3px solid transparent;
  border-top: 3px solid #ffffff;
}

#tt-header.tt-header-02 #tt-desctop-menu nav > ul > li.is-active > a {
  color: #dd3d53;
}

#tt-header.tt-header-02 #tt-desctop-menu nav > ul > li.is-active > a:after {
  border-top-color: #dd3d53 !important;
}

#tt-header.tt-header-02 #tt-desctop-menu nav > ul > li ul {
  position: absolute;
  z-index: 1;
  opacity: 0;
  min-width: 211px;
  z-index: 1000;
  list-style: none;
  margin: 0;
  text-transform: uppercase;
  text-align: left;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: 0.2s ease 0s;
  transition: 0.2s ease 0s;
}

#tt-header.tt-header-02 #tt-desctop-menu nav > ul > li.is-hover ul {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: 0.3s ease 0.15s;
  transition: 0.3s ease 0.15s;
}

#tt-header.tt-header-02 #tt-desctop-menu nav > ul ul {
  background: #ffffff;
  top: auto;
  left: 28px;
  width: 211px;
  border-bottom: 3px solid #dd3d53;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  padding: 9px 0 11px 0;
  pointer-events: none;
  -moz-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2);
}

#tt-header.tt-header-02 #tt-desctop-menu nav > ul ul:before {
  content: '';
  display: block;
  height: 5px;
  margin-top: -5px;
  pointer-events: none;
  position: absolute;
  left: 23px;
  top: -7px;
  transition: all 0.15s ease-in-out;
  border: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
  z-index: 1;
}

#tt-header.tt-header-02 #tt-desctop-menu nav > ul ul:after {
  content: '';
  display: block;
  height: 5px;
  margin-top: -5px;
  pointer-events: none;
  position: absolute;
  left: 23px;
  top: -7px;
  z-index: 0;
  transition: all 0.15s ease-in-out;
  border: 6px solid transparent;
  border-bottom: 6px solid rgba(0, 0, 0, 0.2);
}

#tt-header.tt-header-02 #tt-desctop-menu nav > ul ul li a {
  color: #0e0e0e;
  font-size: 14px;
  line-height: 1.3;
  text-transform: uppercase;
  font-weight: 700;
  padding: 10px 20px 5px;
}

#tt-header.tt-header-02 #tt-desctop-menu nav > ul ul li a:hover {
  color: #dd3d53;
}

#tt-header.tt-header-02 #tt-desctop-menu nav > ul ul li.is-active > a {
  color: #dd3d53;
}

@media (max-width: 1229px) {
  #tt-header.tt-header-02 #tt-desctop-menu nav > ul ul {
    left: 11px;
  }
}

/* stuck tt-header-02*/
#tt-header.tt-header-02 #tt-stuck .col-logo {
  display: inline-block;
}

#tt-header.tt-header-02 #tt-stuck .col-logo .tt-logo {
  max-width: 140px;
}

@media (max-width: 1229px) and (min-width: 768px) {
  #tt-header.tt-header-02 #tt-stuck .col-logo {
    display: none;
  }
}

#tt-header.tt-header-02 #tt-stuck .col-logo .tt-logo-dark {
  display: block;
}

#tt-header.tt-header-02 #tt-stuck .col-logo .tt-logo-light {
  display: none;
}

#tt-header.tt-header-02 #tt-stuck .btn.btn-toggle-modal:hover:hover {
  background: #222222;
  color: #ffffff;
}

@media (max-width: 1024px) {
  #tt-header.tt-header-02 #tt-stuck .col-btn-toggle-modal {
    display: none;
  }
}

@media (min-width: 768px) {
  #tt-header.tt-header-02 #tt-stuck .col-menu-toggle {
    display: none;
  }
}

#tt-header.tt-header-02 #tt-stuck .col-menu {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

@media (min-width: 1230px) {
  #tt-header.tt-header-02 #tt-stuck .col-menu {
    -webkit-flex: 2 1 auto;
    -ms-flex: 2 1 auto;
    flex: 2 1 auto;
  }
}

#tt-header.tt-header-02 #tt-stuck #tt-desctop-menu nav > ul > li > a {
  padding: 20px 10px;
}

@media (max-width: 1024px) {
  #tt-header.tt-header-02 #tt-stuck #tt-desctop-menu nav > ul > li > a {
    padding: 15px 10px;
  }
}

@media (max-width: 780px) {
  #tt-header.tt-header-02 #tt-stuck #tt-desctop-menu nav > ul > li > a {
    padding: 15px 9px;
  }
}

#tt-header.tt-header-02 #tt-stuck #tt-desctop-menu nav > ul > li:not(.active) a:not(:hover) {
  color: #222222;
}

#tt-header.tt-header-02 #tt-stuck #tt-desctop-menu nav > ul > li.is-active > a {
  color: #dd3d53 !important;
}

@media (max-width: 767px) {
  #tt-header.tt-header-02 #tt-stuck .tt-menu-toggle {
    top: 8px;
  }
  #tt-header.tt-header-02 #tt-stuck .col-logo .tt-logo {
    max-width: 120px;
    margin-top: 8px;
  }
}

/*
	tt-header-03(Yacht)
*/
#tt-header.tt-header-03 {
  position: relative;
  z-index: 3;
  /*
		panel-info(desctope top line)
	*/
  /*
		quickLinks(mobile top line)
	*/
  /*
		header-holder layout
	*/
  /*
		header-obj
	*/
  /* desctop-menu */
}

@media (min-width: 1025px) {
  #tt-header.tt-header-03.tt-on-top {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    pointer-events: none;
  }
  #tt-header.tt-header-03.tt-on-top > * {
    pointer-events: auto;
  }
}

#tt-header.tt-header-03:not(.tt-on-top) {
  background: #ffffff;
}

#tt-header.tt-header-03 .tt-panel-info {
  -webkit-box-shadow: 0 0px 40px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0px 40px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0px 40px rgba(0, 0, 0, 0.1);
}

@media (max-width: 767px) {
  #tt-header.tt-header-03 .tt-panel-info {
    display: none;
  }
}

@media (min-width: 1025px) {
  #tt-header.tt-header-03 .tt-panel-info .row {
    height: 43px;
  }
  #tt-header.tt-header-03 .tt-panel-info .nice-select.tt-skin-02 {
    height: 30px;
  }
}

@media (max-width: 1024px) {
  #tt-header.tt-header-03 .tt-panel-info .row {
    height: 28px;
    position: relative;
    top: -1px;
  }
  #tt-header.tt-header-03 .tt-panel-info .nice-select.tt-skin-02 {
    height: 27px;
  }
}

#tt-header.tt-header-03 .tt-box-info {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 12px;
}

#tt-header.tt-header-03 .tt-box-info li {
  text-transform: uppercase;
  color: #222222;
}

#tt-header.tt-header-03 .tt-box-info li a {
  color: #222222;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

#tt-header.tt-header-03 .tt-box-info li a:hover {
  color: #dd3d53;
}

#tt-header.tt-header-03 .tt-box-info li i {
  font-size: 18px;
  line-height: 1;
  color: #dd3d53;
  margin-right: 7px;
  position: relative;
  top: 3px;
}

@media (min-width: 1230px) {
  #tt-header.tt-header-03 .tt-box-info li:not(:last-child) {
    margin-right: 40px;
  }
}

@media (max-width: 1229px) {
  #tt-header.tt-header-03 .tt-box-info li:not(:last-child) {
    margin-right: 22px;
  }
}

@media (max-width: 1024px) {
  #tt-header.tt-header-03 .tt-box-info li:not(:last-child) {
    margin-right: 10px;
  }
}

@media (max-width: 1024px) {
  #tt-header.tt-header-03 .tt-box-info {
    font-size: 10px;
  }
  #tt-header.tt-header-03 .tt-box-info li strong {
    display: none;
  }
  #tt-header.tt-header-03 .tt-box-info li i {
    font-size: 16px;
    margin-right: 7px;
  }
  #tt-header.tt-header-03 .tt-box-info li i.icon-15874 {
    margin-right: 3px;
  }
}

@media (max-width: 767px) {
  #tt-header.tt-header-03 .tt-box-info .tt-text {
    display: none;
  }
}

#tt-header.tt-header-03 .tt-dropdown-obj {
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  margin: 0 20px 0 0px;
  position: relative;
  top: 3px;
}

#tt-header.tt-header-03 .tt-dropdown-obj:last-child {
  margin-right: 0;
}

#tt-header.tt-header-03 .tt-dropdown-obj .tt-dropdown-toggle {
  font-size: 18px;
  line-height: 1;
  color: #dd3d53;
  display: inline-block;
  padding: 6px 3px 4px 3px;
  position: relative;
  -moz-transition: 0.2s linear;
  -o-transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  transition: 0.2s linear;
}

#tt-header.tt-header-03 .tt-dropdown-obj .tt-dropdown-toggle:hover {
  color: #222222;
}

#tt-header.tt-header-03 .tt-dropdown-obj .tt-dropdown-toggle:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
}

@media (max-width: 1024px) {
  #tt-header.tt-header-03 .tt-dropdown-obj {
    margin: 0 5px 0 0px;
  }
  #tt-header.tt-header-03 .tt-dropdown-obj .tt-dropdown-toggle {
    font-size: 16px;
  }
}

#tt-header.tt-header-03 .tt-dropdown-obj.active .tt-dropdown-toggle {
  color: #222222;
}

#tt-header.tt-header-03 .tt-searcher .tt-dropdown-menu {
  position: fixed;
  opacity: 0;
  width: 100%;
  text-align: left;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: 0.2s ease 0s;
  transition: 0.2s ease 0s;
  background: #ffffff;
  right: 0;
  left: 0;
  z-index: 22;
  -webkit-box-shadow: 0 0px 40px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0px 40px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0px 40px rgba(0, 0, 0, 0.1);
  border-bottom: 3px solid #dd3d53;
}

@media (min-width: 1025px) {
  #tt-header.tt-header-03 .tt-searcher .tt-dropdown-menu {
    top: 43px;
  }
}

@media (max-width: 1024px) {
  #tt-header.tt-header-03 .tt-searcher .tt-dropdown-menu {
    top: 28px;
  }
}

#tt-header.tt-header-03 .tt-searcher .tt-dropdown-menu form {
  position: relative;
  display: block;
}

#tt-header.tt-header-03 .tt-searcher .tt-dropdown-menu .tt-row-search {
  height: 70px;
}

#tt-header.tt-header-03 .tt-searcher .tt-dropdown-menu .tt-search-input {
  font-family: "Open Sans", sans-serif;
  background: transparent;
  font-size: 20px;
  font-weight: 500;
  border: none;
  padding: 24px 12px 10px 0px;
  margin-left: 38px;
  box-shadow: none;
  outline: none;
  width: calc(100% - 71px);
  color: #5f5f5f;
  border-radius: 0;
  transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}

#tt-header.tt-header-03 .tt-searcher .tt-dropdown-menu .tt-search-input:not(focus)::-webkit-input-placeholder {
  color: #5f5f5f;
}

#tt-header.tt-header-03 .tt-searcher .tt-dropdown-menu .tt-search-input:not(focus)::-moz-placeholder {
  color: #5f5f5f;
}

#tt-header.tt-header-03 .tt-searcher .tt-dropdown-menu .tt-search-input:not(focus):-moz-placeholder {
  color: #5f5f5f;
}

#tt-header.tt-header-03 .tt-searcher .tt-dropdown-menu .tt-search-input:not(focus):-ms-input-placeholder {
  color: #5f5f5f;
}

#tt-header.tt-header-03 .tt-searcher .tt-dropdown-menu .tt-btn-search {
  color: #5f5f5f;
  font-size: 20px;
  line-height: 1;
  font-family: "carleader";
  position: absolute;
  cursor: pointer;
  top: 22px;
  padding: 4px 0 4px 4px;
  left: 0px;
  z-index: 3;
  display: inline-block;
  background: none;
  border: none;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

#tt-header.tt-header-03 .tt-searcher .tt-dropdown-menu .tt-btn-search:before {
  content: "\e909";
}

#tt-header.tt-header-03 .tt-searcher .tt-dropdown-menu .tt-btn-search:hover {
  color: #dd3d53;
}

#tt-header.tt-header-03 .tt-searcher .tt-dropdown-menu .tt-btn-close {
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  position: absolute;
  top: 20px;
  right: 0;
  color: #5f5f5f;
  font-size: 20px;
  line-height: 1;
  font-family: "carleader";
  padding: 5px 0px 5px 5px;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

#tt-header.tt-header-03 .tt-searcher .tt-dropdown-menu .tt-btn-close:before {
  font-family: "carleader";
  content: "\e933";
  line-height: 1;
}

#tt-header.tt-header-03 .tt-searcher .tt-dropdown-menu .tt-btn-close:hover {
  color: #dd3d53;
}

@media (max-width: 765px) {
  #tt-header.tt-header-03 .tt-searcher .tt-dropdown-menu .tt-row-search {
    height: 60px;
  }
  #tt-header.tt-header-03 .tt-searcher .tt-dropdown-menu .tt-search-input {
    font-size: 18px;
    padding-top: 18px;
  }
  #tt-header.tt-header-03 .tt-searcher .tt-dropdown-menu .tt-btn-close {
    top: 15px;
  }
  #tt-header.tt-header-03 .tt-searcher .tt-dropdown-menu .tt-btn-search {
    top: 17px;
  }
}

#tt-header.tt-header-03 .tt-searcher:not(.active) .tt-dropdown-menu {
  pointer-events: none;
}

@media (max-width: 1024px) {
  #tt-header.tt-header-03 .tt-searcher .tt-dropdown-toggle {
    font-size: 16px;
  }
}

#tt-header.tt-header-03 .tt-searcher.active .tt-dropdown-menu {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: 0.3s ease 0.15s;
  transition: 0.3s ease 0.15s;
}

@media (max-width: 765px) {
  #tt-header.tt-header-03 .tt-searcher .container {
    max-width: 100%;
  }
}

#tt-header.tt-header-03 .tt-language {
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  margin-right: 20px;
}

#tt-header.tt-header-03 .tt-language select {
  opacity: 0;
}

@media (max-width: 1229px) {
  #tt-header.tt-header-03 .tt-language {
    margin-right: 17px;
  }
}

@media (max-width: 1024px) {
  #tt-header.tt-header-03 .tt-language {
    margin-right: 12px;
  }
}

#tt-header.tt-header-03 .tt-cart {
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  position: relative;
}

@media (min-width: 768px) {
  #tt-header.tt-header-03 .tt-cart {
    top: 3px;
  }
}

#tt-header.tt-header-03 .tt-cart .tt-dropdown-toggle {
  font-size: 18px;
  line-height: 1;
  color: #dd3d53;
  display: inline-block;
  padding: 6px 0 4px 5px;
  position: relative;
  -moz-transition: 0.2s linear;
  -o-transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  transition: 0.2s linear;
}

#tt-header.tt-header-03 .tt-cart .tt-dropdown-toggle i {
  pointer-events: none;
}

#tt-header.tt-header-03 .tt-cart .tt-dropdown-toggle:hover {
  color: #222222;
}

#tt-header.tt-header-03 .tt-cart .tt-dropdown-menu {
  position: absolute;
  opacity: 0;
  width: 299px;
  margin: 15px 0 0 0;
  text-align: left;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: 0.2s ease 0s;
  transition: 0.2s ease 0s;
  background: #ffffff;
  right: 0;
  top: 100%;
  z-index: 22;
  -webkit-box-shadow: 0 0px 40px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0px 40px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0px 40px rgba(0, 0, 0, 0.1);
  border-bottom: 3px solid #dd3d53;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}

#tt-header.tt-header-03 .tt-cart .tt-dropdown-menu:before {
  content: '';
  display: block;
  height: 5px;
  margin-top: -5px;
  pointer-events: none;
  position: absolute;
  right: 23px;
  top: -7px;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  border: 7px solid transparent;
  border-bottom: 7px solid #ffffff;
  z-index: 1;
}

#tt-header.tt-header-03 .tt-cart .tt-dropdown-menu:after {
  content: '';
  display: block;
  height: 5px;
  margin-top: -5px;
  pointer-events: none;
  position: absolute;
  right: 23px;
  top: -7px;
  z-index: 0;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  border: 7px solid transparent;
  border-bottom: 7px solid rgba(0, 0, 0, 0.2);
}

#tt-header.tt-header-03 .tt-cart .tt-dropdown-menu .tt-dropdown-title {
  font-family: "Montserrat", sans-serif;
  color: #222222;
  font-weight: 600;
  border-bottom: 1px solid #e8e8e8;
  position: relative;
  padding: 15px 7px 14px 22px;
}

#tt-header.tt-header-03 .tt-cart .tt-dropdown-menu .tt-dropdown-title .tt-btn-close {
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  color: #dd3d53;
  position: absolute;
  right: 15px;
  top: 17px;
  cursor: pointer;
}

#tt-header.tt-header-03 .tt-cart .tt-dropdown-menu .tt-dropdown-title .tt-btn-close:hover {
  color: #222222;
}

#tt-header.tt-header-03 .tt-cart .tt-dropdown-menu .tt-search-results {
  padding: 0 20px 0 20px;
}

#tt-header.tt-header-03 .tt-cart .tt-dropdown-menu .tt-search-results li {
  margin: 24px 0 0 0;
  position: relative;
}

#tt-header.tt-header-03 .tt-cart .tt-dropdown-menu .tt-search-results li a {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-right: 18px;
}

#tt-header.tt-header-03 .tt-cart .tt-dropdown-menu .tt-search-results li a .thumbnail {
  width: 89px;
  min-width: 89px;
}

#tt-header.tt-header-03 .tt-cart .tt-dropdown-menu .tt-search-results li a .tt-description {
  padding: 0 5px 0 12px;
}

#tt-header.tt-header-03 .tt-cart .tt-dropdown-menu .tt-search-results li a .tt-description .tt-title {
  font-size: 15px;
  line-height: 22px;
  color: #222222;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

#tt-header.tt-header-03 .tt-cart .tt-dropdown-menu .tt-search-results li a .tt-description .tt-price {
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  color: #222222;
  font-family: "Open Sans", sans-serif;
  display: inline-block;
  margin-top: 9px;
}

#tt-header.tt-header-03 .tt-cart .tt-dropdown-menu .tt-search-results li a:hover .tt-description .tt-title {
  color: #dd3d53;
}

#tt-header.tt-header-03 .tt-cart .tt-dropdown-menu .tt-search-results li .tt-close-item {
  color: #222222;
  font-size: 12px;
  padding: 5px 0px 5px 5px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

#tt-header.tt-header-03 .tt-cart .tt-dropdown-menu .tt-search-results li .tt-close-item:hover {
  color: #dd3d53;
}

#tt-header.tt-header-03 .tt-cart .tt-dropdown-menu .tt-search-bottom {
  border-top: 1px solid #e8e8e8;
  margin-top: 24px;
  padding: 12px 20px 23px 20px;
}

#tt-header.tt-header-03 .tt-cart .tt-dropdown-menu .tt-search-bottom .price-total {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  font-size: 18px;
  line-height: 1.5;
  color: #222222;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}

#tt-header.tt-header-03 .tt-cart .tt-dropdown-menu .tt-search-bottom .price-total label {
  padding-right: 10px;
}

#tt-header.tt-header-03 .tt-cart .tt-dropdown-menu .tt-search-bottom .price-total .tt-price {
  padding-left: 10px;
  font-size: 18px;
  line-height: 1.4;
  color: #222222;
}

#tt-header.tt-header-03 .tt-cart .tt-dropdown-menu .tt-search-bottom .btn {
  width: 100%;
  margin-top: 9px;
}

#tt-header.tt-header-03 .tt-cart:not(.active) .tt-dropdown-menu {
  pointer-events: none;
}

@media (max-width: 1024px) {
  #tt-header.tt-header-03 .tt-cart .tt-dropdown-toggle {
    font-size: 16px;
  }
}

#tt-header.tt-header-03 .tt-cart.active .tt-dropdown-menu {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: 0.3s ease 0.15s;
  transition: 0.3s ease 0.15s;
}

#tt-header.tt-header-03 .tt-account {
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  position: relative;
}

@media (min-width: 768px) {
  #tt-header.tt-header-03 .tt-account {
    top: 3px;
  }
}

#tt-header.tt-header-03 .tt-account .tt-dropdown-toggle {
  font-size: 18px;
  line-height: 1;
  color: #dd3d53;
  display: inline-block;
  padding: 6px 0 4px 5px;
  position: relative;
  -moz-transition: 0.2s linear;
  -o-transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  transition: 0.2s linear;
}

#tt-header.tt-header-03 .tt-account .tt-dropdown-toggle i {
  pointer-events: none;
}

#tt-header.tt-header-03 .tt-account .tt-dropdown-toggle:hover {
  color: #222222;
}

#tt-header.tt-header-03 .tt-account .tt-dropdown-menu {
  position: absolute;
  opacity: 0;
  width: 230px;
  margin: 15px 0 0 0;
  padding: 0 20px 20px;
  text-align: left;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: 0.2s ease 0s;
  transition: 0.2s ease 0s;
  background: #ffffff;
  right: -23px;
  top: 100%;
  z-index: 22;
  -webkit-box-shadow: 0 0px 40px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0px 40px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0px 40px rgba(0, 0, 0, 0.1);
  border-bottom: 3px solid #dd3d53;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}

#tt-header.tt-header-03 .tt-account .tt-dropdown-menu:before {
  content: '';
  display: block;
  height: 5px;
  margin-top: -5px;
  pointer-events: none;
  position: absolute;
  right: 23px;
  top: -7px;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  border: 7px solid transparent;
  border-bottom: 7px solid #ffffff;
  z-index: 1;
}

#tt-header.tt-header-03 .tt-account .tt-dropdown-menu:after {
  content: '';
  display: block;
  height: 5px;
  margin-top: -5px;
  pointer-events: none;
  position: absolute;
  right: 23px;
  top: -7px;
  z-index: 0;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  border: 7px solid transparent;
  border-bottom: 7px solid rgba(0, 0, 0, 0.2);
}

#tt-header.tt-header-03 .tt-account .tt-dropdown-menu .tt-row-close {
  position: relative;
  padding: 15px 0px 14px 22px;
}

#tt-header.tt-header-03 .tt-account .tt-dropdown-menu .tt-row-close .tt-btn-close {
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  color: #222222;
  position: absolute;
  right: -4px;
  top: 17px;
  cursor: pointer;
}

#tt-header.tt-header-03 .tt-account .tt-dropdown-menu .tt-row-close .tt-btn-close:hover {
  color: #dd3d53;
}

#tt-header.tt-header-03 .tt-account .tt-dropdown-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

#tt-header.tt-header-03 .tt-account .tt-dropdown-menu ul li {
  color: #222222;
  font-size: 16px;
}

#tt-header.tt-header-03 .tt-account .tt-dropdown-menu ul li a {
  color: #222222;
  display: block;
  padding: 4px 0;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

#tt-header.tt-header-03 .tt-account .tt-dropdown-menu ul li a:hover {
  color: #dd3d53;
}

#tt-header.tt-header-03 .tt-account:not(.active) .tt-dropdown-menu {
  pointer-events: none;
}

@media (max-width: 1024px) {
  #tt-header.tt-header-03 .tt-account .tt-dropdown-toggle {
    font-size: 16px;
  }
}

#tt-header.tt-header-03 .tt-account.active .tt-dropdown-menu {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: 0.3s ease 0.15s;
  transition: 0.3s ease 0.15s;
}

#tt-header.tt-header-03 .tt-quickLinks-popup {
  position: relative;
}

@media (min-width: 768px) {
  #tt-header.tt-header-03 .tt-quickLinks-popup {
    display: none;
  }
}

@media (min-width: 766px) {
  #tt-header.tt-header-03 .tt-mobile-quickLinks {
    display: none;
  }
}

@media (max-width: 767px) {
  #tt-header.tt-header-03 .tt-mobile-quickLinks {
    display: block;
    background-color: #222222;
  }
  #tt-header.tt-header-03 .tt-mobile-quickLinks .row .col {
    text-align: center;
    line-height: 24px;
    display: inline-block;
  }
  #tt-header.tt-header-03 .tt-mobile-quickLinks .row .col:not(:last-child) {
    border-right: 1px solid #3a3a3a;
  }
  #tt-header.tt-header-03 .tt-mobile-quickLinks .row .col:not(:first-child) {
    border-left: 1px solid #181818;
  }
  #tt-header.tt-header-03 .tt-mobile-quickLinks .row .col.tt-cart {
    display: block;
  }
  #tt-header.tt-header-03 .tt-mobile-quickLinks .row .col a.btn-toggle {
    color: #dd3d53;
    display: block;
    height: 28px;
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    -moz-transition: color 0.2s linear;
    -o-transition: color 0.2s linear;
    -webkit-transition: color 0.2s linear;
    transition: color 0.2s linear;
  }
  #tt-header.tt-header-03 .tt-mobile-quickLinks .row .col a.btn-toggle i {
    position: relative;
    top: 3px;
  }
  #tt-header.tt-header-03 .tt-mobile-quickLinks .row .col a.btn-toggle:hover {
    color: #ffffff;
  }
  #tt-header.tt-header-03 .tt-mobile-quickLinks .row .col a.btn-toggle.active {
    color: #ffffff;
  }
  #tt-header.tt-header-03 .tt-mobile-quickLinks .row .col .nice-select.tt-skin-02 {
    display: inline-block;
    float: inherit;
    height: 25px;
    padding: 5px 12px 2px 2px;
    color: #ffffff;
    min-width: 37px;
  }
  #tt-header.tt-header-03 .tt-mobile-quickLinks .row .col .nice-select.tt-skin-02:after {
    border-top-color: #ffffff;
    margin-top: -1px;
  }
  #tt-header.tt-header-03 .tt-mobile-quickLinks .row .col .nice-select.tt-skin-02 .list {
    background: #ffffff;
    color: #222222;
    -webkit-box-shadow: 0 0px 8px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0px 8px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0px 8px rgba(0, 0, 0, 0.1);
  }
  #tt-header.tt-header-03 .tt-mobile-quickLinks .row .quickLinks-layout {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    background-color: #f5f5f5;
    color: #424242;
    padding: 30px;
    top: 100%;
    left: 0;
    transform: translateY(-20px);
    z-index: -1;
    transition: opacity 0.3s ease-in-out 0s, transform 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
    pointer-events: none;
    text-align: left;
    text-transform: none;
    -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  }
}

#tt-header.tt-header-03 .quickLinks-map address {
  font-size: 12px;
  line-height: 1.1;
  margin: 0;
  padding: 4px 10px 9px 13px;
  color: #707070;
  text-transform: uppercase;
}

#tt-header.tt-header-03 .quickLinks-map address i {
  color: #dd3d53;
  font-size: 18px;
  line-height: 1.1;
  margin: 0 10px 0 0;
  position: relative;
  top: 3px;
}

#tt-header.tt-header-03 .quickLinks-address {
  padding: 13px 10px 13px 13px;
}

#tt-header.tt-header-03 .quickLinks-address i {
  color: #dd3d53;
  font-size: 18px;
  line-height: 1.1;
  margin: 0 10px 0 0;
  position: relative;
  top: 3px;
}

#tt-header.tt-header-03 .quickLinks-address .tt-title {
  font-size: 19px;
  line-height: 1.2;
  font-family: "Montserrat", sans-serif;
  color: #222222;
  font-weight: 600;
  margin: 0;
  padding: 0 0 8px 0;
}

#tt-header.tt-header-03 .quickLinks-address ul li {
  color: #707070;
}

#tt-header.tt-header-03 .quickLinks-address ul li a {
  color: #707070;
  text-decoration: none;
}

#tt-header.tt-header-03 .quickLinks-address ul li:not(:first-child) {
  margin-top: 6px;
}

#tt-header.tt-header-03 .header-holder {
  position: relative;
  z-index: 7;
  min-height: 99px;
}

#tt-header.tt-header-03 .header-holder .col-logo {
  z-index: 2;
}

@media (min-width: 1025px) {
  #tt-header.tt-header-03 .header-holder .col-logo {
    margin-top: 12px;
    margin-bottom: 21px;
  }
}

@media (max-width: 1024px) {
  #tt-header.tt-header-03 .header-holder .col-logo {
    margin-top: 21px;
    margin-bottom: 11px;
  }
}

@media (max-width: 767px) {
  #tt-header.tt-header-03 .header-holder .col-logo {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin-top: 22px;
    margin-bottom: 22px;
    -webkit-flex: 2 1 auto;
    -ms-flex: 2 1 auto;
    flex: 2 1 auto;
  }
}

@media (min-width: 1025px) {
  #tt-header.tt-header-03 .header-holder .col-btn-toggle-modal {
    margin-top: -1px;
  }
}

@media (max-width: 1024px) {
  #tt-header.tt-header-03 .header-holder .col-btn-toggle-modal {
    position: absolute;
    right: 0px;
    top: 24px;
  }
}

@media (min-width: 768px) {
  #tt-header.tt-header-03 .header-holder .col-menu-toggle {
    display: none;
  }
}

@media (max-width: 1024px) and (min-width: 768px) {
  #tt-header.tt-header-03 .header-holder .col-logo {
    width: 100%;
  }
  #tt-header.tt-header-03 .header-holder .col-menu {
    width: 100%;
  }
}

@media (max-width: 767px) {
  #tt-header.tt-header-03 .header-holder {
    min-height: 82px;
  }
  #tt-header.tt-header-03 .header-holder .container {
    max-width: 100%;
  }
  #tt-header.tt-header-03 .header-holder .col-menu,
  #tt-header.tt-header-03 .header-holder .col-btn-toggle-modal {
    display: none;
  }
  #tt-header.tt-header-03 .header-holder .col-logo {
    margin-left: 15px;
  }
  #tt-header.tt-header-03 .header-holder .col-menu-toggle {
    display: inline-block;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  #tt-header.tt-header-03 .header-holder .col-menu-toggle .tt-menu-toggle {
    padding-top: 15px;
    padding-bottom: 15px;
    display: inline-block;
  }
}

#tt-header.tt-header-03 .tt-logo {
  max-width: 215px;
  display: inline-block;
}

@media (max-width: 1024px) {
  #tt-header.tt-header-03 .tt-logo {
    max-width: 168px;
  }
}

@media (max-width: 767px) {
  #tt-header.tt-header-03 .tt-logo {
    max-width: 131px;
    margin-top: 3px;
  }
}

#tt-header.tt-header-03 .tt-logo img {
  max-width: 100%;
  height: auto;
}

#tt-header.tt-header-03 .tt-menu-toggle {
  color: #222222;
  font-size: 26px;
  line-height: 1.2em;
  padding: 5px;
  z-index: 3;
  outline: none;
  position: relative;
  top: 2px;
  display: inline-block !important;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

#tt-header.tt-header-03 .tt-menu-toggle:hover {
  color: #dd3d53;
}

#tt-header.tt-header-03 .btn.btn-toggle-modal {
  padding-left: 15px;
  padding-right: 18px;
  padding-top: 5px;
  position: relative;
  z-index: 2;
}

#tt-header.tt-header-03 .btn.btn-toggle-modal i {
  font-size: 22px;
  margin-top: -3px;
  margin-right: 12px;
}

@media (max-width: 1024px) {
  #tt-header.tt-header-03 .btn.btn-toggle-modal {
    padding-top: 4px;
    height: 38px;
  }
  #tt-header.tt-header-03 .btn.btn-toggle-modal i {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  #tt-header.tt-header-03 #tt-desctop-menu {
    display: none;
  }
}

#tt-header.tt-header-03 #tt-desctop-menu nav > ul {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

#tt-header.tt-header-03 #tt-desctop-menu nav > ul > li {
  display: inline-block;
  position: relative;
}

#tt-header.tt-header-03 #tt-desctop-menu nav > ul > li a {
  color: #222222;
  font-weight: 700;
  font-size: 15px;
  display: inline-block;
  position: relative;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

@media (min-width: 1230px) {
  #tt-header.tt-header-03 #tt-desctop-menu nav > ul > li a {
    padding: 14px 18px 15px;
  }
}

@media (max-width: 1229px) {
  #tt-header.tt-header-03 #tt-desctop-menu nav > ul > li a {
    padding: 14px 7px 15px;
  }
}

@media (max-width: 1024px) {
  #tt-header.tt-header-03 #tt-desctop-menu nav > ul > li a {
    color: #222222;
    padding: 14px 10px 10px;
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  #tt-header.tt-header-03 #tt-desctop-menu nav > ul > li a {
    padding: 14px 10px 15px;
  }
}

#tt-header.tt-header-03 #tt-desctop-menu nav > ul > li a:hover {
  color: #dd3d53;
}

#tt-header.tt-header-03 #tt-desctop-menu nav > ul > li:first-child > a {
  padding-left: 0;
}

#tt-header.tt-header-03 #tt-desctop-menu nav > ul > li:first-child ul {
  left: 0;
}

#tt-header.tt-header-03 #tt-desctop-menu nav > ul > li:last-child a {
  padding-right: 0;
}

#tt-header.tt-header-03 #tt-desctop-menu nav > ul > li.is-subMenu > a:after {
  content: '';
  display: inline-block;
  width: 3px;
  height: 5px;
  margin-top: 1px;
  margin-left: 9px;
  pointer-events: none;
  transition: all 0.15s ease-in-out;
  border: 3px solid transparent;
  border-top: 3px solid #222222;
}

@media (max-width: 1024px) {
  #tt-header.tt-header-03 #tt-desctop-menu nav > ul > li.is-subMenu > a:after {
    border-top-color: #222222;
  }
}

#tt-header.tt-header-03 #tt-desctop-menu nav > ul > li.is-active > a {
  color: #dd3d53;
}

#tt-header.tt-header-03 #tt-desctop-menu nav > ul > li.is-active > a:after {
  border-top-color: #dd3d53 !important;
}

#tt-header.tt-header-03 #tt-desctop-menu nav > ul > li ul {
  position: absolute;
  z-index: 1;
  opacity: 0;
  min-width: 211px;
  z-index: 1000;
  list-style: none;
  margin: 0;
  text-transform: uppercase;
  text-align: left;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: 0.2s ease 0s;
  transition: 0.2s ease 0s;
}

#tt-header.tt-header-03 #tt-desctop-menu nav > ul > li.is-hover ul {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: 0.3s ease 0.15s;
  transition: 0.3s ease 0.15s;
}

#tt-header.tt-header-03 #tt-desctop-menu nav > ul ul {
  background: #ffffff;
  top: auto;
  left: 28px;
  width: 211px;
  border-bottom: 3px solid #dd3d53;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  padding: 9px 0 11px 0;
  pointer-events: none;
  -moz-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2);
}

#tt-header.tt-header-03 #tt-desctop-menu nav > ul ul:before {
  content: '';
  display: block;
  height: 5px;
  margin-top: -5px;
  pointer-events: none;
  position: absolute;
  left: 23px;
  top: -7px;
  transition: all 0.15s ease-in-out;
  border: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
  z-index: 1;
}

#tt-header.tt-header-03 #tt-desctop-menu nav > ul ul:after {
  content: '';
  display: block;
  height: 5px;
  margin-top: -5px;
  pointer-events: none;
  position: absolute;
  left: 23px;
  top: -7px;
  z-index: 0;
  transition: all 0.15s ease-in-out;
  border: 6px solid transparent;
  border-bottom: 6px solid rgba(0, 0, 0, 0.2);
}

#tt-header.tt-header-03 #tt-desctop-menu nav > ul ul li a {
  color: #0e0e0e;
  font-size: 14px;
  line-height: 1.3;
  text-transform: uppercase;
  font-weight: 700;
  padding: 10px 20px 5px;
}

#tt-header.tt-header-03 #tt-desctop-menu nav > ul ul li a:hover {
  color: #dd3d53;
}

#tt-header.tt-header-03 #tt-desctop-menu nav > ul ul li.is-active > a {
  color: #dd3d53;
}

@media (max-width: 1229px) {
  #tt-header.tt-header-03 #tt-desctop-menu nav > ul ul {
    left: 11px;
  }
}

/* stuck tt-header-02*/
#tt-header.tt-header-03 #tt-stuck .col-logo {
  display: inline-block;
}

#tt-header.tt-header-03 #tt-stuck .col-logo .tt-logo {
  max-width: 140px;
  position: relative;
  top: -4px;
}

@media (max-width: 1024px) and (min-width: 768px) {
  #tt-header.tt-header-03 #tt-stuck .col-logo {
    display: none;
  }
}

#tt-header.tt-header-03 #tt-stuck .btn.btn-toggle-modal:hover:hover {
  background: #222222;
  color: #ffffff;
}

#tt-header.tt-header-03 #tt-stuck .col-btn-toggle-modal {
  display: none;
}

@media (min-width: 768px) {
  #tt-header.tt-header-03 #tt-stuck .col-menu-toggle {
    display: none;
  }
}

@media (min-width: 1025px) {
  #tt-header.tt-header-03 #tt-stuck .col-menu {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}

@media (max-width: 1024px) and (min-width: 768px) {
  #tt-header.tt-header-03 #tt-stuck .col-menu {
    margin-left: 0 !important;
  }
}

@media (max-width: 1024px) {
  #tt-header.tt-header-03 #tt-stuck .col-menu {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  #tt-header.tt-header-03 #tt-stuck .col-menu .tt-stuck-parent-logo {
    -webkit-flex: 2 1 auto;
    -ms-flex: 2 1 auto;
    flex: 2 1 auto;
  }
}

#tt-header.tt-header-03 #tt-stuck #tt-desctop-menu nav > ul > li > a {
  padding: 20px 10px;
}

@media (max-width: 1024px) {
  #tt-header.tt-header-03 #tt-stuck #tt-desctop-menu nav > ul > li > a {
    padding: 15px 10px;
  }
}

@media (max-width: 780px) {
  #tt-header.tt-header-03 #tt-stuck #tt-desctop-menu nav > ul > li > a {
    padding: 15px 9px;
  }
}

#tt-header.tt-header-03 #tt-stuck #tt-desctop-menu nav > ul > li:not(.active) a:not(:hover) {
  color: #222222;
}

#tt-header.tt-header-03 #tt-stuck #tt-desctop-menu nav > ul > li.is-active > a {
  color: #dd3d53 !important;
}

@media (max-width: 767px) {
  #tt-header.tt-header-03 #tt-stuck .tt-menu-toggle {
    top: 8px;
  }
  #tt-header.tt-header-03 #tt-stuck .col-logo .tt-logo {
    max-width: 120px;
    margin-top: 9px;
  }
}

/*--------
    Main Slider
--------*/
.mainSlider-layout {
  position: relative;
}
.mainSlider {
  background: url('/images/hero1.jpg') bottom center no-repeat;
background-size: cover;


}
@media (min-width: 1025px) {
  .mainSlider {
    height: 890px;
    max-height: 100vh;
    min-height: 600px;
  }
}

@media (max-width: 1024px) and (min-width: 551px) {
  .mainSlider {
    height: 354px;
  }
}

@media (max-width: 550px) {
  .mainSlider {
    height: 149px;
  }
}

.mainSlider .container {
  position: relative;
}

.mainSlider .slide .img--holder {
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
}

@media (min-width: 1025px) {
  .mainSlider .slide .img--holder {
    height: 890px;
  }
}

@media (max-width: 1024px) and (min-width: 551px) {
  .mainSlider .slide .img--holder {
    height: 354px;
  }
}

@media (max-width: 550px) {
  .mainSlider .slide .img--holder {
    height: 149px;
  }
}

.mainSlider .slide .slide-content {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  /* tp-caption-01(slide-02) */
}

.mainSlider .slide .slide-content div[class^="tp-caption-"] {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  text-align: center;
}

.mainSlider .slide .slide-content div[class^="tp-caption-"]:first-child {
  margin-top: 0px;
}

@media (min-width: 1025px) {
  .mainSlider .slide .slide-content .tp-caption-01-01 {
    font-size: 30px;
    line-height: 1.1;
    letter-spacing: 0.01em;
    font-weight: 300;
    text-align: center;
  }
  .mainSlider .slide .slide-content .tp-caption-01-02 {
    font-size: 62px;
    line-height: 0.97;
    letter-spacing: -0.04em;
    font-weight: 700;
    margin-top: 14px;
  }
  .mainSlider .slide .slide-content .tp-caption-01-03 {
    font-size: 16px;
    line-height: 1.1;
    letter-spacing: 0.01em;
    font-weight: 600;
    margin-top: 22px;
  }
  .mainSlider .slide .slide-content .tp-caption-01-04 {
    font-size: 36px;
    line-height: 1.1;
    letter-spacing: 0.01em;
    font-weight: 700;
    text-align: center;
    margin-top: 6px;
  }
}

@media (max-width: 1024px) {
  .mainSlider .slide .slide-content .tp-caption-01-01 {
    font-size: 17px;
    line-height: 1.1;
    letter-spacing: 0.01em;
    font-weight: 300;
    text-align: center;
  }
  .mainSlider .slide .slide-content .tp-caption-01-02 {
    font-size: 32px;
    line-height: 1;
    letter-spacing: inherit;
    font-weight: 700;
    margin-top: 14px;
  }
  .mainSlider .slide .slide-content .tp-caption-01-03 {
    font-size: 14px;
    line-height: 1.1;
    letter-spacing: 0.01em;
    font-weight: 600;
    margin-top: 22px;
  }
}

@media (max-width: 550px) {
  .mainSlider .slide .slide-content .tp-caption-01-01 {
    font-size: 14px;
    line-height: 1.1;
    letter-spacing: 0.01em;
    font-weight: 300;
    text-align: center;
  }
  .mainSlider .slide .slide-content .tp-caption-01-02 {
    font-size: 20px;
    line-height: 1;
    letter-spacing: inherit;
    font-weight: 700;
    margin-top: 7px;
  }
  .mainSlider .slide .slide-content .tp-caption-01-03 {
    font-size: 14px;
    line-height: 1.1;
    letter-spacing: 0.01em;
    font-weight: 600;
    margin-top: 12px;
  }
}

@media (max-width: 767px) {
  .mainSlider .slide .container {
    width: 100%;
  }
}

.mainSlider .tt-caption-custom {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  right: 10px;
  top: -208px;
  background: url("/images/custom/mainSlider-layout01.png") 0 0 no-repeat;
  width: 430px;
  height: 397px;
  color: #ffffff;
  text-align: center;
  z-index: 2;
  font-family: "Montserrat", sans-serif;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.mainSlider .tt-caption-custom:before {
  content: '';
  position: absolute;
  display: none;
  top: -18px;
  left: -32px;
  background: url("/images/custom/mainSlider-layout02.png") 0 0 no-repeat;
  width: 462px;
  height: 403px;
  z-index: 0;
  animation: moving 3s infinite linear;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@keyframes moving {
  100% {
    transform: rotate(360deg);
  }
}

.mainSlider .tt-caption-custom .tt-title {
  padding-top: 34px;
}

.mainSlider .tt-caption-custom .tt-title .text-small {
  font-size: 30px;
  line-height: 1.1;
  font-weight: 300;
  letter-spacing: 0.01em;
}

.mainSlider .tt-caption-custom .tt-title .text-large {
  font-size: 62px;
  line-height: 1;
  font-weight: 700;
  line-height: 0.97;
  margin-top: 14px;
  letter-spacing: -0.04em;
  text-transform: uppercase;
}

.mainSlider .tt-caption-custom span {
  display: inline-block;
  width: 254px;
  margin-top: 24px;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  padding: 8px 0 0 0;
  font-size: 16px;
  line-height: 1.1;
  text-transform: uppercase;
  font-weight: 600;
}

.mainSlider .tt-caption-custom span i {
  font-size: 36px;
  line-height: 1.1;
  display: inline-block;
  margin: 0px 10px 10px 0;
  top: 13px;
  position: relative;
}

.mainSlider .tt-caption-custom > * {
  position: relative;
  z-index: 2;
}

@media (max-width: 1024px) {
  .mainSlider .tt-caption-custom {
    width: 240px;
    height: 222px;
    top: -102px;
  }
  .mainSlider .tt-caption-custom:before {
    top: -5px;
    left: -10px;
    width: 249px;
    height: 227px;
  }
  .mainSlider .tt-caption-custom .tt-title {
    padding-top: 23px;
  }
  .mainSlider .tt-caption-custom .tt-title .text-small {
    font-size: 16px;
  }
  .mainSlider .tt-caption-custom .tt-title .text-large {
    font-size: 34px;
    line-height: 0.97;
    margin-top: 9px;
  }
  .mainSlider .tt-caption-custom span {
    width: 141px;
    margin-top: 15px;
    padding: 3px 0 0 0;
    font-size: 10px;
    line-height: 1.1;
  }
  .mainSlider .tt-caption-custom span i {
    font-size: 20px;
    line-height: 1.1;
    margin: 0px 10px 10px 0;
    top: 7px;
  }
}

@media (max-width: 550px) {
  .mainSlider .tt-caption-custom {
    width: 102px;
    height: 93px;
    top: -52px;
    right: 19px;
  }
  .mainSlider .tt-caption-custom:before {
    top: -2px;
    left: -3px;
    width: 105px;
    height: 94px;
  }
  .mainSlider .tt-caption-custom .tt-title {
    padding-top: 3px;
  }
  .mainSlider .tt-caption-custom .tt-title .text-small {
    display: none;
  }
  .mainSlider .tt-caption-custom .tt-title .text-large {
    font-size: 16px;
    line-height: 1;
  }
  .mainSlider .tt-caption-custom span {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 141px;
    margin-top: 0px;
    padding: 0;
    position: relative;
    top: -6px;
    font-size: 7px;
    line-height: 1.1;
    border: none;
  }
  .mainSlider .tt-caption-custom span i {
    font-size: 15px;
    line-height: 1.1;
    margin: 0px;
    padding-bottom: 7px;
    top: 7px;
  }
}

.mainSlider .slick-arrow {
  position: absolute;
  top: 50%;
  margin-top: -18px;
  z-index: 7;
  font-size: 0;
  line-height: 0;
  background: none;
  border: none;
  color: #fff;
}

.mainSlider .slick-arrow:hover {
  color: #dd3d53;
}

.mainSlider .slick-arrow:before {
  font-family: "carleader";
  display: block;
  font-size: 40px;
  line-height: 1em;
}

.mainSlider .slick-prev {
  left: 20px;
}

.mainSlider .slick-prev:before {
  content: '\e916';
}

.mainSlider .slick-next {
  right: 20px;
}

.mainSlider .slick-next:before {
  content: '\e917';
}

.fadeInLeftSm {
  -webkit-animation-name: fadeInLeftSm;
  animation-name: fadeInLeftSm;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}

.fadeInRightSm {
  -webkit-animation-name: fadeInRightSm;
  animation-name: fadeInRightSm;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}

.fadeInUpSm {
  -webkit-animation-name: fadeInUpSm;
  animation-name: fadeInUpSm;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}

.animated.fadeInLeftSm,
.animated.fadeInRightSm,
.animated.fadeInUpSm {
  -webkit-animation-duration: .75s;
  animation-duration: .75s;
}

@-webkit-keyframes fadeInUpSm {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUpSm {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@-webkit-keyframes fadeInLeftSm {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-20%, 0, 0);
    transform: translate3d(-20%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInLeftSm {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-20%, 0, 0);
    transform: translate3d(-20%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@-webkit-keyframes fadeInRightSm {
  from {
    opacity: 0;
    -webkit-transform: translate3d(20%, 0, 0);
    transform: translate3d(20%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInRightSm {
  from {
    opacity: 0;
    -webkit-transform: translate3d(20%, 0, 0);
    transform: translate3d(20%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

body.is-loaded .loading-content {
  opacity: 0;
  visibility: hidden;
}

.loading-content {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  z-index: 2;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  pointer-events: none;
  visibility: visible;
  transition: 0.5s;
}

.loading-content.disable {
  opacity: 0;
}

.loading-content .image {
  max-width: 15%;
}

.loading-content .image img {
  animation: flipInY 3s infinite;
  max-width: 100%;
}

.loading-dots {
  margin-left: -1.5em;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-animation: loading-dots-fadein .5s linear forwards;
  -moz-animation: loading-dots-fadein .5s linear forwards;
  -o-animation: loading-dots-fadein .5s linear forwards;
  -ms-animation: loading-dots-fadein .5s linear forwards;
  animation: loading-dots-fadein .5s linear forwards;
}

.loading-dots i {
  width: .5em;
  height: .5em;
  display: inline-block;
  vertical-align: middle;
  background: #dd3d53;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  margin: 0 .125em;
  -webkit-animation: loading-dots-middle-dots .5s linear infinite;
  -moz-animation: loading-dots-middle-dots .5s linear infinite;
  -o-animation: loading-dots-middle-dots .5s linear infinite;
  -ms-animation: loading-dots-middle-dots .5s linear infinite;
  animation: loading-dots-middle-dots .5s linear infinite;
}

.loading-dots.dark-gray i {
  background: #dd3d53;
}

.loading-dots i:first-child {
  -webkit-animation: loading-dots-first-dot .5s infinite;
  -moz-animation: loading-dots-first-dot .5s linear infinite;
  -o-animation: loading-dots-first-dot .5s linear infinite;
  -ms-animation: loading-dots-first-dot .5s linear infinite;
  animation: loading-dots-first-dot .5s linear infinite;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-transform: translate(-1em);
  -moz-transform: translate(-1em);
  -o-transform: translate(-1em);
  -ms-transform: translate(-1em);
  transform: translate(-1em);
}

.loading-dots i:last-child {
  -webkit-animation: loading-dots-last-dot .5s linear infinite;
  -moz-animation: loading-dots-last-dot .5s linear infinite;
  -o-animation: loading-dots-last-dot .5s linear infinite;
  -ms-animation: loading-dots-last-dot .5s linear infinite;
  animation: loading-dots-last-dot .5s linear infinite;
}

@-moz-keyframes loading-dots-fadein {
  100% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}

@-webkit-keyframes loading-dots-fadein {
  100% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}

@-o-keyframes loading-dots-fadein {
  100% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}

@keyframes loading-dots-fadein {
  100% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}

@-moz-keyframes loading-dots-first-dot {
  100% {
    -webkit-transform: translate(1em);
    -moz-transform: translate(1em);
    -o-transform: translate(1em);
    -ms-transform: translate(1em);
    transform: translate(1em);
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}

@-webkit-keyframes loading-dots-first-dot {
  100% {
    -webkit-transform: translate(1em);
    -moz-transform: translate(1em);
    -o-transform: translate(1em);
    -ms-transform: translate(1em);
    transform: translate(1em);
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}

@-o-keyframes loading-dots-first-dot {
  100% {
    -webkit-transform: translate(1em);
    -moz-transform: translate(1em);
    -o-transform: translate(1em);
    -ms-transform: translate(1em);
    transform: translate(1em);
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}

@keyframes loading-dots-first-dot {
  100% {
    -webkit-transform: translate(1em);
    -moz-transform: translate(1em);
    -o-transform: translate(1em);
    -ms-transform: translate(1em);
    transform: translate(1em);
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}

@-moz-keyframes loading-dots-middle-dots {
  100% {
    -webkit-transform: translate(1em);
    -moz-transform: translate(1em);
    -o-transform: translate(1em);
    -ms-transform: translate(1em);
    transform: translate(1em);
  }
}

@-webkit-keyframes loading-dots-middle-dots {
  100% {
    -webkit-transform: translate(1em);
    -moz-transform: translate(1em);
    -o-transform: translate(1em);
    -ms-transform: translate(1em);
    transform: translate(1em);
  }
}

@-o-keyframes loading-dots-middle-dots {
  100% {
    -webkit-transform: translate(1em);
    -moz-transform: translate(1em);
    -o-transform: translate(1em);
    -ms-transform: translate(1em);
    transform: translate(1em);
  }
}

@keyframes loading-dots-middle-dots {
  100% {
    -webkit-transform: translate(1em);
    -moz-transform: translate(1em);
    -o-transform: translate(1em);
    -ms-transform: translate(1em);
    transform: translate(1em);
  }
}

@-moz-keyframes loading-dots-last-dot {
  100% {
    -webkit-transform: translate(2em);
    -moz-transform: translate(2em);
    -o-transform: translate(2em);
    -ms-transform: translate(2em);
    transform: translate(2em);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}

@-webkit-keyframes loading-dots-last-dot {
  100% {
    -webkit-transform: translate(2em);
    -moz-transform: translate(2em);
    -o-transform: translate(2em);
    -ms-transform: translate(2em);
    transform: translate(2em);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}

@-o-keyframes loading-dots-last-dot {
  100% {
    -webkit-transform: translate(2em);
    -moz-transform: translate(2em);
    -o-transform: translate(2em);
    -ms-transform: translate(2em);
    transform: translate(2em);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}

@keyframes loading-dots-last-dot {
  100% {
    -webkit-transform: translate(2em);
    -moz-transform: translate(2em);
    -o-transform: translate(2em);
    -ms-transform: translate(2em);
    transform: translate(2em);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}

/*---------------------------------------*/
/*--------  6. Content layout    --------*/
/*---------------------------------------*/
/* tt-search-filter */
.tt-search-filter {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  position: relative;
  z-index: 2;
  background: #ffffff;
  height: 100px;
  -webkit-box-shadow: 0 5px 40px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 5px 40px rgba(0, 0, 0, 0.05);
  box-shadow: 0 5px 40px rgba(0, 0, 0, 0.05);
}

.tt-search-filter .tt-icon-filter {
  font-size: 40px;
  line-height: 1.1;
  color: #ffffff;
  position: absolute;
  top: 29px;
  left: 21px;
  z-index: 1;
}

.tt-search-filter > div {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100px;
}

.tt-search-filter > div select {
  opacity: 0;
}

.tt-search-filter > div.tt-col-title {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: #222222;
  font-size: 16px;
  line-height: 1.5;
  min-width: 210px;
  position: relative;
  overflow: hidden;
  padding: 3px 0 0 108px;
}

.tt-search-filter > div.tt-col-title:before {
  content: '';
  position: absolute;
  top: -31px;
  left: -69px;
  width: 161px;
  height: 161px;
  background: #dd3d53;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.tt-search-filter > div.tt-col-select {
  margin-top: 1px;
}

.tt-search-filter > div.tt-col-select .tt-select:not(:first-child) {
  margin-left: 8px;
}

.tt-search-filter > div.tt-col-select .tt-select:not(.tt-width-large) {
  width: 122px;
}

.tt-search-filter > div.tt-col-select .tt-select.tt-width-large {
  width: 178px;
}

.tt-search-filter > div.tt-col-btn .btn {
  margin-left: 11px;
  margin-top: 1px;
  padding-left: 41px;
  padding-right: 41px;
  padding-top: 7px;
}

.tt-search-filter > div.tt-col-btn .btn [class^="icon-"] {
  font-size: 13px;
}

@media (min-width: 1025px) {
  .tt-search-filter {
    margin-top: -50px;
  }
}

@media (max-width: 1229px) {
  .tt-search-filter {
    height: 109px;
    padding-left: 8px;
  }
  .tt-search-filter .tt-icon-filter {
    font-size: 28px;
    line-height: 1.1;
    color: #ffffff;
    position: absolute;
    top: 5px;
    left: 46px;
    z-index: 1;
  }
  .tt-search-filter > div.tt-col-title {
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    position: absolute;
    top: 0;
    left: 0;
    padding: 14px 0 0 113px;
  }
  .tt-search-filter > div.tt-col-title br {
    display: none;
  }
  .tt-search-filter > div.tt-col-title:before {
    content: '';
    position: absolute;
    top: -46px;
    left: 15px;
    width: 92px;
    height: 92px;
    background: #dd3d53;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
  }
  .tt-search-filter > div.tt-col-select {
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding-top: 53px;
  }
  .tt-search-filter > div.tt-col-btn {
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding-top: 53px;
  }
  .tt-search-filter > div.tt-col-btn .btn {
    height: 37px;
    padding-top: 4px;
  }
  .tt-search-filter > div .nice-select.tt-skin-01 {
    height: 37px;
    padding: 6px 33px 3px 14px;
  }
}

@media (max-width: 1024px) {
  .tt-search-filter > div.tt-col-select .tt-select:not(.tt-width-large) {
    width: 100px;
  }
  .tt-search-filter > div.tt-col-select .tt-select.tt-width-large {
    width: 134px;
  }
  .tt-search-filter > div.tt-col-btn .btn {
    padding-left: 21px;
    padding-right: 21px;
  }
  .tt-search-filter .tt-mobile-hide {
    display: none;
  }
}

@media (max-width: 790px) {
  .tt-search-filter > div.tt-col-select .tt-select:not(.tt-width-large) {
    width: 100px;
  }
  .tt-search-filter > div.tt-col-select .tt-select.tt-width-large {
    width: 128px;
  }
}

@media (max-width: 767px) {
  .tt-search-filter {
    display: none;
  }
}

.tt-search-filter .visible-desctope {
  display: none;
}

@media (min-width: 1025px) {
  .tt-search-filter .visible-desctope {
    display: block;
  }
}

.tt-search-filter .visible-device {
  display: none;
}

@media (max-width: 1024px) {
  .tt-search-filter .visible-device {
    display: block;
  }
}

/* tt-brand */
.tt-link-redirect {
  font-size: 11px;
  line-height: 1.1;
  font-weight: 600;
  color: #dd3d53;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.tt-link-redirect:after {
  position: absolute;
  right: -15px;
  top: 2px;
  font-family: 'carleader' !important;
  content: "\e92b";
  font-size: 8px;
  line-height: 1.1;
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}

.tt-link-redirect:hover {
  color: #222222;
}

@media (min-width: 769px) {
  .tt-link-redirect {
    position: absolute;
    right: 12px;
    top: 40px;
  }
}

@media (max-width: 768px) {
  .tt-link-redirect {
    position: relative;
    margin-left: -13px;
    top: 4px;
    display: inline-block;
  }
  .tt-link-redirect:after {
    top: 2px;
  }
}

.tt-brand {
  text-align: center;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.tt-brand .tt-img {
  min-height: 60px;
  text-align: center;
}

.tt-brand .tt-img img {
  width: inherit;
  display: inline-block;
  max-width: 100%;
  height: auto;
}

.tt-brand .tt-title {
  font-size: 15px;
  line-height: 1.5;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  margin: 0;
  padding: 0;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.tt-brand .tt-title:hover {
  color: #dd3d53;
}

.tt-brand .tt-title:not(:first-child) {
  margin-top: 18px;
}

.tt-brand:hover .tt-title {
  color: #dd3d53;
}

/* tt-box-map */
.tt-map {
  width: 100%;
}

.tt-map .tt-btn-toggle:not(.tt-style-02) {
  height: 41px;
  font-size: 27px;
  line-height: 1.2;
  text-align: center;
  display: block;
  color: #dd3d53;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.tt-map .tt-btn-toggle:not(.tt-style-02):before {
  font-family: "carleader";
  content: '\e92e';
}

.tt-map .tt-btn-toggle:not(.tt-style-02).is-open:before {
  content: '\e92d';
}

.tt-map .tt-btn-toggle:not(.tt-style-02):hover {
  color: #dd3d53;
}

.tt-map .tt-btn-toggle.tt-style-02 {
  height: 32px;
  font-size: 21px;
  line-height: 1.2;
  text-align: center;
  display: block;
  position: relative;
  color: #ffffff;
  overflow: hidden;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.tt-map .tt-btn-toggle.tt-style-02:before {
  font-family: "carleader";
  content: '\e92c';
  display: inline-block;
  position: relative;
  z-index: 1;
  top: 6px;
}

.tt-map .tt-btn-toggle.tt-style-02.is-open:before {
  content: '\e92b';
}

.tt-map .tt-btn-toggle.tt-style-02:after {
  content: '';
  position: absolute;
  width: 64px;
  height: 64px;
  background-color: #dd3d53;
  display: block;
  top: 0;
  left: 50%;
  margin-left: -32px;
  z-index: 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.tt-map .tt-box-map {
  display: none;
}

/* map */
.google-map {
  width: 100%;
  height: 320px;
}

/*  */
.tt-additional-box {
  margin-bottom: -57px;
}

/* short-structure */
@media (max-width: 575px) {
  .ws-short-structure {
    position: relative;
    padding-bottom: 15px;
  }
  .ws-short-structure:not(.is-open) {
    padding-bottom: 0px;
  }
  .ws-short-structure:not(.is-open) .tt-hide-block {
    display: none;
  }
  .ws-short-structure:not(.is-open):after {
    // -moz-opacity: 1;
    // -khtml-opacity: 1;
    // -webkit-opacity: 1;
    // opacity: 1;
  }
  .ws-short-structure:after {
    content: '';
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 43px;
    left: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    -webkit-opacity: 0;
    opacity: 0;
    background: -webkit-linear-gradient(bottom, rgba(255, 255, 255, 0), white 60%);
    background: -o-linear-gradient(bottom, rgba(255, 255, 255, 0), white 60%);
    background: -moz-linear-gradient(bottom, rgba(255, 255, 255, 0), white 60%);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white 60%);
  }
}

@media (min-width: 576px) {
  .ws-short-btn {
    display: none;
  }
}

@media (max-width: 575px) {
  .ws-short-btn {
    text-align: center;
  }
  .ws-short-btn .ws-btn-more {
    font-size: 11px;
    color: #dd3d53;
    display: inline-block;
    position: relative;
    padding: 5px 5px 5px;
    -moz-transition: color 0.2s linear;
    -o-transition: color 0.2s linear;
    -webkit-transition: color 0.2s linear;
    transition: color 0.2s linear;
  }
  .ws-short-btn .ws-btn-more:after {
    font-family: "carleader";
    content: "\e92c";
    display: block;
    font-size: 8px;
    line-height: 1;
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
    display: inline-block;
    -moz-transition: top 0.2s linear;
    -o-transition: top 0.2s linear;
    -webkit-transition: top 0.2s linear;
    transition: top 0.2s linear;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .ws-short-btn .ws-btn-more:hover {
    color: #222222;
  }
}

/*
	tt-media-02
*/
.tt-media-02 {
  position: relative;
  line-height: 24px;
  display: block;
  overflow: hidden;
}

.tt-media-02 img {
  width: 100%;
  height: auto;
}

.tt-media-02 .tt-description {
  position: absolute;
  bottom: -24px;
  left: 0;
  width: 100%;
  background-color: transparent;
  font-family: "Montserrat", sans-serif;
  transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}

.tt-media-02 .tt-description .tt-title {
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
  margin: 0;
  padding: 0;
}

.tt-media-02 .tt-description .tt-title .tt-total {
  font-size: 11px;
  line-height: 1;
  display: inline-block;
  background-color: #dd3d53;
  display: inline-block;
  padding: 5px 13px;
  min-height: 21px;
  font-weight: 600;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  position: relative;
  top: -3px;
  margin-left: 3px;
  letter-spacing: 0.01em;
}

@media (max-width: 575px) {
  .tt-media-02 .tt-description .tt-title .tt-total {
    padding: 5px;
    width: 24px;
    height: 24px;
    line-height: 1.4;
    text-align: center;
  }
  .tt-media-02 .tt-description .tt-title .tt-total span {
    display: none;
  }
}

.tt-media-02 .tt-description .tt-link-marker {
  color: #dd3d53;
  margin-top: 5px;
  display: inline-block;
  color: #dd3d53;
  font-weight: 700;
  font-size: 11px;
  text-transform: uppercase;
  transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -moz-opacity: 0;
  -khtml-opacity: 0;
  -webkit-opacity: 0;
  opacity: 0;
}

.tt-media-02 .tt-description .tt-link-marker:before {
  font-family: "carleader";
  content: '\e918';
  display: inline-block;
  margin-right: 6px;
  position: relative;
  top: 5px;
  font-size: 20px;
  color: #dd3d53;
  font-weight: 500;
  transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}

.tt-media-02 .tt-description .tt-link-marker:hover {
  color: #ffffff;
}

.tt-media-02 .tt-description .tt-link-marker:hover:before {
  color: #ffffff;
}

@media (min-width: 1024px) {
  .tt-media-02 .tt-description {
    padding: 23px 28px 12px 28px;
  }
}

@media (max-width: 1024px) {
  .tt-media-02 .tt-description {
    padding: 23px 14px 13px;
  }
  .tt-media-02 .tt-description .tt-title {
    font-size: 16px;
    font-weight: 600;
  }
  .tt-media-02 .tt-description .tt-title .tt-total {
    top: -2px;
  }
}

@media (max-width: 768px) {
  .tt-media-02 .tt-description {
    bottom: -20px;
    padding: 15px 10px 15px;
  }
  .tt-media-02 .tt-description .tt-title {
    font-size: 16px;
    font-weight: 600;
  }
  .tt-media-02 .tt-description .tt-title .tt-total {
    top: -3px;
    font-size: 11px;
  }
  .tt-media-02 .tt-description .tt-link-marker {
    margin-top: 0;
  }
  .tt-media-02 .tt-description .tt-link-marker:before {
    top: 5px;
  }
}

.tt-media-02:hover .tt-description {
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.6);
}

.tt-media-02:hover .tt-description p {
  display: block;
}

.tt-media-02:hover .tt-link-marker {
  -moz-opacity: 1;
  -khtml-opacity: 1;
  -webkit-opacity: 1;
  opacity: 1;
}

.tt-list-media-02 {
  margin-top: -19px;
}

.tt-list-media-02 .tt-media-02 {
  margin-top: 30px;
}

.tt-list-media-02 .tt-media-02 .tt-description {
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 576px) {
  .tt-list-media-02 .tt-media-02 .tt-description .tt-title {
    font-size: 16px;
  }
  .tt-list-media-02 .tt-media-02 .tt-description .tt-title .tt-total {
    margin-top: 5px;
    padding: 4px 9px 1px;
    min-height: 18px;
  }
}

@media (max-width: 575px) {
  .tt-list-media-02 .tt-media-02 .tt-description .tt-title .tt-total {
    margin-top: 5px;
  }
}

/*
Portfolio
*/
.portfolio-masonry-layout {
  position: relative;
}

.portfolio-masonry-layout > .container {
  position: relative;
}

.portfolio-masonry-layout .extra-link {
  font-size: 11px;
  line-height: 1.1;
  font-weight: 600;
  z-index: 0;
  color: #dd3d53;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  position: relative;
  margin-right: 13px;
  display: inline-block;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.portfolio-masonry-layout .extra-link:after {
  position: absolute;
  right: -15px;
  top: 2px;
  font-family: 'carleader' !important;
  content: "\e92b";
  font-size: 8px;
  line-height: 1.1;
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}

.portfolio-masonry-layout .extra-link:hover {
  color: #222222;
}

@media (min-width: 1025px) {
  .portfolio-masonry-layout .extra-link {
    position: absolute;
    right: 13px;
    top: 15px;
    z-index: 1;
  }
}

@media (max-width: 1024px) and (min-width: 769px) {
  .portfolio-masonry-layout .extra-link {
    position: absolute;
    right: 13px;
    top: -58px;
  }
}

@media (max-width: 768px) and (min-width: 576px) {
  .portfolio-masonry-layout .extra-link {
    position: absolute;
    right: 13px;
    top: -40px;
  }
}

.portfolio-masonry-layout .container.visible-mobile {
  text-align: center;
  padding: 24px 0 55px 0;
}

@media (max-width: 575px) {
  .portfolio-masonry-layout .container.visible-mobile.indent-bottom-none {
    padding-bottom: 0;
  }
}

.tt-portfolio-masonry {
  position: relative;
  z-index: 0;
}

.tt-portfolio-masonry .grid:after {
  content: '';
  display: block;
  clear: both;
}

.tt-portfolio-masonry .element-item {
  position: relative;
  float: inherit !important;
}

.tt-portfolio-masonry .tt-portfolio-content {

  -webkit-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

.tt-portfolio-masonry .tt-portfolio-content.tt-show {
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  justify-content: flex-start;
  opacity: 1;
  height: 40vw;
}

.tt-portfolio-masonry .layout-default .element-item {
  width: 20%;
  flex-grow: 0;
    flex-shrink: 0;
  // &:nth-child(1){
  //   order: 0;
  // }
  // &:nth-child(2){
  //   order: 1;
  // }
  // &:nth-child(3){
  //   order: 2;
  // }
  // &:nth-child(4){
  //   order: 6;
  // }
  // &:nth-child(5){
  //   order: 3;
  // }
  // &:nth-child(6){
  //   order: 4;
  // }
  // &:nth-child(7){
  //   order: 5;
  // }
}
.tt-portfolio-masonry .layout-default .element-item.element-item-2x {
  width: 40%;
  flex-grow: 0;
    flex-shrink: 0;
}
 

@media (max-width: 789px) {
  .tt-portfolio-masonry .tt-portfolio-content.tt-show {
    flex-flow: row wrap;
    opacity: 1;
    height: auto;
  } 
  .tt-portfolio-masonry .layout-default .element-item {
    flex-basis: 50%;
    width: auto;

    
  }
  .tt-portfolio-masonry .layout-default .element-item.element-item-2x {
    flex-basis: 100%;
    flex-grow: 0;
      flex-shrink: 0;
      width: auto;
  }
}
  
.tt-portfolio-masonry .layout-col-03 {
  margin-top: -15px;
}

.tt-portfolio-masonry .layout-col-03 .element-item {
  padding: 15px;
  width: 33.333%;
}

@media (max-width: 1024px) {
  .tt-portfolio-masonry .layout-col-03 .element-item {
    width: 50%;
  }
}

.tt-portfolio-masonry .tt-filter-nav {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  z-index: 2;
  margin-top: -4px;
  padding-bottom: 45px;
  text-transform: uppercase;
}

.tt-portfolio-masonry .tt-filter-nav .button {
  color: #222222;
  padding: 7px 17px;
  letter-spacing: 0.18em;
  font-size: 14px;
  margin: 0 6px;
  font-weight: 500;
  cursor: pointer;
  border: 1px solid transparent;
  transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}

.tt-portfolio-masonry .tt-filter-nav .button.active {
  color: #dd3d53;
  border-color: #dd3d53;
  cursor: default;
}

.tt-portfolio-masonry .tt-filter-nav .button:hover {
  color: #dd3d53;
}

@media (max-width: 1024px) {
  .tt-portfolio-masonry .tt-filter-nav {
    margin-top: 4px;
    padding-bottom: 22px;
  }
  .tt-portfolio-masonry .tt-filter-nav .button {
    margin: 0 4px;
    padding: 5px 14px;
  }
}

@media (max-width: 575px) {
  .tt-portfolio-masonry .tt-filter-nav {
    webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-top: -4px;
    padding-bottom: 22px;
  }
  .tt-portfolio-masonry .tt-filter-nav .button {
    margin: 4px 4px 0 4px;
  }
}

.tt-portfolio-content {
  transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}

.tt-portfolio-content .tt-portfolio-item {
  line-height: 26px;
}

.tt-portfolio-content .tt-portfolio-item figure {
  position: relative;
  display: block;
  width: 100%;
  overflow: hidden;
  margin: 0;
  z-index: 1;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.tt-portfolio-content .tt-portfolio-item figure img {
  width: 100%;
  height: auto;
  position: relative;
}

.tt-portfolio-content .tt-portfolio-item figure figcaption {
  position: absolute;
  width: 100%;
  bottom: -38px;
  left: 0%;
  padding: 20px 19px 24px;
  z-index: 3;
  color: #ffffff;
  background-color: transparent;
  transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}

.tt-portfolio-content .tt-portfolio-item figure figcaption:before {
  content: '\e918';
  font-family: "carleader";
  font-size: 20px;
  line-height: 1.1;
  display: block;
  position: absolute;
  right: 25px;
  bottom: 28px;
  color: #dd3d53;
  -moz-opacity: 0;
  -khtml-opacity: 0;
  -webkit-opacity: 0;
  opacity: 0;
}

.tt-portfolio-content .tt-portfolio-item figure figcaption .tt-title {
  font-size: 15px;
  line-height: 1.3;
  letter-spacing: 0em;
  font-weight: 600;
  margin: 0;
  padding: 0;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
}

.tt-portfolio-content .tt-portfolio-item figure figcaption .tt-title span {
  display: inline-block;
  background: #070606;
  color: #dd3d53;
  padding: 5px 7px 4px;
  font-size: 11px;
  line-height: 1.2;
  font-weight: 600;
  position: relative;
  top: -1px;
}

.tt-portfolio-content .tt-portfolio-item figure figcaption .tt-price {
  color: #dd3d53;
  font-weight: 700;
  font-size: 18px;
  margin-top: 6px;
  display: inline-block;
  -moz-opacity: 0;
  -khtml-opacity: 0;
  -webkit-opacity: 0;
  opacity: 0;
}

.tt-portfolio-content .tt-portfolio-item figure:hover {
  -webkit-transform: scale(1.06);
  -ms-transform: scale(1.06);
  transform: scale(1.06);
  z-index: 3;
}

.tt-portfolio-content .tt-portfolio-item figure:hover figcaption {
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.6);
}

.tt-portfolio-content .tt-portfolio-item figure:hover figcaption:before {
  -moz-opacity: 1;
  -khtml-opacity: 1;
  -webkit-opacity: 1;
  opacity: 1;
}

.tt-portfolio-content .tt-portfolio-item figure:hover figcaption .tt-price {
  -moz-opacity: 1;
  -khtml-opacity: 1;
  -webkit-opacity: 1;
  opacity: 1;
}

@media (max-width: 1560px) {
  .tt-portfolio-content .tt-portfolio-item figure figcaption .tt-title {
    font-size: 13px;
  }
  .tt-portfolio-content .tt-portfolio-item figure figcaption .tt-title span {
    padding: 3px 5px 3px;
    font-size: 9px;
    line-height: 1.2;
    position: relative;
    top: -2px;
  }
  .tt-portfolio-content .tt-portfolio-item figure figcaption .tt-price {
    font-size: 16px;
  }
  .tt-portfolio-content .tt-portfolio-item figure figcaption:before {
    font-size: 20px;
    right: 25px;
    bottom: 26px;
  }
}

@media (max-width: 1024px) {
  .tt-portfolio-content .tt-portfolio-item figure figcaption {
    padding: 20px 7px 24px 10px;
  }
  .tt-portfolio-content .tt-portfolio-item figure figcaption .tt-title {
    font-size: 12px;
  }
  .tt-portfolio-content .tt-portfolio-item figure figcaption .tt-title span {
    padding: 3px 3px 1px;
    font-size: 6px;
    line-height: 1.2;
    position: relative;
    top: -2px;
  }
  .tt-portfolio-content .tt-portfolio-item figure figcaption .tt-price {
    font-size: 14px;
  }
  .tt-portfolio-content .tt-portfolio-item figure figcaption:before {
    font-size: 16px;
    right: 10px;
    bottom: 28px;
  }
}

@media (max-width: 768px) {
  .tt-portfolio-content .tt-portfolio-item figure figcaption {
    padding: 15px 7px 19px 10px;
  }
}

/*
box 'What We Offer'
*/
.tt-layout01-bg {
  background-color: #fafafa;
  padding: 117px 0 0px 0;
  background-position: 0 0;
  background-repeat: no-repeat;
}

@media (max-width: 768px) {
  .tt-layout01-bg {
    padding: 66px 0 0px 0;
  }
}

.tt-layout01 {
  background: #fafafa url("/images/custom/box-layout01-img01.jpg") center top no-repeat;
  margin-top: -36px;
  width: 100%;
  padding: 39px 0 115px 0;
}

.tt-layout01 .layout01-desctope {
  padding-bottom: 14px;
}

.tt-layout01 .layout01-desctope .row:not(:first-child) .tt-item {
  margin-top: 72px;
}

@media (min-width: 768px) {
  .tt-layout01 .layout01-desctope {
    display: block;
  }
}

@media (max-width: 767px) {
  .tt-layout01 .layout01-desctope {
    display: none;
  }
}

@media (min-width: 768px) {
  .tt-layout01 .layout01-mobile {
    display: none;
  }
}

@media (max-width: 767px) {
  .tt-layout01 .layout01-mobile {
    display: block;
  }
}

.tt-layout01 .layout01-mobile .tt-title {
  padding-left: 15px;
  padding-right: 15px;
}

.tt-layout01 .layout01-mobile p {
  padding-left: 15px;
  padding-right: 15px;
}

.tt-layout01 .tt-item {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding-top: 5px;
}

@media (min-width: 1025px) {
  .tt-layout01 .tt-item.tt-icon-bottom .tt-item-icon {
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
  }
  .tt-layout01 .tt-item.tt-icon-bottom:not(.tt-icon-right) .tt-item-icon {
    margin-bottom: 5px;
  }
}

.tt-layout01 .tt-item.tt-icon-right {
  text-align: right;
}

.tt-layout01 .tt-item.tt-icon-right .tt-item-icon {
  -webkit-order: 2;
  -ms-flex-order: 2;
  order: 2;
  margin-left: 27px;
  margin-top: -3px;
}

.tt-layout01 .tt-item:not(.tt-icon-right) .tt-item-icon {
  margin-right: 27px;
  margin-top: -3px;
}

.tt-layout01 .tt-item .tt-item-icon {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 98px;
  height: 98px;
  min-width: 98px;
  min-height: 98px;
  background-color: #dd3d53;
  font-size: 42px;
  line-height: 1;
  color: #ffffff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.tt-layout01 .tt-item .tt-item-content .tt-title {
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  color: #222222;
  font-size: 18px;
  line-height: 1.2;
  margin: 0;
  padding: 0 0 12px 0;
}

.tt-layout01 .tt-item .tt-item-content .link-tel {
  font-size: 22px;
  display: inline-block;
  margin-top: 9px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  color: #dd3d53;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.tt-layout01 .tt-item .tt-item-content .link-tel:hover {
  color: #222222;
}

.tt-layout01 .tt-item .tt-item-content p {
  margin: 0;
}

.tt-layout01 .tt-item .tt-item-content p + p {
  margin-top: 16px;
}

.tt-layout01 .tt-item:not(:first-child) {
  margin-top: 56px;
}

@media (max-width: 767px) {
  .tt-layout01 .tt-item {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 0 !important;
  }
  .tt-layout01 .tt-item .tt-item-icon {
    margin-right: 0 !important;
  }
  .tt-layout01 .tt-item .tt-item-content {
    text-align: center;
    margin-top: 15px;
  }
  .tt-layout01 .tt-item .tt-item-content .tt-title {
    font-size: 14px;
  }
  .tt-layout01 .tt-item .tt-item-content .tt-title .tt-item-icon {
    width: 98px;
    height: 98px;
    min-width: 98px;
    min-height: 98px;
    font-size: 32px;
  }
  .tt-layout01 .tt-item .tt-item-content p {
    text-align: center;
  }
}

@media (max-width: 1229px) {
  .tt-layout01 {
    margin-top: 0px;
    background-position: center 74px;
    background-size: 79% auto;
  }
  .tt-layout01 .tt-item {
    font-size: 17px;
  }
  .tt-layout01 .tt-item p {
    font-size: 15px;
    line-height: 25px;
  }
  .tt-layout01 .tt-item .tt-item-icon {
    width: 78px;
    height: 78px;
    min-width: 78px;
    min-height: 78px;
    font-size: 42px;
  }
}

@media (max-width: 1024px) {
  .tt-layout01 {
    padding: 39px 0 316px 0;
    background-position: center bottom;
    background-size: 88% auto;
  }
  .tt-layout01 .row [class^="col"]:first-child {
    padding-right: 17px;
  }
  .tt-layout01 .row [class^="col"]:last-child {
    padding-left: 17px;
  }
}

@media (max-width: 768px) {
  .tt-layout01 .tt-item .tt-item-content .tt-title {
    font-size: 16px;
    padding-bottom: 10px;
  }
  .tt-layout01 .tt-item .tt-item-icon {
    width: 71px;
    height: 71px;
    min-width: 71px;
    min-height: 71px;
    font-size: 32px;
  }
  .tt-layout01 .tt-item p {
    line-height: 22px;
  }
}

@media (max-width: 767px) {
  .tt-layout01 {
    padding: 0 0 60% 0;
    background-size: 100% auto;
  }
  .tt-layout01 .tt-item .tt-item-content .tt-title {
    font-size: 14px;
    padding-bottom: 11px;
  }
  .tt-layout01 .tt-item .tt-item-icon {
    width: 97px;
    height: 97px;
    min-width: 97px;
    min-height: 97px;
    font-size: 42px;
  }
  .tt-layout01 .tt-item p {
    line-height: 20px;
  }
}

.tt-layout01-02-bg {
  background-color: #fafafa;
  padding: 117px 0 0px 0;
}

@media (max-width: 768px) {
  .tt-layout01-02-bg {
    padding: 66px 0 0px 0;
  }
}

.tt-layout01-02 {
  background-image: url("/images/motorleader/custom/box-layout01-img01.jpg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  background-size: 100% auto;
  margin-top: -36px;
  width: 100%;
  padding: 39px 0 115px 0;
}

.tt-layout01-02 .layout01-desctope {
  padding-bottom: 14px;
}

.tt-layout01-02 .layout01-desctope .row:not(:first-child) .tt-item {
  margin-top: 72px;
}

@media (min-width: 768px) {
  .tt-layout01-02 .layout01-desctope {
    display: block;
  }
}

@media (max-width: 767px) {
  .tt-layout01-02 .layout01-desctope {
    display: none;
  }
}

@media (min-width: 768px) {
  .tt-layout01-02 .layout01-mobile {
    display: none;
  }
}

@media (max-width: 767px) {
  .tt-layout01-02 .layout01-mobile {
    display: block;
  }
}

.tt-layout01-02 .layout01-mobile .tt-title {
  padding-left: 15px;
  padding-right: 15px;
}

.tt-layout01-02 .layout01-mobile p {
  padding-left: 15px;
  padding-right: 15px;
}

.tt-layout01-02 .tt-item {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding-top: 5px;
}

.tt-layout01-02 .tt-item.tt-icon-right {
  text-align: right;
}

.tt-layout01-02 .tt-item.tt-icon-right .tt-item-icon {
  -webkit-order: 2;
  -ms-flex-order: 2;
  order: 2;
  margin-left: 27px;
  margin-top: -3px;
}

@media (max-width: 767px) {
  .tt-layout01-02 .tt-item.tt-icon-right .tt-item-icon {
    margin-left: 0px;
  }
}

.tt-layout01-02 .tt-item:not(.tt-icon-right) .tt-item-icon {
  margin-right: 27px;
  margin-top: -3px;
}

@media (max-width: 767px) {
  .tt-layout01-02 .tt-item:not(.tt-icon-right) .tt-item-icon {
    margin-left: 0px !important;
  }
}

.tt-layout01-02 .tt-item .tt-item-icon {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 98px;
  height: 98px;
  min-width: 98px;
  min-height: 98px;
  background-color: #dd3d53;
  font-size: 42px;
  line-height: 1;
  color: #ffffff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.tt-layout01-02 .tt-item .tt-item-content .tt-title {
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  color: #222222;
  font-size: 18px;
  line-height: 1.2;
  margin: 0;
  padding: 0 0 12px 0;
}

.tt-layout01-02 .tt-item .tt-item-content p {
  margin: 0;
  padding: 0;
}

.tt-layout01-02 .tt-item:not(:first-child) {
  margin-top: 21px;
}

@media (max-width: 767px) {
  .tt-layout01-02 .tt-item {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 0 !important;
  }
  .tt-layout01-02 .tt-item .tt-item-icon {
    margin-right: 0 !important;
  }
  .tt-layout01-02 .tt-item .tt-item-content {
    text-align: center;
    margin-top: 15px;
    padding-left: 25px;
    padding-right: 25px;
  }
  .tt-layout01-02 .tt-item .tt-item-content .tt-title {
    font-size: 14px;
  }
  .tt-layout01-02 .tt-item .tt-item-content .tt-title .tt-item-icon {
    width: 98px;
    height: 98px;
    min-width: 98px;
    min-height: 98px;
    font-size: 32px;
  }
  .tt-layout01-02 .tt-item .tt-item-content p {
    text-align: center;
  }
}

@media (max-width: 1229px) {
  .tt-layout01-02 {
    background-image: url("/images/motorleader/custom/box-layout01-img01_bot.jpg");
    margin-top: 0px;
    background-position: 50% bottom;
    background-size: 70% auto;
    padding-bottom: 40%;
  }
  .tt-layout01-02 .tt-item {
    font-size: 17px;
  }
  .tt-layout01-02 .tt-item p {
    font-size: 15px;
    line-height: 25px;
  }
  .tt-layout01-02 .tt-item .tt-item-icon {
    width: 78px;
    height: 78px;
    min-width: 78px;
    min-height: 78px;
    font-size: 42px;
  }
}

@media (max-width: 1024px) {
  .tt-layout01-02 .tt-item .tt-item-content .tt-title {
    font-size: 16px;
    padding-bottom: 10px;
  }
  .tt-layout01-02 .tt-item .tt-item-icon {
    width: 71px;
    height: 71px;
    min-width: 71px;
    min-height: 71px;
    font-size: 32px;
  }
  .tt-layout01-02 .tt-item p {
    line-height: 22px;
  }
}

@media (max-width: 767px) {
  .tt-layout01-02 {
    padding: 0 0 60% 0;
    background-size: 100% auto;
  }
  .tt-layout01-02 .tt-item .tt-item-content .tt-title {
    font-size: 14px;
    padding-bottom: 11px;
  }
  .tt-layout01-02 .tt-item .tt-item-icon {
    width: 97px;
    height: 97px;
    min-width: 97px;
    min-height: 97px;
    font-size: 42px;
  }
  .tt-layout01-02 .tt-item p {
    line-height: 20px;
  }
}

/*
	tt-promo-01
*/
.tt-promo-01 {
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  padding: 97px 0 55px 0;
}

.tt-promo-01 .tt-block-title .tt-title {
  color: #ffffff;
}

.tt-promo-01 .tt-promo-layout {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 11px;
}

.tt-promo-01 .tt-item .box-value {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  width: 178px;
  height: 178px;
  border: 7px solid #421219;
  position: relative;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.tt-promo-01 .tt-item .box-value:before {
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px solid #bcbcbd;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.tt-promo-01 .tt-item .box-value i {
  color: #dd3d53;
  font-size: 42px;
  line-height: 1;
}

.tt-promo-01 .tt-item .box-value i + span {
  margin-top: 8px;
}

.tt-promo-01 .tt-item .box-value > span {
  width: 100%;
}

.tt-promo-01 .tt-item .box-value span {
  color: #ffffff;
  font-size: 36px;
  line-height: 1;
  font-family: "Montserrat", sans-serif;
}

.tt-promo-01 .tt-item .box-value + .tt-title {
  margin-top: 15px;
}

.tt-promo-01 .tt-item .tt-title {
  display: block;
  text-align: center;
  font-size: 18px;
  line-height: 1.2;
  font-weight: 600;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
}

.tt-promo-01 .tt-item:not(:first-child):not(:last-child) {
  margin-left: 45px;
  margin-right: 45px;
}

.tt-promo-01 .tt-item:first-child {
  margin-right: 45px;
}

.tt-promo-01 .tt-item:last-child {
  margin-left: 45px;
}

@media (max-width: 1229px) {
  .tt-promo-01 .tt-item:not(:first-child):not(:last-child) {
    margin-left: 32px;
    margin-right: 32px;
  }
  .tt-promo-01 .tt-item:first-child {
    margin-right: 32px;
  }
  .tt-promo-01 .tt-item:last-child {
    margin-left: 32px;
  }
}

@media (max-width: 1024px) {
  .tt-promo-01 {
    padding: 70px 0 39px 0;
  }
  .tt-promo-01 .tt-promo-layout {
    margin-top: 6px;
  }
  .tt-promo-01 .tt-promo-layout .slick-dots {
    bottom: -13px;
  }
  .tt-promo-01 .tt-item:not(:first-child):not(:last-child) {
    margin-left: 24px;
    margin-right: 24px;
  }
  .tt-promo-01 .tt-item:first-child {
    margin-right: 24px;
  }
  .tt-promo-01 .tt-item:last-child {
    margin-left: 24px;
  }
  .tt-promo-01 .tt-item .box-value {
    width: 144px;
    height: 144px;
    border: 7px solid #421219;
  }
  .tt-promo-01 .tt-item .box-value i {
    font-size: 32px;
    line-height: 1;
  }
  .tt-promo-01 .tt-item .box-value i + span {
    margin-top: 8px;
  }
  .tt-promo-01 .tt-item .box-value span {
    font-size: 30px;
  }
  .tt-promo-01 .tt-item .box-value + .tt-title {
    margin-top: 21px;
  }
  .tt-promo-01 .tt-item .tt-title {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .tt-promo-01 .tt-item:not(:first-child):not(:last-child) {
    margin-left: 0px;
    margin-right: 0px;
  }
  .tt-promo-01 .tt-item:first-child {
    margin-right: 0px;
  }
  .tt-promo-01 .tt-item:last-child {
    margin-left: 0px;
  }
  .tt-promo-01 .tt-item .box-value {
    margin: 0 auto;
  }
}

@media (max-width: 575px) {
  .tt-promo-01 {
    padding: 57px 0 72px 0;
  }
  .tt-promo-01 .tt-promo-layout {
    margin-top: 20px;
  }
  .tt-promo-01 .tt-item .box-value {
    width: 160px;
    height: 160px;
  }
  .tt-promo-01 .tt-item .box-value i {
    font-size: 42px;
  }
  .tt-promo-01 .tt-item .box-value span {
    font-size: 32px;
  }
}

.tt-promo-01-nowrapper .tt-promo-layout {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 11px;
}

.tt-promo-01-nowrapper .tt-item .box-value {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  width: 178px;
  height: 178px;
  border: 7px solid #f6f6f6;
  position: relative;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.tt-promo-01-nowrapper .tt-item .box-value:before {
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px solid #eeeeee;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.tt-promo-01-nowrapper .tt-item .box-value i {
  color: #dd3d53;
  font-size: 42px;
  line-height: 1;
}

.tt-promo-01-nowrapper .tt-item .box-value i + span {
  margin-top: 8px;
}

.tt-promo-01-nowrapper .tt-item .box-value > span {
  width: 100%;
}

.tt-promo-01-nowrapper .tt-item .box-value span {
  color: #222222;
  font-size: 36px;
  line-height: 1;
  font-family: "Montserrat", sans-serif;
}

.tt-promo-01-nowrapper .tt-item .box-value + .tt-title {
  margin-top: 15px;
}

.tt-promo-01-nowrapper .tt-item .tt-title {
  display: block;
  text-align: center;
  font-size: 18px;
  line-height: 1.2;
  font-weight: 600;
  color: #222222;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 0;
  padding-bottom: 0;
}

.tt-promo-01-nowrapper .tt-item:not(:first-child):not(:last-child) {
  margin-left: 45px;
  margin-right: 45px;
}

.tt-promo-01-nowrapper .tt-item:first-child {
  margin-right: 45px;
}

.tt-promo-01-nowrapper .tt-item:last-child {
  margin-left: 45px;
}

@media (max-width: 1229px) {
  .tt-promo-01-nowrapper .tt-item:not(:first-child):not(:last-child) {
    margin-left: 32px;
    margin-right: 32px;
  }
  .tt-promo-01-nowrapper .tt-item:first-child {
    margin-right: 32px;
  }
  .tt-promo-01-nowrapper .tt-item:last-child {
    margin-left: 32px;
  }
}

@media (max-width: 1024px) {
  .tt-promo-01-nowrapper .tt-promo-layout {
    margin-top: 6px;
  }
  .tt-promo-01-nowrapper .tt-promo-layout .slick-dots {
    bottom: -13px;
  }
  .tt-promo-01-nowrapper .tt-item:not(:first-child):not(:last-child) {
    margin-left: 24px;
    margin-right: 24px;
  }
  .tt-promo-01-nowrapper .tt-item:first-child {
    margin-right: 24px;
  }
  .tt-promo-01-nowrapper .tt-item:last-child {
    margin-left: 24px;
  }
  .tt-promo-01-nowrapper .tt-item .box-value {
    width: 144px;
    height: 144px;
  }
  .tt-promo-01-nowrapper .tt-item .box-value i {
    font-size: 32px;
    line-height: 1;
  }
  .tt-promo-01-nowrapper .tt-item .box-value i + span {
    margin-top: 8px;
  }
  .tt-promo-01-nowrapper .tt-item .box-value span {
    font-size: 30px;
  }
  .tt-promo-01-nowrapper .tt-item .box-value + .tt-title {
    margin-top: 21px;
  }
  .tt-promo-01-nowrapper .tt-item .tt-title {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .tt-promo-01-nowrapper .tt-item:not(:first-child):not(:last-child) {
    margin-left: 0px;
    margin-right: 0px;
  }
  .tt-promo-01-nowrapper .tt-item:first-child {
    margin-right: 0px;
  }
  .tt-promo-01-nowrapper .tt-item:last-child {
    margin-left: 0px;
  }
  .tt-promo-01-nowrapper .tt-item .box-value {
    margin: 0 auto;
  }
}

@media (max-width: 575px) {
  .tt-promo-01-nowrapper .tt-promo-layout {
    margin-top: 20px;
  }
  .tt-promo-01-nowrapper .tt-item .box-value {
    width: 160px;
    height: 160px;
  }
  .tt-promo-01-nowrapper .tt-item .box-value i {
    font-size: 42px;
  }
  .tt-promo-01-nowrapper .tt-item .box-value span {
    font-size: 32px;
  }
}

/*
	tt-promo-02
*/
.tt-promo-02 {
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  background-position: center top;
  padding: 97px 0 101px 0;
}

@media (max-width: 1024px) {
  .tt-promo-02 {
    padding: 67px 0 71px 0;
  }
}

@media (max-width: 640px) {
  .tt-promo-02 {
    padding: 57px 0 62px 0;
  }
}

.tt-promo-02-layout {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 100%;
  margin-top: 11px;
}

.tt-promo-02-layout .tt-item {
  width: 241px;
  min-width: 241px;
}

.tt-promo-02-layout .tt-item .box-icon {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  text-align: center;
  width: 170px;
  height: 170px;
  border: 8px solid rgba(156, 156, 156, 0.1);
  position: relative;
  margin: 0 auto;
  background-color: #fff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  -moz-transition: border 0.2s linear;
  -o-transition: border 0.2s linear;
  -webkit-transition: border 0.2s linear;
  transition: border 0.2s linear;
}

.tt-promo-02-layout .tt-item .box-icon:before {
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px solid #d3d3d3;
  -moz-transition: border 0.2s linear;
  -o-transition: border 0.2s linear;
  -webkit-transition: border 0.2s linear;
  transition: border 0.2s linear;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.tt-promo-02-layout .tt-item .box-icon i {
  color: #dd3d53;
  font-size: 52px;
  line-height: 1;
  display: inline-block;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.tt-promo-02-layout .tt-item .box-icon .tt-line-dotted {
  position: absolute;
  top: 50%;
  z-index: 1;
  width: 47px;
  height: 3px;
  margin-top: -2px;
  background: url("/images/custom/promo02_dotted.png") 0 0 repeat-x;
}

.tt-promo-02-layout .tt-item .box-icon .tt-left {
  left: -48px;
}

.tt-promo-02-layout .tt-item .box-icon .tt-right {
  right: -48px;
}

.tt-promo-02-layout .tt-item .tt-title {
  display: block;
  text-align: center;
  font-size: 18px;
  line-height: 1.2;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  color: #222222;
  margin: 0px 0 0 0;
}

.tt-promo-02-layout .tt-item .box-icon + .tt-title {
  margin-top: 28px;
}

.tt-promo-02-layout .tt-item:hover .box-icon {
  border-color: rgba(221, 61, 83, 0.1);
}

.tt-promo-02-layout .tt-item:hover .box-icon:before {
  border-color: #dd3d53;
}

.tt-promo-02-layout .tt-item:hover .box-icon i {
  color: #222222;
}

@media (max-width: 768px) {
  .tt-promo-02-layout .tt-item .tt-title {
    font-size: 14px;
  }
}

.tt-promo-02-layout .tt-item-smal {
  width: 59px;
  min-width: 59px;
  max-width: 59px;
}

.tt-promo-02-layout .tt-item-smal .box-icon {
  width: 38px;
  height: 38px;
  margin: 0 auto;
  position: relative;
  z-index: 3;
  overflow: hidden;
  background: #ffffff;
  top: 66px;
  border: 5px solid rgba(156, 156, 156, 0.1);
  text-align: center;
  font-size: 12px;
  line-height: 2.5;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.tt-promo-02-layout .tt-item-smal .box-icon:before {
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  z-index: 2;
  border: 1px solid #d3d3d3;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.tt-promo-02-layout .tt-item-smal:nth-child(2) .box-icon {
  color: #dd3d53;
  -webkit-animation: blink 1s infinite;
  animation: blink 1s infinite;
}

.tt-promo-02-layout .tt-item-smal:nth-child(4) .box-icon {
  color: #dd3d53;
  -webkit-animation: blink 1s infinite;
  animation: blink 1s infinite;
}

.tt-promo-02-layout .tt-item-smal:nth-child(6) .box-icon {
  color: #dd3d53;
  -webkit-animation: blink 1s infinite;
  animation: blink 1s infinite;
}

@-webkit-keyframes blink {
  0% {
    color: #d3d3d3;
  }
  50% {
    color: #dd3d53;
  }
  100% {
    color: #d3d3d3;
  }
}

@keyframes blink {
  0% {
    color: #d3d3d3;
  }
  50% {
    color: #dd3d53;
  }
  100% {
    color: #d3d3d3;
  }
}

@media (max-width: 1125px) {
  .tt-promo-02-layout .tt-item-smal {
    display: none;
  }
  .tt-promo-02-layout .tt-item {
    width: 190px;
    min-width: 190px;
  }
  .tt-promo-02-layout .tt-item .box-icon {
    width: 148px;
    height: 148px;
  }
  .tt-promo-02-layout .tt-item .box-icon i {
    font-size: 40px;
  }
  .tt-promo-02-layout .tt-item .box-icon .tt-right {
    right: -51px;
  }
  .tt-promo-02-layout .tt-item .box-icon .tt-left {
    display: none;
  }
  .tt-promo-02-layout .tt-item .box-icon .tt-line-dotted {
    width: 44px;
  }
  .tt-promo-02-layout .tt-item .box-icon + .tt-title {
    margin-top: 12px;
  }
  .tt-promo-02-layout .tt-item .tt-title {
    font-size: 14px;
  }
}

@media (max-width: 760px) {
  .tt-promo-02-layout .tt-item {
    width: 155px;
    min-width: 155px;
  }
  .tt-promo-02-layout .tt-item .box-icon {
    width: 113px;
    height: 113px;
  }
  .tt-promo-02-layout .tt-item .box-icon i {
    font-size: 40px;
  }
  .tt-promo-02-layout .tt-item .box-icon .tt-right {
    right: -51px;
  }
  .tt-promo-02-layout .tt-item .box-icon .tt-left {
    display: none;
  }
  .tt-promo-02-layout .tt-item .box-icon + .tt-title {
    margin-top: 12px;
  }
  .tt-promo-02-layout .tt-item .tt-title {
    font-size: 14px;
  }
}

@media (max-width: 640px) {
  .tt-promo-02-layout {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
  }
  .tt-promo-02-layout .tt-item .box-icon .tt-right {
    display: none;
  }
  .tt-promo-02-layout .tt-item .box-icon + .tt-title {
    margin-top: 7px;
  }
  .tt-promo-02-layout .tt-item:not(:last-child) {
    padding-bottom: 18px;
  }
}

/*
	tt-promo-03-layout
 */
.tt-promo-03-layout {
  text-align: center;
  background: url("/images/custom/tt-promo-03-layout.jpg") center center no-repeat;
  padding: 0 0 50px 0;
}

.tt-promo-03-layout h5.tt-title {
  font-size: 36px;
  line-height: 1.2;
  font-weight: 600;
  color: #222222;
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0 0 17px 0;
}

.tt-promo-03-layout p {
  max-width: 560px;
  margin: 0 auto 0;
  color: #222222;
}

@media (min-width: 1025px) {
  .tt-promo-03-layout p {
    font-size: 16px;
    line-height: 26px;
  }
}

.tt-promo-03-layout h6.tt-title {
  font-size: 18px;
  line-height: 1.2;
  font-weight: 600;
  color: #222222;
  font-family: "Montserrat", sans-serif;
  margin: 21px 0 0 0;
  padding: 0 0 27px 0;
}

@media (min-width: 1025px) {
  .tt-promo-03-layout .btn {
    padding-left: 34px;
    padding-right: 34px;
  }
}

@media (max-width: 1024px) {
  .tt-promo-03-layout {
    background-size: 95% auto;
  }
  .tt-promo-03-layout h5.tt-title {
    font-size: 30px;
  }
  .tt-promo-03-layout h6.tt-title {
    font-size: 16px;
  }
}

@media (max-width: 766px) {
  .tt-promo-03-layout {
    background-size: 120% auto;
  }
}

@media (max-width: 766px) {
  .tt-promo-03-layout h6.tt-title {
    max-width: 170px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 575px) {
  .tt-promo-03-layout {
    background-position: center 76%;
    padding-bottom: 0;
  }
  .tt-promo-03-layout h5.tt-title {
    font-size: 24px;
    line-height: 1.1;
  }
  .tt-promo-03-layout h6.tt-title {
    font-size: 14px;
    max-width: 130px;
  }
  .tt-promo-03-layout p {
    font-size: 14px;
    line-height: 24px;
  }
}

/*
	tt-media
 */
.js-carousel {
  margin-left: -15px;
  margin-right: -15px;
}

.js-carousel.tt-extra-shadow {
  margin-top: -15px;
  margin-bottom: -15px;
}

@media (min-width: 767px) {
  .tt-media-carousel {
    margin-top: -4px !important;
  }
}

@media (max-width: 766px) {
  .tt-media-carousel {
    margin-top: 7px !important;
  }
  .tt-media-carousel .slick-slide {
    padding-bottom: 15px !important;
  }
}

.tt-media {
  margin: 15px;
  display: block;
  color: #222222;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -moz-transition: box-shadow 0.2s linear;
  -o-transition: box-shadow 0.2s linear;
  -webkit-transition: box-shadow 0.2s linear;
  transition: box-shadow 0.2s linear;
}

@media (max-width: 766px) and (min-width: 490x) {
  .tt-media {
    max-width: 371px;
    margin: 0 auto;
  }
}

.tt-media:hover {
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
}

.tt-media .tt-layot {
  position: relative;
}

.tt-media .tt-img + .tt-layot .tt-time {
  position: absolute;
  top: -32px;
}

.tt-media .tt-time {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 62px;
  height: 62px;
  margin-left: -11px;
  background-color: #dd3d53;
  color: #ffffff;
  font-size: 12px;
  line-height: 1;
  margin-bottom: 40px;
  text-transform: uppercase;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.tt-media .tt-time span {
  display: block;
  text-align: center;
  font-size: 24px;
  line-height: 1;
  font-weight: 600;
  margin-top: -2px;
  width: 100%;
}

.tt-media .tt-layot {
  padding: 56px 30px 37px 30px;
}

.tt-media .tt-layot .title {
  font-size: 18px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0 0 20px 0;
}

.tt-media .tt-layot p {
  padding: 0;
  margin: 0;
}

.tt-media .tt-layot .tt-link {
  color: #dd3d53;
  font-size: 11px;
  line-height: 26px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  margin-top: 18px;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.tt-media .tt-layot .tt-link:after {
  position: absolute;
  right: -15px;
  top: 9px;
  font-family: 'carleader' !important;
  content: "\e92b";
  font-size: 8px;
  line-height: 1.1;
  color: #dd3d53;
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.tt-media .tt-layot .tt-link:hover {
  color: #222222;
}

.tt-media .tt-layot .tt-link:hover:after {
  color: #222222;
}

.tt-media:hover {
  color: #222222;
}

@media (max-width: 1024px) {
  .tt-media .tt-layot {
    padding: 46px 30px 15px 30px;
  }
  .tt-media .tt-layot .title {
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 10px;
  }
  .tt-media .tt-layot p {
    font-size: 15px;
    line-height: 24px;
  }
  .tt-media .tt-layot .tt-link {
    margin-top: 27px;
  }
}

@media (max-width: 575px) {
  .tt-media .tt-img + .tt-layot .tt-time {
    top: -25px;
  }
  .tt-media .tt-time {
    width: 47px;
    height: 47px;
    font-size: 10px;
    line-height: 0.9;
    margin-left: -11px;
  }
  .tt-media .tt-time span {
    font-size: 20px;
    line-height: 0.9;
    margin-top: -2px;
  }
  .tt-media .tt-layot {
    padding: 35px 21px 15px 21px;
  }
  .tt-media .tt-layot .title {
    font-size: 14px;
    line-height: 20px;
    padding-bottom: 10px;
  }
  .tt-media .tt-layot p {
    font-size: 14px;
    line-height: 20px;
  }
  .tt-media .tt-layot .tt-link {
    margin-top: 16px;
  }
}

/*
	box-reviews
*/
.box-reviews {
  width: 100%;
  height: 100%;
  z-index: 2;
  padding: 99px 0 83px 0;
  background: url("/images/custom/parallax01-img01.jpg") center top no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  color: #ffffff;
  position: relative;
  z-index: 0;
}

.box-reviews .tt-block-title .tt-title {
  color: #ffffff;
}

.box-reviews .container {
  position: relative;
  z-index: 2;
}

@media (min-width: 1025px) {
  .box-reviews .slick-slider .slick-dots {
    margin-top: 51px;
  }
}

.box-reviews .tt-reviews-content {
  font-size: 19px;
  line-height: 30px;
  padding: 0 20px 0px;
  text-align: center;
}

.box-reviews .tt-reviews-content .box-show-rating {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 15px;
  line-height: 1.1;
  color: #dd3d53;
  position: relative;
  margin-bottom: 34px;
  margin-top: 2px;
}

.box-reviews .tt-reviews-content .box-show-rating:not(.no-separator):before, .box-reviews .tt-reviews-content .box-show-rating:not(.no-separator):after {
  content: '';
  display: inline-block;
  position: relative;
  width: 233px;
  height: 1px;
  top: 1px;
  background-color: #63656d;
}

.box-reviews .tt-reviews-content .box-show-rating:not(.no-separator):before {
  margin-right: 24px;
}

.box-reviews .tt-reviews-content .box-show-rating:not(.no-separator):after {
  margin-left: 24px;
}

.box-reviews .tt-reviews-content .box-show-rating .tt-rating {
  margin-top: 0;
}

.box-reviews .tt-reviews-content p {
  margin: 23px 0 0 0;
}

.box-reviews .tt-reviews-content .tt-title {
  font-size: 15px;
  font-weight: bold;
  padding: 0;
}

.box-reviews .tt-reviews-content .tt-title span {
  color: #949495;
  font-style: italic;
  font-weight: normal;
}

.box-reviews .tt-reviews-content02 {
  position: relative;
  padding: 37px 23px 0;
}

.box-reviews .tt-reviews-content02 .tt-border {
  border-top: 1px solid #7d8f9d;
  border-left: 1px solid #7d8f9d;
  border-right: 1px solid #7d8f9d;
  font-size: 16px;
  line-height: 26px;
  padding: 53px 30px 27px;
  text-align: center;
  position: relative;
}

.box-reviews .tt-reviews-content02 .tt-border .tt-box-icon {
  width: 75px;
  height: 75px;
  position: absolute;
  left: 50%;
  margin-left: -37px;
  top: -38px;
  text-align: center;
  background-color: #dd3d53;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.box-reviews .tt-reviews-content02 .tt-border .tt-box-icon img {
  max-width: 100%;
  width: auto;
  display: inline-block;
  height: auto;
  position: relative;
  top: 26px;
}

.box-reviews .tt-reviews-content02 .tt-border .box-show-rating {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 15px;
  line-height: 1.1;
  color: #dd3d53;
  position: relative;
  margin-bottom: 15px;
  margin-top: -1px;
}

.box-reviews .tt-reviews-content02 .tt-border .box-show-rating .tt-rating {
  margin-top: 0;
}

.box-reviews .tt-reviews-content02 .tt-border p {
  margin: 23px 0 0 0;
}

.box-reviews .tt-reviews-content02 .tt-title {
  position: relative;
  font-size: 15px;
  font-weight: bold;
  padding: 15px 0 0 74px;
}

.box-reviews .tt-reviews-content02 .tt-title span {
  color: #949495;
  font-style: italic;
  font-weight: normal;
}

.box-reviews .tt-reviews-content02 .tt-title:before {
  content: '';
  width: 33px;
  height: 1px;
  background-color: #7d8f9d;
  position: absolute;
  top: 0;
  left: 0;
}

.box-reviews .tt-reviews-content02 .tt-title:after {
  content: '';
  width: calc(100% - 56px);
  height: 1px;
  background-color: #7d8f9d;
  position: absolute;
  top: 0;
  right: 0;
}

.box-reviews .tt-reviews-content02 .tt-title .tt-obj {
  width: 24px;
  height: 27px;
  position: absolute;
  top: 0;
  left: 32px;
  display: block;
}

.box-reviews .tt-reviews-content02 .tt-title .tt-obj:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 100%;
  background-color: #7d8f9d;
}

.box-reviews .tt-reviews-content02 .tt-title .tt-obj:after {
  content: '';
  display: block;
  position: absolute;
  right: 11px;
  top: -4px;
  width: 1px;
  height: calc(100% + 8px);
  background-color: #7d8f9d;
  -o-transform: rotate(-40deg);
  -ms-transform: rotate(-40deg);
  -moz-transform: rotate(-40deg);
  -webkit-transform: rotate(-40deg);
  transform: rotate(-40deg);
}

.box-reviews .box-reviews-img {
  position: absolute;
  bottom: -58px;
  height: 100%;
  right: 0;
  width: 100%;
  overflow: hidden;
  z-index: 1;

}
.box-reviews .box-reviews-img .slide-img {
  width: 600px;
  max-width: 100%;
    right: 0px !important;
}

.box-reviews .box-reviews-img div {
  // opacity: 0;
  position: absolute;
  bottom: -30px;
  // right: -600px;
 transition: all 2s linear;

@include respond-to(1200){
  bottom: 0;
}
}

.box-reviews .box-reviews-img div.tt-show {
  opacity: 1;
  // right: 100%;
  right: 0px !important;
}

.box-reviews .box-reviews-img.tt-show {
  right: 0;
   
} 

.box-reviews .tt-arrow-center .slick-prev:not(:hover),
.box-reviews .tt-arrow-center .slick-next:not(:hover) {
  border-color: #ffffff;
  color: #ffffff;
}

@media (max-width: 1024px) {
  .box-reviews {
    padding: 69px 0 151px 0;
  }
  .box-reviews.box-reviews-02 {
    padding: 69px 0 69px 0;
  }
  .box-reviews .tt-reviews-content {
    font-size: 17px;
    line-height: 28px;
  }
  .box-reviews .tt-reviews-content .box-show-rating {
    font-size: 12px;
    margin-top: 12px;
    margin-bottom: 35px;
  }
  .box-reviews .tt-reviews-content p {
    margin: 18px 0 0 0;
  }
  .box-reviews .box-reviews-img {
    max-width: 457px;
  }
  .box-reviews .box-reviews-img img {
    max-width: 457px;
  }
  .box-reviews .box-reviews-img div {
    overflow: hidden;
    right: -500px;
  }
  .box-reviews .box-reviews-img div.tt-show {
    // right: -75px;
  }
}

@media (max-width: 768px) {
  .box-reviews .tt-reviews-content .box-show-rating:before,
  .box-reviews .tt-reviews-content .box-show-rating:after {
    width: 181px;
  }
  .box-reviews .tt-reviews-content:not(.no-separator):before {
    margin-right: 20px;
  }
  .box-reviews .tt-reviews-content:not(.no-separator):after {
    margin-left: 20px;
  }
}

@media (max-width: 575px) {
  .box-reviews {
    padding: 57px 0 125px 0;
  }
  .box-reviews .tt-reviews-content {
    font-size: 14px;
    line-height: 20px;
  }
  .box-reviews .tt-reviews-content .box-show-rating {
    font-size: 11px;
    margin-top: 13px;
    margin-bottom: 29px;
  }
  .box-reviews .tt-reviews-content .box-show-rating:not(.no-separator):before {
    margin-right: 15px;
  }
  .box-reviews .tt-reviews-content .box-show-rating:not(.no-separator):after {
    margin-left: 15px;
  }
  .box-reviews .tt-reviews-content .box-show-rating:before,
  .box-reviews .tt-reviews-content .box-show-rating:after {
    width: 60px;
  }
  .box-reviews .tt-reviews-content .tt-title {
    margin-top: 27px;
  }
  .box-reviews .box-reviews-img {
    max-width: 100%;
    right: 0px !important;
    bottom: -40px;
  }
  .box-reviews .box-reviews-img img {
    max-width: 320px;
    // max-width: 100%;
  }
  .box-reviews .box-reviews-img div {
    overflow: hidden;
    right: -400px;
  }
  .box-reviews .box-reviews-img div.tt-show {
    // right: -65px;
  }
}

/*
	slick
*/
.js-carousel {
  margin-top: 11px;
}

.js-carousel-brand {
  margin-top: 11px;
}

/*
	slick arrow
*/
/* tt-arrow-center */
 .slick-prev,
 .slick-next {
    z-index: 99;
}
.tt-arrow-center .slick-prev,
.tt-arrow-center .slick-next {
  position: absolute;
  top: 0;
  background: none;
  border: none;
  outline: none;
  display: inline-block;
  width: 49px;
  height: 49px;
  border: 1px solid #222222;
  font-size: 0;
  line-height: 0;
  cursor: pointer;
  color: #222222;
  transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}

.tt-arrow-center .slick-prev:before,
.tt-arrow-center .slick-next:before {
  font-family: "carleader";
  font-size: 18px;
  line-height: 1.2;
}

.tt-arrow-center .slick-prev:hover,
.tt-arrow-center .slick-next:hover {
  border-color: #dd3d53;
  color: #dd3d53;
}

.tt-arrow-center .slick-prev {
  left: -133px;
}

.tt-arrow-center .slick-prev:before {
  content: '\e916';
}

.tt-arrow-center .slick-next {
  right: -133px;
}

.tt-arrow-center .slick-next:before {
  content: '\e917';
}

@media (max-width: 1600px) {
  .tt-arrow-center .slick-prev {
    left: -70px;
  }
  .tt-arrow-center .slick-next {
    right: -70px;
  }
}

.tt-arrow-center-fluid .slick-prev,
.tt-arrow-center-fluid .slick-next {
  position: absolute;
  top: 0;
  background: none;
  border: none;
  outline: none;
  display: inline-block;
  width: 49px;
  height: 49px;
  border: 1px solid #222222;
  font-size: 0;
  line-height: 0;
  cursor: pointer;
  color: #222222;
  transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}

.tt-arrow-center-fluid .slick-prev:before,
.tt-arrow-center-fluid .slick-next:before {
  font-family: "carleader";
  font-size: 18px;
  line-height: 1.2;
}

.tt-arrow-center-fluid .slick-prev:hover,
.tt-arrow-center-fluid .slick-next:hover {
  border-color: #dd3d53;
  color: #dd3d53;
}

.tt-arrow-center-fluid .slick-prev {
  left: 12.5%;
}

.tt-arrow-center-fluid .slick-prev:before {
  content: '\e916';
}

.tt-arrow-center-fluid .slick-next {
  right: 12.5%;
}

.tt-arrow-center-fluid .slick-next:before {
  content: '\e917';
}

.tt-arrow-center-small .slick-prev,
.tt-arrow-center-small .slick-next {
  position: absolute;
  top: 50%;
  background: none;
  border: none;
  outline: none;
  display: inline-block;
  width: 39px;
  height: 39px;
  border: 1px solid #ffffff;
  font-size: 0;
  line-height: 0;
  cursor: pointer;
  color: #ffffff;
  margin-top: -19.5px;
  transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}

.tt-arrow-center-small .slick-prev:before,
.tt-arrow-center-small .slick-next:before {
  font-family: "carleader";
  font-size: 16px;
  line-height: 1.2;
}

.tt-arrow-center-small .slick-prev:hover,
.tt-arrow-center-small .slick-next:hover {
  border-color: #dd3d53;
  color: #dd3d53;
}

.tt-arrow-center-small .slick-prev {
  left: 20px;
}

.tt-arrow-center-small .slick-prev:before {
  content: '\e916';
}

.tt-arrow-center-small .slick-next {
  right: 20px;
}

.tt-arrow-center-small .slick-next:before {
  content: '\e917';
}

.tt-arrow-color02 .slick-prev,
.tt-arrow-color02 .slick-next {
  border-color: #ffffff;
  color: #ffffff;
}

/* dots */
.slick-slider .slick-dots {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  z-index: 0;
}

.slick-slider .slick-dots li {
  width: 8px;
  height: 8px;
  background-color: #cfcfcf;
  cursor: pointer;
  margin: 0 11px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  -moz-transition: background-color 0.2s linear;
  -o-transition: background-color 0.2s linear;
  -webkit-transition: background-color 0.2s linear;
  transition: background-color 0.2s linear;
}

.slick-slider .slick-dots li button {
  display: none;
}

.slick-slider .slick-dots li:hover {
  background-color: #dd3d53;
}

.slick-slider .slick-dots li.slick-active {
  background-color: #dd3d53;
}

@media (min-width: 1025px) {
  .slick-slider .slick-dots {
    margin-top: 60px;
  }
}

@media (max-width: 1024px) {
  .slick-slider .slick-dots {
    margin-top: 30px;
  }
  .slick-slider .slick-dots li {
    width: 5px;
    height: 5px;
    margin: 0 6px;
  }
}

@media (min-width: 1025px) {
  .slick-slider.dots-error .slick-dots {
    margin-top: 45px;
  }
}

@media (max-width: 1024px) {
  .slick-slider.dots-error .slick-dots {
    margin-top: 15px;
  }
}

/*
	tt-slick-nav01 (*blog)
*/
.tt-slick-nav01 {
  position: relative;
}

.tt-slick-nav01 .slick-prev,
.tt-slick-nav01 .slick-next {
  position: absolute;
  top: 50%;
  background: none;
  border: none;
  outline: none;
  display: inline-block;
  width: 49px;
  height: 49px;
  border: 1px solid #ffffff;
  font-size: 0;
  line-height: 0;
  cursor: pointer;
  color: #ffffff;
  margin-top: -23.5px;
  transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}

.tt-slick-nav01 .slick-prev:before,
.tt-slick-nav01 .slick-next:before {
  font-family: "carleader";
  font-size: 18px;
  line-height: 1.2;
}

.tt-slick-nav01 .slick-prev:hover,
.tt-slick-nav01 .slick-next:hover {
  border-color: #dd3d53;
  color: #dd3d53;
}

.tt-slick-nav01 .slick-prev {
  left: 30px;
}

.tt-slick-nav01 .slick-prev:before {
  content: '\e916';
}

.tt-slick-nav01 .slick-next {
  right: 30px;
}

.tt-slick-nav01 .slick-next:before {
  content: '\e917';
}

.tt-slick-nav01 .slick-dots {
  position: absolute !important;
  bottom: 26px;
}

/*
	tt-box-layout01
*/
.tt-col-img .tt-col-img {
  padding: 3px 0 0 0;
}

/*
tt-box-layout01
 */
.tt-box-layout01 .tt-col-description p {
  padding: 0 0 27px 0;
  margin: 0;
}

@media (max-width: 767px) {
  .tt-box-layout01 .tt-col-description {
    padding-top: 31px;
  }
  .tt-box-layout01 .tt-col-description p {
    padding: 0 0 21px 0;
  }
}

@media (max-width: 575px) {
  .tt-box-layout01 .tt-col-description {
    padding-top: 20px;
  }
  .tt-box-layout01 .tt-col-description p {
    padding: 0 0 10px 0;
  }
}

.tt-block-title:not(.tt-sub-pages) + .tt-box-layout01 {
  margin-top: 11px;
}

/*
	tt-box-layout02
*/
.tt-box-layout02 {
  background: url("/images/custom/tt-box-layout02.jpg") center top no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  padding: 97px 0 97px 0;
  color: #ffffff;
}

.tt-box-layout02 h6 {
  color: #ffffff;
}

.tt-box-layout02 .tt-block-title .tt-title {
  color: #ffffff;
}

.tt-box-layout02 .tt-item {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding-right: 45px;
  margin-top: 5px;
}

.tt-box-layout02 .tt-item .tt-col-icon {
  width: 85px;
  min-width: 85px;
  margin-right: 23px;
}

.tt-box-layout02 .tt-item .tt-col-icon .tt-icon {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 85px;
  height: 85px;
  font-size: 42px;
  line-height: 1.1;
  margin-right: 23px;
  color: #ffffff;
  background: #dd3d53;
  position: relative;
  top: -4px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.tt-box-layout02 .tt-item .tt-col-description {
  -webkit-flex: 2 1 auto;
  -ms-flex: 2 1 auto;
  flex: 2 1 auto;
}

.tt-box-layout02 .tt-item .tt-col-description h6 {
  padding-bottom: 13px;
}

.tt-box-layout02 .tt-item:not(:first-child) {
  margin-top: 74px;
}

@media (max-width: 1024px) {
  .tt-box-layout02 {
    padding: 67px 0;
  }
  .tt-box-layout02 .tt-item .tt-col-icon {
    width: 75px;
    min-width: 75px;
  }
  .tt-box-layout02 .tt-item .tt-col-icon .tt-icon {
    width: 75px;
    height: 75px;
    font-size: 38px;
  }
  .tt-box-layout02 .tt-item {
    margin-top: 34px;
  }
}

@media (max-width: 575px) {
  .tt-box-layout02 {
    padding: 57px 0;
  }
  .tt-box-layout02 .tt-item {
    padding-right: 10px;
  }
  .tt-box-layout02 .tt-item .tt-col-icon {
    width: 65px;
    min-width: 65px;
  }
  .tt-box-layout02 .tt-item .tt-col-icon .tt-icon {
    width: 65px;
    height: 65px;
    font-size: 31px;
  }
  .tt-box-layout02 .tt-item:not(:first-child) {
    margin-top: 24px;
  }
}

.tt-box-layout03 .tt-item {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.tt-box-layout03 .tt-item .tt-col-icon {
  width: 83px;
  min-width: 83px;
  margin-right: 3px;
  margin-left: 15px;
  font-size: 56px;
  line-height: 1.2;
  color: #dd3d53;
}

.tt-box-layout03 .tt-item .tt-col-description {
  -webkit-flex: 2 1 auto;
  -ms-flex: 2 1 auto;
  flex: 2 1 auto;
}

.tt-box-layout03 [class^="col-"] h6 {
  margin-top: 24px;
  padding-bottom: 11px;
}

.tt-box-layout03 [class^="col-"] h6:nth-child(1) {
  margin-top: -4px;
}

.tt-box-layout03 [class^="col-"] > *:nth-child(1) {
  margin-top: 0;
}

.tt-box-layout03 .tt-video-block {
  max-width: 640px;
  float: right;
}

@media (max-width: 768px) {
  .tt-box-layout03 [class^="col-"] h6 {
    margin-top: 25px;
  }
  .tt-box-layout03 .tt-video-block {
    margin-bottom: 31px;
  }
}

@media (max-width: 575px) {
  .tt-box-layout03 [class^="col-"] h6 {
    margin-top: 15px;
  }
  .tt-box-layout03 .tt-video-block {
    margin-bottom: 21px;
  }
}

.tt-block-title + .tt-box-layout03 {
  margin-top: 11px;
}

/*
	tt-media03
*/
@media (max-width: 766px) {
  .js-carousel-col-4 .slick-slide {
    padding-bottom: 7px !important;
  }
}

.tt-media03 {
  margin: 15px;
  display: block;
  color: #222222;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.02);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.02);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.02);
  -moz-transition: box-shadow 0.2s linear;
  -o-transition: box-shadow 0.2s linear;
  -webkit-transition: box-shadow 0.2s linear;
  transition: box-shadow 0.2s linear;
}

.tt-media03:hover {
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.09);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.09);
}

.tt-media03 .tt-layot {
  position: relative;
}

.tt-media03 .tt-layot {
  padding: 21px 10px 26px;
  background-color: #ffffff;
  text-align: center;
}

.tt-media03 .tt-layot .title {
  font-size: 18px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0 0 0px 0;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.tt-media03 .tt-layot p {
  padding: 0;
  margin: 0;
}

.tt-media03 .tt-layot address {
  color: #dd3d53;
  font-weight: 600;
  margin: 14px 0 0 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}

.tt-media03 .tt-layot address i {
  color: #dd3d53;
  font-size: 18px;
  line-height: 1.2;
  position: relative;
  top: 2px;
}

.tt-media03:hover .tt-layot .title {
  color: #dd3d53;
}

@media (max-width: 766px) {
  .tt-media03 {
    max-width: 270px;
    margin: 0 auto;
  }
}

/* tt-list-review */
.tt-list-review .tt-item {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.04);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.04);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.04);
  padding: 26px 20px 31px;
}

.tt-list-review .tt-item .tt-col-img {
  width: 228px;
  min-width: 228px;
  margin-right: 40px;
  margin-top: 4px;
}

.tt-list-review .tt-item .tt-col-img img {
  width: 100%;
  max-width: 100%;
  min-width: 228px;
}

.tt-list-review .tt-item .tt-col-description {
  -webkit-flex: 2 1 auto;
  -ms-flex: 2 1 auto;
  flex: 2 1 auto;
}

.tt-list-review .tt-item .tt-col-description .tt-title {
  color: #222222;
  font-size: 15px;
  line-height: 1.2;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.tt-list-review .tt-item .tt-col-description .tt-title span {
  font-style: italic;
  font-weight: 400;
}

.tt-list-review .tt-item .tt-col-description .tt-rating {
  margin-top: 24px;
}

.tt-list-review .tt-item .tt-col-description p {
  margin: 15px 0 0 0;
}

.tt-list-review .tt-item:not(:first-child) {
  margin-top: 20px;
}

@media (max-width: 768px) {
  .tt-list-review .tt-item {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 16px 20px 16px;
  }
  .tt-list-review .tt-item .tt-col-description {
    margin-top: 15px;
    width: 100%;
  }
  .tt-list-review .tt-item .tt-col-description .tt-rating {
    margin-top: 16px;
  }
  .tt-list-review .tt-item .tt-col-description p {
    margin: 12px 0 0 0;
    padding: 0;
  }
}

.tt-pagination {
  margin-top: 59px;
  text-align: center;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.tt-pagination .btn-pagination {
  outline: none;
  display: inline-block;
  width: 49px;
  height: 49px;
  border: 1px solid #222222;
  font-size: 0;
  line-height: 0;
  cursor: pointer;
  color: #222222;
  margin: 0 10px;
  text-align: center;
  transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}

.tt-pagination .btn-pagination:before {
  font-family: "carleader";
  font-size: 18px;
  line-height: 2.6;
  display: block;
}

.tt-pagination .btn-pagination.btn-prev:before {
  content: '\e916';
}

.tt-pagination .btn-pagination.btn-next:before {
  content: '\e917';
}

.tt-pagination .btn-pagination:hover {
  color: #dd3d53;
  border-color: #dd3d53;
}

.tt-pagination ul {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
  padding: 0;
}

.tt-pagination ul li {
  margin: 0 2px;
}

.tt-pagination ul li a {
  display: inline-block;
  width: 45px;
  height: 42px;
  color: #222222;
  border: 1px solid #f5f5f5;
  font-size: 11px;
  line-height: 3.6;
  font-weight: 600;
  text-align: center;
  -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.07);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}

.tt-pagination ul li a:hover {
  border-color: #dd3d53;
  background-color: #dd3d53;
  color: #ffffff;
}

.tt-pagination ul li.active a {
  border-color: #dd3d53;
  background-color: #dd3d53;
  color: #ffffff;
}

@media (max-width: 1024px) {
  .tt-pagination {
    margin-top: 27px;
  }
}

.tt-rating {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  color: #dd3d53;
  font-size: 16px;
  line-height: 1.2;
  margin-top: 24px;
}

.tt-rating i:not(:last-child) {
  margin-right: 3px;
}

@media (max-width: 1024px) {
  .asideColumn {
    margin-top: 27px;
  }
}

.asideColumn.asideColumn-right {
  padding-left: 35px;
}

.asideColumn.asideColumn-left {
  padding-right: 25px;
}

.asideColumn .tt-block-aside:not(:first-child) {
  margin-top: 51px;
}

.asideColumn .tt-block-aside .tt-aside-title {
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  line-height: 1.2;
  color: #222222;
  padding: 0 0 20px 0;
}

.tt-form-review {
  max-width: 569px;
}

.tt-form-review .form-group {
  margin-bottom: 25px;
}

@media (min-width: 1025px) {
  .tt-form-review .tt-rating {
    margin-top: 90px;
  }
  .tt-form-review .btn {
    margin-top: 37px;
  }
}

@media (max-width: 1024px) {
  .tt-form-review .tt-rating {
    margin-top: 60px;
  }
  .tt-form-review .btn {
    margin-top: 18px;
  }
}

.box-aside-info {
  background-color: #fafafa;
  padding: 33px 20px 20px 20px;
}

.box-aside-info h3 {
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  line-height: 1.2;
  color: #222222;
  padding: 0 0 20px 0;
  margin: 0;
}

.box-aside-info h3:not(:first-child) {
  margin-top: 19px;
}

.box-aside-info ul:not([class]) li {
  padding: 0 0 10px 29px;
  font-size: 15px;
  line-height: 22px;
  position: relative;
  color: #222222;
}

.box-aside-info ul:not([class]) li i {
  position: absolute;
  left: 0;
  top: 2px;
  font-size: 18px;
  line-height: 1.2;
  color: #dd3d53;
}

.box-aside-info ul:not([class]) li strong {
  display: block;
  font-weight: 600;
}

.box-aside-info ul:not([class]) li:not(:last-child) {
  margin-bottom: 19px;
  padding-bottom: 19px;
  border-bottom: 1px solid #e3e3e4;
}

.box-info {
  background-color: #fafafa;
  padding: 42px 49px 55px 49px;
  /*social-icon */
}

.box-info h3 {
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  line-height: 1.2;
  color: #222222;
  padding: 0 0 22px 0;
  margin: 0;
}

.box-info h3:not(:first-child) {
  margin-top: 19px;
}

.box-info .tt-item-layout {
  margin-top: 12px;
}

.box-info .tt-item-layout .tt-item {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding: 0 0 10px 0;
  font-size: 15px;
  line-height: 22px;
  position: relative;
  color: #222222;
}

.box-info .tt-item-layout .tt-item strong {
  display: block;
  font-weight: 600;
}

.box-info .tt-item-layout .tt-item:not(:last-child) {
  margin-bottom: 9px;
  padding-bottom: 9px;
  border-bottom: 1px solid #e3e3e4;
}

.box-info .tt-item-layout .tt-item .tt-col {
  position: relative;
  padding: 0 0 0 39px;
}

.box-info .tt-item-layout .tt-item .tt-col:first-child {
  width: 35%;
  font-weight: 600;
}

.box-info .tt-item-layout .tt-item .tt-col:first-child i {
  position: absolute;
  left: 0;
  top: -2px;
  font-size: 24px;
  line-height: 1.2;
  color: #dd3d53;
}

.box-info .tt-item-layout .tt-item .tt-col:last-child {
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 65%;
  padding: 0px 0 0 38px;
}

.box-info .tt-item-layout .tt-item strong {
  font-weight: 600;
  display: block;
  padding-bottom: 1px;
}

.box-info .tt-item-layout .tt-item p {
  margin: 0;
  padding: 0 0 10px 0;
}

.box-info .tt-item-layout .tt-item p:last-child {
  padding-bottom: 0;
}

@media (max-width: 1024px) {
  .box-info .tt-item-layout .tt-item .tt-col {
    width: 100%;
  }
  .box-info .tt-item-layout .tt-item .tt-col:first-child {
    width: 100%;
  }
  .box-info .tt-item-layout .tt-item .tt-col:last-child {
    width: 100%;
    padding-top: 10px;
  }
}

@media (max-width: 575px) {
  .box-info .tt-item-layout .tt-item .tt-col:last-child {
    padding-top: 5px;
  }
}

.box-info .tt-social-icon {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.box-info .tt-social-icon li a {
  width: 43px;
  height: 43px;
  font-size: 18px;
  line-height: 2.4;
  text-align: center;
  display: inline-block;
  color: #ffffff;
  background-color: #dd3d53;
  transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.box-info .tt-social-icon li a:hover {
  color: #ffffff;
  background-color: #222222;
}

.box-info .tt-social-icon li:not(:first-child):not(:last-child) {
  margin-right: 4px;
  margin-left: 4px;
}

.box-info .tt-social-icon li:not(:last-child):not(:first-child) {
  margin: 0 5px;
}

@media (max-width: 1024px) {
  .box-info .tt-social-icon li a {
    width: 35px;
    height: 35px;
    font-size: 15px;
    line-height: 2.4;
  }
}

@media (max-width: 1024px) {
  .box-info {
    padding: 23px 30px 45px 30px;
  }
  .box-info .tt-item-layout {
    margin-top: 12px;
  }
}

@media (max-width: 575px) {
  .box-info {
    padding: 23px 20px 35px 20px;
  }
}

.box-info-indent {
  margin-right: 70px;
}

@media (max-width: 1229px) {
  .box-info-indent {
    margin-right: 50px;
  }
}

@media (max-width: 1024px) {
  .box-info-indent {
    margin-right: 0px;
  }
}

/*
	tt-promo
*/
.tt-promo-list {
  margin-top: -30px;
}

.tt-promo-list .tt-promo {
  margin-top: 30px;
}

@media (max-width: 767px) {
  .tt-promo-list {
    margin-top: -25px;
  }
  .tt-promo-list .tt-promo {
    margin-top: 25px;
  }
}

.tt-promo {
  display: block;
  position: relative;
}

.tt-promo img {
  width: 100%;
  height: auto;
}

.tt-promo .tt-description {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 7% 15% 3%;
  background-color: rgba(5, 5, 5, 0);
  -moz-transition: background-color 0.2s linear;
  -o-transition: background-color 0.2s linear;
  -webkit-transition: background-color 0.2s linear;
  transition: background-color 0.2s linear;
}

.tt-promo .tt-description .tt-icon {
  width: 98px;
  height: 98px;
  color: #ffffff;
  font-size: 42px;
  line-height: 2.35;
  text-align: center;
  background-color: #dd3d53;
  transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.tt-promo .tt-description .tt-title {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  margin-top: 17px;
  font-size: 18px;
  line-height: 1.2;
  text-align: center;
}

.tt-promo .tt-description .tt-text {
  color: #ffffff;
  height: 0;
  overflow: hidden;
  opacity: 0;
  width: 100%;
  margin-top: 26px;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}

@media (max-width: 1024px) {
  .tt-promo .tt-description {
    padding: 7% 10% 3%;
  }
  .tt-promo .tt-description .tt-icon {
    width: 80px;
    height: 80px;
    font-size: 35px;
    line-height: 2.4;
  }
  .tt-promo .tt-description .tt-title {
    font-size: 16px;
  }
  .tt-promo .tt-description .tt-text {
    margin-top: 13px;
    font-size: 14px;
    line-height: 23px;
  }
}

@media (max-width: 768px) {
  .tt-promo .tt-description {
    padding: 7% 5% 3%;
  }
  .tt-promo .tt-description .tt-icon {
    width: 70px;
    height: 70px;
    font-size: 30px;
    line-height: 2.4;
  }
  .tt-promo .tt-description .tt-title {
    font-size: 15px;
  }
  .tt-promo .tt-description .tt-text {
    display: none;
  }
}

body:not(.touch) .tt-promo:hover .tt-icon {
  background-color: #ffffff;
  color: #dd3d53;
}

body:not(.touch) .tt-promo:hover .tt-description {
  background-color: rgba(5, 5, 5, 0.75);
}

body:not(.touch) .tt-promo:hover .tt-description .tt-text {
  height: 75px;
  opacity: 1;
}

@media (max-width: 1024px) {
  body:not(.touch) .tt-promo:hover .tt-description .tt-text {
    height: 87px;
  }
}

/* tt-sub-menu */
.tt-sub-menu {
  list-style: none;
  margin: 10px 0 0 0;
  padding: 0;
}

.tt-sub-menu li {
  margin: 0 0 9px 0;
  display: block;
}

.tt-sub-menu li a {
  display: block;
  color: #222222;
  font-family: "Montserrat", sans-serif;
  background-color: transparent;
  font-size: 11px;
  line-height: 1.2;
  font-weight: 600;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  padding: 8px 10px 19px 16px;
  transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
}

.tt-sub-menu li a i {
  font-size: 22px;
  line-height: 1.1;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
}

.tt-sub-menu li a:hover {
  color: #ffffff;
  background-color: #dd3d53;
}

.tt-sub-menu li.active a {
  color: #ffffff;
  background-color: #dd3d53;
}

@media (max-width: 1024px) {
  .tt-sub-menu li a {
    display: inline-block;
  }
}

/*
	tt-faq
*/
.tt-faq-layout h3.tt-title {
  color: #222222;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  line-height: 1.2;
  font-weight: 600;
  margin: -3px 0 0 0;
  padding: 0 0 26px 0;
}

.tt-faq-layout h3.tt-title + .tt-faq {
  border-top: 1px solid #e8e8e8;
}

.tt-faq-layout [class^="col-"]:first-child {
  padding-right: 25px;
}

.tt-faq-layout [class^="col-"]:last-child {
  padding-left: 25px;
}

.tt-faq-layout [class^="col-"] .tt-title:not(:first-child) {
  margin-top: 57px;
}

@media (max-width: 768px) {
  .tt-faq-layout [class^="col-"] .tt-title {
    margin-top: 40px;
  }
}

.tt-faq-layout .tt-faq {
  border-bottom: 1px solid #e8e8e8;
}

.tt-faq-layout .tt-faq .tt-title {
  display: block;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  color: #222222;
  font-weight: 500;
  padding: 19px 30px 16px 0;
  margin: 0;
  position: relative;
  font-size: 12px;
  line-height: 1.4;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.tt-faq-layout .tt-faq .tt-title:before {
  content: '\e917';
  font-family: "carleader";
  position: absolute;
  display: inline-block;
  font-size: 13px;
  line-height: 1.2;
  right: 0;
  top: 18px;
  color: #dd3d53;
}

.tt-faq-layout .tt-faq .tt-title:hover {
  color: #dd3d53;
}

.tt-faq-layout .tt-faq .tt-content {
  display: none;
  border-bottom: 1px solid #dfdfdf;
  background-color: #fafafa;
  padding: 20px;
  color: #707070;
}

.tt-faq-layout .tt-faq .tt-content p:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
}

.tt-faq-layout .tt-faq.active .tt-title {
  color: #dd3d53;
  font-weight: 600;
}

.tt-faq-layout .tt-faq.active .tt-content {
  display: block;
}

/*
	inventory layout (*inventory-item.html)
*/
@media (min-width: 1025px) {
  .tt-aside-btn .btn {
    height: 52px;
  }
  .tt-aside-btn .btn [class^="icon-"] {
    font-size: 22px;
  }
}

@media (max-width: 1024px) {
  .tt-aside-btn .btn {
    padding-left: 9px;
    padding-right: 9px;
    height: 42px;
  }
}

.tt-aside03-layout {
  margin-top: 45px;
}

.tt-aside03-layout .tt-aside-btn {
  margin-top: 0px !important;
  margin-left: -10px;
}

@media (min-width: 767px) {
  .tt-aside03-layout .tt-aside-btn {
    text-align: right;
    padding-bottom: 1px;
  }
  .tt-aside03-layout .tt-aside-btn .btn {
    margin: 10px 0 0 10px;
  }
}

@media (max-width: 766px) {
  .tt-aside03-layout .tt-aside-btn .btn {
    margin: 10px 10px 0 0;
  }
}

.tt-aside03-layout > *:first-child {
  margin-top: 0px;
}

.tt-aside03-layout > *:not(:first-child) {
  margin-top: 40px;
}

.tt-aside03-layout .tt-aside-calculator {
  margin-top: 49px;
}

@media (max-width: 766px) {
  .tt-aside03-layout .tt-aside-calculator {
    margin-top: 42px;
  }
}

.tt-aside03-layout .tt-aside-btn + .tt-aside-promo {
  margin-top: 34px;
}

@media (max-width: 1024px) {
  .tt-aside03-layout .tt-aside-btn + .tt-aside-promo {
    margin-top: 29px;
  }
}

@media (max-width: 766px) {
  .tt-aside03-layout {
    margin-top: 40px;
  }
}

.tt-aside-promo {
  font-size: 12px;
  line-height: 1.3;
  text-align: center;
  font-family: "Montserrat", sans-serif;
}

.tt-aside-promo .tt-wrapper {
  background-color: #434547;
  color: #ffffff;
  padding: 14px 10px 20px;
}

.tt-aside-promo .tt-wrapper .tt-value {
  font-weight: 600;
  font-size: 36px;
}

.tt-aside-promo .tt-wrapper p {
  font-size: 12px;
  text-transform: uppercase;
  margin: 3px 0 0 0;
  padding: 0;
}

.tt-aside-promo .tt-wrapper .btn {
  margin-top: 18px;
}

.tt-aside-promo .tt-info {
  text-align: center;
  color: #222222;
  font-size: 11px;
  padding: 12px 0 2px 0;
  text-transform: uppercase;
}

.tt-aside-promo .tt-info span {
  font-weight: 600;
}

@media (min-width: 1025px) {
  .tt-aside03-box + .tt-aside-btn {
    margin-top: 39px !important;
  }
}

@media (max-width: 1024px) {
  .tt-aside03-box + .tt-aside-btn {
    margin-top: 39px !important;
  }
}

@media (max-width: 480px) {
  .tt-aside03-box + .tt-aside-btn {
    margin-top: 30px !important;
  }
}

.tt-table-options {
  width: 100%;
  border-spacing: 1px;
  border-collapse: separate;
}

.tt-table-options tr td {
  color: #222222;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  line-height: 1.3;
  text-transform: uppercase;
  padding: 9px 12px;
}

@media (max-width: 1024px) {
  .tt-table-options tr td {
    font-size: 11px;
  }
}

@media (max-width: 480px) {
  .tt-table-options tr td {
    padding: 5px 7px;
  }
}

.tt-table-options tr td:first-child {
  background-color: #f7f7f7;
}

.tt-table-options tr td:last-child {
  background-color: #fcfcfc;
  font-weight: 600;
}

.tt-table-listing .tt-table-options tr td:first-child {
  width: 40%;
}

.tt-table-listing .tt-table-options tr td:last-child {
  width: 60%;
}

@media (max-width: 1024px) {
  .tt-table-listing .row > div:last-child .tt-table-options {
    margin-top: -1px;
  }
}

.tt-aside03-title + .tt-aside03-content {
  margin-top: -7px;
}

.tt-aside-calculator {
  background: #ffffff;
  -webkit-box-shadow: 0 0 49px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 0 49px rgba(0, 0, 0, 0.12);
  box-shadow: 0 0 49px rgba(0, 0, 0, 0.12);
}

.tt-aside-calculator .tt-calculator-title {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  padding: 9px 0 0 0;
}

.tt-aside-calculator .tt-calculator-title span {
  display: block;
  width: 82px;
  height: 75px;
  background-color: #dd3d53;
  margin: 0 17px 0 0;
  -webkit-border-top-right-radius: 35px;
  -webkit-border-bottom-right-radius: 35px;
  -moz-border-radius-topright: 35px;
  -moz-border-radius-bottomright: 35px;
  border-top-right-radius: 35px;
  border-bottom-right-radius: 35px;
  font-size: 40px;
  line-height: 1.9;
  color: #ffffff;
}

.tt-aside-calculator .tt-calculator-title span:before {
  position: relative;
  left: 15px;
}

.tt-aside-calculator .tt-calculator-content {
  padding: 27px 30px 18px;
}

.tt-aside-calculator .tt-calculator-content .form-group {
  margin-bottom: 13px;
}

.tt-aside-calculator .tt-calculator-content .btn {
  margin-top: 6px;
  height: 42px;
}

.tt-aside-calculator .tt-wrapper-top {
  background-color: #ffffff;
}

.tt-aside-calculator .tt-wrapper-bottom {
  background-color: #f6f6f6;
  border-top: 1px solid #ececec;
  padding: 19px 15px 19px;
  position: relative;
}

.tt-aside-calculator .tt-wrapper-bottom:before {
  content: '';
  display: block;
  height: 5px;
  margin-top: -5px;
  pointer-events: none;
  position: absolute;
  left: 50%;
  top: -7px;
  margin-left: -4px;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  border: 6px solid transparent;
  border-bottom: 6px solid #f6f6f6;
  z-index: 1;
}

.tt-aside-calculator .tt-wrapper-bottom:after {
  content: '';
  display: block;
  height: 5px;
  margin-top: -6px;
  pointer-events: none;
  position: absolute;
  left: 50%;
  top: -6px;
  margin-left: -4px;
  z-index: 0;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  border: 6px solid transparent;
  border-bottom: 6px solid rgba(0, 0, 0, 0.2);
}

.tt-aside-calculator .tt-data-output {
  text-align: center;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.tt-aside-calculator .tt-data-output li {
  display: block;
  text-align: center;
  font-size: 11px;
  line-height: 19px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  color: #222222;
}

.tt-aside-calculator .tt-data-output li span {
  font-size: 15px;
  display: block;
  margin-top: -1px;
  text-align: center;
}

.tt-aside-calculator .tt-data-output li:not(:first-child) {
  margin-top: 8px;
}

/*
shop-item.html
*/
.tt-product-vertical-layout {
  padding-right: 70px;
  margin-top: 17px;
}

.tt-product-vertical-layout .tt-product-single-img {
  float: right;
  width: 73.3%;
  position: relative;
}

.tt-product-vertical-layout .tt-product-single-img img {
  width: 100%;
  height: auto;
}

.tt-product-vertical-layout .tt-product-single-carousel-vertical {
  margin: 0 0 44px 0;
  top: 0px;
  left: 0;
  width: 13%;
  max-width: 110px;
}

.tt-product-vertical-layout .tt-product-single-carousel-vertical .slick-slider {
  width: 100%;
  padding: 0;
  margin: 0;
}

.tt-product-vertical-layout .tt-product-single-carousel-vertical .slick-slider .slick-slide {
  padding: 0 0 12px 0;
}

.tt-product-vertical-layout .tt-product-single-carousel-vertical .slick-slider .slick-slide img {
  -moz-opacity: 1;
  -khtml-opacity: 1;
  -webkit-opacity: 1;
  opacity: 1;
}

.tt-product-vertical-layout .tt-product-single-carousel-vertical .slick-slider .slick-slide a {
  display: block;
  position: relative;
}

.tt-product-vertical-layout .tt-product-single-carousel-vertical .slick-slider .slick-slide a:before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #ffffff;
  -moz-opacity: 0.6;
  -khtml-opacity: 0.6;
  -webkit-opacity: 0.6;
  opacity: 0.6;
  -moz-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  -webkit-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

.tt-product-vertical-layout .tt-product-single-carousel-vertical .slick-slider .slick-slide:hover a:before,
.tt-product-vertical-layout .tt-product-single-carousel-vertical .slick-slider .slick-slide .zoomGalleryActive:before {
  -moz-opacity: 0;
  -khtml-opacity: 0;
  -webkit-opacity: 0;
  opacity: 0;
}

.tt-product-vertical-layout .tt-product-single-carousel-vertical .slick-arrow {
  top: auto;
  bottom: -33px;
  position: absolute;
  margin-top: 0;
  z-index: 2;
}

.tt-product-vertical-layout .tt-product-single-carousel-vertical .slick-slider:before {
  content: '';
  display: block;
  width: 100%;
  height: 14px;
  position: absolute;
  bottom: 0;
  z-index: 1;
  background: -webkit-linear-gradient(bottom, #ffffff, #ffffff 20%);
  background: -o-linear-gradient(bottom, #ffffff, #ffffff 20%);
  background: -moz-linear-gradient(bottom, #ffffff, #ffffff 20%);
  background: linear-gradient(to bottom, #ffffff, #ffffff 20%);
}

.tt-product-vertical-layout .tt-product-single-carousel-vertical .slick-prev,
.tt-product-vertical-layout .tt-product-single-carousel-vertical .slick-next {
  position: absolute;
  bottom: -54px;
  background: none;
  border: none;
  outline: none;
  display: inline-block;
  width: 39px;
  height: 39px;
  border: 1px solid #222222;
  font-size: 0;
  line-height: 0;
  cursor: pointer;
  color: #222222;
  margin-top: -19.5px;
  transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}

.tt-product-vertical-layout .tt-product-single-carousel-vertical .slick-prev:before,
.tt-product-vertical-layout .tt-product-single-carousel-vertical .slick-next:before {
  font-family: "carleader";
  font-size: 16px;
  line-height: 1.2;
}

.tt-product-vertical-layout .tt-product-single-carousel-vertical .slick-prev:hover,
.tt-product-vertical-layout .tt-product-single-carousel-vertical .slick-next:hover {
  border-color: #dd3d53;
  color: #dd3d53;
}

.tt-product-vertical-layout .tt-product-single-carousel-vertical .slick-prev {
  left: 0px;
}

.tt-product-vertical-layout .tt-product-single-carousel-vertical .slick-prev:before {
  content: '\e916';
}

.tt-product-vertical-layout .tt-product-single-carousel-vertical .slick-next {
  right: 0px;
}

.tt-product-vertical-layout .tt-product-single-carousel-vertical .slick-next:before {
  content: '\e917';
}

.product-single-info .tt-wrapper:first-child {
  margin-top: 30px;
}

.product-single-info .tt-wrapper:not(:first-child) {
  margin-top: 23px;
}

.product-single-info .tt-wrapper .tt-price {
  font-size: 36px;
  line-height: 1.4;
  font-weight: 600;
  color: #222222;
  display: inline-block;
  font-family: "Montserrat", sans-serif;
}

.product-single-info .tt-wrapper .tt-price + .tt-info {
  margin-left: 10px;
}

.product-single-info .tt-wrapper .tt-info {
  display: inline-block;
  font-size: 15px;
  line-height: 1.4;
  color: #222222;
}

.product-single-info .tt-wrapper .tt-label {
  font-size: 16px;
  line-height: 1.6;
  font-weight: 700;
  margin-top: 11px;
}

.product-single-info .tt-wrapper .tt-label .tt-label-instock {
  color: #dd3d53;
}

.product-single-info .tt-wrapper .tt-info-list {
  border-top: 1px solid #dfdfdf;
  border-bottom: 1px solid #dfdfdf;
  padding: 24px 0 35px;
}

.product-single-info .tt-wrapper .tt-info-list li {
  position: relative;
  padding: 0 0 0 24px;
  color: #222222;
  font-size: 15px;
  line-height: 1.5;
}

.product-single-info .tt-wrapper .tt-info-list li:before {
  content: '\e934';
  font-family: "carleader";
  color: #dd3d53;
  font-size: 8px;
  line-height: 1;
  left: 0;
  top: 8px;
  display: inline-block;
  position: absolute;
}

.product-single-info .tt-wrapper .tt-info-list li:not(:first-child) {
  margin-top: 22px;
}

.product-single-info .tt-wrapper .tt-row-counter {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin: 44px 0 0 0;
}

.product-single-info .tt-wrapper .tt-row-counter label {
  display: block;
  padding-bottom: 10px;
}

.product-single-info .tt-wrapper .tt-row-counter input {
  text-align: center;
  width: 53px;
  height: 50px;
  padding: 3px 12px 4px 14px;
}

.product-single-info .tt-wrapper .tt-row-counter .tt-col:first-child {
  width: 53px;
}

.product-single-info .tt-wrapper .tt-row-counter .tt-col:last-child {
  margin-left: 19px;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
}

@media (max-width: 575px) {
  .product-single-info .tt-wrapper .tt-row-counter .tt-col {
    width: 100%;
  }
  .product-single-info .tt-wrapper .tt-row-counter .tt-col:last-child {
    margin-top: 10px;
    margin-left: 0;
  }
}

body.modal-open {
  height: 100%;
  overflow: hidden;
  width: 100%;
  // position: fixed;
}

#compareSlide {
  position: fixed;
  z-index: 10000;
  bottom: -150%;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  display: block;
  -webkit-transition: bottom 200ms linear 0s;
  -moz-transition: bottom 200ms linear 0s;
  -ms-transition: bottom 200ms linear 0s;
  -o-transition: bottom 200ms linear 0s;
  transition: bottom 200ms linear 0s;
}

#compareSlide.open {
  bottom: 0;
  -webkit-transition: bottom 200ms linear 0s;
  -moz-transition: bottom 200ms linear 0s;
  -ms-transition: bottom 200ms linear 0s;
  -o-transition: bottom 200ms linear 0s;
  transition: bottom 200ms linear 0s;
}

.layout-slide .slide-title {
  width: 100%;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: #191919;
  position: fixed;
  z-index: 1;
}

.layout-slide .slide-title .title {
  color: #ffffff;
  font-size: 18px;
  line-height: 28px;
  padding: 22px 0 0 37px;
}

.layout-slide .slide-title .btn-close-slide {
  color: #ffffff;
  font-size: 12px;
  line-height: 22px;
  padding: 24px 31px 25px;
  display: inline-block;
  cursor: pointer;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.layout-slide .slide-title .btn-close-slide:hover {
  color: #dd3d53;
}

.layout-slide .slide-content {
  height: 100vh;
}

.layout-slide .slide-content .tt-wrapper-col {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  vertical-align: top;
  position: relative;
  z-index: 0;
  padding-top: 72px;
  padding-bottom: 40px;
}

.layout-slide .slide-content .col-value {
  width: 211px;
  min-width: 211px;
}

.layout-slide .slide-content .col-value ul {
  margin-top: 265px;
}

.layout-slide .slide-content .col-value ul li {
  background-color: #f7f7f7;
  color: #222222;
  font-family: "Montserrat", sans-serif;
  height: 33px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 6px 7px 3px 12px;
  font-size: 12px;
  line-height: 22px;
  font-weight: 500;
}

.layout-slide .slide-content .col-value ul li:not(:first-child) {
  margin-top: 3px;
}

.layout-slide .slide-content .col-item {
  width: 241px;
  min-width: 241px;
  margin-left: 18px;
}

.layout-slide .slide-content .col-item .item-close {
  display: block;
  text-align: center;
  height: 49px;
  font-size: 14px;
  line-height: 49px;
  color: #222222;
  overflow: hidden;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.layout-slide .slide-content .col-item .item-close:hover {
  color: #dd3d53;
}

.layout-slide .slide-content .col-item .img-obj {
  text-align: center;
  width: auto;
  height: 194px;
  vertical-align: top;
  overflow: hidden;
}

.layout-slide .slide-content .col-item ul {
  margin-top: 22px;
}

.layout-slide .slide-content .col-item ul li {
  background-color: #fcfcfc;
  color: #222222;
  font-family: "Montserrat", sans-serif;
  height: 33px;
  width: 100%;
  padding: 6px 7px 3px 12px;
  font-size: 12px;
  line-height: 22px;
  font-weight: 500;
}

.layout-slide .slide-content .col-item ul li:not(:first-child) {
  margin-top: 3px;
}

.layout-slide .slide-content .col-item ul li:not(:last-child) {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.layout-slide .slide-content .col-item ul li:last-child {
  height: 221px;
  overflow: auto;
  overflow-x: hidden;
}

/*
	tt-box-wrapper
*/
@media (min-width: 576px) {
  .js-carousel-col-3 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.tt-box-wrapper {
  padding: 130px 0 100px 0;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
}

@media (max-width: 1024px) {
  .tt-box-wrapper {
    padding: 134px 0 40px 0;
  }
}

.tt-box-wrapper02 {
  padding: 117px 0 117px 0;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
}

@media (max-width: 1024px) {
  .tt-box-wrapper02 {
    padding: 79px 0 79px 0;
  }
}

@media (max-width: 575px) {
  .tt-box-wrapper02 {
    padding: 57px 0 57px 0;
  }
}

.tt-media-box01 {
  margin: 15px;
  background-color: #ffffff;
  display: block;
  color: #222222;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -moz-transition: box-shadow 0.2s linear;
  -o-transition: box-shadow 0.2s linear;
  -webkit-transition: box-shadow 0.2s linear;
  transition: box-shadow 0.2s linear;
}

.tt-media-box01:hover {
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
}

.tt-media-box01 .tt-img {
  position: relative;
}

.tt-media-box01 .tt-img img {
  max-width: 100%;
  height: auto;
}

.tt-media-box01 .tt-img i {
  position: absolute;
  width: 53px;
  height: 53px;
  left: 50%;
  bottom: -26px;
  margin-left: -26px;
  background-color: #dd3d53;
  color: #ffffff;
  text-align: center;
  font-size: 28px;
  line-height: 1.9;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.tt-media-box01 .tt-description {
  padding: 40px 30px 37px 30px;
  text-align: center;
}

.tt-media-box01 .tt-description .tt-title {
  font-size: 24px;
  line-height: 1.3;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0 0 12px 0;
}

.tt-media-box01 .tt-description .tt-title span {
  display: block;
  text-align: center;
  font-size: 16px;
  color: #dd3d53;
  margin-top: 7px;
}

.tt-media-box01 .tt-description p {
  padding: 0;
  margin: 0;
  font-size: 16px;
  line-height: 26px;
}

.tt-media-box01 .tt-description .tt-link {
  color: #dd3d53;
  font-size: 11px;
  line-height: 26px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  margin-top: 18px;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.tt-media-box01 .tt-description .tt-link:after {
  position: absolute;
  right: -15px;
  top: 9px;
  font-family: 'carleader' !important;
  content: "\e92b";
  font-size: 8px;
  line-height: 1.1;
  color: #dd3d53;
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.tt-media-box01 .tt-description .tt-link:hover {
  color: #222222;
}

.tt-media-box01 .tt-description .tt-link:hover:after {
  color: #222222;
}

.tt-media-box01:hover {
  color: #222222;
}

@media (max-width: 1024px) {
  .tt-media-box01 .tt-description .title {
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 10px;
  }
  .tt-media-box01 .tt-description p {
    font-size: 15px;
    line-height: 24px;
  }
  .tt-media-box01 .tt-description .tt-link {
    margin-top: 27px;
  }
}

@media (max-width: 766px) {
  .tt-media-box01 {
    max-width: 371px;
    margin: 0 auto;
    display: block;
  }
}

/*
	color
*/
.tt-defaul-color {
  color: #dd3d53;
}

.tt-defaul-color02 {
  color: #222222;
}

.nice-select.tt-skin-01 span.current {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
}

/*
	filters-options
*/
.tt-filters-options {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 14px;
}

.tt-filters-options .tt-btn-toggle {
  position: fixed;
  z-index: 7;
  left: 0;
}

@media (min-width: 1025px) {
  .tt-filters-options .tt-btn-toggle {
    display: none;
    visibility: hidden;
  }
}

.tt-filters-options .tt-btn-toggle a {
  width: 58px;
  height: 54px;
  display: block;
  background-color: #dd3d53;
  color: #ffffff;
  -webkit-border-top-right-radius: 25px;
  -webkit-border-bottom-right-radius: 25px;
  -moz-border-radius-topright: 25px;
  -moz-border-radius-bottomright: 25px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}

.tt-filters-options .tt-btn-toggle a:before {
  content: '\e931';
  font-family: "carleader";
  font-size: 26px;
  line-height: 2;
  position: relative;
  left: 9px;
  top: 1px;
  color: #ffffff;
}

@media (max-width: 1024px) and (min-width: 768px) {
  .tt-filters-options .tt-btn-toggle {
    top: 212px;
  }
}

@media (max-width: 767px) {
  .tt-filters-options .tt-btn-toggle {
    top: 138px;
  }
}

.tt-filters-options .nice-select.tt-skin-01 {
  padding: 7px 33px 3px 14px;
  height: 38px;
  min-width: 177px;
}

.tt-filters-options .tt-sort {
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.tt-filters-options .tt-sort .tt-sort-total {
  display: inline-block;
  font-size: 12px;
  line-height: 1.2;
  vertical-align: top;
  padding-top: 14px;
  color: #222222;
}

.tt-filters-options .tt-sort .tt-sort-total:not(:first-child) {
  margin-left: 25px;
}

.tt-filters-options .tt-quantity {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.tt-filters-options .tt-quantity a {
  width: 40px;
  height: 38px;
  display: inline-block;
  text-align: center;
  color: #222222;
  border: 1px solid #dfdfdf;
  font-size: 16px;
  line-height: 2.3;
  position: relative;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0px 0px 1px #dfdfdf;
  -moz-box-shadow: 0px 0px 1px #dfdfdf;
  box-shadow: 0px 0px 1px #dfdfdf;
  transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  margin-left: 10px;
}

.tt-filters-options .tt-quantity a:hover {
  color: #dd3d53;
}

.tt-filters-options .tt-quantity a.active {
  color: #dd3d53;
  border-color: #dd3d53;
  -webkit-box-shadow: 0px 0px 1px rgba(221, 61, 83, 0.8);
  -moz-box-shadow: 0px 0px 1px rgba(221, 61, 83, 0.8);
  box-shadow: 0px 0px 1px rgba(221, 61, 83, 0.8);
}

@media (max-width: 425px) {
  .tt-filters-options .tt-quantity {
    display: none;
  }
}

@media (max-width: 575px) {
  .tt-filters-options .tt-quantity {
    display: none;
  }
}

.tt-filter-value {
  list-style: none;
  margin: 7px 0 0 -7px;
  padding: 0;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.tt-filter-value li {
  margin: 7px 0 0 7px;
}

.tt-filter-value li a {
  display: inline-block;
  background-color: #f2f2f2;
  color: #222222;
  position: relative;
  font-size: 11px;
  line-height: 1.2;
  letter-spacing: 0.05em;
  padding: 6px 11px 7px 11px;
  font-weight: 500;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}

.tt-filter-value li a:after {
  font-family: "carleader";
  content: '\e933';
  display: inline-block;
  font-size: 6px;
  line-height: 1;
  top: -1px;
  position: relative;
  margin-left: 8px;
}

.tt-filter-value li a:hover {
  background-color: #222222;
  color: #ffffff;
}

.tt-filters-aside [class^="tt-col"] {
  width: 100%;
}

.tt-filters-aside [class^="tt-col"]:not(:first-child) {
  margin-top: 20px;
}

.tt-filters-aside .tt-col-btn .btn [class^="icon-"] {
  font-size: 13px;
}

.tt-filters-aside .tt-item {
  position: relative;
}

.tt-filters-aside .tt-item .nice-select.tt-skin-01 {
  width: 100%;
  text-transform: uppercase;
  margin-top: 10px;
}

.tt-filters-aside .tt-col-btn .btn {
  width: 100%;
  height: 42px;
}

.tt-filters-aside .tt-col-btn .btn:not(:first-child) {
  margin-top: 7px;
}

.tt-filters-aside .tt-aside-title {
  padding-left: 0 !important;
}

.tt-filters-aside .tt-slider-price {
  margin-top: 5px;
}

.tt-filters-fullwidth {
  min-height: 108px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding-bottom: 16px;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 18px;
}

@media (max-width: 1024px) {
  .tt-filters-fullwidth {
    display: none;
  }
}

.tt-filters-fullwidth .tt-col-select {
  margin-top: -10px;
  margin-left: -20px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.tt-filters-fullwidth .tt-col-select .tt-item {
  width: calc(20% - 20px);
  margin-left: 20px;
}

.tt-filters-fullwidth .tt-col-select .tt-item .nice-select.tt-skin-01 {
  width: 100%;
  margin-top: 10px;
}

@media (max-width: 1229px) {
  .tt-filters-fullwidth .tt-col-select .tt-item {
    width: calc(25% - 20px);
  }
  .tt-filters-fullwidth .tt-col-select .tt-item:last-child {
    width: calc(100% - 20px);
    margin-left: 20px;
  }
  .tt-filters-fullwidth .tt-col-select .tt-item:last-child .nice-select.tt-skin-01 {
    width: calc(50% - 10px);
    margin-top: 10px;
  }
  .tt-filters-fullwidth .tt-col-select .tt-item:last-child *:last-child {
    margin-left: 16px;
  }
}

.tt-filters-fullwidth .tt-col-select .nice-select.tt-skin-01 {
  height: 40px;
  padding: 7px 33px 3px 14px;
}

.tt-filters-fullwidth > *:not(:first-child) {
  margin-left: 19px;
}

.tt-filters-fullwidth .tt-col-price {
  min-width: 173px;
}

.tt-filters-fullwidth .tt-col-price .tt-aside-title {
  color: #222222;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  margin: -6px 0 0 0;
  padding-bottom: 12px;
}

.tt-filters-fullwidth .tt-col-price .slider-value-row {
  margin-top: 17px;
}

.tt-filters-fullwidth .tt-col-price .slider-value-row .slider-value {
  height: 40px;
  font-size: 12px;
  line-height: 32px;
  font-weight: 500;
}

.tt-filters-fullwidth .tt-col-btn {
  min-width: 169px;
}

.tt-filters-fullwidth .tt-col-btn .btn {
  width: 100%;
  height: 41px;
}

.tt-filters-fullwidth .tt-col-btn .btn:not(:first-child) {
  margin-top: 7px;
}

.tt-filters-fullwidth .tt-col-btn .btn [class^="icon-"] {
  font-size: 13px;
}

.tt-filters-layout + .tt-filters-options {
  border: none !important;
}

.tt-filters-fullwidth + .tt-filters-options {
  padding-bottom: 18px;
}

/*
	product-listing
*/
.tt-product-listing {
  margin: 11px -15px 0 -15px;
}

.tt-product-listing .tt-col-item {
  margin-top: 30px;
}

@media (min-width: 576px) {
  .tt-product-listing.tt-row-view .tt-col-item {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .tt-product-listing.tt-row-view .tt-description {
    text-align: left;
    padding-right: 15px;
  }
  .tt-product-listing.tt-row-view .tt-description .tt-title {
    text-align: left;
    padding-left: 0;
  }
}

@media (max-width: 425px) {
  .tt-product-listing .tt-col-item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 25px;
    padding-right: 25px;
  }
}

/*
	product obj (shop.html)
*/
.tt-product {
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.04);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.04);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.04);
}

.tt-product .tt-image-box {
  text-align: center;
}

.tt-product .tt-image-box img {
  max-width: 100%;
  height: auto;
}

.tt-product .tt-description {
  padding: 10px 10px 18px 10px;
  text-align: center;
}

.tt-product .tt-description .tt-title {
  color: #222222;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  line-height: 25px;
  text-align: center;
  font-weight: 600;
  letter-spacing: 0.01em;
  margin: 0;
  padding: 0 10px;
}

.tt-product .tt-description .tt-title a {
  color: #222222;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.tt-product .tt-description .tt-title a:hover {
  color: #dd3d53;
}

.tt-product .tt-description .tt-rating {
  text-align: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  margin-top: 12px;
  padding-bottom: 3px;
}

.tt-product .tt-description .tt-rating i {
  font-size: 11px;
}

.tt-product .tt-description .tt-rating i:not(:last-child) {
  margin-right: 2px;
}

.tt-product .tt-description .tt-price {
  margin-top: 16px;
  font-size: 24px;
  line-height: 1.2;
  color: #222222;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

.tt-product .tt-description .tt-btn-addtocart {
  margin-top: 25px;
  background: #dd3d53;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  border: none;
  font-size: 11px;
  line-height: 1.1;
  font-weight: 600;
  position: relative;
  outline: none;
  padding: 4px 22px 4px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 42px;
  cursor: pointer;
  -webkit-box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}

.tt-product .tt-description .tt-btn-addtocart:hover {
  background: #222222;
  color: #ffffff;
  outline: none;
}

.tt-product .tt-description .tt-btn-addtocart:active, .tt-product .tt-description .tt-btn-addtocart:visited, .tt-product .tt-description .tt-btn-addtocart:focus, .tt-product .tt-description .tt-btn-addtocartbutton:focus {
  outline: none;
  box-shadow: none;
}

.tt-product .tt-description .tt-btn-addtocart i {
  color: #ffffff;
  font-size: 16px;
  line-height: 1.2;
  margin-right: 8px;
}

.tt-product.tt-view {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.tt-product.tt-view .tt-description .tt-rating {
  text-align: left;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  -webkit-box-pack: flex-start;
}

/*
	product 02 obj (inventory.html)
*/
.tt-product-02 {
  text-align: center;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.04);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.04);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.04);
  padding: 0 0 23px 0;
}

.tt-product-02 .tt-image-box {
  position: relative;
  display: block;
  overflow: hidden;
}

.tt-product-02 .tt-image-box .tt-img {
  background-color: #222222;
  display: block;
  overflow: hidden;
  position: relative;
}

.tt-product-02 .tt-image-box .tt-img img {
  max-width: inherit;
  width: 100%;
  height: auto;
}

.tt-product-02 .tt-image-box .tt-img:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #222222;
  -moz-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  -webkit-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
  -moz-opacity: 0;
  -khtml-opacity: 0;
  -webkit-opacity: 0;
  opacity: 0;
}

.tt-product-02 .tt-image-box .tt-img .tt-label-location {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  position: absolute;
  top: 0px;
  left: 0px;
  font-size: 11px;
  line-height: 1.2;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}

.tt-product-02 .tt-image-box .tt-img .tt-label-location [class^="tt-label-"] {
  padding: 5px 6px 4px 6px;
  margin: 9px 0 0 9px;
}

.tt-product-02 .tt-image-box .tt-img .tt-label-location .tt-label-promo {
  background-color: #2ac528;
  color: #ffffff;
}

.tt-product-02 .tt-image-box .tt-img .tt-label-location .tt-label-new {
  background-color: #222222;
  color: #dd3d53;
}

.tt-product-02 .tt-image-box .tt-img .tt-label-location .tt-label-info {
  background-color: #ffffff;
  color: #222222;
}

.tt-product-02 .tt-image-box .tt-img .tt-label-location .tt-label-info02 {
  background-color: #dd3d53;
  color: #ffffff;
}

.tt-product-02 .tt-image-box .tt-img .tt-label-custom {
  position: absolute;
  right: 13px;
  bottom: 13px;
  display: block;
}

.tt-product-02 .tt-image-box .tt-img .tt-label-custom i {
  font-size: 80px;
  line-height: 1.1;
  color: #dd3d53;
}

.tt-product-02 .tt-image-box .tt-img .tt-data {
  background-color: #ededec;
  color: #222222;
  position: absolute;
  bottom: 0;
  width: 100%;
  display: block;
  padding: 2px 0 8px 0;
  font-size: 11px;
  font-weight: 500;
  line-height: 1.1;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  transform: translate(0px, 100%);
  -webkit-transform: translate(0px, 100%);
  -o-transform: translate(0px, 100%);
  -moz-transform: translate(0px, 100%);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  -webkit-opacity: 0;
  opacity: 0;
  transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}

.tt-product-02 .tt-image-box .tt-img .tt-data:before {
  content: '\e919';
  font-family: "carleader";
  display: inline-block;
  font-size: 18px;
  line-height: 1.1;
  position: relative;
  top: 3px;
  margin-right: 5px;
}

.tt-product-02 .tt-image-box .tt-icon {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  top: 50%;
  width: 100%;
  margin-top: -27px;
  text-align: center;
  -moz-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  -webkit-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
  -moz-opacity: 0;
  -khtml-opacity: 0;
  -webkit-opacity: 0;
  opacity: 0;
}

@media (max-width: 1024px) {
  .tt-product-02 .tt-image-box .tt-icon {
    display: none;
  }
}

.tt-product-02 .tt-image-box .tt-icon li {
  margin: 6px 6px 0;
}

.tt-product-02 .tt-image-box .tt-icon li a {
  width: 43px;
  height: 43px;
  display: inline-block;
  border: 2px solid #dd3d53;
  background-color: #dd3d53;
  color: #ffffff;
  font-size: 18px;
  line-height: 2.4;
  -moz-transition: background-color 0.2s linear;
  -o-transition: background-color 0.2s linear;
  -webkit-transition: background-color 0.2s linear;
  transition: background-color 0.2s linear;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.tt-product-02 .tt-image-box .tt-icon li a:hover {
  background-color: transparent;
}

.tt-product-02 .tt-image-box:hover .tt-img:before {
  -moz-opacity: 0.7;
  -khtml-opacity: 0.7;
  -webkit-opacity: 0.7;
  opacity: 0.7;
}

.tt-product-02 .tt-image-box:hover .tt-icon {
  -moz-opacity: 1;
  -khtml-opacity: 1;
  -webkit-opacity: 1;
  opacity: 1;
}

.tt-product-02 .tt-image-box:hover .tt-data {
  -moz-opacity: 1;
  -khtml-opacity: 1;
  -webkit-opacity: 1;
  opacity: 1;
  transform: translate(0px, 0%);
  -webkit-transform: translate(0px, 0%);
  -o-transform: translate(0px, 0%);
  -moz-transform: translate(0px, 0%);
}

.tt-product-02 .tt-wrapper-description .tt-box-title {
  padding: 18px 7px 15px;
  border-bottom: 1px solid #f0f0f0;
}

.tt-product-02 .tt-wrapper-description .tt-box-title .tt-title {
  font-size: 18px;
  line-height: 1.3;
  font-weight: 600;
  color: #222222;
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
}

.tt-product-02 .tt-wrapper-description .tt-box-title .tt-title a {
  color: #222222;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.tt-product-02 .tt-wrapper-description .tt-box-title .tt-title a:hover {
  color: #dd3d53;
}

.tt-product-02 .tt-wrapper-description .tt-box-title .tt-description {
  font-family: "Montserrat", sans-serif;
  font-size: 11px;
  line-height: 1.3;
  font-weight: 500;
  margin: 1px 0 0 0;
  text-transform: uppercase;
  color: #222222;
}

.tt-product-02 .tt-wrapper-description .tt-box-price {
  position: relative;
  margin-top: 21px;
}

.tt-product-02 .tt-wrapper-description .tt-box-price .tt-text {
  color: #222222;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
}

.tt-product-02 .tt-wrapper-description .tt-box-price .tt-text + .tt-price {
  margin-left: 6px;
}

.tt-product-02 .tt-wrapper-description .tt-box-price .tt-price {
  font-size: 24px;
  line-height: 1.3;
  color: #dd3d53;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

.tt-product-02 .tt-wrapper-description .tt-box-price .tt-old-price {
  font-size: 12px;
  line-height: 1;
  text-decoration: line-through;
  color: #222222;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  position: absolute;
  top: -12px;
  width: 100%;
  text-align: center;
  display: block;
}

.tt-product-02 .tt-wrapper-description .tt-box-price .tt-info-price {
  font-size: 24px;
  line-height: 1.3;
  color: #dd3d53;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

.tt-product-02 .tt-wrapper-description .tt-box-price .tt-info {
  display: none;
}

.tt-product-02 .tt-wrapper-description .tt-icon {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  margin-top: 7px;
  padding-bottom: 9px;
  text-align: center;
}

@media (min-width: 1025px) {
  .tt-product-02 .tt-wrapper-description .tt-icon {
    display: none;
  }
}

.tt-product-02 .tt-wrapper-description .tt-icon li {
  margin: 6px 6px 0;
}

.tt-product-02 .tt-wrapper-description .tt-icon li a {
  width: 43px;
  height: 43px;
  display: inline-block;
  border: 2px solid #dd3d53;
  background-color: #dd3d53;
  color: #ffffff;
  font-size: 18px;
  line-height: 2.3;
  -moz-transition: background-color 0.2s linear;
  -o-transition: background-color 0.2s linear;
  -webkit-transition: background-color 0.2s linear;
  transition: background-color 0.2s linear;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.tt-product-02 .tt-wrapper-description .tt-icon li a:hover {
  background-color: transparent;
  color: #dd3d53;
}

.tt-product-02 .tt-wrapper-description .tt-add-info {
  display: none;
  list-style: none;
  margin: 0;
  text-transform: uppercase;
}

.tt-product-02 .tt-wrapper-description .tt-add-info li {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  font-size: 11px;
  color: #222222;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

.tt-product-02 .tt-wrapper-description .tt-add-info li .col-title {
  width: 109px;
  min-width: 109px;
  padding-right: 10px;
}

.tt-product-02 .tt-wrapper-description .tt-add-info li .col-designation {
  font-weight: 600;
}

.tt-product-02 .tt-wrapper-description .tt-btn {
  margin-top: 8px;
}

.tt-product-02 .tt-wrapper-description .tt-btn .tt-btn-moreinfo {
  background: #ffffff;
  color: #222222;
  font-family: "Montserrat", sans-serif;
  border: none;
  font-size: 11px;
  line-height: 1.1;
  font-weight: 600;
  position: relative;
  outline: none;
  padding: 4px 20px 4px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 42px;
  cursor: pointer;
  text-transform: uppercase;
  -webkit-box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}

.tt-product-02 .tt-wrapper-description .tt-btn .tt-btn-moreinfo:hover {
  background: #222222;
  color: #ffffff;
  outline: none;
}

.tt-product-02.tt-not-hover {
  pointer-events: none;
}

@media (min-width: 576px) {
  .tt-product-02.tt-view {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    padding: 0 26px 0 0;
  }
  .tt-product-02.tt-view .tt-image-box {
    min-width: 267px;
  }
  .tt-product-02.tt-view .tt-image-box .tt-icon {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -webkit-box;
    display: flex;
  }
  .tt-product-02.tt-view .tt-wrapper-description {
    padding-left: 30px;
    padding-bottom: 18px;
    -webkit-flex: 2 1 auto;
    -ms-flex: 2 1 auto;
    flex: 2 1 auto;
    text-align: left;
  }
  .tt-product-02.tt-view .tt-wrapper-description .tt-box-title {
    border: none;
    margin: 18px 0 0 0;
    padding: 0;
  }
  .tt-product-02.tt-view .tt-wrapper-description .tt-icon {
    display: none;
  }
  .tt-product-02.tt-view .tt-wrapper-description .tt-box-price {
    margin-top: 9px;
  }
  .tt-product-02.tt-view .tt-wrapper-description .tt-box-price .tt-old-price {
    position: relative;
    top: 0;
    margin-top: 5px;
    text-align: left;
  }
  .tt-product-02.tt-view .tt-wrapper-description .tt-box-price .tt-info {
    display: block;
    text-transform: uppercase;
    color: #222222;
    font-family: "Open Sans", sans-serif;
    font-size: 11px;
    font-weight: 500;
  }
  .tt-product-02.tt-view .tt-wrapper-description .tt-box-price .tt-info span {
    text-transform: none;
    font-weight: 600;
  }
  .tt-product-02.tt-view .tt-wrapper-description .tt-add-info {
    display: block;
    margin-top: 6px;
  }
}

@media (min-width: 576px) and (min-width: 1230px) {
  .tt-product-02.tt-view .tt-wrapper-description .tt-row-01 {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .tt-product-02.tt-view .tt-wrapper-description .tt-row-01 > *:last-child {
    padding-top: 12px;
  }
  .tt-product-02.tt-view .tt-wrapper-description .tt-box-price {
    text-align: right;
  }
  .tt-product-02.tt-view .tt-wrapper-description .tt-box-price .tt-old-price {
    text-align: right;
  }
  .tt-product-02.tt-view .tt-wrapper-description .tt-box-price .tt-info {
    text-align: right;
  }
  .tt-product-02.tt-view .tt-wrapper-description .tt-btn {
    text-align: right;
  }
}

@media (min-width: 576px) {
  .tt-product-02.tt-view .tt-row-02 {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
  }
  .tt-product-02.tt-view .tt-row-02 > *:not(:first-child) {
    margin-left: 60px;
  }
}

@media (min-width: 576px) and (max-width: 1024px) {
  .tt-product-02.tt-view .tt-row-02 {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .tt-product-02.tt-view .tt-row-02 > *:not(:first-child) {
    margin-left: 0px;
    margin-top: 0;
  }
}

@media (min-width: 576px) and (max-width: 766px) {
  .tt-product-02.tt-view .tt-image-box {
    min-width: 240px;
    width: 240px;
  }
}

body.touch .tt-product-02 .tt-image-box .tt-img .tt-data {
  -moz-opacity: 1;
  -khtml-opacity: 1;
  -webkit-opacity: 1;
  opacity: 1;
  transform: translate(0px, 0%);
  -webkit-transform: translate(0px, 0%);
  -o-transform: translate(0px, 0%);
  -moz-transform: translate(0px, 0%);
}

.tt-slider-product .slick-slide {
  padding: 10px;
}

/*
	aside(*shop)
*/
@media (min-width: 1025px) {
  .tt-aside .tt-wrapper-aside {
    -webkit-box-shadow: 0 0 49px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 0 49px rgba(0, 0, 0, 0.12);
    box-shadow: 0 0 49px rgba(0, 0, 0, 0.12);
    padding-bottom: 29px;
  }
}

@media (max-width: 1024px) {
  .tt-aside {
    position: fixed;
    display: block;
    margin: 0;
    background: #ffffff;
    z-index: 14;
    top: 0;
    left: -150%;
    height: 100%;
    width: 287px;
    max-width: inherit;
    text-align: left;
    -moz-transition: left 0.3s linear;
    -o-transition: left 0.3s linear;
    -webkit-transition: left 0.3s linear;
    transition: left 0.3s linear;
  }
}

.tt-aside .tt-aside-box .tt-aside-title {
  color: #222222;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.2;
  padding: 0 28px 3px 28px;
}

.tt-aside .tt-aside-box .tt-content {
  padding: 0 28px 0 28px;
}

.tt-aside .tt-aside-box:not(:last-child) {
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 22px;
  margin-bottom: 24px;
}

.tt-aside .slider-value-row .slider-value {
  height: 39px;
  padding: 1px 4px 1px;
  font-size: 12px;
  font-weight: 500;
}

.tt-label-aside {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 9px 0;
  margin-bottom: 12px;
  color: #222222;
}

.tt-label-aside .tt-icon {
  width: 86px;
  height: 75px;
  background-color: #dd3d53;
  color: #ffffff;
  font-size: 40px;
  line-height: 2;
  text-align: center;
  -moz-border-radius-topright: 35px;
  -webkit-border-top-right-radius: 35px;
  border-top-right-radius: 35px;
  -moz-border-radius-bottomright: 35px;
  -webkit-border-bottom-right-radius: 35px;
  border-bottom-right-radius: 35px;
}

.tt-label-aside .tt-icon i {
  margin-right: 7px;
}

.tt-label-aside.tt-small {
  margin-bottom: 18px;
}

.tt-label-aside.tt-small .tt-icon {
  font-size: 36px;
  line-height: 2.2;
}

@media (max-width: 1024px) {
  .tt-label-aside.tt-small .tt-icon {
    font-size: 26px;
  }
}

.tt-label-aside .tt-title {
  font-size: 16px;
  line-height: 24px;
  font-family: "Montserrat", sans-serif;
  color: #222222;
  padding: 0 0 0 12px;
  font-weight: 500;
}

.tt-label-aside .tt-btn-col-close {
  position: absolute;
  top: 50%;
  right: 15px;
  margin-top: -20px;
  display: none;
  padding: 5px;
}

@media (max-width: 1024px) {
  .tt-label-aside {
    -moz-transition: left 0.3s linear;
    -o-transition: left 0.3s linear;
    -webkit-transition: left 0.3s linear;
    transition: left 0.3s linear;
    background-color: #ffffff;
    z-index: 2;
    top: 0;
    left: -150%;
    position: fixed;
    width: 287px;
  }
  .tt-label-aside .tt-icon {
    width: 58px;
    height: 54px;
    background-color: #dd3d53;
    color: #ffffff;
    font-size: 26px;
    line-height: 2.3;
    text-align: center;
    -moz-border-radius-topright: 24px;
    -webkit-border-top-right-radius: 24px;
    border-top-right-radius: 24px;
    -moz-border-radius-bottomright: 24px;
    -webkit-border-bottom-right-radius: 24px;
    border-bottom-right-radius: 24px;
  }
  .tt-label-aside .tt-btn-col-close {
    display: block;
  }
}

.tt-label-aside:after {
  content: '';
  position: absolute;
  bottom: -10px;
  width: 100%;
  height: 10px;
  left: 0;
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0), white 60%);
  background: -o-linear-gradient(top, rgba(255, 255, 255, 0), white 60%);
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0), white 60%);
  background: linear-gradient(to top, rgba(255, 255, 255, 0), white 60%);
}

#aside-js.column-open .tt-label-aside {
  left: 0;
}

.tt-product-aside {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.tt-product-aside .tt-img {
  min-width: 85px;
}

.tt-product-aside .tt-img + .tt-description {
  margin-left: 11px;
}

.tt-product-aside .tt-description .tt-title {
  font-size: 15px;
  line-height: 22px;
  color: #222222;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

.tt-product-aside .tt-description .tt-title a {
  color: #222222;
  display: inline-block;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.tt-product-aside .tt-description .tt-title a:hover {
  color: #dd3d53;
}

.tt-product-aside .tt-description .tt-rating {
  margin: 11px 0 0 0;
}

.tt-product-aside .tt-description .tt-rating i {
  font-size: 11px;
}

.tt-product-aside .tt-description .tt-price {
  font-weight: 600;
  font-size: 18px;
  margin-top: 7px;
  color: #222222;
  font-family: "Montserrat", sans-serif;
}

.tt-product-aside:not(:first-child) {
  margin-top: 20px;
}

/*
	aside(*inventory.html)
*/
@media (min-width: 1025px) {
  .tt-aside02 .tt-wrapper-aside {
    padding-bottom: 29px;
    -webkit-box-shadow: 0 0 49px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 0 49px rgba(0, 0, 0, 0.12);
    box-shadow: 0 0 49px rgba(0, 0, 0, 0.12);
  }
  .tt-aside02 .tt-wrapper-aside + .tt-wrapper-aside {
    padding-top: 29px;
  }
}

.tt-aside02 .tt-wrapper-aside + .tt-wrapper-aside {
  margin-top: 18px;
}

@media (max-width: 1024px) {
  .tt-aside02 {
    position: fixed;
    display: block;
    margin: 0;
    background: #ffffff;
    z-index: 14;
    top: 0;
    left: -150%;
    height: 100%;
    width: 287px;
    max-width: inherit;
    text-align: left;
    -moz-transition: left 0.3s linear;
    -o-transition: left 0.3s linear;
    -webkit-transition: left 0.3s linear;
    transition: left 0.3s linear;
  }
}

.tt-aside02 .tt-aside-box .tt-aside-title {
  color: #222222;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.2;
  padding: 0 27px 11px 20px;
  margin: 0;
}

@media (min-width: 1025px) {
  .tt-aside02 .tt-aside-box .tt-aside-title {
    padding: 0 27px 11px 29px;
  }
}

@media (min-width: 1025px) {
  .tt-aside02 .tt-aside-box .tt-content {
    padding: 0 27px 0 27px;
  }
}

@media (max-width: 1024px) {
  .tt-aside02 .tt-aside-box .tt-content {
    padding: 0 20px;
  }
}

.tt-aside02 .tt-aside-box:not(:last-child) {
  padding-bottom: 18px;
}

.tt-aside02 .tt-col-select .nice-select.tt-skin-01 {
  padding: 7px 33px 3px 14px;
  height: 39px;
}

.tt-aside02 .slider-value-row .slider-value {
  height: 39px;
  padding: 1px 4px 1px;
  font-size: 12px;
  font-weight: 500;
}

.tt-aside02-box .tt-aside-title {
  color: #222222;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.2;
  padding: 0 27px 3px 27px;
}

.tt-aside02-box .tt-content {
  padding: 0 27px 0 27px;
}

.tt-form-search .tt-item .btn {
  width: 100%;
  margin-top: 16px;
}

.tt-form-search .tt-item .btn [class^="icon-"] {
  font-size: 13px;
}

.tt-slider-price .tt-item {
  margin-top: 10px;
}

.tt-slider-price .tt-item .btn {
  width: 100%;
}

.tt-slider-price .tt-item .btn:not(:first-child) {
  margin-top: 7px;
}

/* ================================================ */
html.ie .aside.leftColumn.desctop-no-sidebar,
html.ie .aside.leftColumn,
html.ie .tt-btn-col-close {
  -moz-transition: left 0s linear;
  -o-transition: left 0s linear;
  -webkit-transition: left 0s linear;
  transition: left 0s linear;
}

body.no-scroll:not(.touch),
html.win.ie body.no-scroll {
  // padding-right: 17px;
}

body.no-scroll {
  overflow: hidden;
  width: 100%;
  position: fixed;
  z-index: 0;
}

.modal-filter {
  background-color: rgba(0, 0, 0, 0.55);
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 11;
  display: block;
  -moz-opacity: 0;
  -khtml-opacity: 0;
  -webkit-opacity: 0;
  opacity: 0;
  -moz-transition: background 0.2s linear;
  -o-transition: background 0.2s linear;
  -webkit-transition: background 0.2s linear;
  transition: background 0.2s linear;
}

@media (max-width: 1024px) {
  .leftColumn {
    position: fixed;
    display: block;
    margin: 0;
    background: #ffffff;
    z-index: 14;
    top: 0;
    left: -150%;
    height: 100%;
    width: 287px;
    max-width: inherit;
    text-align: left;
    -moz-transition: left 0.3s linear;
    -o-transition: left 0.3s linear;
    -webkit-transition: left 0.3s linear;
    transition: left 0.3s linear;
  }
  .aside.leftColumn .tt-btn-col-close {
    display: block;
  }
  .leftColumn.column-open {
    left: 0;
    z-index: 12;
    padding: 97px 0 30px 0;
  }
  #aside-js {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 1025px) {
  .desctop-no-sidebar {
    position: fixed;
    display: block;
    margin: 0;
    background: #ffffff;
    z-index: 14;
    top: 0;
    left: -150%;
    height: 100%;
    width: 287px;
    max-width: inherit;
    text-align: left;
    overflow-x: hidden;
  }
}

.tt-title-single {
  padding-bottom: 28px;
}

.tt-title-single .tt-title {
  font-size: 36px;
  line-height: 1.3;
  color: #222222;
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}

.tt-title-single .tt-title a {
  color: #222222;
}

.tt-title-single .tt-title a:hover {
  color: #dd3d53;
}

@media (max-width: 1024px) {
  .tt-title-single .tt-title {
    font-size: 30px;
  }
}

@media (max-width: 575px) {
  .tt-title-single .tt-title {
    font-size: 24px;
  }
}

.tt-title-single .tt-description {
  color: #222222;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  line-height: 1.3;
  text-transform: uppercase;
  margin-top: 6px;
}

@media (max-width: 1024px) {
  .tt-title-single .tt-description {
    font-size: 12px;
  }
}

@media (max-width: 575px) {
  .tt-title-single .tt-description {
    font-size: 11px;
  }
}

@media (max-width: 1024px) {
  .tt-title-single {
    padding-bottom: 37px;
  }
}

@media (max-width: 767px) {
  .tt-title-single {
    padding-bottom: 19px;
  }
}

.tt-title-single + .tt-aside-btn .btn:not(:first-child) {
  margin-left: 10px;
}

@media (min-width: 767px) {
  .tt-title-single + .tt-aside-btn {
    margin-top: 14px;
  }
}

.tt-mobile-product-layout {
  margin-top: 30px;
  position: relative;
}

.tt-mobile-product-layout .tt-label-location {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 0;
  font-size: 11px;
  line-height: 1.2;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}

.tt-mobile-product-layout .tt-label-location [class^="tt-label-"] {
  padding: 5px 6px 4px 6px;
  margin: 9px 0 0 9px;
}

.tt-mobile-product-layout .tt-label-location .tt-label-promo {
  background-color: #2ac528;
  color: #ffffff;
}

.tt-mobile-product-layout .tt-label-location .tt-label-new {
  background-color: #222222;
  color: #dd3d53;
}

.tt-mobile-product-layout .tt-label-location .tt-label-info {
  background-color: #ffffff;
  color: #222222;
}

.tt-mobile-product-layout .tt-label-location .tt-label-info02 {
  background-color: #dd3d53;
  color: #ffffff;
}

.tt-title-single-sub {
  font-size: 18px;
  line-height: 1.3;
  color: #222222;
  margin: 0;
  padding: 0 0 24px 0;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}

.tt-title-single-sub a {
  color: #222222;
}

.tt-title-single-sub a:hover {
  color: #dd3d53;
}

@media (max-width: 1024px) {
  .tt-title-single-sub {
    padding: 0 0 21px 0;
  }
}

@media (max-width: 767px) {
  .tt-title-single-sub {
    padding: 0 0 19px 0;
  }
}

/* invertory item */
.product-images-carousel {
  position: relative;
  margin-top: 10px;
  margin-right: -10px;
  padding-bottom: 26px;
}

.product-images-carousel ul li {
  padding-right: 10px;
}

.product-images-carousel:before {
  content: '';
  width: 10px;
  height: 100%;
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  background-color: #ffffff;
  z-index: 1;
}

.product-images-carousel .slick-next {
  right: 30px;
}

.product-images-carousel .slick-dots {
  margin-top: 30px;
  position: absolute;
}

.product-images-carousel .slick-dots li {
  width: 8px;
  height: 8px;
}

.product-images-carousel .slick-slider li {
  position: relative;
}

.product-images-carousel .slick-slider .slick-slide a:before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #ffffff;
  -moz-opacity: 0.56;
  -khtml-opacity: 0.56;
  -webkit-opacity: 0.56;
  opacity: 0.56;
  -moz-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  -webkit-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

.product-images-carousel .slick-slider .slick-slide div:before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #ffffff;
  -moz-opacity: 0.56;
  -khtml-opacity: 0.56;
  -webkit-opacity: 0.56;
  opacity: 0.56;
  -moz-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  -webkit-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

.product-images-carousel .slick-slider .slick-slide:hover a:before,
.product-images-carousel .slick-slider .slick-slide:hover div:before,
.product-images-carousel .slick-slider .slick-slide .zoomGalleryActive:before {
  -moz-opacity: 0;
  -khtml-opacity: 0;
  -webkit-opacity: 0;
  opacity: 0;
}

.tt-product-single-img {
  position: relative;
}

.tt-product-single-img .tt-label-location {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  position: absolute;
  top: 0px;
  left: 0px;
  font-size: 11px;
  line-height: 1.2;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}

.tt-product-single-img .tt-label-location [class^="tt-label-"] {
  padding: 5px 6px 4px 6px;
  margin: 9px 0 0 9px;
}

.tt-product-single-img .tt-label-location .tt-label-promo {
  background-color: #2ac528;
  color: #ffffff;
}

.tt-product-single-img .tt-label-location .tt-label-new {
  background-color: #222222;
  color: #dd3d53;
}

.tt-product-single-img .tt-label-location .tt-label-info {
  background-color: #ffffff;
  color: #222222;
}

.tt-product-single-img .tt-label-location .tt-label-info02 {
  background-color: #dd3d53;
  color: #ffffff;
}

.zoomWindowContainer div {
  z-index: 2 !important;
}

/* video-link-product (product.html)*/
.video-link-product {
  position: relative;
  cursor: pointer;
}

.video-link-product div {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-link-product [class^="icon-"] {
  color: #ffffff;
  font-size: 30px;
  line-height: 30px;
  transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}

.video-link-product [class^="icon-"]:hover {
  color: #dd3d53;
}

/*
tabs
 */
/*nav-tabs (tabs)*/
/* tabs(custom) */
.tt-tabs {
  overflow: hidden;
  margin-top: -15px;
}

.tt-tabs .tt-tabs__head > ul {
  list-style: none;
  padding: 0;
}

.tt-tabs .tt-tabs__head .tt-tabs__btn-prev,
.tt-tabs .tt-tabs__head .tt-tabs__btn-next {
  display: none;
}

.tt-tabs .tt-tabs__body > div {
  display: block;
}

.tt-tabs .tt-tabs__body > div > div {
  display: none;
}

.tt-tabs .tt-tabs__body > div:not(:first-child) > span {
  border-top: solid 1px #dfdfdf;
}

@media only screen and (max-width: 1025px) {
  .tt-tabs .tt-tabs__head {
    display: none;
  }
  .tt-tabs .tt-tabs__head.slider .tt-tabs__btn-prev,
  .tt-tabs .tt-tabs__head.slider .tt-tabs__btn-next {
    display: none;
  }
  .tt-tabs .tt-tabs__border {
    display: none;
  }
  .tt-tabs .tt-tabs__body > div > span {
    position: relative;
    display: block;
    padding: 12px 0px 11px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #222222;
    cursor: pointer;
    transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
  }
  .tt-tabs .tt-tabs__body > div > div {
    padding: 30px 0px 27px;
    border-top: solid 1px #dfdfdf;
  }
  .tt-tabs .tt-tabs__body > div:hover > span {
    color: #dd3d53;
  }
  .tt-tabs .tt-tabs__body > div.active > span {
    color: #dd3d53;
  }
  .tt-tabs .tt-tabs__content-head {
    display: none;
  }
}

@media only screen and (min-width: 1025px) {
  .tt-tabs .tt-tabs__head {
    position: relative;
    visibility: hidden;
  }
  .tt-tabs .tt-tabs__head > ul {
    position: relative;
    margin: 0 -10px;
    flex-wrap: wrap;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
  .tt-tabs .tt-tabs__head > ul > li {
    padding: 0 10px 25px;
  }
  .tt-tabs .tt-tabs__head > ul > li > span {
    position: relative;
    display: block;
    padding: 10px 15px 10px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    letter-spacing: 0.17em;
    text-transform: uppercase;
    color: #222222;
    cursor: pointer;
    border: 1px solid transparent;
    transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
  }
  .tt-tabs .tt-tabs__head > ul > li > span:hover {
    display: block;
    color: #dd3d53;
  }
  .tt-tabs .tt-tabs__head > ul > li.active > span {
    color: #dd3d53;
    border-color: #dd3d53;
  }
  .tt-tabs .tt-tabs__head.text-center ul {
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .tt-tabs .tt-tabs__head.slider {
    margin-left: 30px;
    margin-right: 30px;
  }
  .tt-tabs .tt-tabs__head.slider .tt-tabs__btn-prev,
  .tt-tabs .tt-tabs__head.slider .tt-tabs__btn-next {
    display: block;
    position: absolute;
    width: 20px;
    height: 100%;
    top: 0;
    cursor: pointer;
    z-index: 2;
  }
  .tt-tabs .tt-tabs__head.slider .tt-tabs__btn-prev::before,
  .tt-tabs .tt-tabs__head.slider .tt-tabs__btn-next::before {
    position: absolute;
    display: block;
    content: '';
    top: 50%;
    left: 50%;
    margin-top: -15px;
    border-top: solid 3px #222222;
    border-left: solid 3px #222222;
    width: 12px;
    height: 12px;
    transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
  }
  .tt-tabs .tt-tabs__head.slider .tt-tabs__btn-prev.disabled,
  .tt-tabs .tt-tabs__head.slider .tt-tabs__btn-next.disabled {
    pointer-events: none;
  }
  .tt-tabs .tt-tabs__head.slider .tt-tabs__btn-prev.disabled::before,
  .tt-tabs .tt-tabs__head.slider .tt-tabs__btn-next.disabled::before {
    border-top-color: #222222;
    border-left-color: #222222;
  }
  .tt-tabs .tt-tabs__head.slider .tt-tabs__btn-prev {
    left: -30px;
  }
  .tt-tabs .tt-tabs__head.slider .tt-tabs__btn-next {
    right: -30px;
  }
  .tt-tabs .tt-tabs__head.slider .tt-tabs__btn-prev::before {
    margin-left: -4px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  .tt-tabs .tt-tabs__head.slider .tt-tabs__btn-next::before {
    margin-left: -8px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  .tt-tabs .tt-tabs__head.slider::before,
  .tt-tabs .tt-tabs__head.slider::after {
    position: absolute;
    display: block;
    content: '';
    height: 100%;
    width: 30px;
    top: 0;
    cursor: default;
    z-index: 1;
  }
  .tt-tabs .tt-tabs__head.slider::before {
    left: -30px;
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0), white 20%);
    background: -o-linear-gradient(left, rgba(255, 255, 255, 0), white 20%);
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 0), white 20%);
    background: linear-gradient(to left, rgba(255, 255, 255, 0), white 20%);
  }
  .tt-tabs .tt-tabs__head.slider::after {
    right: -30px;
    background: -webkit-linear-gradient(right, rgba(255, 255, 255, 0), white 20%);
    background: -o-linear-gradient(right, rgba(255, 255, 255, 0), white 20%);
    background: -moz-linear-gradient(right, rgba(255, 255, 255, 0), white 20%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0), white 20%);
  }
  .tt-tabs .tt-tabs__border {
    display: none;
  }
  .tt-tabs .tt-tabs__body {
    border-top: solid 1px #dfdfdf;
    margin-top: -1px;
  }
  .tt-tabs .tt-tabs__body > div > span {
    display: none;
  }
  .tt-tabs .tt-tabs__body > div > div {
    padding: 28px 0 0px;
  }
}

.tt-tabs .tab-title {
  font-size: 15px;
  line-height: 1.5;
  font-weight: 400;
  color: #222222;
  margin: 0;
  padding: 0 0 16px 0;
}

.tt-tabs .tt-table-options {
  max-width: 611px;
}

.tt-tabs .tt-rating {
  margin-top: 0;
}

.tt-tabs .tt-rating * {
  margin-right: 3px;
  display: inline-block;
}

.tt-tabs .tt-form-default {
  margin-top: 20px;
}

.tt-tabs .tt-table-options tr td:first-child {
  width: 40%;
}

.tt-tabs .tt-table-options tr td:last-child {
  width: 60%;
}

.tt-aside-gallery .tt-img-large {
  background-image: url("/images/loader.svg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  width: 100%;
  position: relative;
}

.tt-aside-gallery .tt-img-large .tt-label-location {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  position: absolute;
  top: 0px;
  left: 0px;
  font-size: 11px;
  line-height: 1.2;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}

.tt-aside-gallery .tt-img-large .tt-label-location [class^="tt-label-"] {
  padding: 5px 6px 4px 6px;
  margin: 9px 0 0 9px;
}

.tt-aside-gallery .tt-img-large .tt-label-location .tt-label-promo {
  background-color: #2ac528;
  color: #ffffff;
}

.tt-aside-gallery .tt-img-large .tt-label-location .tt-label-new {
  background-color: #222222;
  color: #dd3d53;
}

.tt-aside-gallery .tt-img-large .tt-label-location .tt-label-info {
  background-color: #ffffff;
  color: #222222;
}

.tt-aside-gallery .tt-img-large .tt-label-location .tt-label-info02 {
  background-color: #dd3d53;
  color: #ffffff;
}

.tt-aside-gallery .tt-img-thumbnails {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  list-style: none;
  padding: 0;
  margin: 0 0 0 -10px;
}

.tt-aside-gallery .tt-img-thumbnails li {
  width: calc(33% - 10px);
  height: 100%;
  margin-top: 10px;
  margin-left: 10px;
  text-align: center;
  position: relative;
}

.tt-aside-gallery .tt-img-thumbnails li a {
  display: block;
  width: 100%;
  height: 100%;
}

.tt-aside-gallery .tt-img-thumbnails li.tt-more-hide {
  display: none;
}

.tt-aside-gallery .tt-img-thumbnails .tt-more {
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  line-height: 1.4;
  font-weight: 600;
  color: #dd3d53;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.tt-aside-gallery .tt-img-thumbnails .tt-more:hover {
  color: #222222;
}

.tt-aside-gallery .tt-img-thumbnails .tt-more span {
  top: 0;
  left: 0;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

/*---------------------------------------*/
/*--------   7. Modal            --------*/
/*---------------------------------------*/
/*
Modal
*/
/* open modal mobile menu */
body:not(.touch).mm-open {
  // padding-right: 17px;
}

body:not(.touch).mm-open .tt-stuck-nav.stuck {
  // padding-right: 17px;
}

/* open modal bootstrap */
.modal-open {
  overflow: hidden;
}

body:not(.touch).modal-open {
  overflow-x: hidden;
  // padding-right: 17px;
}

body:not(.touch).modal-open #tt-stuck.stuck {
  // padding-right: 17px;
}

body:not(.touch).modal-open .tt-on-top {
  // padding-right: 17px;
}

html.ie.gecko body.modal-open {
  padding-right: 0px !important;
}

/* open left col (*listing) */
.modal {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 11;
  -webkit-overflow-scrolling: touch;
  outline: none;
  text-align: center;
  padding: 0;
  // -moz-transition: opacity 0.15s linear;
  // -o-transition: opacity 0.15s linear;
  // -webkit-transition: opacity 0.15s linear;
  // transition: opacity 0.15s linear;
  -moz-opacity: 0;
  -khtml-opacity: 0;
  -webkit-opacity: 0;
  opacity: 0;
}

.modal.show {
  opacity: 1;
}

@media (min-width: 320px) {
  .modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
  }
}

@media (min-width: 721px) {
  .modal-md {
    width: 668px;
  }
}

@media (max-width: 720px) {
  .modal-md {
    width: 528px;
  }
}

@media (max-width: 580px) {
  .modal-md {
    width: 420px;
  }
}

@media (max-width: 480px) {
  .modal-md {
    width: 310px;
  }
}

@media (max-width: 360px) {
  .modal-md {
    width: 260px;
  }
}

.modal-backdrop.in {
  -moz-opacity: 1;
  -khtml-opacity: 1;
  -webkit-opacity: 1;
  opacity: 1;
}

.modal-newsletter .modal-backdrop.show {
  -moz-opacity: 0.87;
  -khtml-opacity: 0.87;
  -webkit-opacity: 0.87;
  opacity: 0.87;
}

/* modal layout */
.modal {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 11;
  -webkit-overflow-scrolling: touch;
  outline: none;
}

.modal .modal-content {
  border-radius: 0;
  border: none;
}

.modal .modal-footer {
  border: none;
}

/*
    modal custom
*/
.modal {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 30;
  -webkit-overflow-scrolling: touch;
  outline: none;
}

.modal .modal-dialog {
  display: inline-block;
  vertical-align: middle;
}

@media (min-width: 576px) {
  .modal .modal-dialog {
    max-width: inherit;
  }
}

.modal .modal-body:not(.no-background) {
  background-color: #ffffff;
}

.modal .modal-body {
  text-align: left;
  padding: 38px 38px 40px 41px;
}
.b-modal .modal-dialog{ 
  width: 600px;
  max-width: 98%;
  margin: 30px auto;
}
.b-modal .tt-block-title .tt-title{
margin-bottom: 0;

}
.b-modal .tt-title{
  font-family: "Montserrat",sans-serif;
  font-weight: 600;
  line-height: 1.2;
  margin: 0 0 30px;
  padding: 0;
  font-size: 36px;
  color: #222;
}
.b-modal .modal-header{
  position: absolute;
  z-index: 9;
  color: #000;
  right: 10px;
  top: 10px;
  border: 0;
}
.b-modal .modal-header button{
  color: #000;
  text-align: center;
  text-decoration: none;
  border: 0;
  opacity: 1;
  &:hover{
    opacity: 0.7;
  }

}  
label.error{
  color: red;
}
.modal-message{
// display: none;
position: fixed;
top: 0;
left: 0;
width: 100%;
background: green;
 z-index: 999;
 transform: translateY(-100%);
 transition: all 0.6s ease;
 opacity: 0;
 &.is-show{
  transform: translateY( 0%); 
  opacity:1;
 }
    p{
      text-align: center;
      color:#fff;
      padding: 10px 20px;

      font-size: 16px;
    }
    &-error{
      background: red;
    }
  }
.b-modal .modal-body{
  padding: 50px;
}
.b-modal .modal-body .box-info-indent{
margin: 0;
}
.b-modal .tt-title{
text-align: center;
}
.b-modal .btn { 
  font-size: 18px;

}
.modal .modal-body .close {
  font-size: 12px;
  line-height: 1;
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  z-index: 7;
  padding: 13px;
  color: #393939;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.modal .modal-body .close:hover {
  color: #dd3d53;
}

.modal .modal-body .modal-title {
  font-size: 24px;
  line-height: 1.2;
  padding-bottom: 26px;
  font-family: "Montserrat", sans-serif;
  color: #222222;
  font-weight: 600;
  text-align: left;
}

@media (max-width: 480px) {
  .modal .modal-body {
    padding: 38px 20px 40px;
  }
  .modal .modal-body .modal-title {
    font-size: 22px;
    padding-bottom: 20px;
  }
  .modal .modal-body p {
    padding-bottom: 20px;
  }
}

@media (min-width: 320px) {
  .modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
  }
}

.modal-backdrop {
  background: rgba(0,0,0,0.5);
}

.modal-backdrop.show {
  opacity: 0.5;
}

/*modal-layout-dafault*/
.modal-layout-dafault h5.tt-title {
  font-size: 24px;
  line-height: 1.1;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  color: #222222;
}

.modal-layout-dafault h6.tt-title {
  font-size: 14px;
  line-height: 1.5;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  color: #222222;
  margin: 0;
  padding-bottom: 16px;
}

.modal-layout-dafault .tt-title:not(:first-child) {
  margin-top: 24px;
}

.modal-layout-dafault p {
  margin: 0;
  padding: 0 0 26px 0;
}

.modal-layout-dafault .tt-row-select {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  margin-top: -10px;
  margin-left: -10px;
  padding-bottom: 16px;
}

.modal-layout-dafault .tt-row-select > * {
  width: calc(25% - 10px);
}

.modal-layout-dafault .tt-row-select > * {
  margin-left: 10px;
  margin-top: 10px;
}

@media (max-width: 721px) {
  .modal-layout-dafault .tt-row-select > * {
    width: calc(50% - 10px);
  }
}

@media (max-width: 721px) {
  .modal-layout-dafault .tt-row-select > * {
    width: calc(50% - 10px);
  }
}

@media (max-width: 480px) {
  .modal-layout-dafault .tt-row-select > * {
    width: calc(100% - 10px);
  }
}

.modal-layout-dafault .tt-row-radio {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  margin-top: -10px;
  margin-left: -10px;
  padding-bottom: 7px;
}

.modal-layout-dafault .tt-row-radio > * {
  margin-left: 10px;
  margin-top: 10px;
}

.modal-layout-dafault .tt-row-radio .tt-title {
  width: 163px;
  padding: 0 10px 0 0;
}

.modal-layout-dafault .tt-row-radio > div:not(:first-child) {
  width: 105px;
  padding: 0 10px 0 0;
}

@media (max-width: 721px) {
  .modal-layout-dafault .tt-row-radio .tt-title {
    width: 100%;
  }
  .modal-layout-dafault .tt-row-radio > div:not(:first-child) {
    width: 90px;
  }
}

@media (max-width: 480px) {
  .modal-layout-dafault .tt-row-radio > * {
    margin-top: 7px;
  }
  .modal-layout-dafault .tt-row-radio .radio {
    margin-bottom: 0;
  }
}

.modal-layout-dafault .tt-row-radio + .tt-title {
  margin-top: 18px;
}

.modal-layout-dafault .tt-row-calendar {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 16px;
}

.modal-layout-dafault .tt-row-calendar > *:first-child {
  width: 200px;
  margin-right: 15px;
  height: 44px;
}

.modal-layout-dafault .tt-row-calendar .tt-text-separator {
  padding-left: 7px;
  padding-right: 7px;
}

.modal-layout-dafault .tt-row-calendar .tt-select {
  width: 73px;
}

@media (max-width: 575px) {
  .modal-layout-dafault .tt-row-calendar > *:first-child {
    width: 100%;
    margin-right: 0;
    margin-bottom: 15px;
  }
}

/* modal-video */
.modal-video {
  width: 1055px;
}

@media (max-width: 1170px) {
  .modal-video {
    width: 604px;
  }
}

@media (max-width: 667px) {
  .modal-video {
    width: 461px;
  }
}

@media (max-width: 507px) {
  .modal-video {
    width: 277px;
  }
}

.modal-video-content {
  font-size: 0;
  line-height: 0;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.modal-video-content iframe {
  border: none;
  width: 100%;
  height: 584px;
  max-height: 80vh;
}

@media (max-width: 1100px) {
  .modal-video-content iframe {
    height: 315px;
  }
}

@media (max-width: 667px) {
  .modal-video-content iframe {
    height: 239px;
  }
}

@media (max-width: 507px) {
  .modal-video-content iframe {
    height: 134px;
  }
}

/*---------------------------------------*/
/*--------   8. Back To Top      --------*/
/*---------------------------------------*/
/*
	tt-back-to-top
*/
.tt-back-to-top {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -ms-transition: 0.4s;
  transition: 0.4s;
  width: 58px;
  height: 58px;
  background-color: #dd3d53;
  color: #ffffff;
  font-size: 0;
  line-height: 0;
  position: fixed;
  bottom: -64px;
  z-index: 20;
  -moz-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  -webkit-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  -moz-opacity: 0.8;
  -khtml-opacity: 0.8;
  -webkit-opacity: 0.8;
  opacity: 0.8;
}

.tt-back-to-top:before {
  font-family: "carleader";
  content: "\e92c";
  display: block;
  font-size: 18px;
  line-height: 1;
  position: relative;
  top: -1px;
  -moz-transition: top 0.2s linear;
  -o-transition: top 0.2s linear;
  -webkit-transition: top 0.2s linear;
  transition: top 0.2s linear;
}

.tt-back-to-top:hover {
  background-color: #dd3d53;
  color: #ffffff;
}

.tt-back-to-top.tt-show {
  bottom: 50px;
}

@media (min-width: 1601px) {
  .tt-back-to-top {
    right: 53px;
  }
}

@media (max-width: 1600px) and (min-width: 576px) {
  .tt-back-to-top {
    right: 28px;
  }
}

@media (max-width: 768px) {
  .tt-back-to-top {
    width: 43px;
    height: 43px;
  }
  .tt-back-to-top:before {
    font-size: 12px;
  }
}

@media (max-width: 575px) {
  .tt-back-to-top {
    right: 14px;
  }
}

body:not(.touch) .tt-back-to-top:hover {
  background-color: #dd3d53;
  color: #ffffff;
  -moz-opacity: 1;
  -khtml-opacity: 1;
  -webkit-opacity: 1;
  opacity: 1;
}

body:not(.touch) .tt-back-to-top:hover:before {
  top: -4px;
}

/*---------------------------------------*/
/*--------   9. breadcrumbs      --------*/
/*---------------------------------------*/
/* tt-breadcrumb */
.tt-breadcrumb {
  background: transparent;
}

@media (max-width: 1024px) {
  .tt-breadcrumb {
    display: none;
  }
}

.tt-breadcrumb ul {
  list-style: none;
  margin: 11px 0 11px 0;
  padding: 0;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-transform: uppercase;
}

.tt-breadcrumb ul li {
  font-size: 11px;
  line-height: 24px;
  padding: 1px 9px 1px 17px;
  position: relative;
  font-weight: 500;
  color: #222222;
  font-family: "Montserrat", sans-serif;
}

.tt-breadcrumb ul li a {
  color: #222222;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.tt-breadcrumb ul li a:hover {
  color: #dd3d53;
}

.tt-breadcrumb ul li:not(:first-child):before {
  content: '\e917';
  font-family: "carleader";
  position: absolute;
  display: inline-block;
  font-size: 10px;
  line-height: 1.2;
  left: 0;
  top: 7px;
  color: #dd3d53;
}

.tt-breadcrumb ul li:first-child {
  padding-left: 0;
}

@media (max-width: 767px) {
  .tt-breadcrumb .container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (max-width: 575px) {
  .tt-breadcrumb {
    padding-left: 10px;
    padding-right: 10px;
  }
}

/*---------------------------------------*/
/*--------   10. Blog             --------*/
/*---------------------------------------*/
.rightColumn {
  padding-left: 13px;
}

@media (max-width: 767px) {
  .rightColumn {
    padding-right: 15px;
    padding-top: 30px;
  }
}

@media (min-width: 1230px) {
  .blog-center-right {
    padding-right: 86px;
  }
}

@media (max-width: 1229px) {
  .blog-center-right {
    padding-right: 40px;
  }
}

@media (max-width: 1024px) {
  .blog-center-right {
    padding-right: 20px;
  }
}

@media (max-width: 767px) {
  .blog-center-right {
    padding-bottom: 40px;
    padding-right: 15px;
  }
}

@media (min-width: 1230px) {
  .tt-listing-post .tt-post:not(:first-child) {
    margin-top: 71px;
  }
}

@media (max-width: 1229px) {
  .tt-listing-post .tt-post:not(:first-child) {
    margin-top: 51px;
  }
}

@media (max-width: 1024px) {
  .tt-listing-post .tt-post:not(:first-child) {
    margin-top: 31px;
  }
}

.tt-post-music iframe,
.tt-post-music object,
.tt-post-music embed {
  width: 100%;
  height: auto;
  border: 0;
}

.tt-post-music {
  padding-bottom: 42px;
}

.tt-img-link {
  display: block;
  position: relative;
}

.tt-img-link .tt-wrapper-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 36px;
  line-height: 1.3;
  font-weight: 600;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.tt-img-link .tt-wrapper-text i {
  font-size: 32px;
  line-height: 1.2;
  color: #dd3d53;
  margin-right: 10px;
}

.tt-img-link:hover .tt-wrapper-text {
  color: #dd3d53;
}

@media (max-width: 1229px) {
  .tt-img-link .tt-wrapper-text {
    font-size: 30px;
  }
  .tt-img-link .tt-wrapper-text i {
    font-size: 28px;
  }
}

@media (max-width: 1024px) {
  .tt-img-link .tt-wrapper-text {
    font-size: 20px;
  }
  .tt-img-link .tt-wrapper-text i {
    font-size: 21px;
  }
}

@media (max-width: 575px) {
  .tt-img-link .tt-wrapper-text {
    font-size: 16px;
  }
  .tt-img-link .tt-wrapper-text i {
    font-size: 16px;
  }
}

@media (max-width: 360px) {
  .tt-img-link .tt-wrapper-text {
    font-size: 15px;
  }
  .tt-img-link .tt-wrapper-text i {
    font-size: 15px;
  }
}

/*
	tt-post
*/
.tt-post .tt-post-img img {
  width: 100%;
  height: auto;
}

.tt-post .tt-post-content {
  position: relative;
  padding: 0 0 0 31px;
}

.tt-post .tt-post-content .tt-title {
  color: #222222;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.4;
}

.tt-post .tt-post-content .tt-meta {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin: -30px 0 0 -11px;
}

.tt-post .tt-post-content .tt-meta .tt-time {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 62px;
  height: 62px;
  background-color: #dd3d53;
  color: #ffffff;
  text-align: center;
  font-size: 12px;
  line-height: 1;
  position: relative;
  text-transform: uppercase;
  padding: 0 0 1px 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.tt-post .tt-post-content .tt-meta .tt-time span {
  font-size: 24px;
  line-height: 1;
  font-weight: 600;
}

.tt-post .tt-post-content .tt-meta .tt-autor {
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  color: #999999;
  font-size: 13px;
  line-height: 1.2;
  position: relative;
  top: 3px;
}

.tt-post .tt-post-content .tt-meta .tt-autor span {
  color: #dd3d53;
}

.tt-post .tt-post-content .tt-title {
  margin: 22px 0 0 0;
  padding: 0;
  font-size: 18px;
  line-height: 1.3;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  color: #222222;
}

.tt-post .tt-post-content .tt-title a {
  color: #222222;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.tt-post .tt-post-content .tt-title a:hover {
  color: #dd3d53;
}

.tt-post .tt-post-content .tt-description {
  margin: 17px 0 0 0;
  padding: 0;
}

.tt-post .tt-post-content .tt-btn {
  margin-top: 20px;
  font-size: 11px;
}

.tt-post .tt-post-content .tt-btn a {
  color: #dd3d53;
  font-size: 11px;
  line-height: 26px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.tt-post .tt-post-content .tt-btn a:after {
  position: absolute;
  right: -15px;
  top: 9px;
  font-family: 'carleader' !important;
  content: "\e92b";
  font-size: 8px;
  line-height: 1.1;
  color: #dd3d53;
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.tt-post .tt-post-content .tt-btn a:hover {
  color: #222222;
}

.tt-post .tt-post-content .tt-btn a:hover:after {
  color: #222222;
}

.tt-post .tt-post-content:first-child {
  overflow: hidden;
}

.tt-post .tt-post-content:first-child .tt-meta {
  margin-top: 11px;
}

/* bloa aside*/
.tt-block02-aside {
  max-width: 300px;
}

.tt-block02-aside .tt-block02-title {
  font-size: 24px;
  line-height: 1.3;
  color: #222222;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  margin: 0;
}

@media (min-width: 1230px) {
  .tt-block02-aside .tt-block02-title {
    padding: 0 0 33px 0;
  }
}

@media (max-width: 1229px) {
  .tt-block02-aside .tt-block02-title {
    padding: 0 0 28px 0;
  }
}

@media (max-width: 1024px) {
  .tt-block02-aside .tt-block02-title {
    font-size: 20px;
    padding: 0 0 22px 0;
  }
}

.tt-block02-aside:first-child {
  margin-top: -7px;
}

@media (min-width: 1230px) {
  .tt-block02-aside:not(:first-child) {
    margin-top: 51px;
  }
}

@media (max-width: 1229px) {
  .tt-block02-aside:not(:first-child) {
    margin-top: 41px;
  }
}

@media (max-width: 1024px) {
  .tt-block02-aside:not(:first-child) {
    margin-top: 31px;
  }
}

.tt-block02-aside .tt-post .tt-post-content {
  padding: 0;
}

.tt-block02-aside .tt-post .tt-post-content .tt-description {
  display: none;
}

.tt-block02-aside .tt-post:not(:first-child) {
  margin-top: 31px;
}

.tt-block02-aside .tt-post .tt-post-content .tt-meta .tt-time {
  width: 50px;
  height: 50px;
  font-size: 10px;
  left: 23px;
}

.tt-block02-aside .tt-post .tt-post-content .tt-meta .tt-time span {
  font-size: 20px;
}

.tt-list-arrow {
  padding: 0;
  margin: -5px 0 0 10px;
  list-style: none;
}

.tt-list-arrow li a {
  position: relative;
  display: block;
  padding: 5px 0 5px 32px;
  font-size: 16px;
  color: #222222;
  font-weight: 300;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.tt-list-arrow li a:before {
  font-family: "carleader";
  color: #dd3d53;
  content: "\e917";
  display: block;
  position: absolute;
  top: 9px;
  left: 0;
  font-size: 13px;
  line-height: 1.2;
}

.tt-list-arrow li a:hover {
  color: #dd3d53;
}

.tt-list-box {
  list-style: none;
  padding: 0px;
  margin: -7px 0 0 -7px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.tt-list-box li {
  margin: 7px 0 0 7px;
}

.tt-list-box li a {
  display: inline-block;
  border: 1px solid #e1e1e1;
  color: #707070;
  font-size: 15px;
  line-height: 1.3;
  padding: 6px 10px 7px;
  font-weight: 300;
  transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
}

.tt-list-box li a:hover {
  color: #dd3d53;
  border-color: #dd3d53;
  -webkit-box-shadow: 0px 0px 1px rgba(221, 61, 83, 0.8);
  -moz-box-shadow: 0px 0px 1px rgba(221, 61, 83, 0.8);
  box-shadow: 0px 0px 1px rgba(221, 61, 83, 0.8);
}

.tt-list-box li.active a {
  background-color: #dd3d53;
}

.full-width {
  width: 100%;
}

.tt-blockquote {
  position: relative;
  padding: 0 0 0 59px;
  margin: 29px 0 0 0;
  color: #222222;
  font-style: italic;
  font-size: 15px;
  line-height: 27px;
}

.tt-blockquote:before {
  content: '';
  display: block;
  position: absolute;
  top: 35px;
  left: 27px;
  background-color: #dd3d53;
  width: 2px;
  height: calc(100% - 43px);
}

.tt-blockquote:after {
  content: '”';
  display: block;
  position: absolute;
  top: 0;
  left: 20px;
  font-size: 40px;
  line-height: 1.2;
  color: #dd3d53;
  font-style: normal;
}

.tt-blockquote p {
  font-style: italic;
  margin: 0;
  padding: 0;
}

@media (max-width: 1024px) {
  .tt-blockquote {
    padding: 0 0 0 30px;
  }
  .tt-blockquote:before {
    display: none;
  }
  .tt-blockquote:after {
    left: 0;
  }
}

@media (max-width: 575px) {
  .tt-blockquote {
    font-size: 14px;
    line-height: 20px;
  }
}

.tt-dl-box {
  font-size: 15px;
  margin-top: 27px;
}

.tt-dl-box dt {
  font-weight: bold;
  color: #222222;
}

.tt-dl-box dt:not(:first-child) {
  margin-top: 27px;
}

.tt-dl-box dt + dd {
  margin: 27px 0 0 0;
}

.tt-dl-box dd {
  padding: 0;
}

/*
	tt-single-post
*/
.tt-single-post p {
  margin: 27px 0 0 0;
  padding: 0;
}

.tt-single-post .tt-list-box {
  margin-top: 27px;
}

/*
	tt-layout-01-post
	- blog-masonry-col-3.html
*/
.isotop_showmore_js {
  margin-top: 55px;
}

.tt-layout-01-post {
  margin-top: -30px;
  position: relative;
  padding-bottom: 15px;
}

.tt-layout-01-post .tt-post {
  margin-top: 30px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0px 15px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 0px 15px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.05);
}

.tt-layout-01-post .tt-post .tt-post-content {
  padding: 0 30px 37px 30px;
}

@media (min-width: 576px) {
  .tt-layout-01-post .tt-img-link .tt-wrapper-text {
    font-size: 18px;
  }
  .tt-layout-01-post .tt-img-link .tt-wrapper-text i {
    font-size: 18px;
  }
}

.tt-layout-01-post .tt-blockquote {
  padding-left: 33px;
}

.tt-layout-01-post .tt-blockquote:before {
  left: 10px;
}

.tt-layout-01-post .tt-blockquote:after {
  left: 7px;
}

/* blog-masonry  */
.tt-blog-masonry .tt-blog-init {
  overflow: hidden;
}

.tt-blog-masonry .tt-blog-init.tt-show {
  -moz-opacity: 1;
  -khtml-opacity: 1;
  -webkit-opacity: 1;
  opacity: 1;
}

.tt-blog-masonry .grid:after {
  content: '';
  display: block;
  clear: both;
}

.tt-blog-masonry .element-item {
  position: relative;
  float: left;
}

.tt-blog-masonry .tt-grid-col-3 .element-item {
  width: 33.33%;
  padding: 0 10px;
}

@media (max-width: 1024px) {
  .tt-blog-masonry .tt-grid-col-3 .element-item {
    width: 50%;
  }
}

@media (max-width: 789px) {
  .tt-blog-masonry .tt-grid-col-3 .element-item {
    width: 100%;
  }
}

@media (max-width: 575px) {
  .tt-blog-masonry .tt-grid-col-3 .element-item {
    padding: 0;
  }
}

.tt-title-sub {
  font-size: 24px;
  line-height: 1.3;
  font-weight: 600;
  color: #222222;
  font-family: "Montserrat", sans-serif;
  padding: 0 0 25px 0;
  margin: 0;
}

/* comments (single post)*/
.tt-comments-layout .tt-item div[class^="tt-comments-level-"] {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.tt-comments-layout .tt-item div[class^="tt-comments-level-"] .tt-avatar {
  width: 64px;
  min-width: 64px;
  height: 64px;
  position: relative;
  margin-right: 16px;
  margin-top: -6px;
}

.tt-comments-layout .tt-item div[class^="tt-comments-level-"] .tt-avatar img {
  position: relative;
  z-index: 2;
  max-width: 100%;
  height: auto;
}

.tt-comments-layout .tt-item div[class^="tt-comments-level-"] .tt-avatar:empty:before {
  font-family: "carleader";
  content: "\e91c";
  font-size: 25px;
  line-height: 1.2px;
  top: 32px;
  display: block;
  text-align: center;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  color: #ffffff;
}

.tt-comments-layout .tt-item div[class^="tt-comments-level-"] .tt-avatar:empty:after {
  content: '';
  background: #dd3d53;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  min-width: 59px;
  display: block;
  top: 0;
  left: 0;
  z-index: 0;
}

.tt-comments-layout .tt-item div[class^="tt-comments-level-"] .tt-content {
  margin-top: -12px;
  font-size: 15px;
}

.tt-comments-layout .tt-item div[class^="tt-comments-level-"] .tt-content .tt-comments-title {
  color: #707070;
}

.tt-comments-layout .tt-item div[class^="tt-comments-level-"] .tt-content .tt-comments-title .username {
  color: #dd3d53;
}

.tt-comments-layout .tt-item div[class^="tt-comments-level-"] .tt-content p {
  margin-top: 7px;
}

.tt-comments-layout .tt-item:not(:last-child) {
  padding-bottom: 18px;
  border-bottom: 1px solid #dfdfdf;
}

.tt-comments-layout .tt-item {
  margin-top: 24px;
}

.tt-comments-layout .tt-comments-level-2 {
  margin-left: 81px;
  margin-top: 14px;
}

@media (max-width: 575px) {
  .tt-comments-layout .tt-item div[class^="tt-comments-level-"] {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .tt-comments-layout .tt-item div[class^="tt-comments-level-"] .tt-content {
    margin-top: 10px;
  }
  .tt-comments-layout .tt-item:not(:first-child) {
    margin-top: 10px;
  }
  .tt-comments-layout .tt-item:not(:last-child) {
    padding-bottom: 9px;
  }
  .tt-comments-layout .tt-comments-level-2 {
    margin-top: 2px;
    margin-left: 59px;
  }
}

.tt-comments-layout + * {
  margin-top: 30px;
}

.tt-title-sub + .tt-form-review {
  margin-top: 19px;
}

/*---------------------------------------*/
/*--------      11. Footer        --------*/
/*---------------------------------------*/
#tt-footer:not([class^="tt-footer"]) {
  /*
			Footer layout
		*/
  /* tt-footer-layout */
  /* tt-footer-copyright */
  /*
			Footer objects
		*/
  /*Footer menu*/
  /*logo*/
  /*social-icon */
  /*box info*/
  /*list info*/
  /*newsletterform*/
}

@media (max-width: 767px) {
  #tt-footer:not([class^="tt-footer"]) {
    margin-top: 60px;
  }
}

#tt-footer:not([class^="tt-footer"]) .tt-footer-layout {
  background-color: #1a1a21;
  padding-top: 31px;
  padding-bottom: 40px;
}

@media (max-width: 610px) {
  #tt-footer:not([class^="tt-footer"]) .tt-footer-layout {
    padding-top: 26px;
    padding-bottom: 39px;
  }
}
.tt-footer-powered{
  font-size: 12px;
  text-align: right;
  color: #eee;
  text-decoration: none;
  margin-top: 20px;
  a{
    &:hover{
      color: #fff;
    }
  }
}
#tt-footer:not([class^="tt-footer"]) .tt-footer-copyright {
  background-color: #dd3d53;
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  text-align: center;
}

#tt-footer:not([class^="tt-footer"]) .tt-footer-copyright a {
  color: #dd3d53;
  margin-left: 8px;
}

#tt-footer:not([class^="tt-footer"]) .tt-footer-copyright a:hover {
  color: #dd3d53;
  text-decoration: underline;
}

@media (min-width: 1025px) {
  #tt-footer:not([class^="tt-footer"]) .tt-footer-copyright {
    padding: 20px 15px 15px;
    font-size: 12px;
  }
}

@media (max-width: 1024px) and (min-width: 576px) {
  #tt-footer:not([class^="tt-footer"]) .tt-footer-copyright {
    padding: 10px 15px 9px;
    font-size: 11px;
  }
}

@media (max-width: 575px) {
  #tt-footer:not([class^="tt-footer"]) .tt-footer-copyright {
    padding: 20px 15px 15px;
    font-size: 11px;
  }
}

@media (max-width: 350px) {
  #tt-footer:not([class^="tt-footer"]) .tt-footer-copyright span {
    display: block;
    text-align: center;
  }
}

#tt-footer:not([class^="tt-footer"]) .tt-row-custom {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

#tt-footer:not([class^="tt-footer"]) .tt-row-custom .tt-col {
  margin-top: 26px;
}

@media (min-width: 1230px) {
  #tt-footer:not([class^="tt-footer"]) .tt-row-custom .tt-col:not(:first-child):not(:last-child) {
    margin-left: 25px;
  }
}

#tt-footer:not([class^="tt-footer"]) .tt-row-custom .tt-col:first-child {
  min-width: 240px;
  padding-right: 10px;
}

#tt-footer:not([class^="tt-footer"]) .tt-row-custom .tt-col:last-child {
  min-width: 270px;
  padding-left: 10px;
}

#tt-footer:not([class^="tt-footer"]) .tt-row-custom .tt-list-info li {
  vertical-align: top;
}

@media (max-width: 1229px) and (min-width: 611px) {
  #tt-footer:not([class^="tt-footer"]) .tt-row-custom {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  #tt-footer:not([class^="tt-footer"]) .tt-row-custom .tt-col:not(:first-child) {
    width: 33%;
  }
  // #tt-footer:not([class^="tt-footer"]) .tt-row-custom .tt-col:first-child {
  //   width: 100%;
  // }
  #tt-footer:not([class^="tt-footer"]) .tt-row-custom .tt-col:first-child .tt-list-info li {
    margin: 0;
    width: 33%;
    display: inline-block;
  }
  #tt-footer:not([class^="tt-footer"]) .tt-row-custom .tt-col:last-child {
    padding-left: 0px;
  }
  #tt-footer:not([class^="tt-footer"]) .tt-row-custom .tt-col:last-child .tt-box-info .tt-item i.icon-mail1 {
    margin-left: -6px;
  }
}

@media (max-width: 1024px) {
  #tt-footer:not([class^="tt-footer"]) .tt-row-custom .tt-col:first-child {
    padding-right: 0;
    min-width: inherit;
  }
  #tt-footer:not([class^="tt-footer"]) .tt-row-custom .tt-col:first-child .tt-list-info li:last-child {
    width: 32%;
  }
  #tt-footer:not([class^="tt-footer"]) .tt-row-custom .tt-box-info li {
    font-size: 11px;
  }
  #tt-footer:not([class^="tt-footer"]) .tt-row-custom .tt-box-info .tt-ttile {
    font-size: 11px;
  }
  #tt-footer:not([class^="tt-footer"]) .tt-row-custom .tt-box-info .tt-ttile i[class^="icon-"] {
    top: 4px;
  }
  #tt-footer:not([class^="tt-footer"]) .tt-row-custom .tt-col:not(:first-child) {
    min-width: inherit;
  }
}

@media (max-width: 610px) {
  #tt-footer-menu{
    display: none;
  }
  #tt-footer:not([class^="tt-footer"]) .tt-row-custom {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }
  #tt-footer:not([class^="tt-footer"]) .tt-row-custom .tt-col {
    width: 100%;
    margin-top: 0;
    margin-bottom: 10px;
    &:first-child{
      order: 1;
    }
    &:nth-child(2){
      order: 0;
    }
    &:nth-child(3){
      order: 2;
      padding-left: 0;
    }
  }
  #tt-footer:not([class^="tt-footer"]) .tt-row-custom .tt-col .tt-list-info li {
    width: 100%;
    display: block;
  }
  #tt-footer:not([class^="tt-footer"]) .tt-row-custom .tt-col:first-child {
    padding-right: 0;
    min-width: inherit;
    order: 1;
  }
  #tt-footer:not([class^="tt-footer"]) .tt-row-custom .tt-box-info {
    // max-width: 270px;
  }
  #tt-footer:not([class^="tt-footer"]) .tt-row-custom .tt-list-info li {
    max-width: 240px;
  }
}

#tt-footer:not([class^="tt-footer"]) #tt-footer-menu ul {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  font-weight: 700;
}

#tt-footer:not([class^="tt-footer"]) #tt-footer-menu ul li a {
  color: #ffffff;
  display: inline-block;
  padding: 5px 20px;
  font-size: 14px;
  line-height: 1.2;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

#tt-footer:not([class^="tt-footer"]) #tt-footer-menu ul li a:hover {
  color: #dd3d53;
}

#tt-footer:not([class^="tt-footer"]) #tt-footer-menu ul li.is-active a {
  color: #dd3d53;
}

#tt-footer:not([class^="tt-footer"]) #tt-footer-menu ul ul {
  display: none;
}

@media (max-width: 1024px) {
  #tt-footer:not([class^="tt-footer"]) #tt-footer-menu ul li a {
    font-size: 13px;
  }
}

@media (max-width: 768px) {
  #tt-footer:not([class^="tt-footer"]) #tt-footer-menu ul li a {
    font-size: 13px;
    padding-left: 8px;
    padding-right: 8px;
  }
}

@media (max-width: 610px) {
  #tt-footer:not([class^="tt-footer"]) #tt-footer-menu ul {
    display: block;
  }
  #tt-footer:not([class^="tt-footer"]) #tt-footer-menu ul li {
    display: block;
    text-align: center;
  }
  #tt-footer:not([class^="tt-footer"]) #tt-footer-menu ul li a {
    display: block;
    text-align: center;
    padding: 6px 20px;
    font-size: 14px;
  }
}

#tt-footer:not([class^="tt-footer"]) .tt-logo {
  text-align: center;
  display: inline-block;
  
  margin-top: 32px;
  padding-bottom: 6px;
  line-height: 0;
}

#tt-footer:not([class^="tt-footer"]) .tt-logo .svg-img {
  max-width: 100%;
  width: 300px;
}

@media (max-width: 610px) {
  #tt-footer:not([class^="tt-footer"]) .tt-logo {
    // margin-top: 39px;
    padding-bottom: 6px;
    width: 300px;
  }
  #tt-footer:not([class^="tt-footer"]) .tt-logo .svg-img {
    // height: 40px;
  }
}

#tt-footer:not([class^="tt-footer"]) .tt-logo .st0 {
  fill: #FFFFFF;
}

#tt-footer:not([class^="tt-footer"]) .tt-logo .st1 {
  display: none;
}

#tt-footer:not([class^="tt-footer"]) .tt-logo .st2 {
  display: inline;
  fill: #222222;
}

#tt-footer:not([class^="tt-footer"]) .tt-logo .st3 {
  display: inline;
  fill: #222222;
}

#tt-footer:not([class^="tt-footer"]) .tt-logo .st4 {
  fill: #dd3d53;
}

#tt-footer:not([class^="tt-footer"]) .tt-social-icon {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 35px 0 0 0;
}

#tt-footer:not([class^="tt-footer"]) .tt-social-icon li a {
  width: 43px;
  height: 43px;
  font-size: 18px;
  line-height: 2.5;
  text-align: center;
  display: inline-block;
  color: #ffffff;
  background-color: #dd3d53;
  transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

#tt-footer:not([class^="tt-footer"]) .tt-social-icon li a:hover {
  color: #dd3d53;
  background-color: #ffffff;
}

#tt-footer:not([class^="tt-footer"]) .tt-social-icon li:not(:first-child):not(:last-child) {
  margin-right: 4px;
  margin-left: 4px;
}

#tt-footer:not([class^="tt-footer"]) .tt-social-icon li:not(:last-child):not(:first-child) {
  margin: 0 5px;
}

@media (max-width: 1024px) {
  #tt-footer:not([class^="tt-footer"]) .tt-social-icon li a {
    width: 35px;
    height: 35px;
    font-size: 15px;
    line-height: 2.4;
  }
}

#tt-footer:not([class^="tt-footer"]) .tt-box-info .tt-item {
  text-transform: uppercase;
  position: relative;
  font-size: 12px;
  line-height: 1.6;
  padding: 0 0 0 28px;
  width: 100%;
  font-size: 12px;
  color: #ffffff;
}

#tt-footer:not([class^="tt-footer"]) .tt-box-info .tt-item i[class^="icon-"] {
  font-size: 18px;
  line-height: 1.1;
  color: #dd3d53;
  position: absolute;
  left: 0;
  top: 4px;
}

#tt-footer:not([class^="tt-footer"]) .tt-box-info .tt-item i.icon-mail1 {
  top: 4px;
  margin-left: -4px;
}

#tt-footer:not([class^="tt-footer"]) .tt-box-info .tt-item a:not([class]) {
  color: #ffffff;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

#tt-footer:not([class^="tt-footer"]) .tt-box-info .tt-item a:not([class]):hover {
  color: #dd3d53;
}

#tt-footer:not([class^="tt-footer"]) .tt-box-info .tt-item:not(:first-child) {
  margin-top: 15px;
}

#tt-footer:not([class^="tt-footer"]) .tt-box-info .tt-ttile {
  font-size: 14px;
  line-height: 28px;
  font-weight: 700;
  color: #ffffff;
  padding: 0 0 4px 0;
  margin: 0;
  font-family: "Open Sans", sans-serif;
}

#tt-footer:not([class^="tt-footer"]) .tt-list-info {
  margin-top: 4px;
}

#tt-footer:not([class^="tt-footer"]) .tt-list-info li {
  text-transform: uppercase;
  position: relative;
  font-size: 12px;
  line-height: 1.6;
  padding: 0 0 0 26px;
  width: 100%;
  color: #ffffff;
}

#tt-footer:not([class^="tt-footer"]) .tt-list-info li i[class^="icon-"] {
  font-size: 18px;
  line-height: 1.1;
  color: #dd3d53;
  position: absolute;
  left: 0;
  top: 0px;
}

#tt-footer:not([class^="tt-footer"]) .tt-list-info li a:not([class]) {
  color: #ffffff;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

#tt-footer:not([class^="tt-footer"]) .tt-list-info li a:not([class]):hover {
  color: #dd3d53;
}

#tt-footer:not([class^="tt-footer"]) .tt-list-info li:not(:first-child) {
  margin-top: 11px;
}

#tt-footer:not([class^="tt-footer"]) #newsletterform {
  position: relative;
  margin-top: 1px;
}

#tt-footer:not([class^="tt-footer"]) #newsletterform .form-control {
  border: none;
  background: transparent;
  font-size: 12px;
  line-height: 1.3;
  border-bottom: 1px solid #4d4d53;
  font-family: "Open Sans", sans-serif;
  outline: none;
  padding: 5px 5px 13px 0;
  box-shadow: none;
  width: 100%;
}

#tt-footer:not([class^="tt-footer"]) #newsletterform .form-control:focus {
  outline: none;
}

#tt-footer:not([class^="tt-footer"]) #newsletterform .form-control:active {
  outline: none;
}

#tt-footer:not([class^="tt-footer"]) #newsletterform .form-control:focus {
  border-bottom-color: #dd3d53;
}

#tt-footer:not([class^="tt-footer"]) #newsletterform .form-control:not(focus)::-webkit-input-placeholder {
  color: #ffffff;
  text-transform: uppercase;
}

#tt-footer:not([class^="tt-footer"]) #newsletterform .form-control:not(focus)::-moz-placeholder {
  color: #ffffff;
  text-transform: uppercase;
}

#tt-footer:not([class^="tt-footer"]) #newsletterform .form-control:not(focus):-moz-placeholder {
  color: #ffffff;
  text-transform: uppercase;
}

#tt-footer:not([class^="tt-footer"]) #newsletterform .form-control:not(focus):-ms-input-placeholder {
  color: #ffffff;
  text-transform: uppercase;
}

#tt-footer:not([class^="tt-footer"]) #newsletterform .form-control:focus::-webkit-input-placeholder {
  color: transparent;
}

#tt-footer:not([class^="tt-footer"]) #newsletterform .form-control:focus::-moz-placeholder {
  color: transparent;
}

#tt-footer:not([class^="tt-footer"]) #newsletterform .form-control:focus:-moz-placeholder {
  color: transparent;
}

#tt-footer:not([class^="tt-footer"]) #newsletterform .form-control:focus:-ms-input-placeholder {
  color: transparent;
}

#tt-footer:not([class^="tt-footer"]) #newsletterform .form-group {
  margin-bottom: 10px;
}

#tt-footer:not([class^="tt-footer"]) #newsletterform .tt-btn {
  background: none;
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
  color: #dd3d53;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  font-size: 11px;
  line-height: 1.1;
  position: relative;
}

#tt-footer:not([class^="tt-footer"]) #newsletterform .tt-btn:hover {
  color: #ffffff;
}

#tt-footer:not([class^="tt-footer"]) #newsletterform .tt-btn:after {
  position: absolute;
  right: -15px;
  top: 2px;
  font-family: "carleader";
  content: "\e92b";
  font-size: 8px;
  line-height: 1.1;
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}

#tt-footer:not([class^="tt-footer"]) #newsletterform label.error {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  text-align: center;
  top: 56px;
  left: 0;
  padding: 8px 19px 8px 43px;
  background: #f8353e;
  color: #ffffff;
  font-size: 14px;
  line-height: 20px;
  z-index: 1;
}

#tt-footer:not([class^="tt-footer"]) #newsletterform label.error:after {
  content: '';
  position: absolute;
  top: -12px;
  left: 50%;
  border: 6px solid transparent;
  border-bottom: 6px solid #f8353e;
}

#tt-footer.tt-footer-02 {
  /*
			Footer objects
		*/
  /*Footer menu*/
  /*logo*/
  /*tt-logo-description*/
  /*social-icon */
  /*box info*/
  /*list info*/
  /*newsletterform*/
}

@media (max-width: 767px) {
  #tt-footer.tt-footer-02 {
    margin-top: 60px;
  }
}

#tt-footer.tt-footer-02 .tt-footer-layout {
  background-color: #1a1a21;
  padding-top: 47px;
  padding-bottom: 61px;
}

@media (max-width: 610px) {
  #tt-footer.tt-footer-02 .tt-footer-layout {
    padding-top: 26px;
    padding-bottom: 39px;
  }
}

#tt-footer.tt-footer-02 .tt-footer-bottom {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #ffffff;
  color: #222222;
  font-size: 12px;
  line-height: 1.4;
  text-transform: uppercase;
  padding: 24px 0 23px 0;
}

#tt-footer.tt-footer-02 .tt-footer-bottom a {
  color: #222222;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

#tt-footer.tt-footer-02 .tt-footer-bottom a:hover {
  color: #dd3d53;
}

#tt-footer.tt-footer-02 .tt-footer-bottom .tt-col-left {
  padding-right: 15px;
}

#tt-footer.tt-footer-02 .tt-footer-bottom .tt-col-right {
  text-align: right;
  padding-left: 15px;
}

#tt-footer.tt-footer-02 .tt-footer-bottom .tt-list {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  list-style: none;
  margin: 0;
  padding: 0;
}

#tt-footer.tt-footer-02 .tt-footer-bottom .tt-list li {
  position: relative;
}

#tt-footer.tt-footer-02 .tt-footer-bottom .tt-list li a {
  display: inline-block;
  padding: 0 10px;
}

#tt-footer.tt-footer-02 .tt-footer-bottom .tt-list li:not(:last-child):before {
  content: '|';
  position: absolute;
  display: block;
  right: -2px;
  top: -1px;
  color: #222222;
}

#tt-footer.tt-footer-02 .tt-footer-bottom .tt-list li:last-child a {
  padding-right: 0;
}

#tt-footer.tt-footer-02 #tt-footer-menu {
  padding-bottom: 30px;
}

#tt-footer.tt-footer-02 #tt-footer-menu ul {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  font-weight: 700;
}

#tt-footer.tt-footer-02 #tt-footer-menu ul li a {
  color: #ffffff;
  display: inline-block;
  padding: 5px 18px;
  font-size: 14px;
  line-height: 1.2;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

#tt-footer.tt-footer-02 #tt-footer-menu ul li a:hover {
  color: #dd3d53;
}

#tt-footer.tt-footer-02 #tt-footer-menu ul li.is-active a {
  color: #dd3d53;
}

#tt-footer.tt-footer-02 #tt-footer-menu ul li:first-child a {
  padding-left: 0;
}

#tt-footer.tt-footer-02 #tt-footer-menu ul li:last-child a {
  padding-right: 0;
}

#tt-footer.tt-footer-02 #tt-footer-menu ul ul {
  display: none;
}

@media (max-width: 1024px) {
  #tt-footer.tt-footer-02 #tt-footer-menu ul li a {
    font-size: 13px;
  }
}

@media (max-width: 768px) {
  #tt-footer.tt-footer-02 #tt-footer-menu ul li a {
    font-size: 13px;
    padding-left: 8px;
    padding-right: 8px;
  }
}

@media (max-width: 610px) {
  #tt-footer.tt-footer-02 #tt-footer-menu ul {
    display: block;
  }
  #tt-footer.tt-footer-02 #tt-footer-menu ul li {
    display: block;
    text-align: center;
  }
  #tt-footer.tt-footer-02 #tt-footer-menu ul li a {
    display: block;
    text-align: center;
    padding: 6px 20px;
    font-size: 14px;
  }
}

#tt-footer.tt-footer-02 .tt-logo {
  display: inline-block;
}

#tt-footer.tt-footer-02 .tt-logo img:not(.svg-img) {
  max-width: 100%;
  height: auto;
}

#tt-footer.tt-footer-02 .tt-logo + .tt-logo-description {
  margin-top: 23px;
}

#tt-footer.tt-footer-02 .tt-logo-description {
  max-width: 275px;
  color: #ffffff;
  font-size: 15px;
  line-height: 22px;
}

#tt-footer.tt-footer-02 .tt-social-icon {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 94px 0 0 0;
}

#tt-footer.tt-footer-02 .tt-social-icon li a {
  width: 43px;
  height: 43px;
  font-size: 18px;
  line-height: 2.5;
  text-align: center;
  display: inline-block;
  color: #ffffff;
  background-color: #dd3d53;
  transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

#tt-footer.tt-footer-02 .tt-social-icon li a:hover {
  color: #dd3d53;
  background-color: #ffffff;
}

#tt-footer.tt-footer-02 .tt-social-icon li:not(:first-child):not(:last-child) {
  margin-right: 4px;
  margin-left: 4px;
}

#tt-footer.tt-footer-02 .tt-social-icon li:not(:last-child):not(:first-child) {
  margin: 0 5px;
}

@media (max-width: 1024px) {
  #tt-footer.tt-footer-02 .tt-social-icon li a {
    width: 35px;
    height: 35px;
    font-size: 15px;
    line-height: 2.4;
  }
}

#tt-footer.tt-footer-02 .tt-box-info .tt-item {
  text-transform: uppercase;
  position: relative;
  font-size: 12px;
  line-height: 1.6;
  padding: 0 0 0 28px;
  width: 100%;
  font-size: 12px;
  color: #ffffff;
}

#tt-footer.tt-footer-02 .tt-box-info .tt-item i[class^="icon-"] {
  font-size: 18px;
  line-height: 1.1;
  color: #dd3d53;
  position: absolute;
  left: 0;
  top: 3px;
}

#tt-footer.tt-footer-02 .tt-box-info .tt-item i.icon-mail1 {
  top: 4px;
  margin-left: -4px;
}

#tt-footer.tt-footer-02 .tt-box-info .tt-item a:not([class]) {
  color: #ffffff;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

#tt-footer.tt-footer-02 .tt-box-info .tt-item a:not([class]):hover {
  color: #dd3d53;
}

#tt-footer.tt-footer-02 .tt-box-info .tt-item:not(:first-child) {
  margin-top: 15px;
}

#tt-footer.tt-footer-02 .tt-box-info .tt-ttile {
  font-size: 14px;
  line-height: 28px;
  font-weight: 700;
  color: #ffffff;
  padding: 0 0 4px 0;
  margin: 0;
  font-family: "Open Sans", sans-serif;
}

#tt-footer.tt-footer-02 .tt-box-info02 {
  margin-top: 4px;
}

#tt-footer.tt-footer-02 .tt-box-info02 .tt-item {
  text-transform: uppercase;
  position: relative;
  font-size: 12px;
  line-height: 1.6;
  padding: 0 0 0 26px;
  width: 100%;
  color: #ffffff;
}

#tt-footer.tt-footer-02 .tt-box-info02 .tt-item i[class^="icon-"] {
  font-size: 18px;
  line-height: 1.1;
  color: #dd3d53;
  position: absolute;
  left: 0;
  top: 0px;
}

#tt-footer.tt-footer-02 .tt-box-info02 .tt-item a:not([class]) {
  color: #ffffff;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

#tt-footer.tt-footer-02 .tt-box-info02 .tt-item a:not([class]):hover {
  color: #dd3d53;
}

#tt-footer.tt-footer-02 .tt-box-info02 .tt-item:not(:first-child) {
  margin-top: 11px;
}

#tt-footer.tt-footer-02 #newsletterform {
  position: relative;
  margin-top: 1px;
}

#tt-footer.tt-footer-02 #newsletterform .form-control {
  border: none;
  background: transparent;
  font-size: 12px;
  line-height: 1.3;
  border-bottom: 1px solid #4d4d53;
  font-family: "Open Sans", sans-serif;
  outline: none;
  padding: 5px 5px 13px 0;
  box-shadow: none;
  width: 100%;
}

#tt-footer.tt-footer-02 #newsletterform .form-control:focus {
  outline: none;
}

#tt-footer.tt-footer-02 #newsletterform .form-control:active {
  outline: none;
}

#tt-footer.tt-footer-02 #newsletterform .form-control:focus {
  border-bottom-color: #dd3d53;
}

#tt-footer.tt-footer-02 #newsletterform .form-control:not(focus)::-webkit-input-placeholder {
  color: #ffffff;
  text-transform: uppercase;
}

#tt-footer.tt-footer-02 #newsletterform .form-control:not(focus)::-moz-placeholder {
  color: #ffffff;
  text-transform: uppercase;
}

#tt-footer.tt-footer-02 #newsletterform .form-control:not(focus):-moz-placeholder {
  color: #ffffff;
  text-transform: uppercase;
}

#tt-footer.tt-footer-02 #newsletterform .form-control:not(focus):-ms-input-placeholder {
  color: #ffffff;
  text-transform: uppercase;
}

#tt-footer.tt-footer-02 #newsletterform .form-control:focus::-webkit-input-placeholder {
  color: transparent;
}

#tt-footer.tt-footer-02 #newsletterform .form-control:focus::-moz-placeholder {
  color: transparent;
}

#tt-footer.tt-footer-02 #newsletterform .form-control:focus:-moz-placeholder {
  color: transparent;
}

#tt-footer.tt-footer-02 #newsletterform .form-control:focus:-ms-input-placeholder {
  color: transparent;
}

#tt-footer.tt-footer-02 #newsletterform .form-group {
  margin-bottom: 10px;
}

#tt-footer.tt-footer-02 #newsletterform .tt-btn {
  background: none;
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
  color: #dd3d53;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  font-size: 11px;
  line-height: 1.1;
  position: relative;
}

#tt-footer.tt-footer-02 #newsletterform .tt-btn:hover {
  color: #ffffff;
}

#tt-footer.tt-footer-02 #newsletterform .tt-btn:after {
  position: absolute;
  right: -15px;
  top: 2px;
  font-family: "carleader";
  content: "\e92b";
  font-size: 8px;
  line-height: 1.1;
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}

#tt-footer.tt-footer-02 #newsletterform label.error {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  text-align: center;
  top: 56px;
  left: 0;
  padding: 8px 19px 8px 43px;
  background: #f8353e;
  color: #ffffff;
  font-size: 14px;
  line-height: 20px;
  z-index: 1;
}

#tt-footer.tt-footer-02 #newsletterform label.error:after {
  content: '';
  position: absolute;
  top: -12px;
  left: 50%;
  border: 6px solid transparent;
  border-bottom: 6px solid #f8353e;
}

#tt-footer.tt-footer-02 .row-info *[class^="col-"] {
  margin-top: 36px;
}

@media (max-width: 1229px) {
  #tt-footer.tt-footer-02 #tt-footer-menu ul li a {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 1024px) {
  #tt-footer.tt-footer-02 #tt-footer-menu {
    padding-bottom: 0;
  }
  #tt-footer.tt-footer-02 #tt-footer-menu ul li a {
    padding-left: 6px;
    padding-right: 6px;
  }
  #tt-footer.tt-footer-02 .tt-logo-description {
    font-size: 14px;
    line-height: 20px;
  }
  #tt-footer.tt-footer-02 .tt-social-icon {
    margin-top: 54px;
  }
}

@media (max-width: 766px) {
  #tt-footer.tt-footer-02 {
    padding-top: 26px;
    padding-bottom: 0px;
  }
  #tt-footer.tt-footer-02 .tt-box-info {
    max-width: 270px;
  }
  #tt-footer.tt-footer-02 .tt-logo-description {
    display: none;
  }
  #tt-footer.tt-footer-02 .tt-logo {
    padding-bottom: 6px;
    width: 154px;
  }
  #tt-footer.tt-footer-02 #tt-footer:not([class^="tt-footer"]) #tt-footer-menu ul li {
    display: block;
    text-align: center;
  }
  #tt-footer.tt-footer-02 #tt-footer-menu ul {
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  #tt-footer.tt-footer-02 .tt-footer-layout .row .row-info *[class^="col-"] {
    margin-top: 26px;
  }
  #tt-footer.tt-footer-02 .tt-footer-layout .row .col-12:first-child {
    text-align: center;
  }
  #tt-footer.tt-footer-02 .tt-social-icon {
    margin-top: 35px;
  }
  #tt-footer.tt-footer-02 .tt-footer-bottom {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  #tt-footer.tt-footer-02 .tt-footer-bottom .tt-col-left,
  #tt-footer.tt-footer-02 .tt-footer-bottom .tt-col-right {
    padding-left: 15px;
    padding-right: 15px;
  }
  #tt-footer.tt-footer-02 .tt-footer-bottom .tt-col-right {
    padding-top: 3px;
  }
}

#tt-footer.tt-footer-03 {
  /*
			Footer layout
		*/
  /* tt-footer-layout */
  /* tt-footer-copyright */
  /*
			Footer objects
		*/
  /*Footer menu*/
  /*logo*/
  /*social-icon */
  /*box info*/
  /*list info*/
  /* subscribe-custom */
}

@media (max-width: 767px) {
  #tt-footer.tt-footer-03 {
    margin-top: 60px;
  }
}

#tt-footer.tt-footer-03 .tt-footer-layout {
  background-color: #ffffff;
  padding-top: 31px;
  padding-bottom: 40px;
}

@media (max-width: 610px) {
  #tt-footer.tt-footer-03 .tt-footer-layout {
    padding-top: 26px;
    padding-bottom: 39px;
  }
}

#tt-footer.tt-footer-03 .tt-footer-copyright {
  background-color: #dd3d53;
  text-transform: uppercase;
  font-size: 12px;
  text-align: center;
  color: #ffffff;
}

#tt-footer.tt-footer-03 .tt-footer-copyright a {
  color: #dd3d53;
  margin-left: 8px;
}

#tt-footer.tt-footer-03 .tt-footer-copyright a:hover {
  color: #dd3d53;
  text-decoration: underline;
}

@media (min-width: 1025px) {
  #tt-footer.tt-footer-03 .tt-footer-copyright {
    padding: 20px 15px 15px;
    font-size: 12px;
  }
}

@media (max-width: 1024px) and (min-width: 576px) {
  #tt-footer.tt-footer-03 .tt-footer-copyright {
    padding: 10px 15px 9px;
    font-size: 11px;
  }
}

@media (max-width: 575px) {
  #tt-footer.tt-footer-03 .tt-footer-copyright {
    padding: 20px 15px 15px;
    font-size: 11px;
  }
}

@media (max-width: 350px) {
  #tt-footer.tt-footer-03 .tt-footer-copyright span {
    display: block;
    text-align: center;
  }
}

#tt-footer.tt-footer-03 .tt-row-custom {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

#tt-footer.tt-footer-03 .tt-row-custom .tt-col {
  margin-top: 26px;
}

@media (min-width: 1230px) {
  #tt-footer.tt-footer-03 .tt-row-custom .tt-col:not(:first-child) {
    margin-left: 65px;
  }
}

@media (max-width: 1229px) {
  #tt-footer.tt-footer-03 .tt-row-custom .tt-col {
    max-width: 220px;
  }
  #tt-footer.tt-footer-03 .tt-row-custom .tt-col:not(:first-child) {
    margin-left: 45px;
  }
}

@media (max-width: 610px) {
  #tt-footer.tt-footer-03 .tt-row-custom .tt-col:not(:first-child) {
    margin-left: 0px;
    padding-left: 0px !important;
  }
}

#tt-footer.tt-footer-03 .tt-row-custom .tt-col:first-child {
  min-width: 240px;
  padding-right: 10px;
}

#tt-footer.tt-footer-03 .tt-row-custom .tt-col:last-child {
  min-width: 270px;
  padding-left: 10px;
}

#tt-footer.tt-footer-03 .tt-row-custom .tt-list-info li {
  vertical-align: top;
}

@media (max-width: 1024px) {
  #tt-footer.tt-footer-03 .tt-row-custom .tt-col:first-child {
    padding-right: 0;
    min-width: inherit;
  }
  #tt-footer.tt-footer-03 .tt-row-custom .tt-col:first-child .tt-list-info li:last-child {
    width: 32%;
  }
  #tt-footer.tt-footer-03 .tt-row-custom .tt-box-info li {
    font-size: 11px;
  }
  #tt-footer.tt-footer-03 .tt-row-custom .tt-box-info .tt-ttile {
    font-size: 11px;
    line-height: 18px;
  }
  #tt-footer.tt-footer-03 .tt-row-custom .tt-box-info .tt-ttile i[class^="icon-"] {
    top: 2px;
  }
  #tt-footer.tt-footer-03 .tt-row-custom .tt-col:not(:first-child) {
    min-width: inherit;
  }
}

@media (max-width: 610px) {
  #tt-footer.tt-footer-03 .tt-row-custom {
    display: block;
  }
  #tt-footer.tt-footer-03 .tt-row-custom .tt-col {
    width: 100%;
  }
  #tt-footer.tt-footer-03 .tt-row-custom .tt-col .tt-list-info li {
    width: 100%;
    display: block;
  }
  #tt-footer.tt-footer-03 .tt-row-custom .tt-col:first-child {
    padding-right: 0;
    min-width: inherit;
  }
  #tt-footer.tt-footer-03 .tt-row-custom .tt-box-info {
    max-width: 270px;
  }
  #tt-footer.tt-footer-03 .tt-row-custom .tt-list-info li {
    max-width: 240px;
  }
}

#tt-footer.tt-footer-03 #tt-footer-menu ul {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  font-weight: 700;
}

#tt-footer.tt-footer-03 #tt-footer-menu ul li a {
  color: #222222;
  display: inline-block;
  padding: 5px 20px;
  font-size: 14px;
  line-height: 1.2;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

#tt-footer.tt-footer-03 #tt-footer-menu ul li a:hover {
  color: #dd3d53;
}

#tt-footer.tt-footer-03 #tt-footer-menu ul li.is-active a {
  color: #dd3d53;
}

#tt-footer.tt-footer-03 #tt-footer-menu ul ul {
  display: none;
}

@media (max-width: 1024px) {
  #tt-footer.tt-footer-03 #tt-footer-menu ul li a {
    font-size: 13px;
  }
}

@media (max-width: 768px) {
  #tt-footer.tt-footer-03 #tt-footer-menu ul li a {
    font-size: 13px;
    padding-left: 8px;
    padding-right: 8px;
  }
}

@media (max-width: 610px) {
  #tt-footer.tt-footer-03 #tt-footer-menu ul {
    display: block;
  }
  #tt-footer.tt-footer-03 #tt-footer-menu ul li {
    display: block;
    text-align: center;
  }
  #tt-footer.tt-footer-03 #tt-footer-menu ul li a {
    display: block;
    text-align: center;
    padding: 6px 20px;
    font-size: 14px;
  }
}

#tt-footer.tt-footer-03 .tt-logo {
  text-align: center;
  display: inline-block;
  width: 215px;
  margin-top: 32px;
  padding-bottom: 6px;
}

@media (max-width: 610px) {
  #tt-footer.tt-footer-03 .tt-logo {
    margin-top: 39px;
    padding-bottom: 6px;
    width: 154px;
  }
}

#tt-footer.tt-footer-03 .tt-social-icon {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 35px 0 0 0;
}

#tt-footer.tt-footer-03 .tt-social-icon li a {
  width: 43px;
  height: 43px;
  font-size: 18px;
  line-height: 2.5;
  text-align: center;
  display: inline-block;
  color: #ffffff;
  background-color: #dd3d53;
  transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

#tt-footer.tt-footer-03 .tt-social-icon li a:hover {
  color: #dd3d53;
  background-color: #ffffff;
}

#tt-footer.tt-footer-03 .tt-social-icon li:not(:first-child):not(:last-child) {
  margin-right: 4px;
  margin-left: 4px;
}

#tt-footer.tt-footer-03 .tt-social-icon li:not(:last-child):not(:first-child) {
  margin: 0 5px;
}

@media (max-width: 1024px) {
  #tt-footer.tt-footer-03 .tt-social-icon li a {
    width: 35px;
    height: 35px;
    font-size: 15px;
    line-height: 2.4;
  }
}

#tt-footer.tt-footer-03 .tt-box-info .tt-item {
  text-transform: uppercase;
  position: relative;
  font-size: 12px;
  line-height: 1.6;
  padding: 0 0 0 28px;
  width: 100%;
  font-size: 12px;
  color: #222222;
}

#tt-footer.tt-footer-03 .tt-box-info .tt-item i[class^="icon-"] {
  font-size: 18px;
  line-height: 1.1;
  color: #dd3d53;
  position: absolute;
  left: 0;
  top: 4px;
}

#tt-footer.tt-footer-03 .tt-box-info .tt-item i.icon-mail1 {
  top: 4px;
  margin-left: -4px;
}

#tt-footer.tt-footer-03 .tt-box-info .tt-item a:not([class]) {
  color: #222222;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

#tt-footer.tt-footer-03 .tt-box-info .tt-item a:not([class]):hover {
  color: #dd3d53;
}

#tt-footer.tt-footer-03 .tt-box-info .tt-item:not(:first-child) {
  margin-top: 15px;
}

#tt-footer.tt-footer-03 .tt-box-info .tt-ttile {
  font-size: 14px;
  line-height: 28px;
  font-weight: 700;
  color: #222222;
  padding: 0 0 4px 0;
  margin: 0;
  font-family: "Open Sans", sans-serif;
}

#tt-footer.tt-footer-03 .tt-list-info {
  margin-top: 4px;
}

#tt-footer.tt-footer-03 .tt-list-info li {
  text-transform: uppercase;
  position: relative;
  font-size: 12px;
  line-height: 1.6;
  padding: 0 0 0 26px;
  width: 100%;
  color: #222222;
}

#tt-footer.tt-footer-03 .tt-list-info li i[class^="icon-"] {
  font-size: 18px;
  line-height: 1.1;
  color: #dd3d53;
  position: absolute;
  left: 0;
  top: 0px;
}

#tt-footer.tt-footer-03 .tt-list-info li a:not([class]) {
  color: #222222;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

#tt-footer.tt-footer-03 .tt-list-info li a:not([class]):hover {
  color: #dd3d53;
}

#tt-footer.tt-footer-03 .tt-list-info li:not(:first-child) {
  margin-top: 11px;
}

#tt-footer.tt-footer-03 .subscribe-custom {
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  padding: 29px 0 30px;
  width: 100%;
  /*newsletterform*/
}

#tt-footer.tt-footer-03 .subscribe-custom .tt-title {
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  color: #ffffff;
  padding: 0 0 15px 65px;
  margin: 0;
}

#tt-footer.tt-footer-03 .subscribe-custom .tt-title i {
  font-size: 37px;
  line-height: 1.2;
  position: absolute;
  left: 0;
  top: -3px;
  color: #dd3d53;
}

#tt-footer.tt-footer-03 .subscribe-custom #newsletterform {
  position: relative;
  margin-top: 1px;
}

#tt-footer.tt-footer-03 .subscribe-custom #newsletterform .form-control {
  border: none;
  background: transparent;
  font-size: 12px;
  line-height: 1.3;
  border-bottom: 1px solid #ffffff;
  font-family: "Open Sans", sans-serif;
  outline: none;
  color: #ffffff;
  text-transform: uppercase;
  padding: 5px 5px 8px 0;
  box-shadow: none;
  width: 100%;
}

#tt-footer.tt-footer-03 .subscribe-custom #newsletterform .form-control:focus {
  outline: none;
}

#tt-footer.tt-footer-03 .subscribe-custom #newsletterform .form-control:active {
  outline: none;
}

#tt-footer.tt-footer-03 .subscribe-custom #newsletterform .form-control:focus {
  border-bottom-color: #dd3d53;
}

#tt-footer.tt-footer-03 .subscribe-custom #newsletterform .form-control:not(focus)::-webkit-input-placeholder {
  color: #ffffff;
  text-transform: uppercase;
}

#tt-footer.tt-footer-03 .subscribe-custom #newsletterform .form-control:not(focus)::-moz-placeholder {
  color: #ffffff;
  text-transform: uppercase;
}

#tt-footer.tt-footer-03 .subscribe-custom #newsletterform .form-control:not(focus):-moz-placeholder {
  color: #ffffff;
  text-transform: uppercase;
}

#tt-footer.tt-footer-03 .subscribe-custom #newsletterform .form-control:not(focus):-ms-input-placeholder {
  color: #ffffff;
  text-transform: uppercase;
}

#tt-footer.tt-footer-03 .subscribe-custom #newsletterform .form-control:focus::-webkit-input-placeholder {
  color: transparent;
}

#tt-footer.tt-footer-03 .subscribe-custom #newsletterform .form-control:focus::-moz-placeholder {
  color: transparent;
}

#tt-footer.tt-footer-03 .subscribe-custom #newsletterform .form-control:focus:-moz-placeholder {
  color: transparent;
}

#tt-footer.tt-footer-03 .subscribe-custom #newsletterform .form-control:focus:-ms-input-placeholder {
  color: transparent;
}

#tt-footer.tt-footer-03 .subscribe-custom #newsletterform .form-group {
  margin-bottom: 10px;
}

#tt-footer.tt-footer-03 .subscribe-custom #newsletterform .tt-btn {
  background: none;
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
  color: #dd3d53;
  -moz-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  font-size: 11px;
  line-height: 1.1;
  position: relative;
}

#tt-footer.tt-footer-03 .subscribe-custom #newsletterform .tt-btn:hover {
  color: #ffffff;
}

#tt-footer.tt-footer-03 .subscribe-custom #newsletterform .tt-btn:after {
  position: absolute;
  right: -15px;
  top: 2px;
  font-family: "carleader";
  content: "\e92b";
  font-size: 8px;
  line-height: 1.1;
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}

#tt-footer.tt-footer-03 .subscribe-custom #newsletterform label.error {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  text-align: center;
  top: 56px;
  left: 0;
  padding: 8px 19px 8px 43px;
  background: #f8353e;
  color: #ffffff;
  font-size: 14px;
  line-height: 20px;
  z-index: 1;
}

#tt-footer.tt-footer-03 .subscribe-custom #newsletterform label.error:after {
  content: '';
  position: absolute;
  top: -12px;
  left: 50%;
  border: 6px solid transparent;
  border-bottom: 6px solid #f8353e;
}

@media (max-width: 1024px) {
  #tt-footer.tt-footer-03 .subscribe-custom .tt-title {
    font-size: 20px;
    line-height: 27px;
    padding: 0 0 0 55px;
  }
  #tt-footer.tt-footer-03 .subscribe-custom .tt-title i {
    font-size: 32px;
  }
}

@media (max-width: 766px) {
  #tt-footer.tt-footer-03 .subscribe-custom {
    padding: 19px 15px;
  }
  #tt-footer.tt-footer-03 .subscribe-custom .tt-title {
    font-size: 20px;
    line-height: 27px;
    padding: 0px 0 10px 45px;
  }
  #tt-footer.tt-footer-03 .subscribe-custom .tt-title i {
    font-size: 27px;
  }
  #tt-footer.tt-footer-03 .subscribe-custom #newsletterform .form-group {
    margin-bottom: 7px;
  }
}

@media (max-width: 575px) {
  #tt-footer.tt-footer-03 .subscribe-custom .tt-title {
    font-size: 18px;
    line-height: 27px;
    padding: 0px 0 10px 35px;
  }
  #tt-footer.tt-footer-03 .subscribe-custom .tt-title i {
    font-size: 25px;
  }
}

.tt-colorswatch {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  position: fixed;
  top: 279px;
  left: 0;
  z-index: 8;
  left: -270px;
}

.tt-colorswatch.tt-open-swatch {
  left: 0;
}

.tt-colorswatch .tt-colorswatch-btn {
  width: 58px;
  height: 54px;
  color: #fff;
  position: relative;
  background: #dd3d53;
  cursor: pointer;
  -webkit-border-top-right-radius: 31px;
  -webkit-border-bottom-right-radius: 31px;
  -moz-border-radius-topright: 31px;
  -moz-border-radius-bottomright: 31px;
  border-top-right-radius: 31px;
  border-bottom-right-radius: 31px;
  transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}

.tt-colorswatch .tt-colorswatch-btn img {
  max-width: 100%;
  height: auto;
}

.tt-colorswatch .tt-colorswatch-btn svg {
  width: 20px;
  height: 20px;
  fill: #ffffff;
  position: absolute;
  top: 17px;
  left: 14px;
}

.tt-colorswatch .tt-colorswatch-options {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 270px;
  padding: 0px 0px 21px;
  background: #ffffff;
  backface-visibility: hidden;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.05);
}

.tt-colorswatch .tt-colorswatch-options a {
  width: 59px;
  height: 59px;
  display: inline-block;
  margin-left: 21px;
  margin-top: 21px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  -moz-transition: background 0.2s linear;
  -o-transition: background 0.2s linear;
  -webkit-transition: background 0.2s linear;
  transition: background 0.2s linear;
}

.tt-colorswatch .tt-colorswatch-options a:before {
  content: "\e934";
  font-family: "carleader";
  font-size: 14px;
  line-height: 1.1;
  color: #fff;
  display: none;
  margin-top: 1px;
}

.tt-colorswatch .tt-colorswatch-options a:nth-child(1) {
  background: #dd3d53;
}

.tt-colorswatch .tt-colorswatch-options a:nth-child(1):hover {
  background: #c15968;
}

.tt-colorswatch .tt-colorswatch-options a:nth-child(2) {
  background: #ffc000;
}

.tt-colorswatch .tt-colorswatch-options a:nth-child(2):hover {
  background: #dfb020;
}

.tt-colorswatch .tt-colorswatch-options a:nth-child(3) {
  background: #f69323;
}

.tt-colorswatch .tt-colorswatch-options a:nth-child(3):hover {
  background: #d99140;
}

.tt-colorswatch .tt-colorswatch-options a:nth-child(4) {
  background: #4598ff;
}

.tt-colorswatch .tt-colorswatch-options a:nth-child(4):hover {
  background: #5c9be8;
}

.tt-colorswatch .tt-colorswatch-options a:nth-child(5) {
  background: #3bbe3e;
}

.tt-colorswatch .tt-colorswatch-options a:nth-child(5):hover {
  background: #5a9f5c;
}

.tt-colorswatch .tt-colorswatch-options a:nth-child(6) {
  background: #50bebd;
}

.tt-colorswatch .tt-colorswatch-options a:nth-child(6):hover {
  background: #6ea0a0;
}

.tt-colorswatch .tt-colorswatch-options a.active:before {
  display: block;
}

@media only screen and (max-width: 768px) {
  .tt-colorswatch {
    top: 205px;
  }
}

body:not(.touch) .tt-colorswatch .tt-colorswatch-btn:hover {
  background: #222222;
}