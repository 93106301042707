.appl{
    &-form{
        &__title{
            font-weight: 600;
            font-family: "Montserrat",sans-serif;
            color: #222;
            font-size: 18px;
            line-height: 1.2;
            margin: 0;
            padding: 0 0 12px;
        }
    }
}