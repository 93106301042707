@import "system/variables";
.list{
    text-align: left;
    margin: 30px auto 0;
    max-width: 620px;
    width: 100%;
     
    ul{
        padding-left: 20px;
        margin-top: 10px;
    }
    &-wrap{
        display: block; 
    }
    li{
        padding-left: 15px;
        padding-bottom: 7px;
        list-style: disc;
    }
}
.link{
    color: $brand;
    text-decoration: underline;
    &:hover{
        text-decoration: none;
    }
}