@import "system/variables";
/* Global */
html{
    position: relative;
}
body {
    // font-size: 18px;
    // font-family: $ff;
    // color: $text;
    // background: #fff;
    // line-height: 1.4;
}
p {
    // font-size: 18px;
    // line-height: 1.4;
}

a {
    font-size: inherit;
    font-weight: inherit;
}

img {
    max-width: 100%;
}
sup{
    font-size: 60%;
}
.p-b{
    padding-bottom: 40px;
}
.g-contact {
    form{
        width: 96%;
        margin: 0 auto;
        max-width: 700px;
    }
    .help-block{
        font-size: 16px;
    }
    .form-group {
        margin-bottom: 10px;
    }
    
    .form-control {
        height: 40px;
    }      
    textarea.form-control {
        min-height: 100px;
    }  
    &_wrap {
        width: 600px;
        max-width: 100%;
        border: 1px solid $brand;
        margin: 0 auto;
        padding: 40px 5% 20px;
    }
    &_header {
        color: $brand;
        text-align: center;
        text-transform: uppercase;
        font-size: 20px;
        margin-bottom: 40px;
    }
    &_btn {
        text-align: center;
        margin: 30px auto 10px;
        button {
            background: $brand;
            color: #fff;
            &:hover {
                color: #fff;
            }
        }
    }
    &_footer{
        text-align: center;
        font-size: 16px;
    }
    &_loader{
        width: 20px;
        height: 20px;
        margin: 10px auto;
        display: none;
        img{
            width: 100%;
        }
    }
}
.social{
    margin-top: 10px; 
    a{
        margin-right: 10px;
        display: inline-block;
        &:hover{
            opacity: 0.7;
        }
    }
}