/*** CALIBRI ***/
@font-face {
    font-family: 'CalibriBold';
    src: url('/web/fonts/calibri/Calibri-Bold.eot');
    src: url('/web/fonts/calibri/Calibri-Bold.eot?#iefix') format('embedded-opentype'),
        url('/web/fonts/calibri/Calibri-Bold.woff2') format('woff2'),
        url('/web/fonts/calibri/Calibri-Bold.woff') format('woff'),
        url('/web/fonts/calibri/Calibri-Bold.ttf') format('truetype'),
        url('/web/fonts/calibri/Calibri-Bold.svg#Calibri-Bold') format('svg');
        font-weight: normal;
        font-style: normal;
}

@font-face {
    font-family: 'CalibriLight';
    src: url('/web/fonts/calibri/Calibri-Light.eot');
    src: url('/web/fonts/calibri/Calibri-Light.eot?#iefix') format('embedded-opentype'),
        url('/web/fonts/calibri/Calibri-Light.woff2') format('woff2'),
        url('/web/fonts/calibri/Calibri-Light.woff') format('woff'),
        url('/web/fonts/calibri/Calibri-Light.ttf') format('truetype'),
        url('/web/fonts/calibri/Calibri-Light.svg#Calibri-Light') format('svg');
        font-weight: normal;
        font-style: normal;
}

@font-face {
    font-family: 'Calibri';
    src: url('/web/fonts/calibri/Calibri.eot');
    src: url('/web/fonts/calibri/Calibri.eot?#iefix') format('embedded-opentype'),
        url('/web/fonts/calibri/Calibri.woff2') format('woff2'),
        url('/web/fonts/calibri/Calibri.woff') format('woff'),
        url('/web/fonts/calibri/Calibri.ttf') format('truetype'),
        url('/web/fonts/calibri/Calibri.svg#Calibri') format('svg');
        font-weight: normal;
        font-style: normal;
}

/*** HELVETICA ***/
@font-face {
    font-family: 'HelveticaHeavy';
    src: url('/web/fonts/helvetica/HelveticaHeavy.eot');
    src: url('/web/fonts/helvetica/HelveticaHeavy.eot?#iefix') format('embedded-opentype'),
		url('/web/fonts/helvetica/HelveticaHeavy.woff') format('woff'),
		url('/web/fonts/helvetica/HelveticaHeavy.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'HelveticaBold';
    src: url('/web/fonts/helvetica/HelveticaBold.eot');
    src: url('/web/fonts/helvetica/HelveticaBold.eot?#iefix') format('embedded-opentype'),
		url('/web/fonts/helvetica/HelveticaBold.woff') format('woff'),
		url('/web/fonts/helvetica/HelveticaBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'HelveticaMedium';
    src: url('/web/fonts/helvetica/HelveticaMedium.eot');
    src: url('/web/fonts/helvetica/HelveticaMedium.eot?#iefix') format('embedded-opentype'),
		url('/web/fonts/helvetica/HelveticaMedium.woff') format('woff'),
		url('/web/fonts/helvetica/HelveticaMedium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'HelveticaRegular';
    src: url('/web/fonts/helvetica/HelveticaRegular.eot');
    src: url('/web/fonts/helvetica/HelveticaRegular.eot?#iefix') format('embedded-opentype'),
		url('/web/fonts/helvetica/HelveticaRegular.woff') format('woff'),
		url('/web/fonts/helvetica/HelveticaRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'HelveticaLight';
    src: url('/web/fonts/helvetica/HelveticaLight.eot');
    src: url('/web/fonts/helvetica/HelveticaLight.eot?#iefix') format('embedded-opentype'),
		url('/web/fonts/helvetica/HelveticaLight.woff') format('woff'),
		url('/web/fonts/helvetica/HelveticaLight.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


/**** AVENIR  *****/

@font-face {
    font-family: 'Avenir';
    src: url('/fonts/avenir/AvenirLTStd-Roman.eot');
    src: url('/fonts/avenir/AvenirLTStd-Roman.eot?#iefix') format('embedded-opentype'),
        url('/fonts/avenir/AvenirLTStd-Roman.woff2') format('woff2'),
        url('/fonts/avenir/AvenirLTStd-Roman.woff') format('woff'),
        url('/fonts/avenir/AvenirLTStd-Roman.ttf') format('truetype'),
        url('/fonts/avenir/AvenirLTStd-Roman.svg#AvenirLTStd-Roman') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir';
    src: url('/fonts/avenir/AvenirLTStd-Oblique.eot');
    src: url('/fonts/avenir/AvenirLTStd-Oblique.eot?#iefix') format('embedded-opentype'),
        url('/fonts/avenir/AvenirLTStd-Oblique.woff2') format('woff2'),
        url('/fonts/avenir/AvenirLTStd-Oblique.woff') format('woff'),
        url('/fonts/avenir/AvenirLTStd-Oblique.ttf') format('truetype'),
        url('/fonts/avenir/AvenirLTStd-Oblique.svg#AvenirLTStd-Oblique') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'AvenirBook';
    src: url('/fonts/avenir/AvenirLTStd-BookOblique.eot');
    src: url('/fonts/avenir/AvenirLTStd-BookOblique.eot?#iefix') format('embedded-opentype'),
        url('/fonts/avenir/AvenirLTStd-BookOblique.woff2') format('woff2'),
        url('/fonts/avenir/AvenirLTStd-BookOblique.woff') format('woff'),
        url('/fonts/avenir/AvenirLTStd-BookOblique.ttf') format('truetype'),
        url('/fonts/avenir/AvenirLTStd-BookOblique.svg#AvenirLTStd-BookOblique') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'AvenirBook';
    src: url('/fonts/avenir/AvenirLTStd-Book.eot');
    src: url('/fonts/avenir/AvenirLTStd-Book.eot?#iefix') format('embedded-opentype'),
        url('/fonts/avenir/AvenirLTStd-Book.woff2') format('woff2'),
        url('/fonts/avenir/AvenirLTStd-Book.woff') format('woff'),
        url('/fonts/avenir/AvenirLTStd-Book.ttf') format('truetype'),
        url('/fonts/avenir/AvenirLTStd-Book.svg#AvenirLTStd-Book') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'AvenirLight';
    src: url('/fonts/avenir/AvenirLTStd-Light.eot');
    src: url('/fonts/avenir/AvenirLTStd-Light.eot?#iefix') format('embedded-opentype'),
        url('/fonts/avenir/AvenirLTStd-Light.woff2') format('woff2'),
        url('/fonts/avenir/AvenirLTStd-Light.woff') format('woff'),
        url('/fonts/avenir/AvenirLTStd-Light.ttf') format('truetype'),
        url('/fonts/avenir/AvenirLTStd-Light.svg#AvenirLTStd-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}


@font-face {
    font-family: 'AvenirLight';
    src: url('/fonts/avenir/AvenirLTStd-LightOblique.eot');
    src: url('/fonts/avenir/AvenirLTStd-LightOblique.eot?#iefix') format('embedded-opentype'),
        url('/fonts/avenir/AvenirLTStd-LightOblique.woff2') format('woff2'),
        url('/fonts/avenir/AvenirLTStd-LightOblique.woff') format('woff'),
        url('/fonts/avenir/AvenirLTStd-LightOblique.ttf') format('truetype'),
        url('/fonts/avenir/AvenirLTStd-LightOblique.svg#AvenirLTStd-LightOblique') format('svg');
    font-weight: 300;
    font-style: italic;
}



@font-face {
    font-family: 'AvenirMedium';
    src: url('/fonts/avenir/AvenirLTStd-Medium.eot');
    src: url('/fonts/avenir/AvenirLTStd-Medium.eot?#iefix') format('embedded-opentype'),
        url('/fonts/avenir/AvenirLTStd-Medium.woff2') format('woff2'),
        url('/fonts/avenir/AvenirLTStd-Medium.woff') format('woff'),
        url('/fonts/avenir/AvenirLTStd-Medium.ttf') format('truetype'),
        url('/fonts/avenir/AvenirLTStd-Medium.svg#AvenirLTStd-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'AvenirMedium';
    src: url('/fonts/avenir/AvenirLTStd-MediumOblique.eot');
    src: url('/fonts/avenir/AvenirLTStd-MediumOblique.eot?#iefix') format('embedded-opentype'),
        url('/fonts/avenir/AvenirLTStd-MediumOblique.woff2') format('woff2'),
        url('/fonts/avenir/AvenirLTStd-MediumOblique.woff') format('woff'),
        url('/fonts/avenir/AvenirLTStd-MediumOblique.ttf') format('truetype'),
        url('/fonts/avenir/AvenirLTStd-MediumOblique.svg#AvenirLTStd-MediumOblique') format('svg');
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: 'AvenirBlack';
    src: url('/fonts/avenir/AvenirLTStd-Black.eot');
    src: url('/fonts/avenir/AvenirLTStd-Black.eot?#iefix') format('embedded-opentype'),
        url('/fonts/avenir/AvenirLTStd-Black.woff2') format('woff2'),
        url('/fonts/avenir/AvenirLTStd-Black.woff') format('woff'),
        url('/fonts/avenir/AvenirLTStd-Black.ttf') format('truetype'),
        url('/fonts/avenir/AvenirLTStd-Black.svg#AvenirLTStd-Black') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'AvenirBlack';
    src: url('/fonts/avenir/AvenirLTStd-BlackOblique.eot');
    src: url('/fonts/avenir/AvenirLTStd-BlackOblique.eot?#iefix') format('embedded-opentype'),
        url('/fonts/avenir/AvenirLTStd-BlackOblique.woff2') format('woff2'),
        url('/fonts/avenir/AvenirLTStd-BlackOblique.woff') format('woff'),
        url('/fonts/avenir/AvenirLTStd-BlackOblique.ttf') format('truetype'),
        url('/fonts/avenir/AvenirLTStd-BlackOblique.svg#AvenirLTStd-BlackOblique') format('svg');
    font-weight: 900;
    font-style: italic;
}
@font-face {
    font-family: 'AvenirHeavy';
    src: url('/fonts/avenir/AvenirLTStd-HeavyOblique.eot');
    src: url('/fonts/avenir/AvenirLTStd-HeavyOblique.eot?#iefix') format('embedded-opentype'),
        url('/fonts/avenir/AvenirLTStd-HeavyOblique.woff2') format('woff2'),
        url('/fonts/avenir/AvenirLTStd-HeavyOblique.woff') format('woff'),
        url('/fonts/avenir/AvenirLTStd-HeavyOblique.ttf') format('truetype'),
        url('/fonts/avenir/AvenirLTStd-HeavyOblique.svg#AvenirLTStd-HeavyOblique') format('svg');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'AvenirHeavy';
    src: url('/fonts/avenir/AvenirLTStd-Heavy.eot');
    src: url('/fonts/avenir/AvenirLTStd-Heavy.eot?#iefix') format('embedded-opentype'),
        url('/fonts/avenir/AvenirLTStd-Heavy.woff2') format('woff2'),
        url('/fonts/avenir/AvenirLTStd-Heavy.woff') format('woff'),
        url('/fonts/avenir/AvenirLTStd-Heavy.ttf') format('truetype'),
        url('/fonts/avenir/AvenirLTStd-Heavy.svg#AvenirLTStd-Heavy') format('svg');
    font-weight: 900;
    font-style: normal;
}


/***  AvenirNext Condensed ***/
@font-face {
    font-family: 'AvenirNextCondensedUltraLight';
    src: url('/fonts/avenirNextCondensed/AvenirNextLTPro-UltLtCn.eot');
    src: url('/fonts/avenirNextCondensed/AvenirNextLTPro-UltLtCn.eot?#iefix') format('embedded-opentype'),
        url('/fonts/avenirNextCondensed/AvenirNextLTPro-UltLtCn.woff2') format('woff2'),
        url('/fonts/avenirNextCondensed/AvenirNextLTPro-UltLtCn.woff') format('woff'),
        url('/fonts/avenirNextCondensed/AvenirNextLTPro-UltLtCn.ttf') format('truetype'),
        url('/fonts/avenirNextCondensed/AvenirNextLTPro-UltLtCn.svg#AvenirNextLTPro-UltLtCn') format('svg');
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: 'AvenirNextCondensedUltraLight';
    src: url('/fonts/avenirNextCondensed/AvenirNextLTPro-UltLtCnIt.eot');
    src: url('/fonts/avenirNextCondensed/AvenirNextLTPro-UltLtCnIt.eot?#iefix') format('embedded-opentype'),
        url('/fonts/avenirNextCondensed/AvenirNextLTPro-UltLtCnIt.woff2') format('woff2'),
        url('/fonts/avenirNextCondensed/AvenirNextLTPro-UltLtCnIt.woff') format('woff'),
        url('/fonts/avenirNextCondensed/AvenirNextLTPro-UltLtCnIt.ttf') format('truetype'),
        url('/fonts/avenirNextCondensed/AvenirNextLTPro-UltLtCnIt.svg#AvenirNextLTPro-UltLtCnIt') format('svg');
    font-weight: 200;
    font-style: italic;
}
@font-face {
    font-family: 'AvenirNextCondensed';
    src: url('/fonts/avenirNextCondensed/AvenirNextLTPro-DemiCnIt.eot');
    src: url('/fonts/avenirNextCondensed/AvenirNextLTPro-DemiCnIt.eot?#iefix') format('embedded-opentype'),
        url('/fonts/avenirNextCondensed/AvenirNextLTPro-DemiCnIt.woff2') format('woff2'),
        url('/fonts/avenirNextCondensed/AvenirNextLTPro-DemiCnIt.woff') format('woff'),
        url('/fonts/avenirNextCondensed/AvenirNextLTPro-DemiCnIt.ttf') format('truetype'),
        url('/fonts/avenirNextCondensed/AvenirNextLTPro-DemiCnIt.svg#AvenirNextLTPro-DemiCnIt') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'AvenirNextCondensed';
    src: url('/fonts/avenirNextCondensed/AvenirNextLTPro-CnIt.eot');
    src: url('/fonts/avenirNextCondensed/AvenirNextLTPro-CnIt.eot?#iefix') format('embedded-opentype'),
        url('/fonts/avenirNextCondensed/AvenirNextLTPro-CnIt.woff2') format('woff2'),
        url('/fonts/avenirNextCondensed/AvenirNextLTPro-CnIt.woff') format('woff'),
        url('/fonts/avenirNextCondensed/AvenirNextLTPro-CnIt.ttf') format('truetype'),
        url('/fonts/avenirNextCondensed/AvenirNextLTPro-CnIt.svg#AvenirNextLTPro-CnIt') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'AvenirNextCondensedHeavy';
    src: url('/fonts/avenirNextCondensed/AvenirNextLTPro-HeavyCnIt.eot');
    src: url('/fonts/avenirNextCondensed/AvenirNextLTPro-HeavyCnIt.eot?#iefix') format('embedded-opentype'),
        url('/fonts/avenirNextCondensed/AvenirNextLTPro-HeavyCnIt.woff2') format('woff2'),
        url('/fonts/avenirNextCondensed/AvenirNextLTPro-HeavyCnIt.woff') format('woff'),
        url('/fonts/avenirNextCondensed/AvenirNextLTPro-HeavyCnIt.ttf') format('truetype'),
        url('/fonts/avenirNextCondensed/AvenirNextLTPro-HeavyCnIt.svg#AvenirNextLTPro-HeavyCnIt') format('svg');
    font-weight: 900;
    font-style: italic;
}
@font-face {
    font-family: 'AvenirNextCondensedHeavy';
    src: url('/fonts/avenirNextCondensed/AvenirNextLTPro-HeavyCn.eot');
    src: url('/fonts/avenirNextCondensed/AvenirNextLTPro-HeavyCn.eot?#iefix') format('embedded-opentype'),
        url('/fonts/avenirNextCondensed/AvenirNextLTPro-HeavyCn.woff2') format('woff2'),
        url('/fonts/avenirNextCondensed/AvenirNextLTPro-HeavyCn.woff') format('woff'),
        url('/fonts/avenirNextCondensed/AvenirNextLTPro-HeavyCn.ttf') format('truetype'),
        url('/fonts/avenirNextCondensed/AvenirNextLTPro-HeavyCn.svg#AvenirNextLTPro-HeavyCn') format('svg');
    font-weight: 900;
    font-style: normal;
}


@font-face {
    font-family: 'AvenirNextCondensedBold';
    src: url('/fonts/avenirNextCondensed/AvenirNextLTPro-BoldCnIt.eot');
    src: url('/fonts/avenirNextCondensed/AvenirNextLTPro-BoldCnIt.eot?#iefix') format('embedded-opentype'),
        url('/fonts/avenirNextCondensed/AvenirNextLTPro-BoldCnIt.woff2') format('woff2'),
        url('/fonts/avenirNextCondensed/AvenirNextLTPro-BoldCnIt.woff') format('woff'),
        url('/fonts/avenirNextCondensed/AvenirNextLTPro-BoldCnIt.ttf') format('truetype'),
        url('/fonts/avenirNextCondensed/AvenirNextLTPro-BoldCnIt.svg#AvenirNextLTPro-BoldCnIt') format('svg');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'AvenirNextCondensedMedium';
    src: url('/fonts/avenirNextCondensed/AvenirNextLTPro-MediumCn.eot');
    src: url('/fonts/avenirNextCondensed/AvenirNextLTPro-MediumCn.eot?#iefix') format('embedded-opentype'),
        url('/fonts/avenirNextCondensed/AvenirNextLTPro-MediumCn.woff2') format('woff2'),
        url('/fonts/avenirNextCondensed/AvenirNextLTPro-MediumCn.woff') format('woff'),
        url('/fonts/avenirNextCondensed/AvenirNextLTPro-MediumCn.ttf') format('truetype'),
        url('/fonts/avenirNextCondensed/AvenirNextLTPro-MediumCn.svg#AvenirNextLTPro-MediumCn') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'AvenirNextCondensed';
    src: url('/fonts/avenirNextCondensed/AvenirNextLTPro-Cn.eot');
    src: url('/fonts/avenirNextCondensed/AvenirNextLTPro-Cn.eot?#iefix') format('embedded-opentype'),
        url('/fonts/avenirNextCondensed/AvenirNextLTPro-Cn.woff2') format('woff2'),
        url('/fonts/avenirNextCondensed/AvenirNextLTPro-Cn.woff') format('woff'),
        url('/fonts/avenirNextCondensed/AvenirNextLTPro-Cn.ttf') format('truetype'),
        url('/fonts/avenirNextCondensed/AvenirNextLTPro-Cn.svg#AvenirNextLTPro-Cn') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AvenirNextCondensedDemi';
    src: url('/fonts/avenirNextCondensed/AvenirNextLTPro-DemiCn.eot');
    src: url('/fonts/avenirNextCondensed/AvenirNextLTPro-DemiCn.eot?#iefix') format('embedded-opentype'),
        url('/fonts/avenirNextCondensed/AvenirNextLTPro-DemiCn.woff2') format('woff2'),
        url('/fonts/avenirNextCondensed/AvenirNextLTPro-DemiCn.woff') format('woff'),
        url('/fonts/avenirNextCondensed/AvenirNextLTPro-DemiCn.ttf') format('truetype'),
        url('/fonts/avenirNextCondensed/AvenirNextLTPro-DemiCn.svg#AvenirNextLTPro-DemiCn') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AvenirNextCondensedBold';
    src: url('/fonts/avenirNextCondensed/AvenirNextLTPro-BoldCn.eot');
    src: url('/fonts/avenirNextCondensed/AvenirNextLTPro-BoldCn.eot?#iefix') format('embedded-opentype'),
        url('/fonts/avenirNextCondensed/AvenirNextLTPro-BoldCn.woff2') format('woff2'),
        url('/fonts/avenirNextCondensed/AvenirNextLTPro-BoldCn.woff') format('woff'),
        url('/fonts/avenirNextCondensed/AvenirNextLTPro-BoldCn.ttf') format('truetype'),
        url('/fonts/avenirNextCondensed/AvenirNextLTPro-BoldCn.svg#AvenirNextLTPro-BoldCn') format('svg');
    font-weight: bold;
    font-style: normal;
}



@font-face {
    font-family: 'AvenirNextCondensedMedium';
    src: url('/fonts/avenirNextCondensed/AvenirNextLTPro-MediumCnIt.eot');
    src: url('/fonts/avenirNextCondensed/AvenirNextLTPro-MediumCnIt.eot?#iefix') format('embedded-opentype'),
        url('/fonts/avenirNextCondensed/AvenirNextLTPro-MediumCnIt.woff2') format('woff2'),
        url('/fonts/avenirNextCondensed/AvenirNextLTPro-MediumCnIt.woff') format('woff'),
        url('/fonts/avenirNextCondensed/AvenirNextLTPro-MediumCnIt.ttf') format('truetype'),
        url('/fonts/avenirNextCondensed/AvenirNextLTPro-MediumCnIt.svg#AvenirNextLTPro-MediumCnIt') format('svg');
    font-weight: 500;
    font-style: italic;
}

/***  AvenirNext  ***/

@font-face {
    font-family: 'AvenirNext';
    src: url('/fonts/avenirNext/AvenirNextLTPro-Regular.eot');
    src: url('/fonts/avenirNext/AvenirNextLTPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('/fonts/avenirNext/AvenirNextLTPro-Regular.woff2') format('woff2'),
        url('/fonts/avenirNext/AvenirNextLTPro-Regular.woff') format('woff'),
        url('/fonts/avenirNext/AvenirNextLTPro-Regular.ttf') format('truetype'),
        url('/fonts/avenirNext/AvenirNextLTPro-Regular.svg#AvenirNextLTPro-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AvenirNext';
    src: url('/fonts/avenirNext/AvenirNextLTPro-It.eot');
    src: url('/fonts/avenirNext/AvenirNextLTPro-It.eot?#iefix') format('embedded-opentype'),
        url('/fonts/avenirNext/AvenirNextLTPro-It.woff2') format('woff2'),
        url('/fonts/avenirNext/AvenirNextLTPro-It.woff') format('woff'),
        url('/fonts/avenirNext/AvenirNextLTPro-It.ttf') format('truetype'),
        url('/fonts/avenirNext/AvenirNextLTPro-It.svg#AvenirNextLTPro-It') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'AvenirNextMedium';
    src: url('/fonts/avenirNext/AvenirNext-Medium.eot');
    src: url('/fonts/avenirNext/AvenirNext-Medium.eot?#iefix') format('embedded-opentype'),
        url('/fonts/avenirNext/AvenirNext-Medium.woff2') format('woff2'),
        url('/fonts/avenirNext/AvenirNext-Medium.woff') format('woff'),
        url('/fonts/avenirNext/AvenirNext-Medium.ttf') format('truetype'),
        url('/fonts/avenirNext/AvenirNext-Medium.svg#AvenirNext-Medium') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AvenirNextDemi';
    src: url('/fonts/avenirNext/AvenirNextLTPro-Demi.eot');
    src: url('/fonts/avenirNext/AvenirNextLTPro-Demi.eot?#iefix') format('embedded-opentype'),
        url('/fonts/avenirNext/AvenirNextLTPro-Demi.woff2') format('woff2'),
        url('/fonts/avenirNext/AvenirNextLTPro-Demi.woff') format('woff'),
        url('/fonts/avenirNext/AvenirNextLTPro-Demi.ttf') format('truetype'),
        url('/fonts/avenirNext/AvenirNextLTPro-Demi.svg#AvenirNextLTPro-Demi') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AvenirNextDemi';
    src: url('/fonts/avenirNext/AvenirNextLTPro-DemiIt.eot');
    src: url('/fonts/avenirNext/AvenirNextLTPro-DemiIt.eot?#iefix') format('embedded-opentype'),
        url('/fonts/avenirNext/AvenirNextLTPro-DemiIt.woff2') format('woff2'),
        url('/fonts/avenirNext/AvenirNextLTPro-DemiIt.woff') format('woff'),
        url('/fonts/avenirNext/AvenirNextLTPro-DemiIt.ttf') format('truetype'),
        url('/fonts/avenirNext/AvenirNextLTPro-DemiIt.svg#AvenirNextLTPro-DemiIt') format('svg');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'AvenirNextBold';
    src: url('/fonts/avenirNext/AvenirNextLTPro-Bold.eot');
    src: url('/fonts/avenirNext/AvenirNextLTPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/avenirNext/AvenirNextLTPro-Bold.woff2') format('woff2'),
        url('/fonts/avenirNext/AvenirNextLTPro-Bold.woff') format('woff'),
        url('/fonts/avenirNext/AvenirNextLTPro-Bold.ttf') format('truetype'),
        url('/fonts/avenirNext/AvenirNextLTPro-Bold.svg#AvenirNextLTPro-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}




/****  Georgia  ****/

@font-face {
    font-family: 'GeorgiaBold';
    src: url('/fonts/georgia/Georgia-Bold.eot');
    src: url('/fonts/georgia/Georgia-Bold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/georgia/Georgia-Bold.woff2') format('woff2'),
        url('/fonts/georgia/Georgia-Bold.woff') format('woff'),
        url('/fonts/georgia/Georgia-Bold.ttf') format('truetype'),
        url('/fonts/georgia/Georgia-Bold.svg#Georgia-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Georgia';
    src: url('/fonts/georgia/Georgia.eot');
    src: url('/fonts/georgia/Georgia.eot?#iefix') format('embedded-opentype'),
        url('/fonts/georgia/Georgia.woff2') format('woff2'),
        url('/fonts/georgia/Georgia.woff') format('woff'),
        url('/fonts/georgia/Georgia.ttf') format('truetype'),
        url('/fonts/georgia/Georgia.svg#Georgia') format('svg');
    font-weight: normal;
    font-style: normal;
}
/*** MUSEO ***/

@font-face {
    font-family: 'Museo';
    src: url('/fonts/Museo/Museo-100.woff2') format('woff2'),
        url('/fonts/Museo/Museo-100.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'MuseoUltraLight';
    src: url('/fonts/Museo/Museo-100Italic.woff2') format('woff2'),
        url('/fonts/Museo/Museo-100Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}


@font-face {
    font-family: 'Museo';
    src: url('/fonts/Museo/Museo-300.woff2') format('woff2'),
        url('/fonts/Museo/Museo-300.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Museo';
    src: url('/fonts/Museo/Museo-300Italic.woff2') format('woff2'),
        url('/fonts/Museo/Museo-300Italic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Museo';
    src: url('/fonts/Museo/Museo-500Italic.woff2') format('woff2'),
        url('/fonts/Museo/Museo-500Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Museo';
    src: url('/fonts/Museo/Museo-500.woff2') format('woff2'),
        url('/fonts/Museo/Museo-500.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Museo';
    src: url('/fonts/Museo/Museo-700.woff2') format('woff2'),
        url('/fonts/Museo/Museo-700.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Museo';
    src: url('/fonts/Museo/Museo-700Italic.woff2') format('woff2'),
        url('/fonts/Museo/Museo-700Italic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: 'Museo';
    src: url('/fonts/Museo/Museo-900.woff2') format('woff2'),
        url('/fonts/Museo/Museo-900.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Museo';
    src: url('/fonts/Museo/Museo-900Italic.woff2') format('woff2'),
        url('/fonts/Museo/Museo-900Italic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
}


/*** MUSEO Sans ***/
@font-face {
    font-family: 'Museo Sans';
    src: url('/fonts/Museo/MuseoSans-100Italic.woff2') format('woff2'),
        url('/fonts/Museo/MuseoSans-100Italic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
}


@font-face {
    font-family: 'Museo Sans';
    src: url('/fonts/Museo/MuseoSans-700.woff2') format('woff2'),
        url('/fonts/Museo/MuseoSans-700.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Museo Sans';
    src: url('/fonts/Museo/MuseoSans-700Italic.woff2') format('woff2'),
        url('/fonts/Museo/MuseoSans-700Italic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Museo Sans';
    src: url('/fonts/Museo/MuseoSans-900Italic.woff2') format('woff2'),
        url('/fonts/Museo/MuseoSans-900Italic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Museo Sans';
    src: url('/fonts/Museo/MuseoSans-900.woff2') format('woff2'),
        url('/fonts/Museo/MuseoSans-900.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Museo Sans';
    src: url('/fonts/Museo/MuseoSans-300.woff2') format('woff2'),
        url('/fonts/Museo/MuseoSans-300.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Museo Sans';
    src: url('/fonts/Museo/MuseoSans-100.woff2') format('woff2'),
        url('/fonts/Museo/MuseoSans-100.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Museo Sans';
    src: url('/fonts/Museo/MuseoSans-500.woff2') format('woff2'),
        url('/fonts/Museo/MuseoSans-500.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Museo Sans';
    src: url('/fonts/Museo/MuseoSans-300Italic.woff2') format('woff2'),
        url('/fonts/Museo/MuseoSans-300Italic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Museo Sans';
    src: url('/fonts/Museo/MuseoSans-500Italic.woff2') format('woff2'),
        url('/fonts/Museo/MuseoSans-500Italic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

